import React from "react";
import { Exam, PSCExamMaster, CourseCategories } from "lakshya-shared";
import ExamEditorModule from "..";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { Modal } from "reactstrap";
import { app } from "../../..";

export default (props: any) => {
  const [instructionToDelete, setInstructionToDelete] = React.useState(-1);
  const exam: Exam = props.exam;
  const module: ExamEditorModule = props.module;

  return (
    <>
      <div className="exam-catalogue-wrapper container-fluid px-5 py-3">
        <div className="row">
          <div className="col-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Availability & Pricing</h5>

                <div className="form-group mt-4">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      checked={exam.isVisible}
                      onChange={(e) =>
                        module.controller.updateExam(
                          "isVisible",
                          e.target.checked
                        )
                      }
                      className="custom-control-input"
                      id="visibilityCheck"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="visibilityCheck"
                    >
                      {exam.isVisible === true
                        ? "This exam is enabled for public visibility"
                        : "Public visibility has turned off for this exam"}
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="exam-description">Publish Date & Time</label>
                  <div>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Date"
                        format="ll"
                        value={moment(exam.publishDateTimeInMs)}
                        onChange={(dt) =>
                          module.controller.updateExam(
                            "publishDateTimeInMs",
                            dt.valueOf()
                          )
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Time"
                        value={moment(exam.publishDateTimeInMs)}
                        onChange={(dt) =>
                          module.controller.updateExam(
                            "publishDateTimeInMs",
                            dt.valueOf()
                          )
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="form-group">
                  <label className="">Category</label>
                  <select
                    value={exam.examCategoryKey}
                    onChange={(e) =>
                      module.controller.updateExam(
                        "examCategoryKey",
                        e.target.value
                      )
                    }
                    className="form-control"
                  >
                    <option value="">Please choose an option</option>
                    {CourseCategories.sort((a, b) =>
                      a.category > b.category ? 1 : -1
                    ).map((category, index) => {
                      return (
                        <option key={`${index}-${category.key}`}>
                          {category.category}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label className="">Exam Course Type</label>
                  <select
                    value={exam.examCourseTypeKey}
                    onChange={(e) =>
                      module.controller.updateExam(
                        "examCourseTypeKey",
                        e.target.value
                      )
                    }
                    className="form-control"
                  >
                    <option value="">Please choose an option</option>
                    {PSCExamMaster.sort((a, b) =>
                      a.label > b.label ? 1 : -1
                    ).map((master, index) => {
                      return (
                        <option value={master.value} key={`${index}-${master.value}`}>
                          {master.label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <hr />

                {exam.examType === "freestyle" ? (
                  <>
                    <div className="form-group form-check">
                      <input
                        checked={exam.showInRecentExams}
                        onChange={(e) =>
                          module.controller.updateExam(
                            "showInRecentExams",
                            e.target.checked
                          )
                        }
                        type="checkbox"
                        className="form-check-input"
                        id="recent-exam-check"
                      />
                      <label
                        className="form-check-label"
                        style={{ cursor: "pointer", userSelect: "none" }}
                        htmlFor="recent-exam-check"
                      >
                        Show in Recent Exam
                      </label>
                    </div>
                  </>
                ) : null}

                {exam.examType === "freestyle" &&
                exam.showInRecentExams === false ? (
                  <>
                    <div className="alert alert-warning" role="alert">
                      <h4 className="alert-heading">Warning!</h4>
                      <p>
                        This exam won't be shown in recent tab. If this is
                        intentional, you can ignore this warning.
                      </p>
                      <hr />
                      <p className="mb-0">
                        To publish among recent exam, please enable the checkbox
                        above
                      </p>
                    </div>
                    <hr />
                  </>
                ) : null}

                <div className="form-group">
                  <label htmlFor="rateCardInput">Rate Card</label>
                  <select
                    value={exam.masterSubscription && (exam.masterSubscription as any)._id}
                    onChange={(e) => {
                      module.controller.updateExam(
                        "masterSubscription",
                        e.target.value
                      );
                    }}
                    className="form-control"
                    id="rateCardInput"
                  >
                    <option value="not-selected">
                      Please choose a rate card
                    </option>
                    {app.modules.Subscription.getState().master.map((s, i) => (
                      <option
                        key={i}
                        value={(s as any)._id}
                      >{`${s.title} (INR ${s.netAmount})`}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <h5 className="card-title">Exam Settings</h5>
                <label>Marks</label>
                <div className="mark-wrapper">
                  <div className="form-group pr-4">
                    <label htmlFor="correct-answer-score">Correct Answer</label>
                    <input
                      value={exam.correctAnswerScore}
                      onChange={(e) =>
                        module.controller.updateExam(
                          "correctAnswerScore",
                          e.target.value
                        )
                      }
                      type="number"
                      className="form-control"
                      id="correct-answer-score"
                    />
                  </div>
                  <div className="form-group pr-4">
                    <label htmlFor="wrong-answer-score">Wrong Answer</label>
                    <input
                      value={exam.wrongAnswerScore}
                      onChange={(e) =>
                        module.controller.updateExam(
                          "wrongAnswerScore",
                          e.target.value
                        )
                      }
                      type="number"
                      className="form-control"
                      id="correct-answer-score"
                    />
                  </div>
                  <div className="form-group pr-4">
                    <label htmlFor="unattempted-question-score">
                      Unattempted
                    </label>
                    <input
                      value={exam.unAnsweredScore}
                      onChange={(e) =>
                        module.controller.updateExam(
                          "unAnsweredScore",
                          e.target.value
                        )
                      }
                      type="number"
                      className="form-control"
                      id="unattempted-question-score"
                    />
                  </div>
                </div>

                <hr />

                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      checked={exam.isSticky}
                      onChange={(e) =>
                        module.controller.updateExam(
                          "isSticky",
                          e.target.checked
                        )
                      }
                      className="custom-control-input"
                      id="stickySwitch"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="stickySwitch"
                    >
                      {exam.isSticky === true
                        ? "Stick mode activated"
                        : "Sticky mode not activated"}
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="">Type</label>
                  <select
                    value={exam.examType}
                    onChange={(e) =>
                      module.controller.updateExam("examType", e.target.value)
                    }
                    className="form-control"
                  >
                    <option value="quiz">Quiz</option>
                    <option value="freestyle">Regular (Freestyle)</option>
                  </select>
                  {exam.examType === "quiz" ? (
                    <small className="form-text text-muted">
                      Questions will be randomised in quiz
                    </small>
                  ) : null}
                </div>

                <div className="form-group">
                  <label className="">Attempt Lock</label>
                  <select
                    value={exam.maxNumberOfAttempts}
                    onChange={(e) =>
                      module.controller.updateExam(
                        "maxNumberOfAttempts",
                        e.target.value
                      )
                    }
                    className="form-control"
                  >
                    <option value={-1}>Disabled</option>
                    <option value={1}>1 Attempt</option>
                  </select>
                  {exam.examType === "quiz" ? (
                    <small className="form-text text-muted">
                      Questions will be randomised in quiz
                    </small>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <h5 className="card-title">Basic Information</h5>
                <label className=" mt-3">Weightage</label>
                <div className="mark-wrapper">
                  <div className="form-group pr-4">
                    <label htmlFor="total-marks-input">Total Marks</label>
                    <input
                      value={exam.totalMarks}
                      onChange={(e) =>
                        module.controller.updateExam(
                          "totalMarks",
                          parseInt(e.target.value)
                        )
                      }
                      type="number"
                      className="form-control"
                      id="total-marks-input"
                    />
                  </div>
                  <div className="form-group pr-4">
                    <label htmlFor="question-count">No. of Questions</label>
                    <input
                      value={exam.totalQuestions}
                      onChange={(e) =>
                        module.controller.updateExam(
                          "totalQuestions",
                          parseInt(e.target.value)
                        )
                      }
                      type="number"
                      className="form-control"
                      id="question-count"
                    />
                  </div>
                  <div className="form-group pr-4">
                    <label htmlFor="duration-input">
                      Duration (in Minutes)
                    </label>
                    <input
                      value={exam.examDurationInMinutes}
                      onChange={(e) =>
                        module.controller.updateExam(
                          "examDurationInMinutes",
                          parseInt(e.target.value)
                        )
                      }
                      type="number"
                      className="form-control"
                      id="duration-input"
                    />
                  </div>
                </div>

                <hr />

                <div className="form-group">
                  <label
                    className="font-weight-bold"
                    htmlFor="exam-description"
                  >
                    Description
                  </label>
                  <textarea
                    placeholder="Give a brief description on this exam"
                    className="form-control"
                    id="exam-description"
                    value={exam.description}
                    onChange={(e) =>
                      module.controller.updateExam(
                        "description",
                        e.target.value
                      )
                    }
                    rows={3}
                  ></textarea>
                </div>

                <hr />

                <label className="">Exam Instructions</label>

                {exam.instructions.map((instruction, index) => {
                  return (
                    <div key={index} className="input-group mb-3">
                      <input
                        type="text"
                        value={instruction}
                        onChange={(e) => {
                          module.controller.updateExam(
                            "instructions",
                            exam.instructions.map((data, innerIndex) => {
                              if (innerIndex === index) {
                                return e.target.value;
                              }
                              return data;
                            })
                          );
                        }}
                        className="form-control"
                        placeholder="Instruction goes here..."
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                      />
                      <div className="input-group-append">
                        <button
                          onClick={() => {
                            setInstructionToDelete(index);
                          }}
                          className="btn btn-outline-danger"
                          type="button"
                          id="button-addon2"
                        >
                          <i className="fas fa-minus-circle"></i>
                        </button>
                      </div>
                    </div>
                  );
                })}

                <div>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                      module.controller.updateExam("instructions", [
                        ...exam.instructions,
                        "",
                      ])
                    }
                  >
                    <i className="fas fa-plus-circle"></i> Add Instruction
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       {/* @ts-ignore */}
      <Modal
        toggle={() => setInstructionToDelete(-1)}
        isOpen={instructionToDelete > -1}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Remove Instruction</h5>
            <button
              type="button"
              className="close"
              onClick={() => setInstructionToDelete(-1)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p
              style={{ overflowWrap: "break-word" }}
            >{`Do you want to remove the instruction '${exam.instructions[instructionToDelete]}'?`}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                module.controller.updateExam(
                  "instructions",
                  exam.instructions.filter((data, innerIndex) => {
                    if (innerIndex === instructionToDelete) {
                      return false;
                    }
                    return true;
                  })
                );
                setInstructionToDelete(-1);
              }}
            >
              Yes, Remove
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setInstructionToDelete(-1)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
