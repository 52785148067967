import React, { Component } from "react";
import ReactTable, { Filter } from "react-table";
import { Link } from "react-router-dom";
import moment from "moment";
import ExamModule from "..";

const TableFilter = (propertyKey: string) => (filter: Filter, row: any, column: any): any => {
  if (row._original[propertyKey]) {
    try {
      return row._original[propertyKey].trim().toLowerCase().indexOf(filter.value.trim().toLowerCase()) > -1;
    } catch (e) {
      console.error(e);
    }
  }
  return false;
}

export default class ExamListing extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      data: [],
    };
  }

  convertExamToTableFriendlyFormat = (exam: any) => {
    return {
      ...exam,
      createdAt: moment(exam.createdAt).format("ll"),
    };
  };

  _handleFetchExams = () => {
    const currentTab = this.props.currentTab;
    const module: ExamModule = this.props.module;
    module.services
      .getAllExam(currentTab === "all" ? null : currentTab)
      .then((exams: any) => {
        this.setState({
          data: exams.map((exam: any) =>
            this.convertExamToTableFriendlyFormat(exam)
          ),
        });
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching course failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  };

  componentDidMount() {
    this._handleFetchExams();
  }

  render() {
    const data = this.state.data;
    return (
      <>
        <div className="">
          <div className="pt-3">
            <ReactTable
              defaultPageSize={10}
              minRows={3}
              data={data}
              columns={[
                {
                  Header: "Exams",
                  accessor: "title",
                  filterable: true,
                  filterMethod: TableFilter('title'),
                  Cell: (row) => (
                    <Link
                      className="text-primary"
                      rel="noopener noreferrer"
                      to={`/admin/exams/editor/${row.original._id}`}
                    >
                      {row.value}
                    </Link>
                  ),
                },
                {
                  Header: "Status",
                  accessor: "isPublished",
                  Cell: (row) => {
                    const publishMessage: string = row.original.publishMessage;
                    const hasMessage: boolean =
                      publishMessage !== null && publishMessage !== "";
                    return (
                      <span
                        className={`${
                          row.value === true
                            ? "text-success"
                            : `${
                                hasMessage ? "text-warning" : "text-danger"
                              } font-weight-bold`
                        }`}
                      >
                        {row.value === true
                          ? "Published"
                          : hasMessage === true
                          ? publishMessage
                          : "Unpublished"}
                      </span>
                    );
                  },
                },
                {
                  Header: "Date Created",
                  accessor: "createdAt",
                },
                {
                  Header: "Template Type",
                  accessor: "templateId",
                  className: "lh-110 text-capitalize",
                },
              ]}
            />
          </div>
        </div>
      </>
    );
  }
}
