import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default (props: any) => {
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();
  const onOtpVerification = props.onOtpVerification;
  const setStep = props.setStep;
  const resendOtp = props.resendOtp;

  return (
    <>
      <div style={{ flex: 1 }} className="pt-5 px-2 px-sm-4">
        <div className="text-center">
          <h6 className="text-secondary">{t("Step 3 - Account Recovery")}</h6>
          <h5> {t("Enter Verification Code")}</h5>
          <hr />
        </div>
        <label>
          {t(
            'If "9442572358" matches the phone number on your account, you will get an OTP'
          )}
        </label>
        <div className="pt-3">
          <form onSubmit={handleSubmit(onOtpVerification)}>
            <div className="px-4">
              <span className="text-muted" style={{ fontSize: 14 }}>
                {t("Enter the OTP from your mobile number")}
              </span>
              <div className="form-group pt-3">
                <input
                  name="otp"
                  ref={register({ required: true })}
                  type="text"
                  className="form-control"
                  id="opt-input"
                  placeholder="OTP"
                />
                {errors.otp && (
                  <p className="text-danger" id="otp-error">
                    {t("OTP is Required")}
                  </p>
                )}
              </div>
            </div>
            <div>
              <button
                id="resend-otp"
                onClick={resendOtp}
                type="button"
                className="btn btn-link px-0 mt-4"
              >
                {t("Didn't get the code")}
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() => setStep("reoveryOptionSelector")}
                className="btn btn-link px-0 text-left"
              >
                {t("Use a different verification option")}
              </button>
            </div>
            <div className="text-right pt-3">
              <button
                type="button"
                onClick={() => setStep("reoveryOptionSelector")}
                className="btn btn-secondary px-4 mr-3"
              >
                {t("Cancel")}
              </button>
              <button
                type="submit"
                id="otp-verification"
                className="btn btn-info px-4 px-xl-5"
              >
                {t("Next")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
