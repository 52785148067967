import React from "react";
import { Modal } from "reactstrap";
import filesize from "filesize";
import FileUploadModule from "../index";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { app } from "./../../../index";

type PropType = {
  fileName: string;
  fileSizeInBytes: number;
  fileId: string;
  onDelete?: () => void;
  clickable?: boolean;
  enableReplace?: boolean;
  accept?: string;
  accessMode?: "public" | "private";
  onSuccess?: (uploadedFiles: any[]) => void;
  pdfUri?: string;
  enableDownload?: boolean;
};

export default class Dropzone extends React.Component<
  Partial<ViewComponentPropType<FileUploadModule>> & PropType,
  any
> {
  fileInputRef = React.createRef<HTMLInputElement>();

  constructor(props: any) {
    super(props);
    this.state = {
      deleteConfirmationActive: false,
      isDeleting: false,
      isVideoUploaderActive: false,
      progress: 0,
      progressMessage: "Uploading...",
      isMissingPdf: false,
      actionEnabled: true,
      queueItem: null,
      asset: null
    };

    this.handleFileInputChange = this.handleFileInputChange.bind(this);
    this.handleCheckIsPdfMissing = this.handleCheckIsPdfMissing.bind(this);
    this.handleConvertPdfToImage = this.handleConvertPdfToImage.bind(this);
    this.handleStartProcessing = this.handleStartProcessing.bind(this);
    this.handleCheckIsPdfAddedToQueue = this.handleCheckIsPdfAddedToQueue.bind(this);

  }

  removeFile = () => {
    const { fileId, onDelete } = this.props;
    this.setState(
      {
        isDeleting: true,
      },
      () => {
        this.props.module.services
          .deleteAsset(fileId)
          .then((value) => {
            this.setState(
              {
                isDeleting: false,
              },
              () => {
                this.props.module.toast.show({
                  message: "File deleted successfully",
                });
                onDelete && onDelete();
              }
            );
          })
          .catch((err) => {
            this.props.module.showError(
              "Failed to delete asset",
              "Process Failed",
              true
            );
          });
      }
    );
  };

  handleCheckIsPdfMissing() {
    this.props.module.services
      .checkPdfMissing(this.props.fileId)
      .then((response: any) => {
        if (response.fileMissing === true) {
          this.setState({
            isMissingPdf: true,
          });
        }
      })
      .catch(() => {});
  }

  handleCheckIsPdfAddedToQueue() {
    this.props.module.services
      .getQueueItemByFileId(this.props.fileId)
      .then((response: any) => {
        this.setState({
          queueItem: response
      })
      })
      .catch(() => {});
  }


  handleConvertPdfToImage() {
    this.props.module.services
      .convertPdftoImage(this.props.fileId, this.props.pdfUri)
      .then((response: any) => {
        console.log("pdf converted succesfully");
      })
      .catch(() => {});
  }

  componentDidMount() {
    if (this.props.enableDownload === true) {
      this.handleCheckIsPdfMissing();
      this.handleCheckIsPdfAddedToQueue();
      this.props.module.services.getAssetById(this.props.fileId)
      .then((asset) => {
          this.setState({
              asset,
              hasInitialized: true
          })
      })
      .catch((err) => {
          // Do nothing
  
          console.error(err);
      })
    }
   
  }

  handleFileRemoveClick = () => {
    this.setState({
      deleteConfirmationActive: true,
    });
  };

  handleStartProcessing = () => {
    app.modules.FileUploader.services.addToQueue(
      this.props.fileId,
      this.props.fileName,
      true,
      "pdf"
    );
  };

  toggleConfirmationModal = () =>
    this.setState({
      deleteConfirmationActive: !this.state.deleteConfirmationActive,
    });

  getFileIconClass = (fileName: string) => {
    let defaultClass = "fas fa-file";
    fileName = fileName?.toLowerCase().trim();
    if (fileName?.endsWith(".pdf")) {
      defaultClass = "fas fa-file-pdf";
    } else if (fileName?.endsWith(".mp4")) {
      defaultClass = "fas fa-file-video";
    }

    return defaultClass;
  };

  handleFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.handleFile(this.fileInputRef.current.files);
    this.fileInputRef.current.value = "";
  }

  handleFile(files: FileList) {
    const module: FileUploadModule = this.props.module;
    if (files && files.length > 0) {
      this.setState({
        isVideoUploaderActive: true,
      });
      const formData = new FormData();
      for (var i = 0; i < files.length; i++) {
        formData.append(`file_${i}`, files[i]);
      }

      var progress = 0;
      var progressMessage = "Uploading...";

      module
        .getServiceProvider("Main")
        .put(`/api/assets/replace/${this.props.fileId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

          onUploadProgress: (progressEvent) => {
            progress = (progressEvent.loaded / progressEvent.total) * 100;
            progressMessage = progress > 95 ? "Processing..." : "Uploading...";

            this.setState({
              progress,
              progressMessage,
            });
          },
        })
        .then((response) => {
          this.setState(
            {
              isVideoUploaderActive: false,
            },
            () => this.props.onSuccess && this.props.onSuccess(response.data)
          );
        })
        .catch((err) => {
          // Handle error
          console.error(err);
          this.setState({
            isVideoUploaderActive: false,
          });
        });
    }
  }

  toggleVideoUploader = () =>
    this.setState({ isVideoUploaderActive: !this.state.isVideoUploaderActive });

  render() {
    let { fileSizeInBytes, clickable } = this.props;
    let fileSizeStr = "";
    if (fileSizeInBytes && parseFloat(fileSizeInBytes as any) > 0) {
      fileSizeStr = filesize(this.props.fileSizeInBytes, {});
    }
    return (
      <>
        {/* @ts-ignore */}
        <Modal
          backdrop={this.state.isDeleting === true ? "static" : undefined}
          keyboard={this.state.isDeleting === true ? false : undefined}
          toggle={this.toggleConfirmationModal}
          isOpen={this.state.deleteConfirmationActive}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Asset</h5>
              {this.state.isDeleting === false ? (
                <button
                  onClick={this.toggleConfirmationModal}
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              ) : null}
            </div>
            <div className="modal-body">
              <p>{`This action cannot be undone. Do you really want to delete this file (${
                this.props.fileName
              }${fileSizeStr !== "" ? " - " + fileSizeStr : ""})?`}</p>
            </div>
            <div className="modal-footer">
              <button
                disabled={this.state.isDeleting}
                type="button"
                className="btn btn-danger"
                onClick={this.removeFile}
              >
                {this.state.isDeleting === true ? (
                  <span>
                    <i className="fas fa-circle-notch fa-spin"></i> Deleting...
                  </span>
                ) : (
                  "Confirm Delete"
                )}
              </button>
              <button
                disabled={this.state.isDeleting}
                type="button"
                className="btn btn-default"
                onClick={this.toggleConfirmationModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
          {/* @ts-ignore */}
        <Modal
          backdrop="static"
          keyboard={false}
          toggle={this.toggleVideoUploader}
          isOpen={this.state.isVideoUploaderActive}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.state.progressMessage}</h5>
              <button className="cancel-button-wrapper">
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{ width: `${this.state.progress}%` }}
                ></div>
              </div>
            </div>
          </div>
        </Modal>
        <div className="note-component-wrapper px-3 py-2 mt-3">
          {clickable ? (
            <a href="/" className="pdf-button-wrapper">
              <i
                className={`${this.getFileIconClass(this.props.fileName)} pr-2`}
              ></i>
              {this.props.fileName}
              {fileSizeStr !== "" ? ` - ${fileSizeStr}` : ""}
            </a>
          ) : (
            <span className="pdf-button-wrapper">
              <i
                className={`${this.getFileIconClass(this.props.fileName)} pr-2`}
              ></i>
              {this.props.fileName}
              {fileSizeStr !== "" ? ` - ${fileSizeStr}` : ""}
            </span>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {this.props.enableReplace === true ? (
              // <button
              //   onClick={this.handleFileRemoveClick}
              // >
              //   Update
              // </button>
              <label
                className="remove-file-button-wrapper mr-2 mb-0"
                style={{ cursor: "pointer" }}
              >
                <input
                  type="file"
                  accept={this.props.accept}
                  onChange={this.handleFileInputChange}
                  ref={this.fileInputRef}
                  multiple={false}
                  style={{ display: "none" }}
                />
                Replace
              </label>
            ) : null}
            {this.props.enableDownload === true && this.state.asset ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {this.state.asset.hasConvertedtoImage === true ? (
                  <label style={{color: "#155724",paddingRight: 15, fontSize: 12}}>Ready</label>
                ): (
                  this.state.queueItem ? (
                    <label style={{ paddingRight: 15, fontSize: 12}}>In queue</label>
                  ) : (
                    <button
                    style={{ fontSize: 12 }}
                    className="remove-file-button-wrapper mr-2"
                    onClick={() => this.handleStartProcessing()}
                  >
                    Add to queue
                  </button>
                  )
                 
                )}
               
                {/* <button
                  className="remove-file-button-wrapper mr-2"
                  onClick={() => this.handleConvertPdfToImage()}
                >
                  C
                </button> */}
                {this.state.isMissingPdf === true ? (
                  <i
                    style={{
                      marginRight: 10,
                      paddingTop: 3,
                      color: "red",
                      fontSize: 15,
                    }}
                    className="fas fa-exclamation-circle"
                  ></i>
                ) : null}
                <div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={this.props.pdfUri}
                    className="remove-file-button-wrapper mr-2"
                  >
                    <i className="fas fa-download"></i>
                  </a>
                </div>
              </div>
            ) : null}

            <button
              onClick={this.handleFileRemoveClick}
              className="remove-file-button-wrapper"
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </>
    );
  }
}
