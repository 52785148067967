import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import { app } from "./../../../";

function NavLink(props: any) {
  const selected: boolean = props.currentPathName.startsWith(props.to);
  return (
    <li className={`${selected === true ? "selected" : ""} px-3`}>
      <Link className="py-3" to={props.to}>
        <i className={`${props.icon} pr-3`} style={{ width: 30 }}></i>
        {props.label}
      </Link>
    </li>
  );
}

export default (props: any) => {
  const user: any = props.user;
  const module: any = props.module;
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const toggleLogoutModalOpen = () => setIsLogoutModalOpen(!isLogoutModalOpen);
  const NotificationCounter = app.modules.Notification.views.NotificationCount;

  const isStaff = React.useMemo(() => {
    let value = false;
    if (user) {
      value =
        user.roles?.includes("staff");
    }
    return value;
  }, [user]);

  const isMarketingAccount = React.useMemo(() => {
    let value = false;
    if (user) {
      value =
        user.roles?.includes("marketing");
    }
    return value;
  }, [user]);

  return (
    <>
      <div className="col-auto side-navbar-wrapper pt-5 px-0 overflow-auto">
        <div className="profile-wrapper">
          <div>
            <i className="fas fa-user p-3"></i>
          </div>
          <h6 className="pt-2 mb-0">{user.name}</h6>
          <label>Administrator</label>
          <button
            type="button"
            onClick={toggleLogoutModalOpen}
            className="btn btn-link logout-button-wrapper"
          >
            Logout
          </button>
        </div>
        <div className="nav-bar-wrapper pt-5">
          <ul className="flex-column">
            <a
              className="link"
              href="https://dashboard.lakshyakerala.app/admin/v2/users"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                style={{ marginRight: 13.6 }}
                className="fas fa-user-shield"
              ></i>
              V2 Dashboard
            </a>
            <NotificationCounter>
              {(count: number) => (
                <NavLink
                  to="/admin/notifications"
                  label={`Notifications${count > 0 ? ` (${count})` : ""}`}
                  icon="fas fa-bell"
                  currentPathName={props.currentPathName}
                />
              )}
            </NotificationCounter>

            {isStaff === false && isMarketingAccount === false ? (
              <>
                <li>
                  <hr />
                </li>
                <NavLink
                  to="/admin/subscriptions"
                  label="Subscriptions"
                  icon="fas fa-rupee-sign"
                  currentPathName={props.currentPathName}
                />
                <li>
                  <hr />
                </li>
              </>
            ) : null}
            <NavLink
              to="/admin/videos"
              label="Lecture Videos"
              icon="fas fa-video"
              currentPathName={props.currentPathName}
            />
            {!isStaff && !isMarketingAccount ? (
              <NavLink
                to="/admin/custom-lectures"
                label="Custom Lecture Collection"
                icon="fas fa-burn"
                currentPathName={props.currentPathName}
              />
            ) : null}
            <NavLink
              to="/admin/exams"
              label="Exams"
              icon="fas fa-clipboard-check"
              currentPathName={props.currentPathName}
            />
            <li>
              <hr />
            </li>
            {!isStaff ? (
              <NavLink
                to="/admin/courses"
                label="Courses"
                icon="fas fa-book"
                currentPathName={props.currentPathName}
              />
            ) : null}
             <NavLink
                  to="/admin/rank-files"
                  label="Rank Files"
                  icon="fas fa-file-archive"
                  currentPathName={props.currentPathName}
                />
            {!isMarketingAccount ? (
              <>
                <NavLink
                  to="/admin/current-affairs"
                  label="Current Affairs"
                  icon="fas fa-newspaper"
                  currentPathName={props.currentPathName}
                />
                <li>
                  <hr />
                </li>
              </>
            ) : null}
            {!isStaff ? (
              <>
                {!isMarketingAccount ? (
                  <NavLink
                    to="/admin/lakshya/students"
                    label="Lakshya Students"
                    icon="fas fa-user-graduate"
                    currentPathName={props.currentPathName}
                  />
                ) : null}
                <NavLink
                  to="/admin/users"
                  label="Users"
                  icon="fas fa-users"
                  currentPathName={props.currentPathName}
                />
                <NavLink
                  to="/admin/orders"
                  label="Orders"
                  icon="fas fa-chart-line"
                  currentPathName={props.currentPathName}
                />
                {!isMarketingAccount ? (
                  <NavLink
                    to="/admin/winners"
                    label="Winners"
                    icon="fas fa-trophy"
                    currentPathName={props.currentPathName}
                  />
                ) : null}
                <NavLink
                  to="/admin/coupons"
                  label="Coupons"
                  icon="fas fa-tags"
                  currentPathName={props.currentPathName}
                />
                {!isMarketingAccount ? (
                  <NavLink
                    to="/admin/meeting-links"
                    label="Meeting Links"
                    icon="fas fa-link"
                    currentPathName={props.currentPathName}
                  />
                ) : null}
                <li>
                  <hr />
                </li>
                {!isMarketingAccount ? (
                  <>
                   
                    <NavLink
                      to="/admin/pending-pdf"
                      label="Pending PDF"
                      icon="fas fa-microchip"
                      currentPathName={props.currentPathName}
                    />
                  </>
                ) : null}
              </>
            ) : null}
            {!isMarketingAccount ? (
              <NavLink
                to="/admin/process/queue"
                label="Process Queue"
                icon="fas fa-microchip"
                currentPathName={props.currentPathName}
              />
            ):null}
           


            {/* <NavLink
              to="/admin/discussions"
              label="Discussions"
              icon="fas fa-comments"
              currentPathName={props.currentPathName}
            /> */}
          </ul>
        </div>
      </div>
      {/* @ts-ignore */}
      <Modal
        toggle={toggleLogoutModalOpen}
        backdrop="static"
        keyboard={false}
        isOpen={isLogoutModalOpen}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Logout Confirmation</h5>
            <button
              disabled={isLoggingOut}
              type="button"
              className="close"
              onClick={toggleLogoutModalOpen}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="alert alert-warning">
              Are you sure want to logout?
            </div>
          </div>
          <div className="modal-footer">
            <button
              disabled={isLoggingOut}
              type="button"
              className="btn btn-default"
              onClick={toggleLogoutModalOpen}
            >
              Cancel
            </button>
            <button
              disabled={isLoggingOut}
              onClick={() => {
                setIsLoggingOut(true);
                app.modules.Auth.services
                  .logout()
                  .then(() => {
                    toggleLogoutModalOpen();
                    module.setCurrentUser(false);
                  })
                  .catch((err) => {
                    module.showError(
                      err.message || "Unknown error",
                      "Logout Failed",
                      true
                    );
                  });
              }}
              type="submit"
              className="btn btn-primary"
            >
              {isLoggingOut === true ? "Signing off..." : "Log Out"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
