import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import QueueManagerView from "./QueueManager.view";
import Dropzone from "./components/Dropzone";
import FileView from "./components/FileView";
import FileProcessor from "./components/FileProcessor";

export type StateType = {};

export default class FileUploadModule extends ArkModule<
  StateType,
  "Main" | "AdminProc"
> {
  constructor() {
    super("FileUploadModule");

    this.useConnect(connect);

    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          default: {
            return state;
          }
        }
      };
    };

    this.main = () => {};
  }

  services = {
    getProcessStatus: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("AdminProc")
          .get("/api/admin/assets/process/video/queue/state")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getQueueItems: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/admin/assets/process/video/queue/items")
          .then((response) => {
            this.services
              .getProcessStatus()
              .then((statusResponse: any) => {
                resolve(
                  Object.assign({}, response.data, {
                    state: statusResponse.state,
                  })
                );
              })
              .catch((err) => {
                throw err;
              });
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getQueueItemByFileId: (fileId:string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/assets/process/video/queue-items/${fileId}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    addToQueue: (
      fileId: string,
      title: string,
      autoStartProcessing: boolean = true,
      type?: string
    ) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post(
            `/api/admin/assets/process/video/trigger/${fileId}?title=${title}&type=${type}`
          )
          .then((response: any) => {
            if (autoStartProcessing === true) {
              return this.services.activateTrigger();
            } else {
              return new Promise((resolve, reject) => resolve(response));
            }
          })
          .then((response: any) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    pullData: (force: boolean, count: number) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(
            `/api/admin/assets/process/video/queue/pull?force=${force}&count=${count}`
          )
          .then((response: any) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    pullPdfData: (force: boolean, count: number) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(
            `/api/admin/assets/process/video/queue/pull/pdf?force=${force}&count=${count}`
          )
          .then((response: any) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    activateTrigger: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("AdminProc")
          .post("/api/admin/assets/process/video/trigger/start")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    cancelProcess: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("AdminProc")
          .post("/api/admin/assets/process/video/trigger/end")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    deleteAsset: (fileId: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .delete(`/api/assets/${fileId}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    deleteFromQueueAndS3: (fileId: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .delete(`/api/admin/assets/process/video/queueAndS3/items/${fileId}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getAssetById: (fileId: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/assets/${fileId}`,)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    startVideoProcessing: (fileId: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post(`/api/admin/assets/process/video/${fileId}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    checkPdfMissing: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/pdf-missing/${id}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    convertPdftoImage: (resId: string, pdfUri: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/admin/pdfimg", {
            resId,
            pdfUri,
          })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
  };

  views = {
    Dropzone,
    FileView,
    FileProcessor,
    QueueManagerView,
  };

  initialState: StateType = {};
}
