import React, { useState, createRef } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap";
import QuestionItem from "./QuestionGroupItem";

import { Exam, ExamSubjectMaster } from "lakshya-shared";
import ExamEditorModule from "..";
import { AxiosInstance } from "axios";
import { app } from "../../..";

class ImportModal extends React.Component<any, any> {
  fileInputRef = createRef<HTMLInputElement>();
  constructor(props: any) {
    super(props);
    this.state = {
      isVideoUploaderActive: false,
      progress: 0,
      progressMessage: "Uploading...",
      selectedOption: "regular",
      issues: [],
      hasIssues: false,
      answerInIndex: false
    };
  }

  handleFile = (files: FileList) => {
    if (files && files.length > 0) {
      this.setState({
        isVideoUploaderActive: true,
      });

      const formData = new FormData();
      for (var i = 0; i < files.length; i++) {
        formData.append("csv", files[i]);
      }

      var progress = 0;
      var progressMessage = "Uploading...";
      var axios: AxiosInstance = this.props.axios;
      axios
        .post(
          `/api/exams/upload/spreadsheet?template=${this.state.selectedOption}&answerInIndex=${this.state.answerInIndex}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },

            onUploadProgress: (progressEvent) => {
              progress = (progressEvent.loaded / progressEvent.total) * 100;
              progressMessage =
                progress > 95 ? "Processing..." : "Uploading...";

              this.setState({
                progress,
                progressMessage,
              });
            },
          }
        )
        .then((response) => {
          setTimeout(() => {
            this.setState(
              {
                isVideoUploaderActive: false,
              },
              () => {
                const data = response.data;
                if (data) {
                  if (data.hasIssues === false) {
                    this.props.onSuccess &&
                      this.props.onSuccess(response.data.output);
                  } else {
                    this.setState({
                      hasIssues: true,
                      issues: data.issues,
                    });
                  }
                }
              }
            );
          }, 1200);
        })
        .catch((err) => {
          // Handle error
          console.error(err);
          this.setState({
            isVideoUploaderActive: false,
          });
        });
    }
  };

  handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.handleFile(this.fileInputRef.current.files);
    this.fileInputRef.current.value = "";
  };

  toggleIssueModal = () => this.setState({ hasIssues: !this.state.hasIssues });

  render() {
    return (
      <>
        {/* @ts-ignore */}
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}
          keyboard={false}
          backdrop="static"
        >
          <div className="modal-content">
            {this.state.isVideoUploaderActive === true ? (
              <>
                <div className="modal-header">
                  <h5 className="modal-title">{this.state.progressMessage}</h5>
                </div>
                <div className="modal-body">
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${this.state.progress}%` }}
                    ></div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="modal-header">
                  <h5 className="modal-title">Bulk Import</h5>
                </div>
                <div className="modal-body">
                  <p>
                    Please select the file format below and click '
                    <em>Choose File</em>' to initiate upload
                  </p>
                  <form>
                    <fieldset>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="uploadOption"
                          id="uploadOption1"
                          checked={this.state.selectedOption === "regular"}
                          onChange={(e) =>
                            e.target.checked === true
                              ? this.setState({ selectedOption: "regular" })
                              : null
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="uploadOption1"
                        >
                          Questions without solutions attached
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="uploadOption"
                          id="uploadOption2"
                          checked={
                            this.state.selectedOption === "with-solution"
                          }
                          onChange={(e) =>
                            e.target.checked === true
                              ? this.setState({
                                selectedOption: "with-solution",
                              })
                              : null
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="uploadOption2"
                        >
                          Questions with solutions attached
                        </label>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className="form-check my-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="convertOption"
                          id="convertOption"
                          checked={this.state.answerInIndex}
                          onChange={(e) =>
                            this.setState({ answerInIndex: e.target.checked })
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="convertOption"
                        >
                          Answer in numbers
                        </label>
                      </div>
                    </fieldset>
                  </form>
                  <a
                    rel="noopener noreferrer"
                    href={`${app.getConfig().Main.baseURL
                      }/api/exams/download/sample/spreadsheet?template=${this.state.selectedOption
                      }`}
                    target="_blank"
                    className="btn btn-secondary mt-3"
                  >
                    <i className="fas fa-download"></i> Download sample .csv
                    file
                    {(() => {
                      const selectedOption: string = this.state.selectedOption;
                      switch (selectedOption) {
                        case "regular": {
                          return " (regular)";
                        }
                        case "with-solution": {
                          return " (with solution)";
                        }
                        default: {
                          return "";
                        }
                      }
                    })()}
                  </a>
                </div>
              </>
            )}
            <div className="modal-footer d-flex justify-content-between">
              <div></div>
              <div>
                <label
                  htmlFor="spreadsheet-upload"
                  className="btn btn-primary m-0"
                  style={{
                    cursor: "pointer",
                    opacity:
                      this.state.isVideoUploaderActive === true ? 0.5 : 1,
                  }}
                >
                  <i className="fas fa-upload"></i> Upload File
                  {(() => {
                    const selectedOption: string = this.state.selectedOption;
                    switch (selectedOption) {
                      case "regular": {
                        return " (without solution)";
                      }
                      case "with-solution": {
                        return " (with solution)";
                      }
                      default: {
                        return "";
                      }
                    }
                  })()}
                  <input
                    disabled={this.state.isVideoUploaderActive}
                    ref={this.fileInputRef}
                    onChange={this.handleFileInputChange}
                    name="csv"
                    type="file"
                    style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                    id="spreadsheet-upload"
                  />
                </label>
                <button
                  disabled={this.state.isVideoUploaderActive}
                  type="button"
                  className="btn btn-default"
                  onClick={this.props.onToggle}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* @ts-ignore */}
        <Modal isOpen={this.state.hasIssues} toggle={this.toggleIssueModal}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Import Cancelled</h5>
            </div>
            <div className="modal-body">
              <div className="alert alert-warning" role="alert">
                <p>
                  We have found few issues with the content you are trying to
                  import. Please resolve these warnings and try again.
                </p>
                <hr />
                <ol>
                  {this.state.issues.map((issue: string, index: number) => (
                    <li key={index}>{issue}</li>
                  ))}
                </ol>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div></div>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.toggleIssueModal}
                >
                  Hide
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default (props: any) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isImportModelOpen, setIsImportModelOpen] = useState(false);
  const [classUpdateQuestionIndex, setclassUpdateQuestionIndex] = useState(-1);
  const [selectedQuestionSubject, setselectedQuestionSubject] = useState("");
  const [selectedQuestionCategory, setselectedQuestionCategory] = useState("");
  const [groupDurationValue, setgroupDurationValue] = useState("");
  const [groupOptModalVisible, setGroupOptModalVisible] = useState(false);
  const [
    isDeleteQuestionGroupVisible,
    setIsDeleteQuestionGroupVisible,
  ] = useState(false);
  const [
    isQuestionDeleteConfirmationOpen,
    setQuestionDeleteConfirmationOpen,
  ] = useState(-1);
  const [questionToDelete, setquestionToDelete] = useState("");
  const [currentGroupResId, setcurrentGroupResId] = useState(-1);
  const [expandedGroups, setExpandedGroups] = useState([]);
  const exam: Exam = props.exam;
  const module: ExamEditorModule = props.module;
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleIsQuestionDeleteConfirmationOpen = () =>
    setQuestionDeleteConfirmationOpen(-1);
  const toggleClassUpdateModal = () => setclassUpdateQuestionIndex(-1);

  // const openGroupSettings = (groupResId: number, defaultOpts: any) => {
  //   setcurrentGroupResId(groupResId);
  //   setgroupDurationValue(defaultOpts.durationInMins);
  //   setGroupOptModalVisible(true);
  // };

  const closeGroupSettingsModal = () => {
    setGroupOptModalVisible(false);
  };

  const deleteGroup = (resId: number) => {
    setcurrentGroupResId(resId);
    setIsDeleteQuestionGroupVisible(true);
  };

  const confirmDeleteGroup = () => {
    setIsDeleteQuestionGroupVisible(false);
    setGroupOptModalVisible(false);
    module.controller.deleteQuestionGroup(currentGroupResId);
  };

  return (
    <>
      <div className="exam-creater-wrapper container-fluid">
        <div className="exam-details px-5 pt-3">
          <div className="d-flex">
            <div className="flex-fill">
              <label className="font-weight-bold">Question Groups</label>
              <p>
                Edit questions, options and subject details for each question
              </p>
            </div>
            <div className="flex-shrink d-flex flex-row align-items-center">
              <button
                onClick={() => module.controller.addQuestionGroup()}
                type="button"
                className="btn btn-primary selected px-4 mr-3"
              >
                + New Question Group
              </button>
              {/* @ts-ignore */}
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                {/* @ts-ignore */}
                <DropdownToggle className="import-export-dropdown-button" caret>
                  Bulk Import
                </DropdownToggle>
                {/* @ts-ignore */}
                <DropdownMenu>
                  {/* @ts-ignore */}
                  <DropdownItem onClick={() => setIsImportModelOpen(true)}>
                    Import .csv
                  </DropdownItem>
                  {/* <DropdownItem>Export</DropdownItem> */}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Question</th>
                  <th>Options</th>
                  <th>Class</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {exam.questionGroups.map((group, index) => {
                  const isSelected = expandedGroups.indexOf(group.resId) > -1;
                  return (
                    <QuestionItem
                      key={`${index}-${group.resId}`}
                      index={index}
                      context={group}
                      module={module}
                      hasTimeRestriction={exam.hasTimeRestrictedGroup}
                      isExpanded={isSelected}
                      onOptionClicked={(id: number, durationInMins: any) =>
                        // openGroupSettings(id, { durationInMins })
                        deleteGroup(id)
                      }
                      toggleExpanded={() => {
                        if (isSelected) {
                          setExpandedGroups(
                            expandedGroups.filter((r) => r !== group.resId)
                          );
                        } else {
                          setExpandedGroups([...expandedGroups, group.resId]);
                        }
                      }}
                      onDeleteQuestion={(
                        groupResId: number,
                        resId: number,
                        question: string
                      ) => {
                        setcurrentGroupResId(groupResId);
                        setquestionToDelete(question);
                        setQuestionDeleteConfirmationOpen(resId);
                      }}
                      updateClass={(
                        resId: any,
                        subjectKey: string,
                        category: string
                      ) => {
                        setselectedQuestionSubject(subjectKey);
                        setselectedQuestionCategory(category);
                        setclassUpdateQuestionIndex(resId);
                      }}
                    />
                  );
                })}
                {exam.questionGroups.length < 1 ? (
                  <tr>
                    <td className="text-muted text-center" colSpan={5}>
                      No Items
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* @ts-ignore */}
      <Modal
        toggle={toggleIsQuestionDeleteConfirmationOpen}
        isOpen={isQuestionDeleteConfirmationOpen > -1}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Delete Question</h5>
            <button
              type="button"
              className="close"
              onClick={toggleIsQuestionDeleteConfirmationOpen}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p
              style={{ overflowWrap: "break-word" }}
            >{`Do you want to delete the question '${questionToDelete}'?`}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                module.controller.deleteQuestion(
                  currentGroupResId,
                  isQuestionDeleteConfirmationOpen
                );
                setQuestionDeleteConfirmationOpen(-1);
              }}
            >
              Yes, Delete
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={toggleIsQuestionDeleteConfirmationOpen}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      {/* @ts-ignore */}
      <Modal
        toggle={toggleClassUpdateModal}
        isOpen={classUpdateQuestionIndex > -1}
      >
        <div className="modal-content">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // module.controller.updateQuestion(
              //   classUpdateQuestionIndex,
              //   "subjectKey",
              //   selectedQuestionSubject
              // );
              // module.controller.updateQuestion(
              //   classUpdateQuestionIndex,
              //   "subjectCategory",
              //   selectedQuestionCategory
              // );
              toggleClassUpdateModal();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">Update Class</h5>
              <button
                type="button"
                className="close"
                onClick={toggleClassUpdateModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>Choose Subject</label>
                <select
                  className="custom-select"
                  value={selectedQuestionSubject}
                  onChange={(e) => setselectedQuestionSubject(e.target.value)}
                >
                  <option value="">No Subject</option>
                  {ExamSubjectMaster.map((subject) => (
                    <option key={subject.value} value={subject.value}>
                      {subject.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="categoryInput">Category</label>
                <input
                  type="text"
                  className="form-control"
                  id="categoryInput"
                  placeholder="No Category"
                  value={selectedQuestionCategory}
                  onChange={(e) =>
                    setselectedQuestionCategory(e.currentTarget.value)
                  }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Update
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={toggleClassUpdateModal}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {/* @ts-ignore */}
      <Modal toggle={closeGroupSettingsModal} isOpen={groupOptModalVisible}>
        <div className="modal-content">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              module.controller.updateQuestionGroup(
                currentGroupResId,
                "durationInMins",
                groupDurationValue
              );
              closeGroupSettingsModal();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">
                Group Settings {">"}{" "}
                <em>
                  {(() => {
                    const group = exam.questionGroups.find(
                      (g) => g.resId === currentGroupResId
                    );
                    if (group) {
                      return group.title;
                    }
                    return "";
                  })()}
                </em>
              </h5>
              <button
                type="button"
                className="close"
                onClick={closeGroupSettingsModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>Group Duration (in Min.)</label>
                <input
                  autoComplete="off"
                  className="form-control"
                  value={groupDurationValue}
                  onChange={(e) => {
                    setgroupDurationValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div>
                <button
                  onClick={() => deleteGroup(currentGroupResId)}
                  type="button"
                  className="btn btn-primary"
                >
                  Delete Group
                </button>
              </div>
              <div>
                <button className="btn btn-primary">Update</button>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={closeGroupSettingsModal}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      {/* @ts-ignore */}
      <Modal
        toggle={() => setIsDeleteQuestionGroupVisible(false)}
        isOpen={isDeleteQuestionGroupVisible}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Delete Question Group</h5>
            <button
              type="button"
              className="close"
              onClick={() => setIsDeleteQuestionGroupVisible(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="alert alert-warning" role="alert">
              Deleting a question group will remove all of the questions it
              contain. Are you sure you want to continue?
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <button
                onClick={() => confirmDeleteGroup()}
                className="btn btn-primary"
              >
                Yes, Delete
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => setIsDeleteQuestionGroupVisible(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ImportModal
        onSuccess={(data: any) => {
          module.controller.updateExam("questionGroups", [
            ...exam.questionGroups,
            ...data,
          ]);
          setIsImportModelOpen(false);
        }}
        isOpen={isImportModelOpen}
        onToggle={() => setIsImportModelOpen(!isImportModelOpen)}
        axios={module.getServiceProvider("Main")}
      />
    </>
  );
};
