import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default (props: any) => {
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();
  const onEmailVerification = props.onEmailVerification;
  return (
    <>
      <div style={{ flex: 1 }} className="pt-5 px-2 px-sm-4">
        <div className="text-center">
          <h6 className="text-secondary">{t("Step 1 - Account Recovery")}</h6>
          <h5>{t("Identify your account")}</h5>
          <hr />
        </div>
        <form onSubmit={handleSubmit(onEmailVerification)}>
          <div className="form-group">
            <label>{t("Enter email or phone number on your account")}</label>
            <input
              name="verificationEmail"
              ref={register({ required: true })}
              type="email"
              className="form-control"
              id="email-verification-input"
              placeholder="Enter email or phone"
            />
            {errors.verificationEmail && (
              <p className="text-danger" id="email-error">
                {t("Email is Required")}
              </p>
            )}
          </div>
          <div className="text-right pt-3">
            <button
              id="email-verification"
              className="btn btn-info px-5"
              type="submit"
            >
              {t("Next")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
