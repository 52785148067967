import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import LayoutModule from "..";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export default (props: ViewComponentPropType<LayoutModule>) => {
  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>404 Page Not Found</title>
      </Helmet>
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-12">
            <div className="jumbotron">
              <h1 className="display-4">404 Page Not Found!</h1>
              <p className="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
              <hr className="my-4" />
              <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
              <p className="lead">
                <Link className="btn btn-primary btn-lg" to="/" role="button">Go to Dashboard</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
