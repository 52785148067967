import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "reactstrap";
import { ViewComponentPropType } from "@skyslit/ark-react";
import RankFileModule from "..";
import ReactTable, { Filter } from "react-table";
import { Link } from "react-router-dom";
import { RankFile, Subscription } from "lakshya-shared";
import { resolveAssetUrl } from "../../../shared/utils";

const defaultImage = require("./../assets/images/default-image.png");

type DataFilter = (data: RankFile, index: number) => boolean;

type PropType = {
  isOpen: boolean;
  toggle: any;
  onSelect: (rankFiles: RankFile[]) => void;
  isMultiSelect?: boolean;
  filter: DataFilter;
};

const TableFilter = (propertyKey: string) => (
  filter: Filter,
  row: any,
  column: any
): any => {
  if (row._original[propertyKey]) {
    try {
      return (
        row._original[propertyKey]
          .trim()
          .toLowerCase()
          .indexOf(filter.value.trim().toLowerCase()) > -1
      );
    } catch (e) {
      console.error(e);
    }
  }
  return false;
};

export default function (
  props: ViewComponentPropType<RankFileModule> & PropType
) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState<RankFile[]>([]);
  const [selectedIds, updateSelectedIds] = React.useState([]);

  const isMultiSelect: boolean = props.isMultiSelect
    ? props.isMultiSelect
    : false;

  useEffect(() => {
    function loadData() {
      const dataFilter: DataFilter = props.filter || ((e, i) => true);
      setIsLoading(true);
      props.module.services
        .getAll()
        .then((data: any) => {
          setData(data.filter(dataFilter));
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setTimeout(() => {
            loadData();
          }, 1000);
        });
    }

    loadData();
  }, [props.module.services, props.filter]);

  const handleSelection = () => {
    // Resolve ids to data
    props.onSelect &&
      props.onSelect(
        selectedIds.map((id) => {
          return data.find((e) => (e as any)._id === id);
        })
      );
    updateSelectedIds([]);
  };

  return (
    //@ts-ignore
    <Modal size="lg" isOpen={props.isOpen} toggle={props.toggle}>
      {/* @ts-ignore */}
      <ModalHeader toggle={props.toggle}>
        <i className="fas fa-scroll" /> Rank File Picker
      </ModalHeader>
      {/* @ts-ignore */}
      <ModalBody>
        <ReactTable
          loading={isLoading}
          data={data}
          minRows={5}
          defaultPageSize={10}
          columns={[
            {
              Header: "",
              accessor: "_id",
              width: 50,
              Cell: (row) => {
                const id = row.original._id;
                return (
                  <input
                    type="checkbox"
                    checked={selectedIds.indexOf(id) > -1}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        if (isMultiSelect === true) {
                          // Add to selection
                          updateSelectedIds([...selectedIds, id]);
                        } else {
                          updateSelectedIds([id]);
                        }
                      } else {
                        // Remove from selection
                        updateSelectedIds(
                          selectedIds.filter((_id) => _id !== id)
                        );
                      }
                    }}
                  />
                );
              },
            },
            {
              Header: "Title",
              accessor: "title",
              filterable: true,
              filterMethod: TableFilter("title"),
              Cell: (row) => {
                const data = row.original;
                return (
                  <div className="d-flex">
                    <img
                      width={124}
                      src={
                        data.bannerImageId
                          ? resolveAssetUrl(data.bannerImageId, "public")
                          : defaultImage
                      }
                      alt="Video thumbnail"
                    />
                    <span className="ml-2">{row.value}</span>
                  </div>
                );
              },
            },
            {
              Header: "Status",
              accessor: "isPublished",
              width: 100,
              Cell: (row) => {
                return (
                  <span
                    className={`${row.value === true
                        ? "text-success"
                        : "text-danger font-weight-bold"
                      }`}
                  >
                    {row.value === true ? "Published" : "Unpublished"}
                  </span>
                );
              },
            },
            {
              Header: "Subscription",
              accessor: "masterSubscription",
              Cell: (row) => {
                const subscription: Subscription = row.value;
                if (subscription) {
                  return (
                    <Link
                      target="_blank"
                      className="text-success"
                      rel="noopener noreferrer"
                      to={`/admin/subscriptions/editor/${(subscription as any)._id
                        }`}
                    >
                      {subscription.title}
                      <i className="fas fa-external-link-alt ml-3"></i>
                    </Link>
                  );
                } else {
                  return <span className="text-muted">No Associations</span>;
                }
              },
            },
          ]}
        />
      </ModalBody>
      {/* @ts-ignore */}
      <ModalFooter>
        {/* @ts-ignore */}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>{" "}
        {/* @ts-ignore */}
        <Button
          disabled={selectedIds.length < 1}
          color="primary"
          onClick={handleSelection}
        >
          {selectedIds.length > 0
            ? `Select ${selectedIds.length} item(s)`
            : "Confirm Selection"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
