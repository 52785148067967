import React, { Component } from "react";
import ReactTable, { Filter } from "react-table";
import { Modal } from "reactstrap";
import { Link } from "react-router-dom";

const TableFilter = (propertyKey: string) => (
  filter: Filter,
  row: any,
  column: any
): any => {
  if (row._original[propertyKey]) {
    try {
      return (
        row._original[propertyKey]
          .trim()
          .toLowerCase()
          .indexOf(filter.value.trim().toLowerCase()) > -1
      );
    } catch (e) {
      console.error(e);
    }
  }
  return false;
};

export default class PendingPdf extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      data: [],
      idToMarkResolved: "",
      isVideoMarkAsModalOpen: false,
    };

    this.handleFetch = this.handleFetch.bind(this);
    this.handleMarkAsResolved = this.handleMarkAsResolved.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleFetch() {
    this.props.module.services
      .getAllPendingPdfs()
      .then((response: any) => {
        const lectures = response.map((l: any, index: number) => ({
          ...l,
          slNo: index + 1,
        }));
        this.setState({
          data: lectures,
        });
      })
      .catch(() => {});
  }

  handleMarkAsResolved() {
    this.props.module.services
      .markVideoAsResolved(this.state.idToMarkResolved)
      .then((response: any) => {
        this.setState({
          isVideoMarkAsModalOpen: false,
        });
      })
      .catch(() => {});
  }
  openModal(id: string) {
    this.setState({
      isVideoMarkAsModalOpen: true,
      idToMarkResolved: id,
    });
  }

  closeModal() {
    this.setState({
      isVideoMarkAsModalOpen: false,
    });
  }

  componentDidMount() {
    this.handleFetch();
  }

  render() {
    // const data = this.props.lectures;
    // if (!data) {
    //   return <div>Loading</div>;
    // }
    return (
      <>
        <div className="col-12">
          <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
            <h4>Pending PDF In Lectures ({this.state.data.length} lectures)</h4>
          </div>
          <div className="pt-3">
            <ReactTable
              minRows={3}
              defaultPageSize={50}
              data={this.state.data || []}
              columns={[
                {
                  Header: "SL No",
                  accessor: "slNo",
                },
                {
                  Header: "Lecture Title",
                  accessor: "title",
                  filterable: true,
                  filterMethod: TableFilter("title"),
                  Cell: (row) => {
                    return (
                      <Link
                        target="_blank"
                        className="text-success"
                        rel="noopener noreferrer"
                        to={`/admin/videos/lectures/${row.original._id}`}
                      >
                        {row.original.title}
                        <i className="fas fa-external-link-alt ml-3"></i>
                      </Link>
                    );
                  },
                },
                {
                  Header: "Missing Pdfs",
                  accessor: "missingPdfs",
                  Cell: (row: any) => {
                    return row.original.missingPdfs.map(
                      (pdf: any, index: number) => {
                        return (
                          <div key={index}>
                            <p>{pdf.fileName}</p>
                          </div>
                        );
                      }
                    );
                  },
                },
                {
                  Header: "Courses Attached",
                  accessor: "__coursesAttachedTo",
                  Cell: (row: any) => {
                    return row.original.__coursesAttachedTo[0].map(
                      (course: any, index: number) => {
                        return (
                          <div key={index}>
                            <p>{course.courseTitle}</p>
                          </div>
                        );
                      }
                    );
                  },
                },
                {
                  Header: "Actions",
                  accessor: "_id",
                  className: "d-flex align-items-center justify-content-end",
                  Cell: (row) => {
                    return (
                      <button
                        onClick={() => this.openModal(row.original._id)}
                        className="btn btn-sm btn-danger"
                      >
                        Mark as resolved
                      </button>
                    );
                  },
                },
                // {
                //   Header: "Title",
                //   accessor: "title",
                //   filterable: true,
                //   filterMethod: TableFilter('title'),
                //   Cell: (row) => {
                //     const lecture = row.original;
                //     return (
                //       <div className="d-flex">
                //         <img
                //           width={124}
                //           src={
                //             lecture.bannerImageId
                //               ? resolveAssetUrl(lecture.bannerImageId, "public")
                //               : defaultImage
                //           }
                //           alt="Video thumbnail"
                //         />
                //         <Link
                //           className="text-primary ml-3"
                //           rel="noopener noreferrer"
                //           to={`/admin/videos/lectures/${row.original._id}`}
                //         >
                //           {row.value}
                //         </Link>
                //       </div>
                //     );
                //   },
                // },
                // {
                //   Header: "Status",
                //   accessor: "isPublished",
                //   width: 100,
                //   Cell: (row) => {
                //     return (
                //       <span
                //         className={`${
                //           row.value === true
                //             ? "text-success"
                //             : "text-danger font-weight-bold"
                //         }`}
                //       >
                //         {row.value === true ? "Published" : "Unpublished"}
                //       </span>
                //     );
                //   },
                // },
                // {
                //   Header: "Subscription",
                //   accessor: "masterSubscription",
                //   Cell: (row) => {
                //     const subscription: Subscription = row.value;
                //     if (subscription) {
                //       return (
                //         <Link
                //           target="_blank"
                //           className="text-success"
                //           rel="noopener noreferrer"
                //           to={`/admin/subscriptions/editor/${
                //             (subscription as any)._id
                //           }`}
                //         >
                //           {subscription.title}
                //           <i className="fas fa-external-link-alt ml-3"></i>
                //         </Link>
                //       );
                //     } else {
                //       return (
                //         <span className="text-muted">No Associations</span>
                //       );
                //     }
                //   },
                // },
                // {
                //   Header: "Duration (File Size)",
                //   accessor: "videoDurationInMinutes",
                //   Cell: (row) => {
                //     const inMins: number = parseFloat(row.value);
                //     const lecture: LectureVideo = row.original;
                //     const hasVideo = lecture.videoResourceId !== null;
                //     if (hasVideo) {
                //       return (
                //         <>
                //           {!isNaN(inMins) ? (
                //             <>
                //               <span className="text-muted">{`${inMins} Mins.`}</span>
                //               <br />
                //             </>
                //           ) : null}
                //           <span className="text-muted">{`File: ${fileSize(
                //             lecture.videoFileSizeInBytes
                //           )}`}</span>
                //         </>
                //       );
                //     } else {
                //       return (
                //         <span className="text-danger">Video not uploaded</span>
                //       );
                //     }
                //   },
                // },
                // {
                //   Header: "Notes (PDF)",
                //   accessor: "notes",
                //   Cell: (row) => {
                //     const notes: any[] = row.value;
                //     return (
                //       <span className="text-muted">
                //         {notes.length > 0
                //           ? `${notes.length} Note(s)`
                //           : "No Notes Attached"}
                //       </span>
                //     );
                //   },
                // },
              ]}
            />
          </div>
        </div>
         {/* @ts-ignore */}
        <Modal
          autoFocus={true}
          centered={true}
          toggle={this.closeModal}
          isOpen={this.state.isVideoMarkAsModalOpen}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Lecture</h5>
              <button type="button" onClick={this.closeModal} className="close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure want to mark this lecture as resolved?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={this.closeModal}
                className="btn btn-default"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => this.handleMarkAsResolved()}
                className="btn btn-primary"
              >
                Mark as resolved
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
