import React from "react";
import { CurrentAffair } from "lakshya-shared";
import CourseEditorModule from "..";
import { app } from "../../..";
import { resolveAssetUrl } from "../../../shared/utils";

export default (props: any) => {
  const context: CurrentAffair = props.context;
  const module: CourseEditorModule = props.module;
  const Dropzone = app.modules.FileUploader.views.Dropzone;
  const FileView = app.modules.FileUploader.views.FileView;

  return (
    <>
      <div className="lecture-details-page-wrapper">
        <div className="col-12 col-md-6 col-lg-6 current-lecture-wrapper py-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Visibility & Subscription</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Attach or Modify Master Subscription
              </h6>
              <div className="form-group mt-4">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    checked={context.isVisible}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "isVisible",
                        e.target.checked
                      )
                    }
                    className="custom-control-input"
                    id="visibilityCheck"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="visibilityCheck"
                  >
                    {context.isVisible === true
                      ? "This item is enabled for public visibility"
                      : "Public visibility has turned off for this item"}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Cover Art</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Upload PDF Preview
              </h6>

              <div className="form-group mt-4">
                <label htmlFor="descriptionField">Preview Image</label>
                {context.bannerImageId ? (
                  <>
                    <img
                      className="d-block"
                      src={resolveAssetUrl(context.bannerImageId, "public")}
                      style={{
                        height: 300,
                        maxWidth: "100%",
                        margin: "0 auto",
                      }}
                      alt="Course Banner"
                    />
                    <FileView
                      fileId={context.bannerImageId}
                      fileName="Preview Image"
                      fileSizeInBytes={context.bannerImageFizeSizeInBytes}
                      onDelete={() => {
                        module.controller.updateEditorData(
                          "bannerImageId",
                          null
                        );
                        module.controller.updateEditorData(
                          "bannerImageFizeSizeInBytes",
                          0
                        );
                        module.controller.saveEditorData();
                      }}
                    />
                  </>
                ) : (
                  <Dropzone
                    label="Upload Preview Image"
                    accept="image/*"
                    accessMode="public"
                    onSuccess={(files: any[]) => {
                      if (files && Array.isArray(files)) {
                        if (files.length > 0) {
                          module.controller.updateEditorData(
                            "bannerImageId",
                            files[0]._id.toString()
                          );
                          module.controller.updateEditorData(
                            "bannerImageFizeSizeInBytes",
                            files[0].sizeInBytes
                          );
                          module.controller.saveEditorData();
                        }
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Current Affair PDF</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Attach and Manage PDF
              </h6>
              {context.pdfFileId ? (
                <div className="notes-wrapper">
                  <FileView
                    key={context.pdfFileId}
                    fileId={context.pdfFileId}
                    pdfUri={resolveAssetUrl(context.pdfFileId, "private")}
                    enableDownload={true}
                    fileName={context.fileName}
                    fileSizeInBytes={context.fileSizeInBytes}
                    onDelete={() => {
                      module.controller.updateEditorData("pdfFileId", null);
                      module.controller.updateEditorData("fileName", null);
                      module.controller.updateEditorData("fileSizeInBytes", 0);
                      module.controller.saveEditorData();
                    }}
                  />
                </div>
              ) : (
                <div className="pt-3">
                  <Dropzone
                    mode="mini"
                    accessMode="private"
                    label="Add a note file"
                    accept="application/pdf"
                    onSuccess={(files: any[]) => {
                      if (files && Array.isArray(files)) {
                        if (files.length > 0) {
                          module.controller.updateEditorData(
                            "pdfFileId",
                            files[0]._id.toString()
                          );
                          module.controller.updateEditorData(
                            "fileName",
                            files[0].fileName
                          );
                          module.controller.updateEditorData(
                            "fileSizeInBytes",
                            files[0].sizeInBytes
                          );
                          module.controller.saveEditorData();
                        }
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
