import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import { ComponentMap } from "@skyslit/ark-react/build/types";
import DiscussionPageView from "./views/DiscussionPage.view";
import DiscussionEditorPageView from "./views/DiscussionEditorPage.view";
import DiscussionViewComponent from "./components/DiscussionView";
import { Comment } from "lakshya-shared";

export type StateType = {
  StartMessage: {
    msg1: string;
  };
};

export default class DiscussionModule extends ArkModule<StateType> {
  constructor() {
    super("DiscussionModule");

    this.useConnect(connect);

    this.main = () => {};
  }

  views: ComponentMap = {
    DiscussionPage: DiscussionPageView,
    DiscussionEditorPage: DiscussionEditorPageView,
    DiscussionViewComponent,
  };

  services = {
    postMessageAsAdmin: (comment: Partial<Comment>) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post(`/api/admin/comments`, comment)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    updateMessageAsAdmin: (id: string, comment: Partial<Comment>) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/comments/${id}`, comment)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getMessages: (resType: string, resId: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/comments/${resType}/${resId}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    deleteMessage: (commentId: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .delete(`/api/admin/comments/${commentId}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    postReplyAsAdmin: (
      resourceType: string,
      resourceId: string,
      message: string,
      id: string,
      name: string,
      isAdministrator: boolean
    ) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post(`/api/admin/comments/reply/${id}`, {
            resourceType,
            resourceId,
            message,
            name,
            isAdministrator,
          })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getAllEducators: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/educators`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    updateReplyAsAdmin: (
      commentid: string,
      replyId: string,
      message: string,
      displayName: string,
      isAdministrator: boolean
    ) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/comments/reply/${commentid}/${replyId}`, {
            message,
            displayName,
            isAdministrator,
          })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    deleteReply: (commentId: string, replyId: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .delete(`/api/admin/comments/reply/${commentId}/${replyId}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
  };
}
