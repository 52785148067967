import React, { Component } from "react";
import ReactTable, { Filter } from "react-table";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import VideoCatalogueModule from "..";
import { Link } from "react-router-dom";
import { resolveAssetUrl } from "../../../shared/utils";
import { Winner } from "lakshya-shared";

const defaultImage = require("./../assets/images/default-image.png");

const TableFilter = (propertyKey: string) => (filter: Filter, row: any, column: any): any => {
  if (row._original[propertyKey]) {
    try {
      return row._original[propertyKey].trim().toLowerCase().indexOf(filter.value.trim().toLowerCase()) > -1;
    } catch (e) {
      console.error(e);
    }
  }
  return false;
}

export default class TableView extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isNewModalOpen: false,
      idToActivate: null,
      idToDeactivate: null
    };
    this.toggleAddModal = this.toggleAddModal.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
  }

  openActivationModal = (id: string) => this.setState({ idToActivate: id })
  openDeactivationModal = (id: string) => this.setState({ idToDeactivate: id })
  closeActivationModal = () => this.setState({ idToActivate: null, idToDeactivate: null })

  toggleActivation = (id: string, state: 'activate' | 'deactivate') => {
    const module: VideoCatalogueModule = this.props.module;
    this.closeActivationModal();
    module.showWait(state === 'activate' ? 'Activating announcement...' : 'Deactivating announcement...', 'Processing', false);
    module.services.toggleCurrent(state, id)
      .then((value) => {
        module.clearAlert();
        if (state === 'activate') {
          module.package.updateContext('activeWinnerAnnouncement', id);
        } else {
          module.package.updateContext('activeWinnerAnnouncement', null);
        }
      })
      .catch((err) => {
        module.showError(err.message || 'Unknown error', 'Process Failed', true)
      })
  }

  toggleAddModal() {
    this.setState({
      isNewModalOpen: !this.state.isNewModalOpen,
    });
  }

  handleAddClick = (data: any) => {
    const module: VideoCatalogueModule = this.props.module;
    const history = this.props.history;
    module.services
      .create(data.title)
      .then((response: any) => {
        this.toggleAddModal();
        history.push(`/admin/rank-files/editor/${response._id}`);
      })
      .catch((e) => {
        this.props.module.showError(
          "Item cannot be added",
          e.message ? e.message : "Network error",
          true
        );
      });
  };

  handleFetch() {
    const module: VideoCatalogueModule = this.props.module;
    module.services
      .getAll()
      .then((response: any) => {
        module.controller.setListingData(response);
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching course failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }

  componentDidMount() {
    this.handleFetch();
  }

  render() {
    const data = this.props.data;
    if (!data) {
      return <div>Loading</div>;
    }

    const { activeWinnerAnnouncement } = this.props.context;
    const activeWinnerAnnouncementData: Winner = data.find((d: any) => d._id === activeWinnerAnnouncement);

    return (
      <>
        <div className="col-12">
          <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
            <h4>Winner Announcement Manager</h4>
            {/* <button
              onClick={this.toggleAddModal}
              className="add-lecture-button-wrapper px-5 py-3"
            >
              Add New Winner
            </button> */}
          </div>
          {
            activeWinnerAnnouncementData ? (
              <div className="row">
                <div className="col-12">
                  <div className="card bg-light">
                    <div className="card-body">
                      <h5 className="card-title">Active Announcement</h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        Achievement Details
                      </h6>
                      <table className="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th scope="col">Photo / Name</th>
                            <th scope="col">Public Visible</th>
                            <th scope="col">Is Active</th>
                            <th scope="col">Rank</th>
                            <th scope="col">Prize Amount (INR)</th>
                            <th scope="col">Beneficiary GPay / UPI ID</th>
                            <th scope="col">Beneficiary Name</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">
                              <div className="d-flex">
                                <img
                                  width={124}
                                  src={
                                    activeWinnerAnnouncementData.bannerImageId
                                      ? resolveAssetUrl(activeWinnerAnnouncementData.bannerImageId, "public")
                                      : defaultImage
                                  }
                                  alt="Student"
                                />
                                <Link
                                  className="text-primary ml-3"
                                  rel="noopener noreferrer"
                                  to={`/admin/winners/editor/${activeWinnerAnnouncementData._id}`}
                                >
                                  {activeWinnerAnnouncementData.winnerName || 'No name provided'}
                                </Link>
                              </div>
                            </th>
                            <td>{activeWinnerAnnouncementData.isActionRequiredFromUser === false && activeWinnerAnnouncementData.isPublic === true ? "Yes" : activeWinnerAnnouncementData.isActionRequiredFromUser === false ? <span className="text-warning">Not Public (No action needed from student)</span> : <span className="text-danger">Action Required</span>}</td>
                            <td>Yes</td>
                            <td>{activeWinnerAnnouncementData.rank}</td>
                            <td>{activeWinnerAnnouncementData.prizeAmount}</td>
                            <td>{activeWinnerAnnouncementData.googlePayOrUPI}</td>
                            <td>{activeWinnerAnnouncementData.beneficiaryName}</td>
                            <td>
                              {/* @ts-ignore */}
                              <Button onClick={() => {
                                this.openDeactivationModal(activeWinnerAnnouncementData._id);
                              }} color="danger" size="sm">Deactivate</Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }
          <div className="pt-3">
            <ReactTable
              minRows={3}
              defaultPageSize={100}
              data={data}
              columns={[
                {
                  Header: "Photo / Name",
                  accessor: "winnerName",
                  filterable: true,
                  filterMethod: TableFilter('winnerName'),
                  Cell: (row) => {
                    const data = row.original;
                    return (
                      <div className="d-flex">
                        <img
                          width={124}
                          src={
                            data.bannerImageId
                              ? resolveAssetUrl(data.bannerImageId, "public")
                              : defaultImage
                          }
                          alt="Student"
                        />
                        <Link
                          className="text-primary ml-3"
                          rel="noopener noreferrer"
                          to={`/admin/winners/editor/${row.original._id}`}
                        >
                          {row.value || 'No name provided'}
                        </Link>
                      </div>
                    );
                  },
                },
                {
                  Header: "Status",
                  accessor: "isPublished",
                  width: 100,
                  Cell: (row) => {
                    return (
                      <span
                        className={`${row.value === true
                            ? "text-success"
                            : "text-danger font-weight-bold"
                          }`}
                      >
                        {row.value === true ? "Published" : "Unpublished"}
                      </span>
                    );
                  },
                },
                {
                  Header: "Is Activated",
                  accessor: "_id",
                  width: 100,
                  Cell: (row) => {
                    return (
                      <span
                        className={`${row.value === activeWinnerAnnouncement
                            ? "text-success"
                            : "text-danger font-weight-bold"
                          }`}
                      >
                        {row.value === activeWinnerAnnouncement ? "Yes" : "No"}
                      </span>
                    );
                  },
                },
                {
                  Header: "Rank",
                  accessor: "rank",
                  filterable: true,
                  filterMethod: TableFilter('rank'),
                },
                {
                  Header: "Prize Amount",
                  accessor: "prizeAmount",
                  filterable: true,
                  filterMethod: TableFilter('prizeAmount'),
                },
                {
                  Header: "Beneficiary GPAY / UPI ID",
                  accessor: "googlePayOrUPI",
                  filterable: true,
                  filterMethod: TableFilter('googlePayOrUPI'),
                },
                {
                  Header: "Beneficiary Name",
                  accessor: "beneficiaryName",
                  filterable: true,
                  filterMethod: TableFilter('beneficiaryName'),
                },
                {
                  Header: "Actions",
                  accessor: "_id",
                  Cell: (props) => {
                    const isActive = props.value === activeWinnerAnnouncement
                    return (
                      <>
                        {/* @ts-ignore */}
                        <Button onClick={() => {
                          if (isActive === true) {
                            this.openDeactivationModal(props.value);
                          } else {
                            this.openActivationModal(props.value);
                          }
                        }} color={isActive === true ? 'danger' : 'success'} size="sm">{isActive === true ? 'Deactivate' : 'Activate'}</Button>
                      </>
                    )
                  }
                },
              ]}
            />
          </div>
        </div>
        {/* @ts-ignore */}
        <Modal
          autoFocus={true}
          centered={true}
          toggle={this.toggleAddModal}
          isOpen={this.state.isNewModalOpen}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Item</h5>
              <button
                type="button"
                onClick={this.toggleAddModal}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <NewItemForm
                handleAddClick={this.handleAddClick}
                toggleAddModal={this.toggleAddModal}
              />
            </div>
          </div>
        </Modal>
        {/* @ts-ignore */}
        <Modal
          toggle={this.closeActivationModal}
          isOpen={this.state.idToActivate !== null}
        >
          {/* @ts-ignore */}
          <ModalHeader toggle={this.closeActivationModal}>
            Activate Announcement
          </ModalHeader>
          {/* @ts-ignore */}
          <ModalBody>
            Do you want to activate this announcement?
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            {/* @ts-ignore */}
            <Button onClick={this.closeActivationModal}>Cancel</Button>
            {/* @ts-ignore */}
            <Button color="success" onClick={() => this.toggleActivation(this.state.idToActivate, 'activate')}>Yes, Activate</Button>
          </ModalFooter>
        </Modal>
        {/* @ts-ignore */}
        <Modal
          toggle={this.closeActivationModal}
          isOpen={this.state.idToDeactivate !== null}
        >
          {/* @ts-ignore */}
          <ModalHeader toggle={this.closeActivationModal}>
            Deactivate Announcement
          </ModalHeader>
          {/* @ts-ignore */}
          <ModalBody>
            Do you want to deactivate this announcement?
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            {/* @ts-ignore */}
            <Button onClick={this.closeActivationModal}>Cancel</Button>
            {/* @ts-ignore */}
            <Button color="danger" onClick={() => this.toggleActivation(this.state.idToDeactivate, 'deactivate')}>Yes, Deactivate</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

function NewItemForm(props: any) {
  const { register, handleSubmit, errors } = useForm();
  const toggleAddModal = props.toggleAddModal;
  const handleAddClick = props.handleAddClick;

  return (
    <form onSubmit={handleSubmit(handleAddClick)}>
      <input
        autoFocus={true}
        autoComplete="off"
        name="title"
        ref={register({ required: true })}
        type="text"
        className="form-control"
        placeholder="Enter Title"
      />
      {errors.title && (
        <small className="text-danger" id="otp-error">
          Please provide a title
        </small>
      )}
      <div className="modal-footer">
        <button
          type="button"
          onClick={toggleAddModal}
          className="btn btn-default"
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-primary">
          Create Rank File
        </button>
      </div>
    </form>
  );
}
