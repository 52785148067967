import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import { ComponentMap } from "@skyslit/ark-react/build/types";
import ListingView from "./views/Listing.view";
import EditorView from "./views/Editor.view";
import RankFilePicker from "./components/RankFilePicker";
import { RankFile } from "lakshya-shared";

export type StateType = {
  editorData: RankFile;
  listingData: RankFile[];
  haveContextUpdated: boolean;
};

export default class RankFileModule extends ArkModule<StateType> {
  constructor() {
    super("RankFileModule");

    this.useConnect(connect);

    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          case this.actionTypes.SET_LISTING: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              listingData: value,
            });
          }
          case this.actionTypes.UPDATE_EDITOR_DATA: {
            const { key, value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              editorData: Object.assign({}, state.editorData, {
                [key]: value,
              }),
            });
          }
          case this.actionTypes.UPDATE_EDITOR_DATA_GROUP: {
            const { resId, key, value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              editorData: Object.assign({}, state.editorData, {
                groups: state.editorData.groups.map((g) => {
                  if (g.resId === resId) {
                    return Object.assign({}, g, {
                      [key]: value
                    })
                  }
                  return g;
                })
              }),
            });
          }
          case this.actionTypes.MARK_AS_SAVED: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: false,
              editorData: Object.assign({}, state.editorData, {
                publishWarnings: value.publishWarnings,
                isPublished: value.isPublished,
              }),
            });
          }
          case this.actionTypes.SET_EDITOR_DATA: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              editorData: value,
            });
          }
          default: {
            return state;
          }
        }
      };
    };

    this.main = () => {
      this.loadListingData();
    };
  }

  loadListingData = () => {
    if (this.package.store.getState().__CORE_PACKAGE.isAuthenticated === true) {
      this.services
      .getAll()
      .then((data) => {
        this.controller.setListingData(data);
      })
      .catch(() => {
        setTimeout(() => {
          // REMOVED FROM REPEAT
          // this.loadListingData();
        }, 100);
      });
    } else {
      setTimeout(() => {
        this.loadListingData();
      }, 1000);
    }
  };

  controller = {
    setListingData: (value: any) => {
      this.dispatch({
        type: this.actionTypes.SET_LISTING,
        payload: {
          value,
        },
      });
    },
    setEditorData: (value: RankFile) => {
      this.dispatch({
        type: this.actionTypes.SET_EDITOR_DATA,
        payload: {
          value,
        },
      });
    },
    updateEditorData: (key: string, value: any) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_EDITOR_DATA,
        payload: {
          key,
          value,
        },
      });
    },
    updateExamGroup: (resId: number, key: string, value: any) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_EDITOR_DATA_GROUP,
        payload: {
          resId,
          key,
          value,
        },
      });
    },
    saveEditorData: () => {
      const courseToUpdate = this.getState().editorData;
      this.services
        .updateById((courseToUpdate as any)._id, courseToUpdate)
        .then((response: any) => {
          this.dispatch({
            type: this.actionTypes.MARK_AS_SAVED,
            payload: {
              value: response,
            },
          });
        })
        .catch((e) => {
          this.showError(
            "Update subject failed",
            e.message ? e.message : "Network error",
            true
          );
        });
    },
  };

  actionTypes = {
    GET_ALL_BUNDLES: "GET_ALL_BUNDLES",
    SET_LISTING: "SET_LISTING",
    SET_EDITOR_DATA: "SET_EDITOR_DATA",
    UPDATE_EDITOR_DATA: "UPDATE_EDITOR_DATA",
    UPDATE_EDITOR_DATA_GROUP: "UPDATE_EDITOR_DATA_GROUP",
    MARK_AS_SAVED: "MARK_AS_SAVED"
  };

  services = {
    create: (title: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/admin/rankfiles", {
            title: title,
          })
          .then((response) => {
            this.loadListingData();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getAll: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/admin/rankfiles")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getOneById: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/rankfiles/${id}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    updateById: (id: string, value: RankFile) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/rankfiles/${id}`, { value: value })
          .then((response) => {
            this.loadListingData();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    delete: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .delete(`/api/admin/rankfiles/${id}`)
          .then((response) => {
            this.loadListingData();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
  };

  views: ComponentMap = {
    Listing: ListingView,
    Editor: EditorView,
    RankFilePicker
  };

  initialState: StateType = {
    editorData: null,
    listingData: [],
    haveContextUpdated: false,
  };
}
