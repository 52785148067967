import React from "react";
import FileUploadModule from "../index";
import { ViewComponentPropType } from "@skyslit/ark-react";
import filesize from "filesize";
import { app } from './../../../index';

type PropType = {
    fileResId: string
    title?: string
}

type StateType = {
    actionEnabled: boolean
    hasInitialized: boolean
    status: 'uploaded' | 'preparing' | 'downloading' | 'processing' | 'post-processing' | 'uploading' | 'ready',
    asset: any
}

export default class FileProcessor extends React.Component<
    Partial<ViewComponentPropType<FileUploadModule>> & PropType,
    StateType
    > {

    constructor(props: PropType) {
        super(props);

        this.state = {
            actionEnabled: true,
            hasInitialized: false,
            status: null,
            asset: null
        }
    }

    componentDidMount() {
        // app.modules.RTDClient.io.on(`new-status-${this.props.fileResId}`, (res: any) => {
        //     this.setState({
        //         asset: res,
        //         hasInitialized: true
        //     })
        // })

        // app.modules.RTDClient.onConnect(() => {
        //     app.modules.RTDClient.io.emit('join-file-processor-status', this.props.fileResId, (res: any) => {
        //         this.setState({
        //             asset: res,
        //             hasInitialized: true
        //         })
        //     });
        // });

        this.props.module.services.getAssetById(this.props.fileResId)
        .then((asset) => {
            this.setState({
                asset,
                hasInitialized: true
            })
        })
        .catch((err) => {
            // Do nothing

            console.error(err);
        })
    }

    componentWillUnmount() {
        // app.modules.RTDClient.io.emit('leave-file-processor-status', this.props.fileResId);
    }

    handleStartProcessing = () => {
        this.setState({
            actionEnabled: false
        }, () => {
            app.modules.FileUploader.services.addToQueue(this.props.fileResId, this.props.title);
        })
    }
    
    render() {

        if (this.state.hasInitialized === true) {
            if (this.state.asset.state === 'ready') {
                return (
                    <>
                        <div className="alert alert-success" role="alert">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <span>Ready</span>
                                    <br /><small>Video has been completed processing and ready for playback</small>
                                </div>
                                <div>
                                    <button disabled={this.state.actionEnabled === false} onClick={this.handleStartProcessing} className="btn btn-primary btn-sm">
                                        {
                                            this.state.actionEnabled === true ? (
                                                'Add to Queue'
                                            ) : (
                                                'Processing...'
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Resolution</th>
                                        <th scope="col">Bitrate</th>
                                        <th scope="col">Audio Rate</th>
                                        <th scope="col">Segments</th>
                                        <th scope="col">Size</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (this.state.asset.renditions || []).map((rendition: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{`${rendition.width}X${rendition.height}`}</td>
                                                    <td>{rendition.bitrate}</td>
                                                    <td>{rendition.audioRate}</td>
                                                    <td>{rendition.segmentCount}</td>
                                                    <td>{filesize(rendition.totalSizeInBytes)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                );
            } else {

                let title: string = 'Please wait...';
                let subtitle: string = 'It might take some time depending on the video file size, you can close this tab and check back later';
                let theme: string = 'info';
                let showProgress: boolean = false;
                let progress = 0;
                let actionComponent = (
                    <i className="fas fa-circle-notch fa-spin"></i>
                )

                switch (this.state.asset.state) {
                    case 'uploaded': {
                        title = 'Require Processing';
                        subtitle = 'Video requires processing before it can be viewed by students';
                        theme = 'danger';
                        actionComponent = (
                            <button onClick={this.handleStartProcessing} disabled={this.state.actionEnabled === false} className="btn btn-sm btn-success">
                                {
                                    this.state.actionEnabled === true ? (
                                        'Start Processing'
                                    ) : (
                                        'Processing...'
                                    )
                                }
                            </button>
                        )
                        break;
                    }
                    case 'preparing': {
                        title = 'Step 1 of 5: Preparing...';
                        break;
                    }
                    case 'downloading': {
                        showProgress = true;
                        title = 'Step 2 of 5: Acquiring asset...';
                        
                        try {
                            let total = 0;
                            let loaded = 0;
                            if (this.state.asset) {
                                if (this.state.asset.processProgress) {
                                    total = parseFloat(this.state.asset.processProgress[this.state.asset.state].total);
                                    loaded = parseFloat(this.state.asset.processProgress[this.state.asset.state].loaded);
                                }
                            }

                            progress = (loaded / total) * 100;
                            console.log(loaded, total);
                            if (isNaN(progress)) {
                                progress = 0;
                            }
                        } catch (e) {
                            // Do nothing
                        }

                        break;
                    }
                    case 'processing': {
                        showProgress = true;
                        title = 'Step 3 of 5: Processing video...';

                        try {
                            let total = 0;
                            let loaded = 0;
                            if (this.state.asset) {
                                if (this.state.asset.processProgress) {
                                    total = parseFloat(this.state.asset.processProgress[this.state.asset.state].total);
                                    loaded = parseFloat(this.state.asset.processProgress[this.state.asset.state].loaded);
                                }
                            }

                            progress = (loaded / total) * 100;
                            console.log(loaded, total);
                            if (isNaN(progress)) {
                                progress = 0;
                            }
                        } catch (e) {
                            // Do nothing
                        }

                        break;
                    }
                    case 'post-processing': {
                        title = 'Step 4 of 5: Computing metadata...';
                        break;
                    }
                    case 'uploading': {
                        showProgress = true;
                        title = 'Step 5 of 5: Delivery in progress...';

                        try {
                            let total = 0;
                            let loaded = 0;
                            if (this.state.asset) {
                                if (this.state.asset.processProgress) {
                                    total = parseFloat(this.state.asset.processProgress[this.state.asset.state].total);
                                    loaded = parseFloat(this.state.asset.processProgress[this.state.asset.state].loaded);
                                }
                            }

                            progress = (loaded / total) * 100;
                            console.log(loaded, total);
                            if (isNaN(progress)) {
                                progress = 0;
                            }
                        } catch (e) {
                            // Do nothing
                        }

                        break;
                    }
                    default: {
                        title = 'Please wait...';
                        subtitle = 'You may close this tab and check back later';
                        break;
                    }
                }

                return (
                    <>
                        <div className={`alert alert-${theme}`} role="alert">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <span>{title}</span>
                                    <br /><small>{subtitle}</small>
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    {actionComponent}
                                </div>
                            </div>
                            {
                                showProgress === true ? (
                                    <div className="progress mt-2">
                                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${progress}%` }}></div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </>
                );
            }
        } else {
            return (
                <>
                    <div className="alert alert-info d-flex justify-content-between" role="alert">
                        <div>
                            <span>Loading...</span>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <i className="fas fa-circle-notch fa-spin"></i>
                        </div>
                    </div>
                </>
            );
        }
    }
}
