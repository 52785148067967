import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import CoursePageView from "./views/CoursePage.view";
import { ComponentMap } from "@skyslit/ark-react/build/types";

export type StateType = {};

export default class CourseModule extends ArkModule<StateType> {
  constructor() {
    super("CourseModule");

    this.useConnect(connect);

    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          default: {
            return state;
          }
        }
      };
    };

    this.main = () => {};
  }

  services = {
    addCourse: (title: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/admin/courses", {
            title: title,
          })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getAllCourse: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/admin/courses")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    duplicateCourseById: (id: any, count: any) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post(`/api/admin/course/duplicate/${id}`, { count: count })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
  };

  views: ComponentMap = {
    CoursePage: CoursePageView,
  };

  initialState: StateType = {};
}
