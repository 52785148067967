import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "..";
import ReactTable, { Filter } from "react-table";
import { Link } from "react-router-dom";
import { resolveAssetUrl } from "../../../shared/utils";
import { LectureVideo } from "lakshya-shared";
// import fileSize from "filesize";
import VideoCatalogueModule from "..";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

// import { Link } from "react-router-dom";
type StateType = {
  data: [];
  isRemoveLectureModalOpen: boolean;
  isMoveToModalOpen: boolean;
  currentId: string;
  title: string;
  databaseTitle: string;
  currentIndex: number;
  lectureIds: string[];
  newIndex: number;
  currentLectureTitle: string;
};
const TableFilter = (propertyKey: string) => (
  filter: Filter,
  row: any,
  column: any
): any => {
  if (row._original[propertyKey]) {
    try {
      return (
        row._original[propertyKey]
          .trim()
          .toLowerCase()
          .indexOf(filter.value.trim().toLowerCase()) > -1
      );
    } catch (e) {
      console.error(e);
    }
  }
  return false;
};
class VideoCataloguePage extends React.Component<
  ViewComponentPropType<DefaultModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: [],
      isMoveToModalOpen: false,
      isRemoveLectureModalOpen: false,
      currentId: "",
      title: "",
      databaseTitle: "",
      currentIndex: 0,
      newIndex: 0,
      lectureIds: [],
      currentLectureTitle: "",
    };
    this.handleFetch = this.handleFetch.bind(this);
    this.closeRemoveLectureModal = this.closeRemoveLectureModal.bind(this);
    this._handleUpdateTitle = this._handleUpdateTitle.bind(this);
    this._moveArrayItemToNewIndex = this._moveArrayItemToNewIndex.bind(this);
    this._handleChangeCustomLectureOrder = this._handleChangeCustomLectureOrder.bind(
      this
    );
    this.toggleMoveToModal = this.toggleMoveToModal.bind(this);
  }

  toggleMoveToModal = (index: number, title: string) => {
    this.setState({
      isMoveToModalOpen: true,
      currentIndex: index,
      currentLectureTitle: title,
    });
  };
  closeMoveToModal = () => {
    this.setState({
      isMoveToModalOpen: !this.state.isMoveToModalOpen,
    });
  };

  openRemoveLectureModal(id: string) {
    this.setState({
      isRemoveLectureModalOpen: true,
      currentId: id,
    });
  }

  closeRemoveLectureModal() {
    this.setState({
      isRemoveLectureModalOpen: !this.state.isRemoveLectureModalOpen,
    });
  }
  handleFetch() {
    const module: VideoCatalogueModule = this.props.module;
    module.services
      .getCustomCollection()
      .then((response: any) => {
        this.setState({
          lectureIds: response.lectureIds,
          data: response.lecturesMetaData,
          title: response.title,
          databaseTitle: response.title,
        });
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching course failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }
  _handleRemoveLectureToCollection = (id: string) => {
    const module: VideoCatalogueModule = this.props.module;
    module.services
      .removeLectureToCustomCollection(id)
      .then((response: any) => {
        this.closeRemoveLectureModal();
        this.handleFetch();
      })
      .catch((e) => { });
  };
  _handleUpdateTitle = () => {
    const module: VideoCatalogueModule = this.props.module;
    module.services
      .updateLectureToCustomCollectionTitle(this.state.title)
      .then((response: any) => {
        this.handleFetch();
      })
      .catch((e) => { });
  };

  _handleChangeCustomLectureOrder = () => {
    const module: VideoCatalogueModule = this.props.module;
    module.services
      .updateCustomLectureCollectionOrder(this.state.data)
      .then((response: any) => { })
      .catch((e) => { });
  };

  _moveArrayItemToNewIndex = (
    arr: any[],
    old_index: number,
    new_index: number
  ) => {
    if (this.state.newIndex !== null && this.state.newIndex <= arr.length) {
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      this.setState({
        newIndex: 0,
      });
      this.closeMoveToModal();
      this._handleChangeCustomLectureOrder();
      return arr;
    }
  };
  componentDidMount() {
    this.handleFetch();
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    });
  }

  render() {
    const defaultImage = require("../assets/images/default-image.png");

    return (
      <>
        {/* @ts-ignore */}
        <Helmet>
          <title>
            Custom Lecture Collection Manager - Lakshya eLearning System
          </title>
        </Helmet>
        <div className="container-fluid video-catalogue-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
                <input
                  className="ml-2 pl-2 title-edit-input"
                  value={this.state.title}
                  onChange={(e) =>
                    this.setState({
                      title: e.target.value,
                    })
                  }
                />
                <button
                  disabled={this.state.title === this.state.databaseTitle}
                  onClick={() => this._handleUpdateTitle()}
                  className="btn btn-primary add-lecture-button-wrapper px-5 py-3"
                >
                  Update Title
                </button>
              </div>
              <div className="pt-3">
                <ReactTable
                  minRows={3}
                  defaultPageSize={10}
                  data={this.state.data}
                  columns={[
                    {
                      Header: "Title",
                      accessor: "title",
                      filterable: true,
                      filterMethod: TableFilter("title"),
                      Cell: (row) => {
                        const lecture = row.original;
                        return (
                          <div className="d-flex">
                            <img
                              width={124}
                              src={
                                lecture.bannerImageId
                                  ? resolveAssetUrl(
                                    lecture.bannerImageId,
                                    "public"
                                  )
                                  : defaultImage
                              }
                              alt="Video thumbnail"
                            />
                            <Link
                              className="text-primary ml-3"
                              rel="noopener noreferrer"
                              to={`/admin/videos/lectures/${row.original._id}`}
                            >
                              {row.value}
                            </Link>
                          </div>
                        );
                      },
                    },
                    {
                      Header: "Status",
                      accessor: "isPublished",
                      width: 100,
                      Cell: (row) => {
                        return (
                          <span
                            className={`${row.value === true
                                ? "text-success"
                                : "text-danger font-weight-bold"
                              }`}
                          >
                            {row.value === true ? "Published" : "Unpublished"}
                          </span>
                        );
                      },
                    },
                    // {
                    //   Header: "Subscription",
                    //   accessor: "masterSubscription",
                    //   Cell: (row) => {
                    //     const subscription: Subscription = row.value;
                    //     if (subscription) {
                    //       return (
                    //         <Link
                    //           target="_blank"
                    //           className="text-success"
                    //           rel="noopener noreferrer"
                    //           to={`/admin/subscriptions/editor/${(subscription as any)._id
                    //             }`}
                    //         >
                    //           {subscription.title}
                    //           <i className="fas fa-external-link-alt ml-3"></i>
                    //         </Link>
                    //       );
                    //     } else {
                    //       return (
                    //         <span className="text-muted">No Associations</span>
                    //       );
                    //     }
                    //   },
                    // },
                    {
                      Header: "Duration (File Size)",
                      accessor: "videoDurationInMinutes",
                      Cell: (row) => {
                        const inMins: number = parseFloat(row.value);
                        const lecture: LectureVideo = row.original;
                        const hasVideo = lecture.videoResourceId !== null;
                        if (hasVideo) {
                          return (
                            <>
                              {!isNaN(inMins) ? (
                                <>
                                  <span className="text-muted">{`${inMins} Mins.`}</span>
                                  <br />
                                </>
                              ) : null}
                              {/* <span className="text-muted">{`File: ${fileSize(
                                lecture.videoFileSizeInBytes
                              )}`}</span> */}
                            </>
                          );
                        } else {
                          return (
                            <span className="text-danger">
                              Video not uploaded
                            </span>
                          );
                        }
                      },
                    },
                    {
                      Header: "Notes (PDF)",
                      accessor: "notes",
                      Cell: (row) => {
                        const notes: any[] = row.value;
                        return (
                          <span className="text-muted">
                            {notes.length > 0
                              ? `${notes.length} Note(s)`
                              : "No Notes Attached"}
                          </span>
                        );
                      },
                    },
                    {
                      Header: "Actions",
                      accessor: "_id",
                      width: 300,
                      className:
                        "d-flex align-items-start justify-content-center",
                      Cell: (row) => {
                        return (
                          <>
                            <button
                              style={{ marginRight: 25 }}
                              className="btn btn-sm btn-info"
                              onClick={() =>
                                this.toggleMoveToModal(
                                  row.index,
                                  row.original.title
                                )
                              }
                            >
                              <span style={{ fontSize: 12, paddingRight: 10 }}>
                                Move Lecture
                              </span>
                              <i
                                style={{ color: "white" }}
                                className="fas fa-compress-alt rotate90"
                              ></i>
                            </button>
                            <button
                              onClick={() =>
                                this.openRemoveLectureModal(row.original._id)
                              }
                              className="btn btn-sm btn-danger"
                            >
                              Remove Lecture
                            </button>
                          </>
                        );
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        {/* @ts-ignore */}
        <Modal
          isOpen={this.state.isRemoveLectureModalOpen}
          toggle={this.closeRemoveLectureModal}
        >
          {/* @ts-ignore */}
          <ModalHeader>Remove Lecture</ModalHeader>
          {/* @ts-ignore */}
          <ModalBody>
            <div className="alert alert-danger">
              <p className="m-0">
                Are you sure you want to remove this lecture from collection?
              </p>
            </div>
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            {/* @ts-ignore */}
            <Button
              color="secondary"
              onClick={() => this.closeRemoveLectureModal()}
            >
              Cancel
            </Button>
            {/* @ts-ignore */}
            <Button
              color="danger"
              onClick={() =>
                this._handleRemoveLectureToCollection(this.state.currentId)
              }
            >
              Remove
            </Button>
          </ModalFooter>
        </Modal>
        {/* @ts-ignore */}
        <Modal
          toggle={this.closeMoveToModal}
          isOpen={this.state.isMoveToModalOpen}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Move Lecture</h5>
              <button
                type="button"
                className="close"
                onClick={() => this.closeMoveToModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <label className="mb-1">
                  Current lecture name: {this.state.currentLectureTitle}
                </label>
              </div>
              <label className="mb-1">
                Current SL No:{this.state.currentIndex + 1}
              </label>
              <div>
                <label>Total items: {this.state.data.length} </label>
              </div>
              <input
                value={this.state.newIndex ? this.state.newIndex : ""}
                onChange={(e) =>
                  this.setState({ newIndex: parseInt(e.target.value) })
                }
                placeholder="Move to"
                type="number"
                className="form-control mt-2"
              ></input>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => this.closeMoveToModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={
                  this.state.newIndex === null ||
                  this.state.newIndex > this.state.data.length
                }
                onClick={() =>
                  this._moveArrayItemToNewIndex(
                    this.state.data,
                    this.state.currentIndex,
                    this.state.newIndex - 1
                  )
                }
              >
                Move
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default VideoCataloguePage;
