import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import { ComponentMap } from "@skyslit/ark-react/build/types";
import SubscriptionPageView from "./views/SubscriptionPage.view";
import SubscriptionEditorPageView from "./views/SubscriptionEditorPage.view";
import { Subscription } from "lakshya-shared";

export type StateType = {
  haveEditorContextUpdated: boolean;
  editorData: Subscription;
  master: Subscription[];
  hasMasterLoaded: boolean;
};

export default class SubscriptionModule extends ArkModule<StateType> {
  constructor() {
    super("SubscriptionModule");

    this.useConnect(connect);

    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          case this.actionTypes.MARK_AS_SAVED: {
            return Object.assign({}, state, {
              haveEditorContextUpdated: false,
            });
          }
          case this.actionTypes.INFLATE: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              editorData: value,
            });
          }
          case this.actionTypes.UPDATE_EDITOR_DATA: {
            const { key, value } = action.payload;
            return Object.assign({}, state, {
              haveEditorContextUpdated: true,
              editorData: Object.assign({}, state.editorData, {
                [key]: value,
              }),
            });
          }
          case this.actionTypes.SET_MASTER: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              hasMasterLoaded: true,
              master: value,
            });
          }
          default: {
            return state;
          }
        }
      };
    };

    this.main = () => {
      this.controller.refreshMaster(true);
    };
  }

  services = {
    addSubscription: (title: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/admin/subscriptions", {
            title: title,
          })
          .then((response) => {
            this.controller.refreshMaster(true);
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getAllSubscriptions: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/v2/admin/subscriptions")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getSubscriptionById: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/subscriptions/${id}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    deleteSubscriptionById: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .delete(`/api/admin/subscriptions/${id}`)
          .then((response) => {
            this.controller.refreshMaster(true);
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    saveEditorData: (id: string, value: any) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/subscriptions/${id}`, { value: value })
          .then((response) => {
            this.controller.refreshMaster(true);
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
  };

  views: ComponentMap = {
    SubscriptionPage: SubscriptionPageView,
    SubscriptionEditorPage: SubscriptionEditorPageView,
  };

  controller = {
    inflateSubscription: (value: Subscription) => {
      this.dispatch({
        type: this.actionTypes.INFLATE,
        payload: {
          value,
        },
      });
    },
    updateEditorData: (key: string, value: any) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_EDITOR_DATA,
        payload: {
          key,
          value,
        },
      });
    },
    saveEditorContext: () => {
      const dataToSave = this.getState().editorData;
      this.services
        .saveEditorData((dataToSave as any)._id, Object.assign({}, dataToSave, { attachedResources: undefined }))
        .then((response) => {
          this.dispatch({
            type: this.actionTypes.MARK_AS_SAVED,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    refreshMaster: (force: boolean = false) => {
      const state = this.getState();
      if (state.hasMasterLoaded === false || force === true) {
        this.services
          .getAllSubscriptions()
          .then((value) => {
            this.dispatch({
              type: this.actionTypes.SET_MASTER,
              payload: {
                value,
              },
            });
          })
          .catch((err) => {
            setTimeout(() => {
              // REMOVED FROM REPEAT
              // this.controller.refreshMaster(force);
            }, 100);
          });
      }
    },
  };

  actionTypes = {
    MARK_AS_SAVED: "MARK_AS_SAVED",
    INFLATE: "INFLATE",
    UPDATE_EDITOR_DATA: "UPDATE_EDITOR_DATA",
    SET_MASTER: "SET_MASTER",
  };

  initialState: StateType = {
    haveEditorContextUpdated: false,
    editorData: null,
    master: [],
    hasMasterLoaded: false,
  };
}
