import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import { ComponentMap } from "@skyslit/ark-react/build/types";
import ExamEditorView from "./view/ExamEditor.view";
import { Exam } from "lakshya-shared";
import {
  Question,
  QuestionGroup,
  AnswerSheet,
} from "lakshya-shared/build/exam";

export type StateType = {
  exam: Exam;
  haveContextUpdated: boolean;
  analytics: AnswerSheet[];
  isAnalyticsRefreshing: boolean;
};

export default class ExamEditorModule extends ArkModule<StateType> {
  constructor() {
    super("ExamEditorModule");

    this.useConnect(connect);
    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          case this.actionTypes.MARK_AS_SAVED: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: false,
              exam: Object.assign({}, state.exam, {
                isPublished: value.isPublished,
                publishWarnings: value.publishWarnings,
                publishMessage: value.publishMessage,
                hasPublishMessage: value.hasPublishMessage,
              }),
            });
          }
          case this.actionTypes.ADD_EXAM: {
            const { exams } = action.payload;
            return Object.assign({}, state, {
              exam: exams,
            });
          }
          case this.actionTypes.UPDATE_EXAM: {
            const { key, value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              exam: Object.assign({}, state.exam, {
                [key]: value,
              }),
            });
          }
          case this.actionTypes.ADD_QUESTION_GROUP: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              exam: Object.assign({}, state.exam, {
                questionGroups: [...state.exam.questionGroups, value],
              }),
            });
          }
          case this.actionTypes.ADD_QUESTION: {
            const { value, groupResId } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              exam: Object.assign({}, state.exam, {
                questionGroups: state.exam.questionGroups.map((group) => {
                  if (group.resId === groupResId) {
                    return Object.assign({}, group, {
                      questions: [...group.questions, value],
                    });
                  }
                  return group;
                }),
              }),
            });
          }
          case this.actionTypes.UPDATE_QUESTION_GROUP: {
            const { key, value, resId } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              exam: Object.assign({}, state.exam, {
                questionGroups: state.exam.questionGroups.map(
                  (questionGroup) => {
                    if (questionGroup.resId === resId) {
                      return Object.assign({}, questionGroup, {
                        [key]: value,
                      });
                    }
                    return questionGroup;
                  }
                ),
              }),
            });
          }
          case this.actionTypes.UPDATE_QUESTION_OPTION: {
            const { groupResId, value, resId, optionIndex } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              exam: Object.assign({}, state.exam, {
                questionGroups: state.exam.questionGroups.map((group) => {
                  if (group.resId === groupResId) {
                    return Object.assign({}, group, {
                      questions: group.questions.map((question) => {
                        if (question.resId === resId) {
                          return Object.assign({}, question, {
                            options: question.options.map((option, index) => {
                              if (index === optionIndex) {
                                return value;
                              }
                              return option;
                            }),
                          });
                        }
                        return question;
                      }),
                    });
                  }
                  return group;
                }),
              }),
            });
          }
          case this.actionTypes.UPDATE_QUESTION: {
            const { groupResId, key, value, resId } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              exam: Object.assign({}, state.exam, {
                questionGroups: state.exam.questionGroups.map((group) => {
                  if (group.resId === groupResId) {
                    return Object.assign({}, group, {
                      questions: group.questions.map((question) => {
                        if (question.resId === resId) {
                          return Object.assign({}, question, {
                            [key]: value,
                          });
                        }
                        return question;
                      }),
                    });
                  }
                  return group;
                }),
              }),
            });
          }
          case this.actionTypes.DELETE_QUESTION: {
            const { groupResId, resId } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              exam: Object.assign({}, state.exam, {
                questionGroups: state.exam.questionGroups.map((group) => {
                  if (group.resId === groupResId) {
                    return Object.assign({}, group, {
                      questions: group.questions.filter((question) => {
                        if (resId === question.resId) {
                          return false;
                        }
                        return true;
                      }),
                    });
                  }
                  return group;
                }),
              }),
            });
          }
          case this.actionTypes.DELETE_QUESTION_GROUP: {
            const { groupResId } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              exam: Object.assign({}, state.exam, {
                questionGroups: state.exam.questionGroups.filter((g) => {
                  if (g.resId === groupResId) {
                    return false;
                  }
                  return true;
                }),
              }),
            });
          }
          case this.actionTypes.SET_ANALYTICS_REFRESHING: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              isAnalyticsRefreshing: value,
            });
          }
          case this.actionTypes.SET_ANALYTICS: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              isAnalyticsRefreshing: false,
              analytics: value,
            });
          }
          case this.actionTypes.UPDATE_EXAM_RANK_INFO: {
            const { lastRankRefresh, isRankPublished } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              exam: Object.assign({}, state.exam, {
                lastRankRefresh,
                isRankPublished
              }),
            });
          }
          default: {
            return state;
          }
        }
      };
    };

    this.main = () => {};
  }
  controller = {
    saveExam: () => {
      const examToSave = this.getState().exam;
      this.services
        .updateExamById((examToSave as any)._id, examToSave)
        .then((response) => {
          this.dispatch({
            type: this.actionTypes.MARK_AS_SAVED,
            payload: {
              value: response,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addExam: (exams: Exam) => {
      this.dispatch({
        type: this.actionTypes.ADD_EXAM,
        payload: {
          exams,
          analytics: [],
        },
      });
    },
    updateExam: (key: string, value: any) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_EXAM,
        payload: {
          key,
          value,
        },
      });
    },
    addQuestionGroup: () => {
      this.dispatch({
        type: this.actionTypes.ADD_QUESTION_GROUP,
        payload: {
          value: {
            resId: new Date().valueOf(),
            title: "",
            questions: [],
            durationInMins: 40,
            hasTimeRestriction: false,
          } as QuestionGroup,
        },
      });
    },
    updateQuestionGroup: (
      questionGroupResId: number,
      key: string,
      value: any
    ) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_QUESTION_GROUP,
        payload: {
          key,
          value,
          resId: questionGroupResId,
        },
      });
    },
    updateQuestionOption: (
      groupResId: number,
      questionResId: number,
      optionIndex: number,
      value: any
    ) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_QUESTION_OPTION,
        payload: {
          groupResId,
          value,
          optionIndex,
          resId: questionResId,
        },
      });
    },
    updateQuestion: (
      groupResId: number,
      questionResId: number,
      key: string,
      value: any
    ) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_QUESTION,
        payload: {
          groupResId,
          key,
          value,
          resId: questionResId,
        },
      });
    },
    addQuestion: (groupResId: number, question?: Partial<Question>) => {
      question = Object.assign<Question, Partial<Question>>(
        {
          question: "",
          options: ["", "", "", ""],
          correctAnswerIndex: -1,
          bannerImageFizeSizeInBytes: 0,
          bannerImageId: null,
          questionMimeType: 'text',
          tag1: "",
          tag2: "",
          tag3: "",
          tag4: "",
          tag5: "",
          resId: new Date().valueOf(),
          hasCorrectlyMarked: false,
          providedAnswerIndex: -1,
          timeElapsedInMs: 0,
          score: 0,
          solution: "",
        },
        question || {}
      );
      this.dispatch({
        type: this.actionTypes.ADD_QUESTION,
        payload: {
          groupResId,
          value: question,
        },
      });
    },
    deleteQuestion: (groupResId: number, resId: number) => {
      this.dispatch({
        type: this.actionTypes.DELETE_QUESTION,
        payload: {
          groupResId,
          resId,
        },
      });
    },
    deleteQuestionGroup: (groupResId: number) => {
      this.dispatch({
        type: this.actionTypes.DELETE_QUESTION_GROUP,
        payload: {
          groupResId,
        },
      });
    },

    setAnalyticsRefreshing: (value: boolean) => {
      this.dispatch({
        type: this.actionTypes.SET_ANALYTICS_REFRESHING,
        payload: {
          value,
        },
      });
    },
    setAnalytics: (value: AnswerSheet[]) => {
      this.dispatch({
        type: this.actionTypes.SET_ANALYTICS,
        payload: {
          value,
        },
      });
    },
    performRefresh: (examId: string) => {
      this.dispatch({
        type: this.actionTypes.SET_ANALYTICS_REFRESHING,
        payload: {
          value: true,
        },
      });
      setTimeout(() => {
        this.services
          .getAnalytics(examId)
          .then((analytics) => {
            this.dispatch({
              type: this.actionTypes.SET_ANALYTICS,
              payload: {
                value: analytics,
              },
            });
          })
          .catch((err) => {
            this.dispatch({
              type: this.actionTypes.SET_ANALYTICS_REFRESHING,
              payload: {
                value: false,
              },
            });
            this.showError(
              err.message || "Unknown error",
              "Failed to fetch analytics",
              true
            );
          });
      }, 300);
    },
    updateRankInfo: (lastRankRefresh: string, isRankPublished: boolean) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_EXAM_RANK_INFO,
        payload: {
          lastRankRefresh,
          isRankPublished
        }
      })
    }
  };

  actionTypes = {
    MARK_AS_SAVED: "MARK_AS_SAVED",
    ADD_EXAM: "ADD_EXAM",
    UPDATE_EXAM: "UPDATE_EXAM",
    UPDATE_QUESTION: "UPDATE_QUESTION",
    UPDATE_QUESTION_OPTION: "UPDATE_QUESTION_OPTION",
    ADD_QUESTION: "ADD_QUESTION",
    DELETE_QUESTION: "DELETE_QUESTION",
    ADD_QUESTION_GROUP: "ADD_QUESTION_GROUP",
    UPDATE_QUESTION_GROUP: "UPDATE_QUESTION_GROUP",
    DELETE_QUESTION_GROUP: "DELETE_QUESTION_GROUP",

    SET_ANALYTICS: "SET_ANALYTICS",
    SET_ANALYTICS_REFRESHING: "SET_ANALYTICS_REFRESHING",
    UPDATE_EXAM_RANK_INFO: "UPDATE_EXAM_RANK_INFO",
  };

  services = {
    updateExamById: (id: string, value: Exam) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/exams/${id}`, { value: value })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getExamById: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/exams/details/${id}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    deleteExamById: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .delete(`/api/admin/exams/${id}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getAnalytics: (examId: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/exams/analytics/${examId}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    refreshRanking: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/exams/${id}/rank/sync`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    updateRankPublishStatus: (id: string, status: 'published' | 'unpublished') => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/exams/${id}/rank/status/${status}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
  };
  initialState: StateType = {
    exam: null,
    haveContextUpdated: false,
    analytics: [],
    isAnalyticsRefreshing: false,
  };

  views: ComponentMap = {
    ExamEditor: ExamEditorView,
  };
}
