import React, { useState } from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "../module";
import { useTranslation } from "react-i18next";
import AccountIdentification from "../components/AccountIdentification";
import OtpVerification from "../components/OtpVerification";
import ResetPassword from "../components/ResetPassword";
import ReoveryOptionSelector from "../components/RecoveryOptionSelector";
import ContactSupport from "../components/ContactSupport";
import { Link } from "react-router-dom";

export default (props: ViewComponentPropType<DefaultModule>) => {
  const [step, setStep] = useState("accountIdentification");
  const [otp, setOtp] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [recoveryOption, setRecoveryOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState(0);
  const { t } = useTranslation();

  // Email verification service call function
  const _handleEmailVerification = (data: any) => {
    props.module.services
      .getRecoveryOptions(data.verificationEmail)
      .then((response: any) => {
        if (response.isValid === true) {
          setStep("reoveryOptionSelector");
        } else {
          props.module.showError(
            "Please try again",
            "Email Verification Failed",
            true
          );
        }
        setRecoveryOption(response.options);
      })
      .catch(() => {
        props.module.showError(
          "Network error",
          "Email Verification Failed",
          true
        );
      });
  };
  // Intiate recovery service call function
  const _handleInitiateRecovery = (data: any) => {
    if (data.noAccess) {
      setStep("contactSupport");
    } else {
      recoveryOption[data.selectedOption].challenge = data.challenge;
      props.module.services
        .initiateRecovery(recoveryOption)
        .then((response: any) => {
          if (response.otpToken) {
            setStep("otpVerification");
          } else {
            props.module.showError(
              "Please try again",
              "Recovery Initiate Failed",
              true
            );
          }
          setOtpToken(response.otpToken);
        })
        .catch(() => {
          props.module.showError(
            "Network error",
            "Recovery Initiate Failed",
            true
          );
        });
    }
  };

  // Resending OTP service call function
  function resendOtp() {
    props.module.showWait("Resending", "Resending OTP", true);
    setTimeout(() => {
      props.module.services
        .initiateRecovery(recoveryOption)
        .then((response: any) => {
          if (response.otpToken) {
            props.module.clearAlert();
          } else {
            props.module.showError(
              "Please try again",
              "Recovery Initiate Failed",
              true
            );
          }
          setOtpToken(response.otpToken);
        })
        .catch(() => {
          props.module.showError(
            "Network error",
            "Recovery Initiate Failed",
            true
          );
        });
    }, 300);
  }

  // OTP verification service call function
  const _handleOTPVerification = (data: any) => {
    props.module.services
      .validateOTP(data.otp, otpToken)
      .then((response: any) => {
        if (response.status === "valid") {
          setStep("resetPassword");
        } else {
          props.module.showError(
            "Please try again",
            "OTP Verification Failed",
            true
          );
        }
        setOtp(data.otp);
      })
      .catch(() => {
        props.module.showError(
          "Network error",
          "OTP Verification Failed",
          true
        );
      });
  };

  // Reset password service call function
  const _handleResetPassword = async (data: any) => {
    // if (data.newPassword !== data.confirmPassword) {
    //   props.module.showError(
    //     "Entered password does not match",
    //     "Password Reset Failed",
    //     true
    //   );
    //   return;
    // }

    props.module.services
      .resetPassword(data.newPassword, otp, otpToken)
      .then((response: any) => {
        if (response.status === "success") {
          props.history.push("/auth/signin");
        } else {
          props.module.showError(
            "Please try again",
            "Password Reset Failed",
            true
          );
        }
      })
      .catch(() => {
        props.module.showError("Network error", "Password Reset Failed", true);
      });
  };

  let currentView: any = [];
  switch (step) {
    case "accountIdentification": {
      currentView = (
        <AccountIdentification onEmailVerification={_handleEmailVerification} />
      );
      break;
    }
    case "reoveryOptionSelector": {
      currentView = (
        <ReoveryOptionSelector
          onIntiateRecovery={_handleInitiateRecovery}
          setStep={setStep}
          setSelectedOption={setSelectedOption}
          recoveryOption={recoveryOption}
          selectedOption={selectedOption}
        />
      );
      break;
    }
    case "otpVerification": {
      currentView = (
        <OtpVerification
          onOtpVerification={_handleOTPVerification}
          setStep={setStep}
          resendOtp={resendOtp}
        />
      );
      break;
    }
    case "resetPassword": {
      currentView = (
        <ResetPassword
          onResetPassword={_handleResetPassword}
          setStep={setStep}
        />
      );
      break;
    }
    case "contactSupport": {
      currentView = <ContactSupport setStep={setStep} />;
      break;
    }
    default: {
      currentView = (
        <AccountIdentification onEmailVerification={_handleEmailVerification} />
      );
    }
  }
  return (
    <>
    {/* @ts-ignore */}
      <Helmet>
        <title>{t("Account Recovery")}</title>
      </Helmet>
      <div
        className="container-fluid py-5 px-4 px-md-5"
        style={{ backgroundColor: "#C9D1EB", height: "100%" }}
      >
        <div className="row">
          <div
            className="col-sm-8 col-md-6 col-lg-2 offset-sm-2 offset-md-3 offset-lg-5 pt-3 pb-5 px-sm-4 h-100 bg-light authentication-module-dialog-box"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              borderRadius: 5,
            }}
          >
            {currentView}
            <div style={{ fontSize: 14 }} className="text-center pt-5">
              <div>
                <Link to="/">{t("Help & Support")}</Link>
              </div>
              <div className="pt-2">
                <Link to="/">{t("Privacy Policy")}</Link>
              </div>
              <div className="pt-2">
                <Link to="/">{t("Terms & Conditions")}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
