import React from "react";
import { Modal } from "reactstrap";
import FileUploadModule from "../index";
import { ViewComponentPropType } from "@skyslit/ark-react";

type PropType = {
  onSuccess: (uploadedFiles: any[]) => void;
  mode?: "mini" | "default";
  label?: string;
  accept?: string;
  multiple?: boolean;
  accessMode?: 'public' | 'private';
};

export default class Dropzone extends React.Component<
  Partial<ViewComponentPropType<FileUploadModule>> & PropType,
  any
> {
  fileInputRef = React.createRef<HTMLInputElement>();

  constructor(props: any) {
    super(props);
    this.state = {
      isVideoUploaderActive: false,
      progress: 0,
      progressMessage: "Uploading...",
    };

    this.handleFileInputChange = this.handleFileInputChange.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  handleFile(files: FileList) {
    let { accessMode } = this.props;
    accessMode = accessMode ? accessMode : 'private';
    const module: FileUploadModule = this.props.module;
    if (files && files.length > 0) {
      this.setState({
        isVideoUploaderActive: true,
      });
      const formData = new FormData();
      for (var i = 0; i < files.length; i++) {
        formData.append(`file_${i}`, files[i]);
      }

      var progress = 0;
      var progressMessage = "Uploading...";

      module
        .getServiceProvider("Main")
        .post(`/api/assets/${accessMode}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

          onUploadProgress: (progressEvent) => {
            progress = (progressEvent.loaded / progressEvent.total) * 100;
            progressMessage = progress > 95 ? "Processing..." : "Uploading...";

            this.setState({
              progress,
              progressMessage,
            });
          },
        })
        .then((response) => {
          this.setState(
            {
              isVideoUploaderActive: false,
            },
            () => this.props.onSuccess && this.props.onSuccess(response.data)
          );
        })
        .catch((err) => {
          // Handle error
          console.error(err);
          this.setState({
            isVideoUploaderActive: false,
          });
        });
    }
  }

  handleFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.handleFile(this.fileInputRef.current.files);
    this.fileInputRef.current.value = "";
  }

  toggleVideoUploader = () =>
    this.setState({ isVideoUploaderActive: !this.state.isVideoUploaderActive });

  render() {
    let { mode, label } = this.props;
    mode = mode ? mode : "default";
    label = label ? label : "Upload File";
    return (
      <>
        {/* @ts-ignore */}
        <Modal
          backdrop="static"
          keyboard={false}
          toggle={this.toggleVideoUploader}
          isOpen={this.state.isVideoUploaderActive}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.state.progressMessage}</h5>
              <button className="cancel-button-wrapper">
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{ width: `${this.state.progress}%` }}
                ></div>
              </div>
            </div>
          </div>
        </Modal>
        <label className="d-block text-center">
          <input
            type="file"
            accept={this.props.accept}
            onChange={this.handleFileInputChange}
            ref={this.fileInputRef}
            multiple={this.props.multiple}
            style={{ display: 'none' }}
          />

          <span
            className={`video-gallery-wrapper btn-block mr-3 mt-0 ${
              mode === "default" ? "py-5" : "py-3"
            }`}
          >
            {mode === "default" ? <i className="fas fa-upload"></i> : null}
            <h6 className="mb-0">{label}</h6>
            {mode === "default" ? (
              <span>Browse a file from your computer</span>
            ) : null}
          </span>
        </label>
      </>
    );
  }
}
