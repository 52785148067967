import React from "react";
import ReactTable, { Filter } from "react-table";
import { Link } from "react-router-dom";
import { Subscription } from "lakshya-shared";
export default (props: any) => {
  const toggleModal = props.toggleModal;
  const openDuplicateModal = props.openDuplicateModal;

  const data = props.data;
  const TableFilter = (propertyKey: string) => (
    filter: Filter,
    row: any,
    column: any
  ): any => {
    if (row._original[propertyKey]) {
      try {
        return (
          row._original[propertyKey]
            .trim()
            .toLowerCase()
            .indexOf(filter.value.trim().toLowerCase()) > -1
        );
      } catch (e) {
        console.error(e);
      }
    }
    return false;
};

  const isMarketingAccount = React.useMemo(() => {
    let value = false;
    if (props.user) {
      value =
      props.user.roles?.includes("marketing");
    }
    return value;
  }, [props.user]);



  return (
    <>
      <div className="px-3">
        <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
          <h4>Course Manager</h4>
          <button
            onClick={toggleModal}
            className="add-course-button-wrapper px-5 py-3"
          >
            Add Course
          </button>
        </div>
        <ReactTable
          data={data}
          minRows={3}
          defaultPageSize={10}
          columns={[
            {
              Header: "Title",
              accessor: "title",
              filterable: true,
              filterMethod: TableFilter("title"),
              Cell: (row) => (
                isMarketingAccount === true ? (
                  <span
                  className="text-success"
                >
                  {row.value}
                </span>
                ): (
                  <Link
                  className="text-success"
                  rel="noopener noreferrer"
                  to={`/admin/courses/editor/${row.original._id}`}
                >
                  {row.value}
                </Link>
                )
               
              ),
            },
            {
              Header: "Status",
              accessor: "isPublished",
              width: 120,
              Cell: (row) => {
                return (
                  <span
                    className={`${
                      row.value === true
                        ? "text-success"
                        : "text-danger font-weight-bold"
                    }`}
                  >
                    {row.value === true ? "Published" : "Unpublished"}
                  </span>
                );
              },
            },
            {
              Header: "Id",
              accessor: "_id",
              Cell: (row) => {
                const value: string = row.value;
                if (value && value !== "") {
                  return value;
                } else {
                  return <span className="text-muted">N/A</span>;
                }
              },
            },
            {
              Header: "Subscription",
              accessor: "courseRateCard",
              Cell: (row) => {
                const subscription: Subscription = row.value;
                if (subscription) {
                  return (
                    <Link
                      target="_blank"
                      className="text-success"
                      rel="noopener noreferrer"
                      to={`/admin/subscriptions/editor/${
                        (subscription as any)._id
                      }`}
                    >
                      {subscription.title}
                      <i className="fas fa-external-link-alt ml-3"></i>
                    </Link>
                  );
                } else {
                  return <span className="text-muted">No Associations</span>;
                }
              },
            },
            {
              Header: "Promotions",
              accessor: "cmsTags",
              Cell: (row) => {
                const cmsTags: string[] = row.value;
                if (cmsTags) {
                  return cmsTags.join(", ");
                } else {
                  return <span className="text-muted">Disabled</span>;
                }
              },
            },
            {
              Header: "Actions",
              accessor: "_id",
              Cell: (row) => {
                return (
                  <button
                    onClick={() => openDuplicateModal(row.original._id)}
                    style={{ border: "none", borderRadius: 5 }}
                    className="btn-primary px-3 py-1"
                  >
                    Duplicate course
                  </button>
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
};
