import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "..";
import LectureListing from "../components/LectureListing";
// import { Link } from "react-router-dom";
type StateType = {
  currentView: string;
};
class VideoCataloguePage extends React.Component<
  ViewComponentPropType<DefaultModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentView: "videos",
    };
  }

  componentDidMount() { }

  render() {
    let currentTab: any = [];
    switch (this.state.currentView) {
      case "videos": {
        currentTab = (
          <LectureListing
            history={this.props.history}
            lectures={this.props.context.lectures}
            module={this.props.module}
          />
        );
        break;
      }
      default: {
        currentTab = null;
      }
    }
    return (
      <>
        {/* @ts-ignore */}
        <Helmet>
          <title>Lecture Video Manager - Lakshya eLearning System</title>
        </Helmet>
        <div className="container-fluid video-catalogue-wrapper">
          {/* <div className="row">
            <div className="col-12 px-0 top-part-wrapper">
              <div className="tab-switcher pt-4">
                <button
                  onClick={() => this.setState({ currentView: "bundle" })}
                  className={`pb-3 px-4 ${
                    this.state.currentView === "bundle" ? "selected" : ""
                  }`}
                >
                  Bundles
                </button>
                <button
                  onClick={() => this.setState({ currentView: "topic" })}
                  className={`pb-3 px-4 ${
                    this.state.currentView === "topic" ? "selected" : ""
                  }`}
                >
                  Topic
                </button>
                <button
                  onClick={() => this.setState({ currentView: "videos" })}
                  className={`pb-3 px-4 ${
                    this.state.currentView === "videos" ? "selected" : ""
                  }`}
                >
                  Videos
                </button>
              </div>
            </div>
          </div> */}
          <div className="row">{currentTab}</div>
        </div>
      </>
    );
  }
}

export default VideoCataloguePage;
