import React from "react";
import { Winner } from "lakshya-shared";
import CourseEditorModule from "..";
import { app } from "../../..";
import { resolveAssetUrl } from "../../../shared/utils";

export default (props: any) => {
  const context: Winner = props.context;
  const module: CourseEditorModule = props.module;
  const Dropzone = app.modules.FileUploader.views.Dropzone;
  const FileView = app.modules.FileUploader.views.FileView;

  return (
    <>
      <div className="lecture-details-page-wrapper">
        <div className="col-12 col-md-6 col-lg-6 current-lecture-wrapper py-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Student (User Profile)</h5>
              <h6 className="card-subtitle mb-2 text-muted">Read Only</h6>
              {typeof context.user === "object" ? (
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Key</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">ID</th>
                      <td>{context.user._id}</td>
                    </tr>
                    <tr>
                      <th scope="row">Name</th>
                      <td>{context.user.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Phone</th>
                      <td>{context.user.phoneNumber}</td>
                    </tr>
                  </tbody>
                </table>
              ) : null}
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Basic Settings</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Visibility & Information
              </h6>
              <div className="form-group mt-4">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    checked={context.isPublic}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "isPublic",
                        e.target.checked
                      )
                    }
                    className="custom-control-input"
                    id="publicCheck"
                  />
                  <label className="custom-control-label" htmlFor="publicCheck">
                    {context.isPublic === true
                      ? "This item is enabled for public visibility"
                      : "Public visibility has turned off for this item"}
                  </label>
                </div>
              </div>
              <div className="form-group mt-4">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    checked={context.isVisible}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "isVisible",
                        e.target.checked
                      )
                    }
                    className="custom-control-input"
                    id="visibilityCheck"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="visibilityCheck"
                  >
                    {context.isVisible === true
                      ? "Published for candidate action"
                      : "Not visible even to candidate"}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="exam-description">
                  Claim Message
                </label>
                <input
                  type="text"
                  value={context.claimMessage}
                  onChange={(e) => {
                    module.controller.updateEditorData(
                      "claimMessage",
                      e.target.value
                    );
                  }}
                  className="form-control"
                  placeholder="e.g. You are today's rankbuster"
                ></input>
              </div>
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="exam-description">
                  Title Message
                </label>
                <input
                  type="text"
                  value={context.titleMessage}
                  onChange={(e) => {
                    module.controller.updateEditorData(
                      "titleMessage",
                      e.target.value
                    );
                  }}
                  className="form-control"
                  placeholder="e.g. Today's Rankbuster"
                ></input>
              </div>
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="exam-description">
                  Exam Title
                </label>
                <input
                  type="text"
                  value={context.examTitle}
                  onChange={(e) => {
                    module.controller.updateEditorData(
                      "examTitle",
                      e.target.value
                    );
                  }}
                  className="form-control"
                  placeholder="e.g. General Exam, 12 June 2020"
                ></input>
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Weightage</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Prize Amount & Achievement
              </h6>
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="exam-description">
                  Prize Amount (in INR)
                </label>
                <input
                  type="text"
                  value={context.prizeAmount}
                  onChange={(e) => {
                    module.controller.updateEditorData(
                      "prizeAmount",
                      e.target.value
                    );
                  }}
                  className="form-control"
                  placeholder="e.g. You are today's rankbuster"
                ></input>
              </div>
              <hr />
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="exam-description">
                  Rank
                </label>
                <input
                  type="text"
                  value={context.rank}
                  onChange={(e) => {
                    module.controller.updateEditorData("rank", e.target.value);
                  }}
                  className="form-control"
                  placeholder="e.g. Today's Rankbuster"
                ></input>
              </div>
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="exam-description">
                  Total Participants
                </label>
                <input
                  type="text"
                  value={context.totalParticipants}
                  onChange={(e) => {
                    module.controller.updateEditorData(
                      "totalParticipants",
                      e.target.value
                    );
                  }}
                  className="form-control"
                  placeholder="e.g. Today's Rankbuster"
                ></input>
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Photo</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Upload Student Image
              </h6>

              <div className="form-group mt-4">
                {context.bannerImageId ? (
                  <>
                    <img
                      className="d-block"
                      src={resolveAssetUrl(context.bannerImageId, "public")}
                      style={{
                        height: 300,
                        maxWidth: "100%",
                        margin: "0 auto",
                      }}
                      alt="Course Banner"
                    />
                    <FileView
                      fileId={context.bannerImageId}
                      fileName="Student Image"
                      fileSizeInBytes={context.bannerImageFizeSizeInBytes}
                      onDelete={() => {
                        module.controller.updateEditorData(
                          "bannerImageId",
                          null
                        );
                        module.controller.updateEditorData(
                          "bannerImageFizeSizeInBytes",
                          0
                        );
                        module.controller.saveEditorData();
                      }}
                    />
                  </>
                ) : (
                  <Dropzone
                    label="Upload Photo"
                    accept="image/*"
                    accessMode="public"
                    onSuccess={(files: any[]) => {
                      if (files && Array.isArray(files)) {
                        if (files.length > 0) {
                          module.controller.updateEditorData(
                            "bannerImageId",
                            files[0]._id.toString()
                          );
                          module.controller.updateEditorData(
                            "bannerImageFizeSizeInBytes",
                            files[0].sizeInBytes
                          );
                          module.controller.saveEditorData();
                        }
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Beneficiary Details</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Consent & Account Details
              </h6>
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="exam-description">
                  Beneficiary Name
                </label>
                <input
                  type="text"
                  value={context.beneficiaryName}
                  onChange={(e) => {
                    module.controller.updateEditorData(
                      "beneficiaryName",
                      e.target.value
                    );
                  }}
                  className="form-control"
                  placeholder=""
                ></input>
              </div>
              <hr />
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="exam-description">
                  Google Pay / UPI ID
                </label>
                <input
                  type="text"
                  value={context.googlePayOrUPI}
                  onChange={(e) => {
                    module.controller.updateEditorData(
                      "googlePayOrUPI",
                      e.target.value
                    );
                  }}
                  className="form-control"
                  placeholder=""
                ></input>
              </div>
              <div className="form-group mt-4">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    checked={context.hasUserConfirmed}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "hasUserConfirmed",
                        e.target.checked
                      )
                    }
                    className="custom-control-input"
                    id="consentCheck"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="consentCheck"
                  >
                    {context.hasUserConfirmed === true
                      ? "User has approved the listing"
                      : "User has not approved this listing yet"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
