import React from "react";
import { LectureVideo, Subject } from "lakshya-shared";
import { useDrag } from "react-dnd";
import { ItemTypes } from "./../shared/ItemType";
import { useDrop } from "react-dnd";
import DaySelector from "./../../../shared/DaySelector";
import { Modal } from "reactstrap";
import { app } from "../../..";
import { resolveAssetUrl } from "../../../shared/utils";

export default (props: any) => {
  const [newIndex, setNewIndex] = React.useState(null);
  const [isMoveToModalOpen, setIsMoveToModalOpen] = React.useState(false);
  const toggleMoveToModal = () => setIsMoveToModalOpen(!isMoveToModalOpen);

  const subjectContext: Subject = props.subjectContext;
  const context: LectureVideo = props.context;
  const lectures: LectureVideo[] = props.lecture;
  const courseId:string = props.courseId

  const index: number = props.index;
  const module: any = props.module;
  const enableDelete: boolean = props.enableDelete || false;
  const disabledIntendation: boolean = props.disabledIntendation || false;
  const _id: string = props._id;
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.LECTURE_LISTING, payload: context, from: _id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  /* eslint-disable */

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.LECTURE_LISTING,
    canDrop: () => true,
    drop: (item) =>
      props.onMove &&
      props.onMove((item as any).payload, (item as any).from, _id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });
  const moveArrayItemToNewIndex = (
    arr: LectureVideo[],
    old_index: number,
    new_index: number
  ) => {
    if (newIndex !== null && newIndex <= arr.length) {
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      module.controller.updateSubject(
        module.getState().selectedSubjectIndex,
        "lectures",
        arr
      );
      setNewIndex(null);
      toggleMoveToModal();

      return arr;
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }} ref={drop}>
        <div>{index + 1}</div>
        <div
          ref={drag}
          onClick={props.onClick}
          className={`lecture-component-wrapper pt-3 btn-block mr-3 mt-0 ${
            disabledIntendation === true ? "" : "px-5"
          }`}
          style={{
            position: "relative",
            opacity: isDragging ? 0.5 : 1,
            backgroundColor: "white",
          }}
        >
          <div className="lecture-content-wraper pb-4">
            <div>
              <div>
                <a
                  target="_blank"
                  type="primary"
                  href={`/admin/videos/lectures/${(context as any)._id}`}
                >
                  {context.title}
                </a>
              </div>
              <i className="fas fa-video pr-3"></i>
              <label>
                Video -
                {!isNaN(context.videoDurationInMinutes)
                  ? ` ${context.videoDurationInMinutes} min,`
                  : ""}{" "}
                {context.notes.length} Notes,{" "}
                {context.exams ? context.exams.length : 0} Exams
              </label>
            </div>
            <DaySelector
              label="Allotted Days"
              value={(() => {
                return subjectContext.lectureDaySchedulePlan
                  ? subjectContext.lectureDaySchedulePlan[
                      (context as any)._id
                    ] || []
                  : [];
              })().map((d) => ({
                label: `Day ${d}`,
                value: d,
              }))}
              onChange={props.onTagUpdate}
            />
            <input
              value={(() => {
                return subjectContext.lectureTopicMetadata
                  ? subjectContext.lectureTopicMetadata[(context as any)._id] ||
                      ""
                  : "";
              })()}
              onChange={(e) =>
                props.onTopicChange && props.onTopicChange(e.target.value)
              }
              placeholder="Topic Label"
              className="form-control mt-2"
              style={{ width: 300 }}
            />
            {enableDelete === true ? (
              <button
                onClick={props.onDelete}
                className="btn btn-secondary btn-sm"
                style={{ position: "absolute", right: 20, top: "33%" }}
              >
                <i className="fas fa-times"></i>
              </button>
            ) : null}
            
            <button
              onClick={() => toggleMoveToModal()}
              style={{
                height: 27,
                fontSize: 12,
                position: "absolute",
                right: 60,
                top: "33%",
              }}
              className="btn btn-primary d-flex align-items-center"
            >
              Move to
            </button>
            <button
             style={{
              height: 27,
              fontSize: 12,
              position: "absolute",
              right: 130,
              top: "33%",
            }}
                  onClick={() => {
                    app.modules.Composer.showComposer({
                      body: '',
                      imageUrl: resolveAssetUrl(context.bannerImageId, 'public'),
                      resourceID: courseId,
                      resourceType: 'course',
                      title: context.title,
                      subjectId: (subjectContext as any)._id,
                      lectureId: (context as any)._id,
                      clickAction: 'open_course_lecture'
                    })
                  }}
                  className="delete-button-wrapper mr-3"
                >
                  <i className="fas fa-paper-plane"></i>
                </button>
          </div>
          <hr className="m-0" />
        </div>
      </div>
      {/* @ts-ignore */}
      <Modal toggle={toggleMoveToModal} isOpen={isMoveToModalOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Move Lecture</h5>
            <button type="button" className="close" onClick={toggleMoveToModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <label className="mb-1">Current SL No: {index + 1}</label>
            <div>
              <label>Total items: {lectures.length}</label>
            </div>
            <input
              value={newIndex ? newIndex : ""}
              onChange={(e) => setNewIndex(e.target.value)}
              placeholder="Move to"
              type="number"
              className="form-control mt-2"
            ></input>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={toggleMoveToModal}
            >
              Cancel
            </button>
            <button
              onClick={() =>
                moveArrayItemToNewIndex(lectures, index, newIndex - 1)
              }
              type="button"
              disabled={newIndex === null || newIndex > lectures.length}
              className="btn btn-primary"
            >
              Move
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
