import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { ViewComponentPropType } from "@skyslit/ark-react";
import NotificationComposerModule from "./module";
import { ComposerOptions } from "lakshya-shared";

type PropType = {
  isOpen: boolean;
  toggle: any;
} & Partial<ComposerOptions>;

const defaultImage = require("./assets/default-image.png");

export default class NotifciationModal extends React.Component<
  ViewComponentPropType<NotificationComposerModule> & PropType,
  ComposerOptions & { [key: string]: any }
> {
  constructor(
    props: ViewComponentPropType<NotificationComposerModule> & PropType
  ) {
    super(props);
    this.state = {
      body: props.body || "",
      groupTargets: props.groupTargets || [
        "lakshya-students",
        "purchased-users",
      ],
      imageUrl: props.imageUrl || null,
      resourceID: props.resourceID || null,
      resourceType: props.resourceType || null,
      targetPhoneNumber: props.targetPhoneNumber || "",
      targetType: props.targetType || "specific-user",
      title: props.title || "",
      clickAction: props.clickAction || null,
      lectureId: props.lectureId || "",
      subjectId: props.subjectId || "",

      __isConfirmationOpen: false,
      __isConfirmationBusy: false,
      __errorMessage: null,
    };
  }

  showConfirmation = () =>
    this.setState({ __isConfirmationOpen: true, __isConfirmationBusy: false });
  closeConfirmation = () =>
    this.setState({ __isConfirmationOpen: false, __errorMessage: null });

  send = () => {
    this.setState(
      {
        __isConfirmationBusy: true,
      },
      () =>
        setTimeout(() => {
          this.props.module.services
            .sendNotification(this.state)
            .then((value) => {
              this.closeConfirmation();
              this.props.toggle && this.props.toggle();
              this.props.module.showMessage(
                "Notification sent successfully",
                "Process Complete",
                true
              );
            })
            .catch((err) => {
              console.error(err);
              this.closeConfirmation();
              this.setState({
                __errorMessage: err.message || "Something went wrong",
              });
            });
        }, 300)
    );
  };

  render() {
    const { isOpen, toggle } = this.props;
    return (
      <>
        {/* @ts-ignore */}
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          keyboard={false}
          backdrop="static"
          size="lg"
        >
          {/* @ts-ignore */}
          <ModalHeader toggle={toggle}>Compose Message</ModalHeader>
          {/* @ts-ignore */}
          <ModalBody>
            <table className="table table-striped table-sm">
              <tbody>
                <tr>
                  <td
                    style={{ width: 100, verticalAlign: "middle" }}
                    rowSpan={4}
                  >
                    <img
                      width="100"
                      src={
                        this.state.imageUrl ? this.state.imageUrl : defaultImage
                      }
                      alt="Notification banner"
                    />
                  </td>
                  <td>
                    <small>Properties</small>
                  </td>
                </tr>
                <tr>
                  <th>Resource Type: {this.state.resourceType}</th>
                </tr>
                <tr>
                  <th>ID: {this.state.resourceID}</th>
                </tr>
                <tr>
                  <th>Action: {this.state.clickAction}</th>
                </tr>
              </tbody>
            </table>

            <div className="form-group">
              <label>Title</label>
              <input
                value={this.state.title}
                onChange={(e) => this.setState({ title: e.target.value })}
                type="text"
                className="form-control"
                maxLength={100}
              />
            </div>
            <div className="form-group">
              <label>Body</label>
              <textarea
                value={this.state.body}
                onChange={(e) => this.setState({ body: e.target.value })}
                maxLength={255}
                className="form-control"
                rows={3}
                placeholder="Your message"
              ></textarea>
            </div>
            <fieldset>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  name="targetType"
                  type="radio"
                  id="specific-user"
                  checked={this.state.targetType === "specific-user"}
                  onChange={(e) =>
                    e.target.checked === true
                      ? this.setState({ targetType: "specific-user" })
                      : null
                  }
                />
                <label className="form-check-label" htmlFor="specific-user">
                  Specific User
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  name="targetType"
                  type="radio"
                  id="everyone"
                  checked={this.state.targetType === "everyone"}
                  onChange={(e) =>
                    e.target.checked === true
                      ? this.setState({ targetType: "everyone" })
                      : null
                  }
                />
                <label className="form-check-label" htmlFor="everyone">
                  Everyone
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  name="targetType"
                  type="radio"
                  id="group"
                  checked={this.state.targetType === "group"}
                  onChange={(e) =>
                    e.target.checked === true
                      ? this.setState({ targetType: "group" })
                      : null
                  }
                />
                <label className="form-check-label" htmlFor="group">
                  Group
                </label>
              </div>
            </fieldset>
            {this.state.targetType === "specific-user" ? (
              <div className="form-group mt-3">
                <label>Phone Number</label>
                <input
                  value={this.state.targetPhoneNumber}
                  onChange={(e) =>
                    this.setState({ targetPhoneNumber: e.target.value })
                  }
                  type="text"
                  className="form-control"
                />
              </div>
            ) : null}
            {this.state.targetType === "group" ? (
              <div className="mt-3">
                <fieldset>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      name="targetType"
                      type="checkbox"
                      id="lakshya-students"
                      checked={
                        this.state.groupTargets.indexOf("lakshya-students") > -1
                      }
                      onChange={(e) =>
                        e.target.checked === true
                          ? this.setState({
                            groupTargets: [
                              ...this.state.groupTargets,
                              "lakshya-students",
                            ],
                          })
                          : this.setState({
                            groupTargets: this.state.groupTargets.filter(
                              (item) => item !== "lakshya-students"
                            ),
                          })
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="lakshya-students"
                    >
                      Lakshya Students
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      name="targetType"
                      type="checkbox"
                      id="purchased-users"
                      checked={
                        this.state.groupTargets.indexOf("purchased-users") > -1
                      }
                      onChange={(e) =>
                        e.target.checked === true
                          ? this.setState({
                            groupTargets: [
                              ...this.state.groupTargets,
                              "purchased-users",
                            ],
                          })
                          : this.setState({
                            groupTargets: this.state.groupTargets.filter(
                              (item) => item !== "purchased-users"
                            ),
                          })
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="purchased-users"
                    >
                      Purchased Users
                    </label>
                  </div>
                </fieldset>
              </div>
            ) : null}
            {this.state.targetType === "everyone" ? (
              <div className="alert alert-warning mt-3" role="alert">
                This option will send out notification to all user
              </div>
            ) : null}
            {this.state.__errorMessage ? (
              <div className="alert alert-danger mt-3" role="alert">
                {this.state.__errorMessage}
              </div>
            ) : null}
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            {/* @ts-ignore */}
            <Button onClick={this.showConfirmation} color="info">
              {/* @ts-ignore */}
              Send Now
            </Button>
          </ModalFooter>
        </Modal>
        {/* @ts-ignore */}
        <Modal
          isOpen={this.state.__isConfirmationOpen}
          keyboard={false}
          backdrop="static"
        >
          {/* @ts-ignore */}
          <ModalHeader>Publish Confirmation</ModalHeader>
          {/* @ts-ignore */}
          <ModalBody>
            You cannot undo this action. Do you really want to publish this push
            notification now?
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            {/* @ts-ignore */}
            <Button
              disabled={this.state.__isConfirmationBusy}
              onClick={this.closeConfirmation}
              color="secondary"
            >
              Cancel
            </Button>
            {/* @ts-ignore */}
            <Button
              disabled={this.state.__isConfirmationBusy}
              onClick={this.send}
              color="success"
            >
              {this.state.__isConfirmationBusy === true ? (
                <>
                  <i className="fas fa-circle-notch fa-spin" /> Sending...
                </>
              ) : (
                "Confirm"
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
