import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default (props: any) => {
  const { t } = useTranslation();
  const setStep = props.setStep;
  return (
    <>
      <div style={{ flex: 1 }} className="pt-5 px-4">
        <div className="text-center">
          <h6 className="text-secondary">{t("Don't have access?")}</h6>
          <h5>{t("Contact Support")}</h5>
          <hr />
        </div>
        <div className="px-4" style={{ display: "flex", alignItems: "center" }}>
          <i style={{ fontSize: 35 }} className="fas fa-mobile-alt"></i>
          <div className="px-3">
            <div>
              <label className="mb-0 text-secondary">{t("Call us")}</label>
            </div>
            <div>
              <a className="text-secondary" href="tel:21) 8500 404 930">
                {t("21) 8500 404 930")}
              </a>
            </div>
          </div>
        </div>
        <div
          className="pl-4 pt-5"
          style={{ display: "flex", alignItems: "center" }}
        >
          <i style={{ fontSize: 35 }} className="far fa-envelope"></i>
          <div className="px-3">
            <div>
              <label className="mb-0 text-secondary">{t("Mail us")}</label>
            </div>
            <div>
              <a className="text-secondary" href="mailto:support@contact.com">
                {t("support@contact.com")}
              </a>
            </div>
          </div>
        </div>
        <div className="text-right pt-5">
          <div>
            <button
              id="verification-nav"
              onClick={() => setStep("reoveryOptionSelector")}
              className="btn btn-link"
            >
              {t("Use a verification option")}
            </button>
          </div>
          <div className="pt-3">
            <Link id="ok" to="/auth/signin" className="btn btn-info px-5">
              {t(" Ok")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
