import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "..";
import { Link } from "react-router-dom";
import FormView from "../components/FormView";
import { Modal } from "reactstrap";
import { app } from './../../../index';

class Editor extends React.Component<
  ViewComponentPropType<DefaultModule>,
  any
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      isDeleteErrorOpen: false,
      deleteErrorMessage: "",
      deleteErrorWarnings: [],
      isDeleting: false,
    };

    this._handleGetItemById = this._handleGetItemById.bind(this);
    this._handleUpdateCourse = this._handleUpdateCourse.bind(this);
  }

  _handleGetItemById(id: string) {
    this.props.module.services
      .getOneById(id)
      .then((response: any) => {
        this.props.module.controller.setEditorData(response);
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching data failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }

  _handleUpdateCourse() {
    this.props.module.controller.saveEditorData();
  }

  componentDidMount() {
    this._handleGetItemById((this.props.match.params as any).id);
  }

  componentWillMount() {
    this.props.module.controller.setEditorData(null);
  }

  toggleDeleteModal = () =>
    this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen });
  toggleDeleteErrorModal = () =>
    this.setState({ isDeleteErrorOpen: !this.state.isDeleteErrorOpen });

  deleteItem = () => {
    this.setState(
      {
        isDeleting: true,
      },
      () =>
        setTimeout(() => {
          this.props.module.services
            .delete((this.props.match.params as any).id)
            .then((data) => {
              this.setState(
                {
                  isDeleteErrorOpen: false,
                  isDeleteModalOpen: false,
                },
                () => {
                  this.props.history.replace("/admin/winners");
                }
              );
            })
            .catch((err) => {
              if (err.type && err.type === "has-warnings") {
                this.setState({
                  isDeleting: false,
                  deleteErrorWarnings: err.warnings || [],
                  isDeleteErrorOpen: true,
                  isDeleteModalOpen: false,
                  deleteErrorMessage:
                    err.message ||
                    "Unknown error. Check your connection and try again.",
                });
              } else {
                this.setState({
                  isDeleting: false,
                  deleteErrorWarnings: [],
                  isDeleteErrorOpen: true,
                  isDeleteModalOpen: false,
                  deleteErrorMessage:
                    err.message ||
                    "Unknown error. Check your connection and try again.",
                });
              }
            });
        }, 300)
    );
  };

  render() {
    const context = this.props.context;

    if (!context.editorData) {
      return <label>Loading</label>;
    }

    return (
      <>
        {/* @ts-ignore */}
        <Helmet>
          <title>{`Winner Announcement Editor > ${context.editorData.winnerName}`}</title>
        </Helmet>
        <div className="container-fluid course-editor">
          <div className="row pb-5">
            <div
              className="col-12 d-flex flex-direction-column header-wrapper py-4 px-4"
              style={{ position: "sticky" }}
            >
              <div className="flex-shrink-0 d-flex flex-direction-column justify-content-center align-items-center">
                <Link to="/admin/winners" className="go-back-button">
                  <i className="fas fa-arrow-left"></i>
                </Link>
                <input
                  placeholder="Name..."
                  className="ml-2 pl-2 title-edit-input"
                  value={context.editorData.winnerName}
                  onChange={(e) =>
                    this.props.module.controller.updateEditorData(
                      "winnerName",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="flex-fill search-wrapper pl-5"></div>
              <div className="flex-shrink-0 d-flex flex-direction-row">
                <div className="status-wrapper pr-4">
                  <i
                    className={`fas fa-circle pr-2 ${context.editorData.isPublished === false
                        ? "text-danger"
                        : ""
                      }`}
                  ></i>
                  <label className="mb-0">
                    {context.editorData.isPublished === true
                      ? "Published"
                      : "Not Published"}
                  </label>
                </div>
                <button
                  disabled={!this.props.context.haveContextUpdated}
                  type="button"
                  className="btn btn-primary save-button-wrapper px-5 py-2 mr-4"
                  onClick={this._handleUpdateCourse}
                >
                  Save
                </button>
                <button
                  disabled={this.props.context.haveContextUpdated}
                  onClick={() => {
                    app.modules.Composer.showComposer({
                      body: '',
                      imageUrl: null,
                      resourceID: (context.editorData as any)._id,
                      resourceType: 'winner_announcement',
                      title: '',
                      clickAction: 'open_learn_tab',
                      targetPhoneNumber: context.editorData.user ? context.editorData.user.phoneNumber : ''
                    })
                  }}
                  className="delete-button-wrapper mr-3"
                >
                  <i className="fas fa-paper-plane"></i>
                </button>
                <button
                  onClick={this.toggleDeleteModal}
                  className="delete-button-wrapper"
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            </div>
            <div className="col">
              {context.editorData.isVisible === true &&
                context.editorData.publishWarnings.length > 0 ? (
                <div className="card mt-3">
                  <div className="card-body">
                    <div className="alert alert-danger mb-0" role="alert">
                      <h4 className="alert-heading">Item Offline!</h4>
                      <p>
                        This content cannot be seen by students and other users
                        through the app. To make it public, please resolve below
                        warnings.
                      </p>
                      <hr />
                      <ol>
                        {context.editorData.publishWarnings.map(
                          (warning, index) => (
                            <li key={index}>{warning}</li>
                          )
                        )}
                      </ol>
                    </div>
                  </div>
                </div>
              ) : null}
              <FormView
                context={context.editorData}
                module={this.props.module}
              />
            </div>
          </div>
        </div>
        {/* @ts-ignore */}
        <Modal
          toggle={this.toggleDeleteModal}
          isOpen={this.state.isDeleteModalOpen}
          keyboard={false}
          backdrop="static"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Confirmation</h5>
              <button
                disabled={this.state.isDeleting}
                type="button"
                className="close"
                onClick={this.toggleDeleteModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="alert alert-warning" role="alert">
                You are about to delete this item. Do you want to continue?
              </div>
            </div>
            <div className="modal-footer">
              <button
                disabled={this.state.isDeleting}
                type="button"
                className="btn btn-secondary"
                onClick={this.toggleDeleteModal}
              >
                Close
              </button>
              <button
                disabled={this.state.isDeleting}
                onClick={() => this.deleteItem()}
                type="button"
                className="btn btn-danger"
              >
                {this.state.isDeleting === true
                  ? "Deleting..."
                  : "Delete"}
              </button>
            </div>
          </div>
        </Modal>
        {/* @ts-ignore */}
        <Modal
          toggle={this.toggleDeleteErrorModal}
          isOpen={this.state.isDeleteErrorOpen}
          keyboard={false}
          backdrop="static"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Warnings</h5>
              <button
                type="button"
                className="close"
                onClick={this.toggleDeleteErrorModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {Array.isArray(this.state.deleteErrorWarnings) &&
                this.state.deleteErrorWarnings.length > 0 ? (
                <div className="alert alert-warning" role="alert">
                  <p>Please resolve these warnings and try again</p>
                  <ol>
                    {this.state.deleteErrorWarnings.map((msgs, index) => {
                      return <li key={index}>{msgs}</li>;
                    })}
                  </ol>
                </div>
              ) : (
                <div className="alert alert-warning" role="alert">
                  {this.state.deleteErrorMessage}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                disabled={this.state.isDeleting}
                type="button"
                className="btn btn-secondary"
                onClick={this.toggleDeleteErrorModal}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default Editor;
