import React from "react";
import { Subject, LectureVideo } from "lakshya-shared";
import humanize from "humanize-duration";
import { Modal } from "reactstrap";

export default (props: any) => {
  const item: Subject = props.item;
  const index: any = props.index;
  const subjects: Subject[] = props.subjects;
  const module = props.module;
  const [newIndex, setNewIndex] = React.useState(null);

  const [isMoveToModalOpen, setIsMoveToModalOpen] = React.useState(false);
  const toggleMoveToModal = () => setIsMoveToModalOpen(!isMoveToModalOpen);

  const moveArrayItemToNewIndex = (
    arr: Subject[],
    old_index: number,
    new_index: number
  ) => {
    if (newIndex !== null && newIndex <= arr.length) {
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      module.controller.updateCourse("subjects", arr);
      setNewIndex(null);
      toggleMoveToModal();
      return arr;
    }
  };
  return (
    <>
      <div className="subject-wrapper">
        <div
          style={{
            backgroundColor: "#e3e3e3",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="mr-5 mt-5 py-1 px-3"
        >
          <div>
            <i style={{ color: "#c5c5c5" }} className="fas fa-book px-2"></i>
          </div>
          <button className="move-button-wrapper" onClick={toggleMoveToModal}>
            <i className="fas fa-compress-alt rotate90"></i>
          </button>
        </div>
        <button
          onClick={props.onClick}
          className="subject-component-wrapper mr-5 pb-2 pl-0 pt-0 pr-1"
        >
          <div className="overlay px-3 pt-3">
            <label style={{ color: "#c5c5c5" }}>#{index + 1}</label>
            <h6>{item.title}</h6>
            <div>
              <label className="mb-0 pt-1">
                {item.lectures.length} Lectures Videos
              </label>
            </div>
            <div>
              <label className="mb-0">
                {humanize(
                  item.lectures.reduce(
                    (accumulator: number, _item: LectureVideo) => {
                      return (
                        accumulator +
                        (_item.videoDurationInMinutes || 0) * 60000
                      );
                    },
                    0
                  )
                )}
              </label>
            </div>
            <div></div>
          </div>
        </button>
      </div>
      {/* @ts-ignore */}

      <Modal toggle={toggleMoveToModal} isOpen={isMoveToModalOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Move Subjects</h5>
            <button type="button" className="close" onClick={toggleMoveToModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <label className="mb-1">Current SL No: {index + 1}</label>
            <div>
              <label>Total items: {subjects.length}</label>
            </div>
            <input
              value={newIndex ? newIndex : ""}
              onChange={(e) => setNewIndex(e.target.value)}
              placeholder="Move to"
              type="number"
              className="form-control mt-2"
            ></input>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={toggleMoveToModal}
            >
              Cancel
            </button>
            <button
              onClick={() =>
                moveArrayItemToNewIndex(subjects, index, newIndex - 1)
              }
              type="button"
              disabled={newIndex === null || newIndex > subjects.length}
              className="btn btn-primary"
            >
              Move
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
