import React, { createRef } from "react";
import {
  Modal,
} from "reactstrap";

import { AxiosInstance } from "axios";
import { app } from "../../..";

export class ImportModal extends React.Component<any, any> {
  fileInputRef = createRef<HTMLInputElement>();
  constructor(props: any) {
    super(props);
    this.state = {
      isVideoUploaderActive: false,
      progress: 0,
      progressMessage: "Uploading...",
      selectedOption: "regular",
      issues: [],
      hasIssues: false,
      answerInIndex: false
    };
  }

  handleFile = (files: FileList) => {
    if (files && files.length > 0) {
      this.setState({
        isVideoUploaderActive: true,
      });

      const formData = new FormData();
      for (var i = 0; i < files.length; i++) {
        formData.append("csv", files[i]);
      }

      var progress = 0;
      var progressMessage = "Uploading...";
      var axios: AxiosInstance = this.props.axios;
      axios
        .post(
          '/api/admin/lakshya-students/upload',
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },

            onUploadProgress: (progressEvent) => {
              progress = (progressEvent.loaded / progressEvent.total) * 100;
              progressMessage =
                progress > 95 ? "Processing..." : "Uploading...";

              this.setState({
                progress,
                progressMessage,
              });
            },
          }
        )
        .then((response) => {
          setTimeout(() => {
            this.setState(
              {
                isVideoUploaderActive: false,
              },
              () => {
                const data = response.data;
                if (data) {
                  if (data.hasIssues === true) {
                    this.setState({
                        hasIssues: true,
                        issues: data.issues || [],
                      });
                  } else {
                    this.props.onSuccess &&
                      this.props.onSuccess(response.data);
                  }
                }
              }
            );
          }, 1200);
        })
        .catch((err) => {
          // Handle error
          console.error(err);
          this.setState({
            isVideoUploaderActive: false,
          });
        });
    }
  };

  handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.handleFile(this.fileInputRef.current.files);
    this.fileInputRef.current.value = "";
  };

  toggleIssueModal = () => this.setState({ hasIssues: !this.state.hasIssues });

  render() {
    return (
      <>
        {/* @ts-ignore */}
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}
          keyboard={false}
          backdrop="static"
        >
          <div className="modal-content">
            {this.state.isVideoUploaderActive === true ? (
              <>
                <div className="modal-header">
                  <h5 className="modal-title">{this.state.progressMessage}</h5>
                </div>
                <div className="modal-body">
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${this.state.progress}%` }}
                    ></div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="modal-header">
                  <h5 className="modal-title">Bulk Import</h5>
                </div>
                <div className="modal-body">
                  <p>
                    Please select the file format below and click '
                    <em>Choose File</em>' to initiate upload
                  </p>
                  <a
                    rel="noopener noreferrer"
                    href={`${
                      app.getConfig().Main.baseURL
                    }/api/admin/lakshya-students/download/sample`}
                    target="_blank"
                    className="btn btn-secondary mt-3"
                  >
                    <i className="fas fa-download"></i> Download sample .csv
                    file
                    {(() => {
                      const selectedOption: string = this.state.selectedOption;
                      switch (selectedOption) {
                        case "regular": {
                          return " (regular)";
                        }
                        case "with-solution": {
                          return " (with solution)";
                        }
                        default: {
                          return "";
                        }
                      }
                    })()}
                  </a>
                </div>
              </>
            )}
            <div className="modal-footer d-flex justify-content-between">
              <div></div>
              <div>
                <label
                  htmlFor="spreadsheet-upload"
                  className="btn btn-primary m-0"
                  style={{
                    cursor: "pointer",
                    opacity:
                      this.state.isVideoUploaderActive === true ? 0.5 : 1,
                  }}
                >
                  <i className="fas fa-upload"></i> Upload File
                  <input
                    disabled={this.state.isVideoUploaderActive}
                    ref={this.fileInputRef}
                    onChange={this.handleFileInputChange}
                    name="csv"
                    type="file"
                    style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                    id="spreadsheet-upload"
                  />
                </label>
                <button
                  disabled={this.state.isVideoUploaderActive}
                  type="button"
                  className="btn btn-default"
                  onClick={this.props.onToggle}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
          {/* @ts-ignore */}
        <Modal isOpen={this.state.hasIssues} toggle={this.toggleIssueModal}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Import Cancelled</h5>
            </div>
            <div className="modal-body">
              <div className="alert alert-warning" role="alert">
                <p>
                  We have found few issues with the content you are trying to
                  import. Please resolve these warnings and try again.
                </p>
                <hr />
                <ol>
                  {this.state.issues.map((issue: string, index: number) => (
                    <li key={index}>{issue}</li>
                  ))}
                </ol>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div></div>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.toggleIssueModal}
                >
                  Hide
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}