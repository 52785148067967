import React from "react";
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { ViewComponentPropType } from "@skyslit/ark-react";
import FileUploadModule from ".";

export default class QueueManager extends React.Component<
  ViewComponentPropType<FileUploadModule>,
  any
> {
  refreshInterval: number = 15;

  constructor(props: any) {
    super(props);

    this.state = {
      refreshing: false,
      rows: [],
      tick: this.refreshInterval,
      state: "UNKNOWN",
      isCancellationModalVisible: false,
      isPullDataModal: false,
      forcePull: false,
      pullCount: 10,
      operationState: "video",
    };
  }

  timer: any = null;
  cancelTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  trigger = () => {
    this.cancelTimer();
    this.setState({ tick: this.refreshInterval });
    this.timer = setInterval(() => {
      this.setState({ tick: this.state.tick - 1 }, () => {
        if (this.state.tick < 1) {
          this.refresh();
          // this.cancelTimer();
        }
      });
    }, 1000);
  };

  refresh = () => {
    this.cancelTimer();
    this.setState(
      {
        refreshing: true,
      },
      () => {
        this.props.module.services
          .getQueueItems()
          .then((res: any) => {
            this.setState(
              {
                state: res.state,
                rows: res.items.map((r: any) => {
                  r.lastStateUpdatedOn = moment(r.lastStateUpdatedOn).format(
                    "lll"
                  );
                  r.createdAt = moment(r.createdAt).format("lll");
                  return r;
                }),
                refreshing: false,
              },
              () => this.trigger()
            );
          })
          .catch((err) => {
            console.error(err);
            this.props.module.showError(
              err.message || "Something went wrong",
              "Connection Error",
              true
            );
            this.setState({
              refreshing: false,
            });
          });
      }
    );
  };

  requestCancellation = () => {
    this.setState(
      {
        isCancellationModalVisible: false,
      },
      () => {
        this.props.module.showWait(
          "Requesting cancelation...",
          "Please wait",
          false
        );
        setTimeout(() => {
          this.props.module.services
            .cancelProcess()
            .then(() => {
              this.props.module.showMessage(
                "Cancellation requested!",
                "Success",
                true
              );
              setTimeout(() => {
                this.refresh();
              }, 1000);
            })
            .catch((err) => {
              console.error(err);
              this.props.module.showError(
                err.message || "Something went wrong",
                "Connection Error",
                true
              );
            });
        }, 800);
      }
    );
  };

  performPullData = () => {
    if (this.state.operationState === "video") {
      this.props.module.services
        .pullData(this.state.forcePull, this.state.pullCount)
        .then((res) => {
          this.props.module.showMessage(
            "Data pull completed, you may need to start the process manually",
            "Success",
            true
          );
          setTimeout(() => {
            this.refresh();
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          this.props.module.showError(
            err.message || "Unknown error",
            "Request Error",
            true
          );
        });
    } else {
      this.props.module.services
        .pullPdfData(this.state.forcePull, this.state.pullCount)
        .then((res) => {
          this.props.module.showMessage(
            "Data pull completed, you may need to start the process manually",
            "Success",
            true
          );
          setTimeout(() => {
            this.refresh();
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          this.props.module.showError(
            err.message || "Unknown error",
            "Request Error",
            true
          );
        });
    }
  };

  activateTrigger = () => {
    this.props.module.showWait("Activating trigger...", "Executing", false);
    setTimeout(() => {
      this.props.module.services
        .activateTrigger()
        .then((val) => {
          this.props.module.showMessage(
            "Trigger activated successfully",
            "Success",
            true
          );
          setTimeout(() => {
            this.refresh();
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          this.props.module.showError(
            err.message || "Unknown error",
            "Request Error",
            true
          );
        });
    }, 1000);
  };

  handledeleteFromQueue(fileId: any) {
    this.props.module.services
      .deleteFromQueueAndS3(fileId)
      .then((response: any) => {
        this.refresh();
        console.log("pdf deleted succesfully");
      })
      .catch(() => { });
  }



  componentDidMount() {
    this.refresh();
  }

  render() {
    let isStaffAccount = false;
    if (this.props.global.userInfo) {
      isStaffAccount =
        this.props.global.userInfo.roles?.includes("staff");
    }

    return (
      <>
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  Queue Manager ({this.state.rows.length} item(s) pending)
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <span>STATE: </span>
                      <span
                        className={
                          this.state.state === "RUNNING"
                            ? "text-success"
                            : "text-muted"
                        }
                      >
                        <b>{this.state.state}</b>
                      </span>
                    </div>
                    <div>
                      <span className="pr-3">
                        Auto refresh in {this.state.tick}s
                      </span>
                      <button
                        disabled={this.state.refreshing === true}
                        onClick={() => this.refresh()}
                        className="btn btn-primary"
                      >
                        <i
                          className={`fas fa-sync ${this.state.refreshing === true ? "fa-spin" : ""
                            }`}
                        ></i>
                      </button>
                      <button
                        onClick={() => this.activateTrigger()}
                        className="btn ml-3 btn-info"
                      >
                        <i className="fas fa-play" />
                      </button>
                      <button
                        onClick={() =>
                          this.setState({ isCancellationModalVisible: true })
                        }
                        className="btn ml-3 btn-secondary"
                      >
                        <i className="fas fa-stop" />
                      </button>
                      {!isStaffAccount ? (
                        <button
                          onClick={() => this.setState({ isPullDataModal: true })}
                          className="btn ml-4 btn-warning"
                        >
                          <i className="fas fa-file-export" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <table className="table table-striped mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col" style={{ width: 100 }}>
                          File ID
                        </th>
                        <th scope="col">Type</th>
                        <th scope="col">Status</th>
                        <th scope="col" style={{ width: "40%" }}>
                          Progress
                        </th>
                        <th scope="col">Last Updated On</th>
                        <th scope="col">Created On</th>
                        {!isStaffAccount ? (
                          <th scope="col">Action</th>

                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.rows.map((row: any, index: number) => {
                        let progress: number = 0;

                        switch (row.state) {
                          case "downloading":
                          case "processing":
                          case "uploading": {
                            try {
                              let total = 0;
                              let loaded = 0;
                              if (row) {
                                if (row.processProgress) {
                                  total = parseFloat(
                                    row.processProgress[row.state].total
                                  );
                                  loaded = parseFloat(
                                    row.processProgress[row.state].loaded
                                  );
                                }
                              }

                              progress = (loaded / total) * 100;
                              if (isNaN(progress)) {
                                progress = 0;
                              }
                            } catch (e) {
                              // Do nothing
                            }
                            break;
                          }
                          case "ready": {
                            progress = 100;
                            break;
                          }
                          default: {
                            progress = 0;
                            break;
                          }
                        }

                        return (
                          <tr key={row._id}>
                            <th scope="row">{index + 1}</th>
                            <td>{`${row.title}(${row.fileId})`}</td>
                            <td>{row.type === "pdf" ? "Pdf" : "Video"}</td>
                            <td>{row.state}</td>
                            <td>
                              {row.type === "pdf" ? (
                                <div className="progress">
                                  <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    style={{ width: "100%" }}
                                  ></div>
                                </div>
                              ) : (
                                <div className="progress">
                                  <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    style={{ width: `${progress}%` }}
                                  ></div>
                                </div>
                              )}
                            </td>
                            <td>{row.lastStateUpdatedOn}</td>
                            <td>{row.createdAt}</td>
                            {!isStaffAccount ? (
                              <td>
                                <button onClick={() => this.handledeleteFromQueue(row.fileId)} type="button" className="btn btn-danger">
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            ) : null}

                          </tr>
                        );
                      })}
                      {this.state.rows.length < 1 ? (
                        <tr>
                          <td colSpan={7} className="text-center">
                            Empty Queue
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* @ts-ignore */}
        <Modal
          isOpen={this.state.isCancellationModalVisible}
          toggle={() => this.setState({ isCancellationModalVisible: false })}
        >
          {/* @ts-ignore */}
          <ModalHeader
            toggle={() => this.setState({ isCancellationModalVisible: false })}
          >
            Confirm Cancellation
          </ModalHeader>
          {/* @ts-ignore */}
          <ModalBody>
            The process will be cancelled after completing any currently running
            operation. Do you want to cancel?
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            {/* @ts-ignore */}
            <Button
              onClick={() =>
                this.setState({ isCancellationModalVisible: false })
              }
            >
              Dismiss
            </Button>
            {/* @ts-ignore */}
            <Button onClick={this.requestCancellation} color="danger">
              Request Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/* @ts-ignore */}
        <Modal
          isOpen={this.state.isPullDataModal}
          toggle={() => this.setState({ isPullDataModal: false })}
        >
          {/* @ts-ignore */}
          <ModalHeader toggle={() => this.setState({ isPullDataModal: false })}>
            Pull Lecture Videos
          </ModalHeader>
          {/* @ts-ignore */}
          <ModalBody>
            <div className="form-group">
              <label htmlFor="countSelect">Pull Count</label>
              <select
                className="form-control"
                id="countSelect"
                value={this.state.pullCount}
                onChange={(e) => {
                  try {
                    this.setState({
                      pullCount: parseInt(e.target.value),
                    });
                  } catch (e) {
                    // do nothing
                  }
                }}
              >
                <option value={3}>3</option>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={1000}>1000</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="operationSelect">Select Operation</label>
              <select
                className="form-control"
                id="operationSelect"
                value={this.state.operationState}
                onChange={(e) => {
                  try {
                    this.setState({
                      operationState: e.target.value,
                    });
                  } catch (e) {
                    // do nothing
                  }
                }}
              >
                <option value={"video"}>Video</option>
                <option value={"pdf"}>Pdf</option>
              </select>
            </div>
            <div className="form-check">
              <input
                checked={this.state.forcePull}
                onChange={(e) => this.setState({ forcePull: e.target.checked })}
                type="checkbox"
                className="form-check-input"
                id="forceOpts"
              />
              <label className="form-check-label" htmlFor="forceOpts">
                Force
              </label>
            </div>
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            {/* @ts-ignore */}
            <Button onClick={() => this.setState({ isPullDataModal: false })}>
              Cancel
            </Button>
            {/* @ts-ignore */}
            <Button onClick={this.performPullData} color="danger">
              Begin Operation
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
