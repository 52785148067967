import React, { useState } from "react";
import Subject from "./Subject";
import { Modal } from "reactstrap";
import { useForm } from "react-hook-form";
import { Course } from "lakshya-shared";
import Module from "module";

export default (props: any) => {
  const [isAddSubjectModalOpen, setIsAddSubjectModalOpen] = useState(false);
  const toggleAddSubjectModal = () =>
    setIsAddSubjectModalOpen(!isAddSubjectModalOpen);
  const { register, handleSubmit, errors } = useForm();
  const _handleAddSubject = props.__handleAddSubject;
  const subjects = props.subjects;
  const course: Course = props.course;
  const module: Module = props.module;
  return (
    <>
      <div className="sublect-page-wrapper pt-3">
        <div className="title-wrapper">
          <div className="subject-title-wrapper align-items-center">
            <h3 className="pl-3 m-0">Subjects</h3>
          </div>
          <button
            type="button"
            className="btn btn-outline-warning add-button-wrapper px-4 py-2"
            onClick={toggleAddSubjectModal}
          >
            Add Subject
          </button>
        </div>
        <div className="subjects-wrapper container-fluid mt-2">
          {Array.isArray(subjects) &&
            subjects.map((item: any, index: number) => (
              <Subject
                key={index}
                subjects={subjects}
                item={item}
                index={index}
                module={module}
                onClick={() =>
                  props.onSubjectSelected &&
                  props.onSubjectSelected(item, index)
                }
              />
            ))}
          {Array.isArray(subjects) && subjects.length < 1 ? (
            <div className="row w-100">
              <div className="col-12">
                <div className="mt-4 alert alert-warning" role="alert">
                  <strong>No Subjects Added!</strong> You can use 'Add Subject'
                  to get started.
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* @ts-ignore */}
      <Modal toggle={toggleAddSubjectModal} isOpen={isAddSubjectModalOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {`${course.title} >`}
              <em> Add Subject</em>
            </h5>
            <button
              type="button"
              className="close"
              onClick={toggleAddSubjectModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form
              onSubmit={handleSubmit((data, e) => {
                toggleAddSubjectModal();
                _handleAddSubject(data, e);
              })}
            >
              <label className="font-weight-bold">Title</label>
              <input
                name="title"
                ref={register({ required: true })}
                type="text"
                className="form-control"
                autoFocus
                autoComplete="off"
                placeholder="Subject Title (e.g. Mathematics)"
              />
              {errors.title && (
                <small className="text-danger" id="otp-error">
                  Please provide a title for the new subject
                </small>
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={toggleAddSubjectModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
