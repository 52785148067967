import React from "react";
import SideBar from "./SideBar";
import { ViewComponentPropType } from "@skyslit/ark-react";
import LayoutModule from "..";
import { useLocation } from "react-router";

export default (props: ViewComponentPropType<LayoutModule>) => {

  const location = useLocation();


  const linkToV1 = React.useMemo(() => {
    if (location.search.indexOf('type=v1') > -1) {
      return true
    } else {
      return false
    }
  }, [location.search])

  return (
    <>
      <div className="container-fluid courses-wrapper">
        <div className="row">
       {linkToV1 === false ? (
            <SideBar
            module={props.module}
            user={props.global.userInfo}
            currentPathName={props.location.pathname}
          />
       ):null}
          <div style={{marginLeft: linkToV1 === false ? 250 : 0 }}  className="col course-listing-wrapper px-0">
            {(props as any).children}
          </div>
        </div>
      </div>
    </>
  );
};
