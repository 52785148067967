import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "../module";

export default (props: ViewComponentPropType<DefaultModule>) => {
  return (
    <>
    {/* @ts-ignore */}
      <Helmet>
        <title>{props.context.messageToDisplay}</title>
      </Helmet>
      <div className="jumbotron">
        <h1 className="display-4">{props.context.messageToDisplay}</h1>
        {/* <h1 className="display-4">
          {props.global.userInfo
            ? props.global.userInfo.emailAddress
            : "No one"}
        </h1> */}
        <p className="lead">
          This is a simple hero unit, a simple jumbotron-style component for
          calling extra attention to featured content or information.
        </p>
        <hr className="my-4" />
        <p>
          It uses utility classes for typography and spacing to space content
          out within the larger container.
        </p>
        <p className="lead">
          <button
            onClick={() => props.module.controller.toggleTitle()}
            className="btn btn-primary btn-lg"
          >
            Toggle Message
          </button>
        </p>
        <ul>
          {props.context.movies.map((movie: any, index: number) => (
            <li key={index}>{`${movie.title} - cast: ${movie.actor}`}</li>
          ))}
        </ul>
      </div>
    </>
  );
};
