import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "..";
import TableView from "../components/TableView";

type StateType = {
  currentView: string;
};
class CurrentAffairsListingView extends React.Component<
  ViewComponentPropType<DefaultModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentView: "videos",
    };
  }

  componentDidMount() { }

  render() {
    let currentTab: any = [];
    switch (this.state.currentView) {
      case "videos": {
        currentTab = (
          <TableView
            history={this.props.history}
            data={this.props.context.listingData}
            module={this.props.module}
          />
        );
        break;
      }
      default: {
        currentTab = null;
      }
    }
    return (
      <>
        {/* @ts-ignore */}

        <Helmet>
          <title>Current Affairs Manager - Lakshya eLearning System</title>
        </Helmet>
        <div className="container-fluid video-catalogue-wrapper">
          <div className="row">{currentTab}</div>
        </div>
      </>
    );
  }
}

export default CurrentAffairsListingView;
