import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default (props: any) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const registerPassword = useRef({});
  registerPassword.current = watch("registerPassword", "");
  const { t } = useTranslation();
  const onRegister = props.onRegister;
  const SignInView = props.SignInView;

  return (
    <>
      <div className="row h-100">
        <div
          className="col-sm-8 col-md-6 col-lg-2 offset-sm-2 offset-md-3 offset-lg-5 pt-3 pt-md-5 pb-4 px-md-5 h-100 authentication-module-dialog-box bg-light"
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            borderRadius: 5,
          }}
        >
          <div style={{ flex: 1 }}>
            <div className="text-center">
              <h2>{t("Register")}</h2>
            </div>
            <form onSubmit={handleSubmit(onRegister)} className="pt-3 pb-2">
              <div className="form-group">
                <label>{t("Your full name")}</label>
                <input
                  name="fullName"
                  ref={register({ required: true })}
                  type="text"
                  className="form-control"
                  placeholder="Enter full name"
                  id="full-name-input"
                />
                {errors.fullName && (
                  <p className="text-danger" id="register-name-error">
                    {t("Full Name is Required")}
                  </p>
                )}
              </div>
              <div className="form-group">
                <label>{t("Email address")}</label>
                <input
                  name="registerEmail"
                  ref={register({ required: true })}
                  type="email"
                  id="email-input"
                  className="form-control"
                  placeholder="Enter email"
                />
              </div>
              {errors.registerEmail && (
                <p className="text-danger" id="register-email-error">
                  {t("Email is Required")}
                </p>
              )}
              <div className="form-group">
                <label>{t("Password")}</label>
                <input
                  name="registerPassword"
                  ref={register({ required: true, minLength: 5 })}
                  type="password"
                  id="password-input"
                  className="form-control"
                  placeholder="Enter password"
                />
                {errors.registerPassword &&
                  errors.registerPassword.type === "required" && (
                    <p className="text-danger" id="register-password-error">
                      {t("Password is Required")}
                    </p>
                  )}
                {errors.registerPassword &&
                  errors.registerPassword.type === "minLength" && (
                    <p
                      className="text-danger"
                      id="register-password-length-error"
                    >
                      {t("Password must be at least 5 characters")}
                    </p>
                  )}
              </div>
              <div className="form-group">
                <label>{t("Re-enter password")}</label>
                <input
                  name="confirmPassword"
                  ref={register({
                    required: true,
                    validate: (value) => value === registerPassword.current,
                  })}
                  type="password"
                  id="confirm-password-input"
                  className="form-control"
                  placeholder="Enter password"
                />
                {errors.confirmPassword && (
                  <p className="text-danger" id="confrim-password-error">
                    {t("The passwords do not match")}
                  </p>
                )}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  id="sign-up"
                  className="btn btn-info btn-block"
                >
                  {t("Sign up")}
                </button>
              </div>
            </form>
            <small className="text-secondary">
              {t("By signing into this page, you accept our")}{" "}
              <Link to="/">{t("Terms & Conditions and Privacy Policies")}</Link>
            </small>
          </div>
          <div style={{ fontSize: 15 }} className="text-center pt-5 pt-sm-3">
            <div>
              <button
                onClick={() => SignInView("signIn")}
                className="btn btn-link"
              >
                {t("Already have an account? Sign in")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
