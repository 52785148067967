import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "..";
import { Link } from "react-router-dom";
import ResourceTag from "./../../../shared/ResourceTag";
import { Modal } from "reactstrap";
import { Course, LectureVideo, Exam, RankFile } from "lakshya-shared";
import moment from "moment";

type StateType = {
  currentView: string;
  isDeleteModalOpen: boolean;
  isDeleting: boolean;
};
class SubscriptionPage extends React.Component<
  ViewComponentPropType<DefaultModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentView: "general",
      isDeleteModalOpen: false,
      isDeleting: false,
    };
    this.toggleIsOpenDeleteModal = this.toggleIsOpenDeleteModal.bind(this);
  }

  _handleSubscriptionExamById(id: string) {
    this.props.module.services
      .getSubscriptionById(id)
      .then((response: any) => {
        this.props.module.controller.inflateSubscription(response);
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching data failed",
          e.message ? e.message : "Load error",
          true
        );
      });
  }

  _handleDeleteSubscription() {
    this.setState(
      {
        isDeleting: true,
      },
      () =>
        setTimeout(() => {
          this.props.module.services
            .deleteSubscriptionById(
              (this.props.match.params as any).subscriptionId
            )
            .then((response: any) => {
              this.setState(
                {
                  isDeleting: false,
                },
                () => {
                  this.props.module.toast.show({
                    message: "Delete successful",
                  });
                  this.props.history.replace("/admin/subscriptions");
                }
              );
            })
            .catch((e) => {
              this.setState(
                {
                  isDeleting: false,
                },
                () => {
                  this.props.module.showError(
                    e.message ? e.message : "Unknown error",
                    "Delete Failed",
                    true
                  );
                }
              );
            });
        }, 300)
    );
  }

  toggleIsOpenDeleteModal() {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
    });
  }

  componentDidMount() {
    this._handleSubscriptionExamById(
      (this.props.match.params as any).subscriptionId
    );
  }

  updateGSTAmount = (grossAmount: number, rate: number) => {
    grossAmount = parseFloat(grossAmount as any);
    rate = parseFloat(rate as any);

    let gstAmount = parseFloat(((grossAmount * rate) / 100).toFixed(2));
    if (isNaN(gstAmount)) {
      gstAmount = 0;
    }

    this.props.module.controller.updateEditorData("gstAmount", gstAmount);

    return gstAmount;
  };

  updateNetAmount = (grossAmount: number, gstAmount: number) => {
    grossAmount = parseFloat(grossAmount as any);
    gstAmount = parseFloat(gstAmount as any);

    let nettAmount = grossAmount + gstAmount;
    if (isNaN(nettAmount)) {
      nettAmount = 0;
    }
    this.props.module.controller.updateEditorData("netAmount", nettAmount);

    return nettAmount;
  };

  render() {
    const { context, module } = this.props;
    let currentTab: any = [];
    if (!context.editorData) {
      return null;
    }

    const currentView: string =
      (this.props.match.params as any).editorMode || "general";

    switch (currentView) {
      case "general": {
        currentTab = (
          <div className="col-12 col-md-9 col-lg-6 px-5 py-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Basic Settings</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  Subscription Term, Taxes and Pricing
                </h6>
                <div className="form-group">
                  <label>Terms</label>
                  <select
                    value={context.editorData.subscriptionTerm}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "subscriptionTerm",
                        e.target.value
                      )
                    }
                    className="form-control"
                    id="exampleFormControlSelect1"
                  >
                    {/* <option value="not-selected">Please select a option</option> */}
                    <option value="12-months">12 Months</option>
                    <option value="6-months">6 Months</option>
                    <option value="4-months">4 Months</option>
                    <option value="3-months">3 Months</option>
                    <option value="2-months">2 Months</option>
                    <option value="45-days">45 Days</option>
                    <option value="1-month">1 Month</option>
                    <option value="15-days">15 Days</option>

                    <option value="10-days">10 Days</option>
                    <option value="8-days">8 Days</option>
                    <option value="6-days">6 Days</option>
                    <option value="5-days">5 Days</option>
                    <option value="4-days">4 Days</option>
                    <option value="3-days">3 Days</option>
                    <option value="2-days">2 Days</option>
                    <option value="1-days">1 Day</option>
                  </select>
                  <small
                    id="compareAtPriceHelp"
                    className="form-text text-muted"
                  >
                    Determines how long someone can use resources purchased with
                    this subscription
                  </small>
                </div>
                <div className="form-group">
                  <label>Gross Amount</label>
                  <input
                    type="number"
                    step={0.01}
                    value={context.editorData.grossAmount}
                    onChange={(e) => {
                      module.controller.updateEditorData(
                        "grossAmount",
                        e.target.value
                      );
                      const gstAmount = this.updateGSTAmount(
                        parseFloat(e.target.value),
                        context.editorData.gstRate
                      );
                      this.updateNetAmount(
                        parseFloat(e.target.value) as any,
                        gstAmount
                      );
                    }}
                    placeholder="Enter the amount"
                    className="form-control"
                  />
                  <small
                    id="compareAtPriceHelp"
                    className="form-text text-muted"
                  >
                    This will be displayed wherever price is denoted in the app.
                    Its recommended not to add tax to Gross amount
                  </small>
                </div>
                <div className="form-group">
                  <label>GST Rate (%)</label>
                  <input
                    type="number"
                    step={0.01}
                    value={context.editorData.gstRate}
                    onChange={(e) => {
                      module.controller.updateEditorData(
                        "gstRate",
                        e.target.value
                      );
                      const gstAmount = this.updateGSTAmount(
                        context.editorData.grossAmount,
                        parseFloat(e.target.value)
                      );
                      this.updateNetAmount(
                        context.editorData.grossAmount,
                        gstAmount
                      );
                    }}
                    placeholder="Enter the amount"
                    className="form-control"
                  />
                  <small
                    id="compareAtPriceHelp"
                    className="form-text text-muted"
                  >
                    GST Rate (in %) as applicable
                  </small>
                </div>
                <div className="form-group">
                  <label>GST Amount</label>
                  <input
                    type="number"
                    step={0.01}
                    value={context.editorData.gstAmount}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "gstAmount",
                        e.target.value
                      )
                    }
                    placeholder="Enter the amount"
                    className="form-control"
                  />
                  <small
                    id="compareAtPriceHelp"
                    className="form-text text-muted"
                  >
                    GST Amount as applicable
                  </small>
                </div>
                <div className="form-group">
                  <label>Nett Amount</label>
                  <input
                    type="number"
                    step={0.01}
                    value={context.editorData.netAmount}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "netAmount",
                        e.target.value
                      )
                    }
                    placeholder="Enter the amount"
                    className="form-control"
                  />
                  <small
                    id="compareAtPriceHelp"
                    className="form-text text-muted"
                  >
                    Denotes the total amount that has to be charged from the
                    user
                  </small>
                </div>
              </div>
            </div>
            {/* <div className="form-group">
              <div className="form-check">
                <input
                  checked={context.editorData.isVisibleToPublic}
                  onChange={(e) =>
                    module.controller.updateEditorData(
                      "isVisibleToPublic",
                      e.target.checked
                    )
                  }
                  id="visible-to-public-input"
                  type="checkbox"
                  className="form-check-input"
                />
                <label
                  htmlFor="visible-to-public-input"
                  style={{ userSelect: "none" }}
                  className="form-check-label"
                >
                  Visble to public
                </label>
              </div>
            </div> */}
            <div className="card mt-3">
              <div className="card-body">
                <h5 className="card-title">Promotional Settings</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  Discounts and more
                </h6>
                <div className="form-group">
                  <label htmlFor="compareAtPriceInput">
                    Compare At (Price)
                  </label>
                  <input
                    value={context.editorData.compareAtPrice}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "compareAtPrice",
                        e.target.value
                      )
                    }
                    autoComplete="off"
                    type="number"
                    step={0.01}
                    className="form-control"
                    id="compareAtPriceInput"
                    aria-describedby="compareAtPriceHelp"
                  />
                  <small
                    id="compareAtPriceHelp"
                    className="form-text text-muted"
                  >
                    Compare at will be depicted as the original price that is
                    strike off
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="discountRateInput">
                    Discount Rate (in %)
                  </label>
                  <input
                    value={context.editorData.discountRatePromoLabel}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "discountRatePromoLabel",
                        e.target.value
                      )
                    }
                    autoComplete="off"
                    type="number"
                    step={0.01}
                    className="form-control"
                    id="discountRateInput"
                    aria-describedby="discountRateInputHelp"
                  />
                  <small
                    id="discountRateInputHelp"
                    className="form-text text-muted"
                  >
                    Will be showed in search results for promotion purpose
                  </small>
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <h5 className="card-title">Additional Settings</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  Lakshya Student Linking
                </h6>

                <div className="form-group">
                  <label>Free For:</label>
                  <select
                    value={context.editorData.freeMode}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "freeMode",
                        e.target.value
                      )
                    }
                    className="form-control"
                  >
                    <option value="none">None</option>
                    <option value="lakshya-students">Lakshya Students</option>
                  </select>
                  <small
                    id="compareAtPriceHelp"
                    className="form-text text-muted"
                  >
                    You can whitelist / manage students details{" "}
                    <a href="/admin/lakshya/students" target="_blank">
                      here
                    </a>
                  </small>
                </div>
              </div>
            </div>

            {/* <label>Subscription Features</label>
            {context.editorData.features.map((feature, index) => (
              <div key={index} className="input-group mb-3">
                <input
                  type="text"
                  value={feature}
                  onChange={(e) =>
                    module.controller.updateEditorData(
                      "features",
                      context.editorData.features.map((f, i) => {
                        if (i === index) {
                          return e.target.value;
                        }
                        return f;
                      })
                    )
                  }
                  className="form-control"
                  placeholder="Features goes here..."
                  aria-describedby="button-addon2"
                />
                <div className="input-group-append">
                  <button
                    onClick={() =>
                      module.controller.updateEditorData(
                        "features",
                        context.editorData.features.filter(
                          (f, i) => i !== index
                        )
                      )
                    }
                    className="btn btn-outline-danger"
                    type="button"
                    id="button-addon2"
                  >
                    <i className="fas fa-minus-circle"></i>
                  </button>
                </div>
              </div>
            ))}
            <div className="form-group">
              <button
                onClick={() =>
                  module.controller.updateEditorData("features", [
                    ...context.editorData.features,
                    "",
                  ])
                }
                type="button"
                className="btn btn-primary btn-sm"
              >
                <i className="fas fa-plus-circle"></i> Add Instruction
              </button>
            </div> */}
            {/* <div className="form-group">
              <label>Mode</label>
              <select
                value={context.editorData.mode}
                onChange={(e) =>
                  module.controller.updateEditorData("mode", e.target.value)
                }
                className="form-control"
              >
                <option value="rate-card">Rate Card</option>
                <option value="tag-based">Tag Based</option>
              </select>
            </div> */}
            {context.editorData.mode === "tag-based" ? (
              <div className="form-group">
                <ResourceTag
                  label="Resource Tags"
                  value={context.editorData.resourceTags.map((t) => ({
                    label: t,
                    value: t,
                  }))}
                  onChange={(e: any, tags: any) => {
                    module.controller.updateEditorData("resourceTags", [
                      ...tags.map((t: any) => t.value),
                    ]);
                  }}
                />
              </div>
            ) : null}
          </div>
        );
        break;
      }
      case "resources": {
        if (!context.editorData.attachedResources) {
          currentTab = <h6>Data not available. Contact support.</h6>;
        } else {
          currentTab = (
            <div className="col-12">
              {Array.isArray(context.editorData.attachedResources.courses) ? (
                <>
                  <div className="card mb-5">
                    <div className="card-body">
                      <h5 className="card-title">Courses</h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        List of courses associated with this subscription
                      </h6>
                      {context.editorData.attachedResources.courses.length >
                        0 ? (
                        <div className="table-responsive-sm">
                          <table className="table m-0 table-striped">
                            <thead>
                              <tr>
                                <th style={{ width: 400 }} scope="col">
                                  Title
                                </th>
                                <th scope="col">Category</th>
                                <th scope="col">Tags</th>
                              </tr>
                            </thead>
                            <tbody>
                              {context.editorData.attachedResources.courses.map(
                                (course: Course, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <Link
                                          to={`/admin/courses/editor/${(course as any)._id
                                            }`}
                                          target="_blank"
                                        >
                                          {course.title}
                                          <i className="fas fa-external-link-alt ml-3"></i>
                                        </Link>
                                      </td>
                                      <td>{course.category}</td>
                                      <td>
                                        {Array.isArray(course.tags) &&
                                          course.tags.join(", ")}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p className="text-muted text-center">
                          Nothing associated
                        </p>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
              {Array.isArray(context.editorData.attachedResources.lectures) ? (
                <>
                  <div className="card mb-5">
                    <div className="card-body">
                      <h5 className="card-title">Lecture Videos</h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        List of lecture videos associated with this subscription
                      </h6>
                      {context.editorData.attachedResources.lectures.length >
                        0 ? (
                        <div className="table-responsive-sm">
                          <table className="table m-0 table-striped">
                            <thead>
                              <tr>
                                <th style={{ width: 400 }} scope="col">
                                  Title
                                </th>
                                <th scope="col">Duration</th>
                              </tr>
                            </thead>
                            <tbody>
                              {context.editorData.attachedResources.lectures.map(
                                (lecture: LectureVideo, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <Link
                                          to={`/admin/videos/lectures/${(lecture as any)._id
                                            }`}
                                          target="_blank"
                                        >
                                          {lecture.title}
                                          <i className="fas fa-external-link-alt ml-3"></i>
                                        </Link>
                                      </td>
                                      <td>
                                        {lecture.videoDurationInMinutes} Mins.
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p className="text-muted text-center">
                          Nothing associated
                        </p>
                      )}
                    </div>
                  </div>
                </>
              ) : null}

              {Array.isArray(context.editorData.attachedResources.exams) ? (
                <>
                  <div className="card mb-5">
                    <div className="card-body">
                      <h5 className="card-title">Exams</h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        List of exams associated with this subscription
                      </h6>
                      {context.editorData.attachedResources.exams.length > 0 ? (
                        <div className="table-responsive-sm">
                          <table className="table m-0 table-striped">
                            <thead>
                              <tr>
                                <th style={{ width: 400 }} scope="col">
                                  Title
                                </th>
                                <th scope="col">Duration</th>
                                <th scope="col">Published On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {context.editorData.attachedResources.exams.map(
                                (exam: Exam, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <Link
                                          to={`/admin/exams/editor/${(exam as any)._id
                                            }`}
                                          target="_blank"
                                        >
                                          {exam.title}
                                          <i className="fas fa-external-link-alt ml-3"></i>
                                        </Link>
                                      </td>
                                      <td>
                                        {exam.examDurationInMinutes} Mins.
                                      </td>
                                      <td>
                                        {moment(
                                          exam.publishDateTimeInMs
                                        ).format("ll")}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p className="text-muted text-center">
                          Nothing associated
                        </p>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
              {Array.isArray(context.editorData.attachedResources.courses) ? (
                <>
                  <div className="card mb-5">
                    <div className="card-body">
                      <h5 className="card-title">Rank Files</h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        List of rank files associated with this subscription
                      </h6>
                      {context.editorData.attachedResources.rankFiles.length >
                        0 ? (
                        <div className="table-responsive-sm">
                          <table className="table m-0 table-striped">
                            <thead>
                              <tr>
                                <th style={{ width: 400 }} scope="col">
                                  Title
                                </th>
                                <th scope="col">Groups</th>
                              </tr>
                            </thead>
                            <tbody>
                              {context.editorData.attachedResources.rankFiles.map(
                                (rankFile: RankFile, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <Link
                                          to={`/admin/rank-files/editor/${(rankFile as any)._id
                                            }`}
                                          target="_blank"
                                        >
                                          {rankFile.title}
                                          <i className="fas fa-external-link-alt ml-3"></i>
                                        </Link>
                                      </td>
                                      <td>{rankFile.groups.length}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p className="text-muted text-center">
                          Nothing associated
                        </p>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          );
        }
        break;
      }
      default: {
        currentTab = null;
      }
    }
    return (
      <>
        {/* @ts-ignore */}
        <Helmet>
          <title>
            {context.editorData.title} Subscription - Lakshya eLearning System
          </title>
        </Helmet>
        <div className="container-fluid subscription-editor-wrapper">
          <div className="row top-part-wrapper">
            <div className="col-12 px-0 py-4">
              <div className="d-flex flex-direction-column header-wrapper px-5">
                <div className="flex-fill d-flex flex-direction-row">
                  <Link to="/admin/subscriptions" className="go-back-button">
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                  <input
                    value={context.editorData.title}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "title",
                        e.target.value
                      )
                    }
                    className="ml-2 pl-2 title-edit-input"
                  />
                </div>
                <div className="flex-shrink d-flex flex-direction-row">
                  <button
                    disabled={
                      this.props.context.haveEditorContextUpdated === false
                    }
                    onClick={() =>
                      this.props.module.controller.saveEditorContext()
                    }
                    type="button"
                    className="save-button-wrapper px-4 mr-4"
                  >
                    Save
                  </button>
                  <button
                    onClick={this.toggleIsOpenDeleteModal}
                    className="delete-button-wrapper"
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              </div>
              <div className="tab-switcher pl-4 mt-4">
                {/* <button
                  onClick={() => this.setState({ currentView: "general" })}
                  className={`py-3 px-4 ${
                    currentView === "general" ? "selected" : ""
                  }`}
                >
                  General
                </button>
                <button
                  onClick={() => this.setState({ currentView: "resources" })}
                  className={`py-3 px-4 ${
                    currentView === "resources" ? "selected" : ""
                  }`}
                >
                  Resources
                </button> */}
                <Link
                  to={`/admin/subscriptions/editor/${(context.editorData as any)._id
                    }/general`}
                  className={`py-3 px-4 ${currentView === "general" ? "selected" : ""
                    }`}
                >
                  General
                </Link>
                <Link
                  to={`/admin/subscriptions/editor/${(context.editorData as any)._id
                    }/resources`}
                  className={`py-3 px-4 ${currentView === "resources" ? "selected" : ""
                    }`}
                >
                  Resources
                </Link>
              </div>
            </div>
          </div>
          <div className="row">{currentTab}</div>
        </div>
        {/* @ts-ignore */}
        <Modal
          toggle={this.toggleIsOpenDeleteModal}
          isOpen={this.state.isDeleteModalOpen}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Remove Subscription</h5>
              <button
                onClick={this.toggleIsOpenDeleteModal}
                type="button"
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6 style={{ fontWeight: "normal" }}>
                Are you sure want to delete this subscription?
              </h6>
            </div>
            <div className="modal-footer">
              <button
                disabled={this.state.isDeleting}
                type="button"
                className="btn btn-secondary"
                onClick={this.toggleIsOpenDeleteModal}
              >
                Cancel
              </button>
              <button
                disabled={this.state.isDeleting}
                onClick={() => {
                  this._handleDeleteSubscription();
                }}
                type="button"
                className="btn btn-danger"
              >
                {this.state.isDeleting === true ? "Deleting..." : "Yes, Remove"}
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default SubscriptionPage;
