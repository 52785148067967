import React, { useState } from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "../module";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export default (props: ViewComponentPropType<DefaultModule>) => {
  const [errorMessage, setErrorMessage] = useState("");

  const { register, handleSubmit, errors } = useForm();

  const _handleRegisterUser = (data: any) => {
    props.module.services
      .registerUser(data.fullName, data.emailAddress, data.password)
      .then((response: any) => {
        props.history.push("/account");
        props.module.setCurrentUser(true, null, response);
      })
      .catch((err) => {
        setErrorMessage(err.message);
      });
  };

  return (
    <>
    {/* @ts-ignore */}
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <div
        className="container-fluid py-5 px-4 px-md-5"
        style={{ backgroundColor: "#C9D1EB", height: "100%" }}
      >
        <div className="row h-100">
          <div
            className="col-sm-8 col-md-6 col-lg-4 offset-sm-2 offset-md-3 offset-lg-4 pt-3 pt-md-5 pb-4 px-md-5 h-100 bg-light authentication-module-dialog-box"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              borderRadius: 5,
            }}
          >
            <div style={{ flex: 1 }}>
              <div className="text-center">
                <h2>Register</h2>
              </div>
              <form
                className="pt-3 pb-2"
                onSubmit={handleSubmit(_handleRegisterUser)}
              >
                <div className="form-group">
                  <label>Your full name</label>
                  <input
                    name="fullName"
                    ref={register({ required: true })}
                    type="text"
                    className="form-control"
                    placeholder="Enter full name"
                    id="full-name-input"
                  />
                  {errors.fullName && <p>Full Name is required</p>}
                </div>
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    name="emailAddress"
                    ref={register({ required: true })}
                    type="email"
                    id="email-input"
                    className="form-control"
                    placeholder="Enter email"
                  />
                  {errors.emailAddress && <p>Email is required</p>}
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    name="password"
                    ref={register({ required: true, minLength: 5 })}
                    type="password"
                    id="password-input"
                    className="form-control"
                    placeholder="Enter password"
                  />
                  {errors.password && errors.password.type === "required" && (
                    <p>Password is required</p>
                  )}
                  {errors.password && errors.password.type === "minLength" && (
                    <p>Password must be at least 5 characters</p>
                  )}
                </div>
                <div className="form-group">
                  <label>Confirm password</label>
                  <input
                    name="confirmPassword"
                    ref={register({ required: true })}
                    type="password"
                    id="confirm-password-input"
                    className="form-control"
                    placeholder="Enter password"
                    // value={confirmPassword}
                    // onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {errors.confirmPassword && (
                    <p>Confirm Password is required</p>
                  )}
                </div>
                <div className="text-center pt-3">
                  <p id="signup-error">{errorMessage}</p>
                  <button
                    // onClick={() => _handleRegisterUser()}
                    type="submit"
                    id="sign-up"
                    className="btn btn-info btn-block mt-3"
                  >
                    Sign up
                  </button>
                </div>
              </form>
              <small style={{ color: "#989898" }}>
                By signing into this page, you accept our{" "}
                <Link to="/">Terms & Conditions and Privacy Policies</Link>
              </small>
            </div>
            <div style={{ fontSize: 15 }} className="text-center pt-5 pt-sm-3">
              <div>
                <Link to="/auth/signin">Already have an account? Sign in</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
