import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import { ComponentMap } from "@skyslit/ark-react/build/types";
import VideoCataloguePageView from "./views/VideoCataloguePage.view";
import LectureEditorView from "./views/LectureEditor.view";
import PendingPdf from "./views/PendingPdf.view";
import { LectureVideo } from "lakshya-shared";
import CustomLectureCollection from "./views/CustomLectureCollection.view";

export type StateType = {
  editorData: LectureVideo;
  lectures: LectureVideo[];
  haveContextUpdated: boolean;
};

export default class VideoCatalogueModule extends ArkModule<StateType> {
  constructor() {
    super("VideoCatalogueModule");

    this.useConnect(connect);

    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          case this.actionTypes.GET_ALL_LECTURES: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              lectures: value,
            });
          }
          case this.actionTypes.UPDATE_LECTURE: {
            const { key, value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              editorData: Object.assign({}, state.editorData, {
                [key]: value,
              }),
            });
          }
          case this.actionTypes.MARK_AS_SAVED: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: false,
              editorData: Object.assign({}, state.editorData, {
                publishWarnings: value.publishWarnings,
                isPublished: value.isPublished,
              }),
            });
          }
          case this.actionTypes.SET_LECTURE: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              editorData: value,
            });
          }
          default: {
            return state;
          }
        }
      };
    };

    this.main = () => {
      this.loadLectures();
    };
  }

  loadLectures = () => {
    if (this.package.store.getState().__CORE_PACKAGE.isAuthenticated === true) {
      this.services
        .getAllLectures()
        .then((data) => {
          this.controller.fetchLecture(data);
        })
        .catch(() => {
          setTimeout(() => {
            // REMOVED FROM REPEAT
            // this.loadLectures();
          }, 100);
        });
    } else {
      setTimeout(() => {
        // REMOVED FROM REPEAT
        this.loadLectures();
      }, 5000);
    }
  };

  controller = {
    fetchLecture: (value: any) => {
      this.dispatch({
        type: this.actionTypes.GET_ALL_LECTURES,
        payload: {
          value,
        },
      });
    },
    setLecture: (value: LectureVideo) => {
      this.dispatch({
        type: this.actionTypes.SET_LECTURE,
        payload: {
          value,
        },
      });
    },
    updateLecture: (key: string, value: any) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_LECTURE,
        payload: {
          key,
          value,
        },
      });
    },
    saveLecture: () => {
      const courseToUpdate = this.getState().editorData;
      this.services
        .updateLectureById((courseToUpdate as any)._id, courseToUpdate)
        .then((response: any) => {
          this.dispatch({
            type: this.actionTypes.MARK_AS_SAVED,
            payload: {
              value: response,
            },
          });
        })
        .catch((e) => {
          this.showError(
            "Update subject failed",
            e.message ? e.message : "Network error",
            true
          );
        });
    },
  };

  actionTypes = {
    GET_ALL_LECTURES: "GET_ALL_LECTURES",
    SET_LECTURE: "SET_LECTURE",
    UPDATE_LECTURE: "UPDATE_LECTURE",
    MARK_AS_SAVED: "MARK_AS_SAVED",
    REMOVE_LECTURE: "REMOVE_LECTURE",
  };

  services = {
    addLecture: (title: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/admin/lecture", {
            title: title,
          })
          .then((response) => {
            this.loadLectures();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getAllLectures: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/admin/lecture")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getLectureById: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/lecture/${id}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    updateLectureById: (id: string, value: LectureVideo) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/lecture/${id}`, { value: value })
          .then((response) => {
            this.loadLectures();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    deleteLecture: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .delete(`/api/admin/lecture/${id}`)
          .then((response) => {
            this.loadLectures();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    getAllPendingPdfs: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/admin/pending-pdf")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    markVideoAsResolved: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/mark-solved/${id}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    getAllAttachedCourses: (lectureId: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/attached-course/${lectureId}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    addLectureToCustomCollection: (lectureId: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post(`/api/admin/lecture/add/lecture-collection/${lectureId}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    getCustomCollection: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/admin/custom-lectures")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    removeLectureToCustomCollection: (lectureId: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post(`/api/admin/lecture/remove/lecture-collection/${lectureId}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    updateLectureToCustomCollectionTitle: (title: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/admin/lecture/update/lecture-collection/title", { title })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    updateCustomLectureCollectionOrder: ( lecturesMetaData:any[]) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/admin/lecture/update/lecture-collection/order", {lecturesMetaData })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
  };

  views: ComponentMap = {
    VideoCataloguePage: VideoCataloguePageView,
    LectureEditorView,
    PendingPdf,
    CustomLectureCollection,
  };

  initialState: StateType = {
    editorData: null,
    lectures: [],
    haveContextUpdated: false,
  };
}
