import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "..";
import ReactTable from "react-table";
import { Modal } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

type StateType = {
  isModalOpen: boolean;
  data: [];
};

class SubscriptionPage extends React.Component<
  ViewComponentPropType<DefaultModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isModalOpen: false,
      data: [],
    };
    this.toggleModal = this.toggleModal.bind(this);
    this._handleAddSubscription = this._handleAddSubscription.bind(this);
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  _handleAddSubscription = (data: any) => {
    this.props.module.services
      .addSubscription(data.title)
      .then((response: any) => {
        this.toggleModal();
        this.props.history.push(`/admin/subscriptions/editor/${response._id}`);
      })
      .catch((e) => {
        this.props.module.showError(
          "Subscription cannot be added",
          e.message ? e.message : "Network error",
          true
        );
      });
  };

  _handleFetchSubscription() {
    this.props.module.services
      .getAllSubscriptions()
      .then((response: any) => {
        this.setState({
          data: response,
        });
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching subscrptions failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }

  componentDidMount() {
    this._handleFetchSubscription();
  }

  render() {
    return (
      <>
        {/* @ts-ignore */}
        <Helmet>
          <title>Subsciption Manager - Lakshya eLearning System</title>
        </Helmet>
        <div className="container-fluid subscriptions-listings-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
                <h4>Subscription Manager</h4>
                <button
                  onClick={this.toggleModal}
                  className="add-subscription-button-wrapper px-5 py-3"
                >
                  Add Subscription
                </button>
              </div>
              <ReactTable
                minRows={3}
                data={this.state.data}
                defaultPageSize={10}
                columns={[
                  {
                    Header: "Title",
                    accessor: "title",
                    Cell: (row) => (
                      <Link
                        className="text-primary"
                        rel="noopener noreferrer"
                        to={`/admin/subscriptions/editor/${row.original._id}`}
                      >
                        {row.value}
                      </Link>
                    ),
                  },
                  {
                    Header: "Is Free",
                    accessor: "isFree",
                    Cell: (row) => {
                      const isFree: boolean = row.value;
                      if (isFree === true) {
                        return "Yes";
                      } else {
                        if (row.original.freeMode === "lakshya-students") {
                          return "Free for Lakshya Students";
                        }
                        return "No";
                      }
                    },
                  },
                  {
                    Header: "Term",
                    accessor: "subscriptionTerm",
                  },
                  {
                    Header: "Gross Amount",
                    accessor: "grossAmount",
                  },
                  {
                    Header: "GST",
                    accessor: "gstAmount",
                    Cell: (row) => (
                      <span>{`${row.original.gstAmount} (${row.original.gstRate})%`}</span>
                    ),
                  },
                  {
                    Header: "Nett Amount",
                    accessor: "netAmount",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {/* @ts-ignore */}
        <Modal
          autoFocus={true}
          centered={true}
          toggle={this.toggleModal}
          isOpen={this.state.isModalOpen}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Subscription</h5>
              <button
                type="button"
                onClick={this.toggleModal}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AddSubscriptionForm
                _handleAddSubscription={this._handleAddSubscription}
                toggleModal={this.toggleModal}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

function AddSubscriptionForm(props: any) {
  const { register, handleSubmit, errors } = useForm();
  const toggleModal = props.toggleModal;
  const _handleAddSubscription = props._handleAddSubscription;

  return (
    <form onSubmit={handleSubmit(_handleAddSubscription)}>
      <input
        autoFocus={true}
        autoComplete="off"
        name="title"
        ref={register({ required: true })}
        type="text"
        className="form-control"
        placeholder="Enter Subscription Title"
      />
      {errors.title && (
        <small className="text-danger" id="otp-error">
          Please provide a subscription title
        </small>
      )}
      <div className="modal-footer">
        <button type="button" onClick={toggleModal} className="btn btn-default">
          Cancel
        </button>
        <button type="submit" className="btn btn-primary">
          Create Subsciption
        </button>
      </div>
    </form>
  );
}

export default SubscriptionPage;
