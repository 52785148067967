import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "..";
import QuestionEditor from "../components/QuestionEditor";
import { Link } from "react-router-dom";
import GeneralEditor from "../components/GeneralEditor";
import { Modal } from "reactstrap";
import Analytics from "../components/Analytics";
import { app } from './../../../index'

type StateType = {
  isDeleteModalOpen: boolean;
  isDeleting: boolean;
};

class ExamEditor extends React.Component<
  ViewComponentPropType<DefaultModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      isDeleting: false,
    };
    this._handleGetExamById = this._handleGetExamById.bind(this);
    this.toggleIsOpenDeleteModal = this.toggleIsOpenDeleteModal.bind(this);
    this._handleDeleteExam = this._handleDeleteExam.bind(this);
  }

  _handleGetExamById(id: string) {
    this.props.module.services
      .getExamById(id)
      .then((response: any) => {
        this.props.module.controller.addExam(response);
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching data failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }

  _handleDeleteExam() {
    this.setState(
      {
        isDeleting: true,
      },
      () =>
        setTimeout(() => {
          this.props.module.services
            .deleteExamById((this.props.match.params as any).examId)
            .then((response: any) => {
              this.setState(
                {
                  isDeleteModalOpen: false,
                },
                () => this.props.history.replace("/admin/exams")
              );
            })
            .catch((e) => {
              this.setState(
                {
                  isDeleteModalOpen: false,
                },
                () => {
                  this.props.module.showError(
                    "Delete Exam failed",
                    e.message ? e.message : "Network error",
                    true
                  );
                }
              );
            });
        }, 400)
    );
  }

  toggleIsOpenDeleteModal() {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
    });
  }

  componentDidMount() {
    this._handleGetExamById((this.props.match.params as any).examId);
    this.props.module.controller.performRefresh(
      (this.props.match.params as any).examId
    );
  }

  componentWillUnmount() {
    this.props.module.controller.addExam(null);
  }

  render() {
    const context = this.props.context;
    if (!context.exam) {
      return <div>Loading</div>;
    }

    const editorMode: string =
      (this.props.match.params as any).editorMode || "general";

    let currentTab: any = [];
    switch (editorMode) {
      case "general": {
        currentTab = (
          <GeneralEditor exam={context.exam} module={this.props.module} />
        );
        break;
      }
      case "questions": {
        currentTab = (
          <QuestionEditor exam={context.exam} module={this.props.module} />
        );
        break;
      }
      case "analytics": {
        currentTab = (
          <Analytics
            isRefreshing={this.props.context.isAnalyticsRefreshing}
            analytics={this.props.context.analytics}
            exam={this.props.context.exam}
            module={this.props.module}
            onRefreshClick={() =>
              this.props.module.controller.performRefresh(
                (context.exam as any)._id
              )
            }
          />
        );
        break;
      }
      default: {
        currentTab = null;
      }
    }

    let query  = ""

    if (window.location.href.indexOf('type=v1') > -1) {
      query = "?type=v1"
    }

    return (
      <>
      {/* @ts-ignore */}
        <Helmet>
          <title>
            {context.exam.title} - Exam Editor - Lakshya eLearning System
          </title>
        </Helmet>
        <div className="container-fluid exam-editor-wrapper">
          <div className="row">
            <div className="col-12 px-0 top-part-wrapper py-4">
              <div className="d-flex flex-direction-column header-wrapper px-5">
                <div className="flex-fill d-flex flex-direction-row">
                  {!query ? (
                    <Link to="/admin/exams" className="go-back-button">
                      <i className="fas fa-arrow-left"></i>
                    </Link>
                  ) : null}
              
                  <input
                    value={context.exam.title}
                    onChange={(e) =>
                      this.props.module.controller.updateExam(
                        "title",
                        e.target.value
                      )
                    }
                    className="ml-2 pl-2 title-edit-input"
                  />
                  <span className="badge custom-badge px-4 text-capitalize">
                    {context.exam.examType}
                  </span>
                </div>
                <div className="flex-shrink d-flex flex-direction-row">
                  <div className="status-wrapper pr-4">
                    <i
                      className={`fas fa-circle pr-2 ${
                        context.exam.isPublished === false
                          ? context.exam.hasPublishMessage === true
                            ? "text-warning"
                            : "text-danger"
                          : ""
                      }`}
                    ></i>
                    <label className="mb-0">
                      {context.exam.isPublished === true
                        ? "Published"
                        : context.exam.publishMessage !== null
                        ? context.exam.publishMessage
                        : "Unpublished"}
                    </label>
                  </div>
                  <button
                    disabled={this.props.context.haveContextUpdated === false}
                    onClick={() => this.props.module.controller.saveExam()}
                    type="button"
                    className="save-button-wrapper px-4 mr-4"
                  >
                    Save
                  </button>
                  <button
                    disabled={this.props.context.haveContextUpdated}
                    onClick={() => {
                      app.modules.Composer.showComposer({
                        body: '',
                        imageUrl: null,
                        resourceID: (context.exam as any)._id,
                        resourceType: 'exam',
                        title: context.exam.title,
                        clickAction: 'open_exam'
                      })
                    }}
                    className="delete-button-wrapper mr-3"
                  >
                    <i className="fas fa-paper-plane"></i>
                  </button>
                  {!query ? (
                    <button
                      onClick={this.toggleIsOpenDeleteModal}
                      className="delete-button-wrapper"
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  ) : null}
                
                </div>
              </div>
              <div className="tab-switcher mt-4">
                <Link
                  to={`/admin/exams/editor/${(context.exam as any)._id}${query}`}
                  className={`pt-3 pb-3 px-4 ${
                    editorMode === "general" ? "selected" : ""
                  }`}
                >
                  General
                </Link>
                <Link
                  to={`/admin/exams/editor/${
                    (context.exam as any)._id
                  }/questions${query}`}
                  className={`pt-3 pb-3 px-4 ${
                    editorMode === "questions" ? "selected" : ""
                  }`}
                >
                  Questions
                </Link>
                <Link
                  to={`/admin/exams/editor/${
                    (context.exam as any)._id
                  }/analytics${query}`}
                  className={`pt-3 pb-3 px-4 ${
                    editorMode === "analytics" ? "selected" : ""
                  }`}
                >
                  Analytics
                </Link>
              </div>
            </div>
            {context.exam.isVisible === true ? (
              context.exam.publishWarnings.length > 0 ? (
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="alert alert-danger mb-0" role="alert">
                        <h4 className="alert-heading">Exam Offline!</h4>
                        <p>
                          This content cannot be seen by students and other
                          users through the app. To make it public, please
                          resolve below warnings.
                        </p>
                        <hr />
                        <ol>
                          {context.exam.publishWarnings.map(
                            (warning, index) => (
                              <li key={index}>{warning}</li>
                            )
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              ) : context.exam.hasPublishMessage === true ? (
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="alert alert-warning mb-0" role="alert">
                        <h4 className="alert-heading">Publish Message!</h4>
                        <p>
                          Please refer to the following message about publish
                          status
                        </p>
                        <hr />
                        <p className="mb-0">{context.exam.publishMessage}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
            <div className="col-12 px-0 pl-4">{currentTab}</div>
          </div>
        </div>
        {/* @ts-ignore */}
        <Modal
          toggle={this.toggleIsOpenDeleteModal}
          isOpen={this.state.isDeleteModalOpen}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation</h5>
              <button
                onClick={this.toggleIsOpenDeleteModal}
                type="button"
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6 style={{ fontWeight: "normal" }}>
                <div className="alert alert-danger" role="alert">
                  <h4 className="alert-heading">Warning!</h4>
                  <p>
                    You are about to delete this examination, which is undoable.
                    It will delete all associated questions, groups and other
                    associated assets. However, the any results will continue to
                    be remain.
                  </p>
                  <hr />
                  <p className="mb-0">Do you wish to continue?</p>
                </div>
              </h6>
            </div>
            <div className="modal-footer">
              <button
                disabled={this.state.isDeleting}
                type="button"
                className="btn btn-secondary"
                onClick={this.toggleIsOpenDeleteModal}
              >
                Cancel
              </button>
              <button
                disabled={this.state.isDeleting}
                onClick={() => {
                  this._handleDeleteExam();
                }}
                type="button"
                className="btn btn-danger"
              >
                {this.state.isDeleting === true ? "Deleting..." : "Yes, Delete"}
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default ExamEditor;
