import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import LakshyaStudentsModule from "..";
import moment from 'moment';
import ReactTable, { Filter } from "react-table";
import { app } from "../../..";

type Mode = 'create' | 'update';

type StateType = {
  modalMode: Mode
  isModalOpen: boolean;
  idToUpdate: string,
  name: string,
  phoneNumber: string,
  expiryDate: string
  isModalBusy: boolean
  isErrorModalOpen: boolean
  errorMessage: string
  warnings: string[]
  errorModalHeading: string
  errorModalMessage: string
  isImportModalOpen: boolean
  winnerAnnouncementUser: any
};

const TableFilter = (propertyKey: string) => (filter: Filter, row: any, column: any): any => {
  if (row._original[propertyKey]) {
    try {
      return row._original[propertyKey].trim().toLowerCase().indexOf(filter.value.trim().toLowerCase()) > -1;
    } catch (e) {
      console.error(e);
    }
  }
  return false;
}

class StudentManagerView extends React.Component<
  ViewComponentPropType<LakshyaStudentsModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      modalMode: 'create',
      isModalOpen: false,
      idToUpdate: null,
      name: '',
      phoneNumber: '',
      expiryDate: '',
      isModalBusy: false,
      isErrorModalOpen: false,
      errorMessage: null,
      warnings: [],
      errorModalHeading: '',
      errorModalMessage: '',
      isImportModalOpen: false,
      winnerAnnouncementUser: null
    };
  }

  componentDidMount() {
    this.props.module.controller.refreshData(false);
  }

  closeEditorModal = () => this.setState({ isModalOpen: false, idToUpdate: null, name: '', phoneNumber: '', expiryDate: '', isModalBusy: false })
  closeErrorModal = () => this.setState({ isErrorModalOpen: false, errorMessage: null, warnings: [] })
  closeImportModal = () => this.setState({ isImportModalOpen: false });

  openModal = (data: any, mode: Mode) => {
    let expiryDate = moment(data.expiryDate || null);
    this.setState({
      isModalBusy: false,
      isModalOpen: true,
      idToUpdate: data._id || null,
      name: data.name || '',
      phoneNumber: data.phoneNumber || '',
      expiryDate: expiryDate.isValid() === true ? expiryDate.format('YYYY-MM-DD') : '',
      modalMode: mode
    })
  }

  openErrorModal = (msgs: string = null, warnings: string[] = []) => this.setState({
    isModalBusy: false,
    isErrorModalOpen: true,
    errorMessage: msgs,
    warnings,
    errorModalMessage: 'Process failed, you may need to resolve below warnings before retrying',
    errorModalHeading: 'Warnings!',
  })

  handleSaveBtnClick = () => {
    const { modalMode } = this.state;
    this.setState({
      isModalBusy: true
    }, () => setTimeout(() => {
      if (modalMode === 'update') {
        this.props.module.services.updateStudent(this.state.idToUpdate, {
          name: this.state.name,
          phoneNumber: this.state.phoneNumber,
          expiryDate: this.state.expiryDate,
        })
          .then((response) => {
            console.log(response);
            this.props.module.controller.updateRow(this.state.idToUpdate, response);
            this.closeEditorModal();
          })
          .catch((err) => {
            this.closeEditorModal();
            this.openErrorModal(err.message || 'Unknown error');
          })
      } else {
        // Perform create
      }
    }, 400))
  }

  render() {
    const { isModalOpen, modalMode, name, phoneNumber, expiryDate, isModalBusy } = this.state;
    const { isRefreshing, data } = this.props.context;
    return (
      <>
        {/* @ts-ignore */}
        <Helmet>
          <title>User Manager - Lakshya eLearning System</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
                <h4>User Manager {'>'} All Users</h4>
                <div>
                  <button
                    disabled={isRefreshing}
                    onClick={() => {
                      this.props.module.controller.refreshData(true);
                    }}
                    className="btn btn-secondary ml-3"
                  >
                    {
                      isRefreshing === true ? <i className="fas fa-spin fa-sync-alt"></i> : <i className="fas fa-sync-alt"></i>
                    }
                  </button>
                  {/* <button
                    disabled={this.props.context.selectedIds.length < 1}
                    onClick={() => this.showDeletePrompt()}
                    className="btn btn-danger ml-3"
                  >
                    <i className="fas fa-trash"></i>
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-12">
              <ReactTable
                loading={isRefreshing}
                data={data}
                minRows={3}
                defaultPageSize={100}
                columns={[
                  // {
                  //   Header: "",
                  //   accessor: "_id",
                  //   className: 'd-flex align-items-center',
                  //   width: 50,
                  //   Cell: (row) => {
                  //     return (
                  //       <input type="checkbox" checked={this.props.module.controller.isChecked(row.value)} onChange={(e) => {
                  //         if (e.target.checked === true) {
                  //           this.props.module.controller.checkRow(row.value)
                  //         } else {
                  //           this.props.module.controller.uncheckRow(row.value)
                  //         }
                  //       }} />
                  //     );
                  //   },
                  // },
                  {
                    Header: "Name",
                    accessor: "name",
                    filterable: true,
                    filterMethod: TableFilter('name'),
                    className: 'd-flex align-items-center',
                    Cell: (row) => {
                      return <span className={row.value ? '' : 'text-muted'}>{row.value || 'Unspecified'}</span>
                      // return (
                      //   <button onClick={() => this.openModal(row.original, 'update')} className="btn btn-link">{row.value || 'Unspecified'}</button>
                      // );
                    },
                  },
                  {
                    Header: "Phone Number",
                    accessor: "phoneNumber",
                    className: 'd-flex align-items-center',
                    filterable: true,
                    filterMethod: TableFilter('phoneNumber'),
                  },
                  {
                    Header: "Email Address",
                    accessor: "emailAddress",
                    className: 'd-flex align-items-center',
                    filterable: true,
                    filterMethod: TableFilter('emailAddress'),
                    Cell: (row) => (
                      <span className={row.value === 'Not Specified' ? 'text-muted' : ''}>{row.value}</span>
                    )
                  },
                  {
                    Header: "Lakshya Student",
                    accessor: "isLakshyaStudentDisplay",
                    filterable: true,
                    filterMethod: TableFilter('isLakshyaStudentDisplay'),
                    className: 'd-flex align-items-center',
                    Cell: (row) => {
                      const isLakshyaStudentDisplay = row.value;
                      if (isLakshyaStudentDisplay === 'Yes') {
                        return <span className="text-success">Yes</span>
                      }
                      return <span className="text-muted">No</span>
                    },
                  },
                  {
                    Header: "Nickname (Game)",
                    accessor: "gameNickname",
                    filterable: true,
                    filterMethod: TableFilter('gameNickname'),
                    className: 'd-flex align-items-center'
                  },
                  {
                    Header: "Access Roles",
                    accessor: "roles",
                    filterable: true,
                    filterMethod: TableFilter('roles'),
                    className: 'd-flex align-items-center',
                    Cell: (row) => {
                      return <span className="text-muted">{Array.isArray(row.value) ? row.value.join(', ') : 'N/A'}</span>
                    },
                  },
                  {
                    Header: "Joined On",
                    accessor: "joinedOn",
                    filterable: true,
                    filterMethod: TableFilter('joinedOn'),
                    className: 'd-flex align-items-center'
                  },
                  {
                    Header: "Actions",
                    accessor: "_id",
                    Cell: (props) => {
                      return (
                        //@ts-ignore
                        <Button size="sm" onClick={() => {
                          this.setState({
                            winnerAnnouncementUser: props.original
                          })
                        }} color="secondary">Draft<br />Winner<br />Announcement</Button>
                      )
                    }
                  }
                ]}
              />
            </div>
          </div>
        </div>
        {/* @ts-ignore */}
        <Modal isOpen={isModalOpen} backdrop="static" keyboard={false} toggle={this.closeEditorModal}>
          {/* @ts-ignore */}
          <ModalHeader toggle={!isModalBusy ? this.closeEditorModal : undefined}>{modalMode === 'create' ? 'New Student' : 'Edit Student Details'}</ModalHeader>
          {/* @ts-ignore */}
          <ModalBody>
            <div className="form-group">
              <label htmlFor="nameField">Name</label>
              <input disabled={isModalBusy} type="text" className="form-control" id="nameField" value={name} onChange={e => this.setState({ name: e.target.value })} placeholder="(Required)" />
            </div>
            <div className="form-group">
              <label htmlFor="phoneField">Phone Number</label>
              <input disabled={isModalBusy} type="text" className="form-control" id="phoneField" value={phoneNumber} onChange={e => this.setState({ phoneNumber: e.target.value })} placeholder="10 digit phone number (Required)" />
            </div>
            <div className="form-group">
              <label htmlFor="expiryDate">Expiry Date (Optional)</label>
              <input disabled={isModalBusy} type="date" className="form-control" id="expiryDate" value={expiryDate} onChange={e => {
                console.log(e.target.value);
                this.setState({ expiryDate: e.target.value })
              }} placeholder="DD-MM-YYYY (Optional)" />
            </div>
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter className="justify-content-between">
            <div>
              {
                modalMode === 'create' ? (
                  //@ts-ignore
                  <Button disabled={isModalBusy} color="success" onClick={() => {
                    this.closeEditorModal();
                    this.setState({
                      isImportModalOpen: true
                    })
                  }}>
                    <span><i className="fas fa-upload"></i> Upload .csv File</span>
                  </Button>
                ) : null
              }
            </div>
            <div>
              {/* @ts-ignore */}
              <Button disabled={isModalBusy} color="primary" onClick={this.handleSaveBtnClick}>{isModalBusy === true ? 'Processing...' : (modalMode === 'create' ? 'Add Student' : 'Save Changes')}</Button>{' '}
              {/* @ts-ignore */}
              <Button disabled={isModalBusy} color="secondary" onClick={this.closeEditorModal}>Cancel</Button>
            </div>
          </ModalFooter>
        </Modal>
        {/* Error Modal */}
        {/* @ts-ignore */}
        <Modal isOpen={this.state.isErrorModalOpen} toggle={this.closeErrorModal}>
          {/* @ts-ignore */}
          <ModalBody>
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">{this.state.errorModalHeading}</h4>
              <p>{this.state.errorModalMessage}</p>
              <hr />
              <ol>
                {
                  this.state.warnings.length > 0 ? (
                    this.state.warnings.map((msgs, i) => {
                      return <li key={i}>{msgs}</li>
                    })
                  ) : (
                    <li>{this.state.errorMessage}</li>
                  )
                }
              </ol>
            </div>
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            <div>
              {/* @ts-ignore */}
              <Button color="secondary" onClick={this.closeErrorModal}>Close</Button>
            </div>
          </ModalFooter>
        </Modal>
        {/* Winner Announcement */}
        {/* @ts-ignore */}
        <Modal isOpen={this.state.winnerAnnouncementUser !== null} toggle={() => this.setState({ winnerAnnouncementUser: null })}>
          {/* @ts-ignore */}
          <ModalBody>
            <div className="alert alert-info" role="alert">
              <h4 className="alert-heading">
                Announcement Confirmation
              </h4>
              <p>Do you want to draft winner announcement for {this.state.winnerAnnouncementUser ? this.state.winnerAnnouncementUser.name || this.state.winnerAnnouncementUser.phoneNumber : 'this user'}?</p>
            </div>
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            <div>
              {/* @ts-ignore */}
              <Button color="secondary" className="mr-3" onClick={() => this.setState({ winnerAnnouncementUser: null })}>No</Button>
              {/* @ts-ignore */}
              <Button color="success" onClick={() => {
                this.setState({ winnerAnnouncementUser: null });
                app.modules.Winner.controller.draftAnnouncement({
                  user: this.state.winnerAnnouncementUser,
                  winnerName: this.state.winnerAnnouncementUser.name,
                  claimMessage: 'You are today\'s winner',
                  titleMessage: 'Today\'s Winner'
                })
                  .then((response: any) => {
                    // Navigate to winner announcement page
                    this.props.history.push(`/admin/winners/editor/${response._id}`)
                  })
                  .catch(() => {
                    // Do nothing
                  })
              }}>Yes</Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default StudentManagerView;
