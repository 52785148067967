import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { LectureVideo, Exam } from "lakshya-shared";
import CourseEditorModule from "..";
import { app } from "../../..";
import { Note } from "lakshya-shared";
import { resolveAssetUrl } from "../../../shared/utils";

export default (props: any) => {
  const [isExamPickerOpen, setIsExamPickerOpen] = React.useState(false);
  const [examIdToDelete, setExamIdToDelete] = React.useState(null);
  const [attachedCourses, setAttachedCourses] = React.useState([]);
  const [educators, setEducators] = React.useState([]);

  const [customLectureCollection, setCustomLectureCollection] = React.useState(
    []
  );

  const [
    isAddandRemoveLectureFromCollectionModal,
    setIsAddandRemoveLectureFromCollectionModal,
  ] = React.useState(false);
  const toggleAddAndRemoveLectureToModal = () =>
    setIsAddandRemoveLectureFromCollectionModal(
      !isAddandRemoveLectureFromCollectionModal
    );

  const context: LectureVideo = props.context;
  const module: CourseEditorModule = props.module;
  const Dropzone = app.modules.FileUploader.views.Dropzone;
  const FileProcessor = app.modules.FileUploader.views.FileProcessor;
  const FileView = app.modules.FileUploader.views.FileView;
  const ExamPicker = app.modules.Exam.views.ExamPicker;

  const _fetchAllAttachedCourses = () => {
    module.services
      .getAllAttachedCourses((context as any)._id)
      .then((response: any) => {
        setAttachedCourses(response);
      })
      .catch((e) => { });
  };

  const _fetchCustomLectureCollection = () => {
    module.services
      .getCustomCollection()
      .then((response: any) => {
        setCustomLectureCollection(response.lectures);
      })
      .catch((e) => { });
  };

  const _fetchAllEducators = () => {
    app.modules.Discussion.services.getAllEducators()
      .then((response: any) => {
        setEducators(response);
      })
      .catch((e) => { });
  };

  const _handleAddLectureToCollection = () => {
    module.services
      .addLectureToCustomCollection((context as any)._id)
      .then((response: any) => {
        toggleAddAndRemoveLectureToModal();
        _fetchCustomLectureCollection();
      })
      .catch((e) => { });
  };

  const _handleRemoveLectureToCollection = () => {
    module.services
      .removeLectureToCustomCollection((context as any)._id)
      .then((response: any) => {
        toggleAddAndRemoveLectureToModal();
        _fetchCustomLectureCollection();
      })
      .catch((e) => { });
  };

  const handleVideoUploadSuccess = (autoStartProcess: boolean = true) => (
    uploadedFiles: any
  ) => {
    const performSave = (fileId: string) => {
      module.controller.saveLecture();
      app.modules.FileUploader.services.addToQueue(
        fileId,
        context.title,
        autoStartProcess
      );
    };

    if (uploadedFiles && Array.isArray(uploadedFiles)) {
      if (uploadedFiles.length > 0) {
        module.controller.updateLecture(
          "videoResourceId",
          uploadedFiles[0]._id
        );
        module.controller.updateLecture(
          "videoFileName",
          uploadedFiles[0].fileName
        );
        module.controller.updateLecture(
          "videoFileSizeInBytes",
          uploadedFiles[0].sizeInBytes
        );

        const video = document.createElement("video");
        video.src = resolveAssetUrl(uploadedFiles[0]._id);
        video.addEventListener("canplay", (e) => {
          if (video.readyState > 0) {
            if (!isNaN(video.duration) && video.duration > 0) {
              module.controller.updateLecture(
                "videoDurationInMinutes",
                Math.round(video.duration / 60)
              );
              performSave(uploadedFiles[0]._id);
              return;
            }
          }

          module.controller.updateLecture("videoDurationInMinutes", 0);
          performSave(uploadedFiles[0]._id);
          return;
        });

        performSave(uploadedFiles[0]._id);
      }
    }
  };

  const lecturerName = React.useMemo(() => {

    if (educators.length > 0 && context.lecturerId) {
      const data = educators.find((e) => {
        return e._id === context.lecturerId
      })
      if (data) {
        return data.name
      } else {
        return ""
      }

    } else {
      return ""
    }
  }, [context.lecturerId, educators])

  React.useEffect(() => {
    module.controller.updateLecture(
      "lecturerName",
      lecturerName
    )
  }, [context.lecturerId, lecturerName, module.controller])

  React.useEffect(() => {
    _fetchAllAttachedCourses();
    _fetchCustomLectureCollection();
    _fetchAllEducators()
    // eslint-disable-next-line
  }, []);

  const isAddedToCustomCollection =
    customLectureCollection.indexOf((context as any)._id) > -1;

  return (
    <>
      <div className="row lecture-details-page-wrapper">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 current-lecture-wrapper py-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Visibility & Subscription</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Attach or Modify Master Subscription
              </h6>
              <div className="form-group mt-4">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    checked={context.isVisible}
                    onChange={(e) =>
                      module.controller.updateLecture(
                        "isVisible",
                        e.target.checked
                      )
                    }
                    className="custom-control-input"
                    id="visibilityCheck"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="visibilityCheck"
                  >
                    {context.isVisible === true
                      ? "This lecture is enabled for public visibility"
                      : "Public visibility has turned off for this lecture"}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="rateCardInput">Rate Card</label>
                <select
                  value={(context.masterSubscription as any) || "not-selected"}
                  onChange={(e) => {
                    module.controller.updateLecture(
                      "masterSubscription",
                      e.target.value
                    );
                  }}
                  className="form-control"
                  id="rateCardInput"
                >
                  <option value="not-selected">
                    Please choose a rate card
                  </option>
                  {app.modules.Subscription.getState().master.map((s, i) => (
                    <option
                      key={i}
                      value={(s as any)._id}
                    >{`${s.title} (INR ${s.netAmount})`}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="card-body">
              <h5 className="card-title">Lecturer</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Add lecturer
              </h6>
              <div className="form-group">
                <label htmlFor="lecturerId">Choose Lecturer</label>
                <select
                  value={(context.lecturerId as any) || ""}
                  onChange={(e) => {

                    module.controller.updateLecture(
                      "lecturerId",
                      e.target.value
                    )

                  }}
                  className="form-control"
                  id="lecturerId"
                >
                  <option value="">
                    Please choose a lecturer
                  </option>
                  {educators.map((educator, i) => (
                    <option
                      key={i}
                      value={(educator as any)._id}
                    >{educator.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Video Settings</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Upload Video and Thumbnail
              </h6>

              <div className="form-group mt-4">
                <label htmlFor="descriptionField">Banner Image</label>
                {context.bannerImageId ? (
                  <>
                    <img
                      className="d-block"
                      src={resolveAssetUrl(context.bannerImageId, "public")}
                      width="100%"
                      alt="Course Banner"
                    />
                    <FileView
                      fileId={context.bannerImageId}
                      fileName="Thumbnail Image"
                      fileSizeInBytes={context.bannerImageFizeSizeInBytes}
                      onDelete={() => {
                        module.controller.updateLecture("bannerImageId", null);
                        module.controller.updateLecture(
                          "bannerImageFizeSizeInBytes",
                          0
                        );
                        module.controller.saveLecture();
                      }}
                    />
                  </>
                ) : (
                  <Dropzone
                    label="Upload Banner Image"
                    accept="image/*"
                    accessMode="public"
                    onSuccess={(files: any[]) => {
                      if (files && Array.isArray(files)) {
                        if (files.length > 0) {
                          module.controller.updateLecture(
                            "bannerImageId",
                            files[0]._id.toString()
                          );
                          module.controller.updateLecture(
                            "bannerImageFizeSizeInBytes",
                            files[0].sizeInBytes
                          );
                          module.controller.saveLecture();
                        }
                      }
                    }}
                  />
                )}
                <small
                  id="descriptionFieldHelp"
                  className="form-text text-muted"
                >
                  Thumbnail will be shown as the player preview
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="uTubeVideoId">
                  Youtube Video ID <b>(DO NOT PUT FULL URL)</b>
                </label>
                <input
                  value={context.youtubeLink}
                  onChange={(e) =>
                    module.controller.updateLecture(
                      "youtubeLink",
                      e.target.value
                    )
                  }
                  className="form-control"
                  id="uTubeVideoId"
                  placeholder="e.g. A2wG0sXbMhw"
                  aria-describedby="uTubeVideoIdHelp"
                />
                <small id="uTubeVideoIdHelp" className="form-text text-muted">
                  Leave this blank if you don't want to use the Youtube version
                  {context.youtubeLink && context.youtubeLink !== "" ? (
                    <>
                      {" "}
                      <a
                        href={`https://www.youtube.com/watch?v=${context.youtubeLink}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Test Video
                      </a>
                    </>
                  ) : null}
                </small>
              </div>
              <div className="form-group mt-4">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    checked={context.enableYoutubePlayer}
                    onChange={(e) =>
                      module.controller.updateLecture(
                        "enableYoutubePlayer",
                        e.target.checked
                      )
                    }
                    className="custom-control-input"
                    id="youtubePlayerSwitch"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="youtubePlayerSwitch"
                  >
                    Enable Youtube Player
                  </label>
                </div>
              </div>
              <div className="pt-3">
                <label className="font-weight-bold pb-1">Video Lecture</label>
                {context.videoResourceId === null ? (
                  <Dropzone
                    label="Upload Video"
                    accept="video/*"
                    onSuccess={handleVideoUploadSuccess(true)}
                  />
                ) : (
                  <div className="video-wrapper">
                    <div className="mb-3">
                      <FileProcessor
                        fileResId={context.videoResourceId}
                        title={context.title}
                      />
                    </div>
                    <video
                      controls={true}
                      src={`${module.package.getConfig().Main.baseURL
                        }/api/assets/${context.videoResourceId}`}
                      width="100%"
                    />
                  </div>
                )}
              </div>
              {context.videoResourceId !== null ? (
                <>
                  <FileView
                    fileId={context.videoResourceId}
                    fileName={context.videoFileName}
                    fileSizeInBytes={context.videoFileSizeInBytes}
                    enableReplace={true}
                    onSuccess={handleVideoUploadSuccess(true)}
                    onDelete={() => {
                      module.controller.updateLecture("videoResourceId", null);
                      module.controller.updateLecture("videoFileName", "");
                      module.controller.updateLecture(
                        "videoFileSizeInBytes",
                        0
                      );
                      module.controller.updateLecture(
                        "videoDurationInMinutes",
                        0
                      );
                      module.controller.saveLecture();
                    }}
                  />
                  <div className="form-group mt-3">
                    <label
                      className="font-weight-bold pb-1"
                      htmlFor="video-duration-minutes"
                    >
                      Video Duration (approx. in Minutes)
                    </label>
                    <input
                      autoComplete="off"
                      placeholder=""
                      type="number"
                      className="form-control form-control-lg"
                      value={context.videoDurationInMinutes}
                      onChange={(e) =>
                        module.controller.updateLecture(
                          "videoDurationInMinutes",
                          e.target.value
                        )
                      }
                      id="video-duration-minutes"
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Notes</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Attach and Manage Notes
              </h6>
              <div className="notes-wrapper">
                {context.notes?.map((note) => (
                  <FileView
                    key={note.resId}
                    pdfUri={resolveAssetUrl(note.pdfFileId, "private")}
                    enableDownload={true}
                    fileId={note.pdfFileId}
                    fileName={note.fileName}
                    fileSizeInBytes={note.fileSizeInBytes}
                    onDelete={() => {
                      module.controller.updateLecture(
                        "notes",
                        context.notes.filter(
                          (noteToDelete) =>
                            !(
                              noteToDelete.resId === note.resId &&
                              noteToDelete.lectureResId === note.lectureResId
                            )
                        )
                      );
                      module.controller.saveLecture();
                    }}
                  />
                ))}
              </div>
              <div className="pt-3">
                <Dropzone
                  mode="mini"
                  label="Add a note file"
                  accept="application/pdf"
                  onSuccess={(uploadedFiles) => {
                    module.controller.updateLecture("notes", [
                      ...context.notes,
                      ...uploadedFiles.map((file, index) => {
                        const _note: Note = {
                          fileName: file.fileName,
                          fileSizeInBytes: file.sizeInBytes,
                          lectureResId: context.resId,
                          pdfFileId: file._id.toString(),
                          resId: new Date().valueOf() + index,
                        };
                        return _note;
                      }),
                    ]);
                    module.controller.saveLecture();
                  }}
                />
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Exams</h5>
              <h6 className="card-subtitle mb-2 text-muted">Attached Exams</h6>
              <div className="notes-wrapper">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Title</th>
                      <th className="text-right" scope="col">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {context.exams?.map((exam) => {
                      return (
                        <tr key={(exam as any)._id}>
                          <th>
                            <a
                              href={`/admin/exams/editor/${(exam as any)._id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-link"
                            >
                              {exam.title}{" "}
                              <i className="fas fa-external-link-alt" />
                            </a>
                          </th>
                          <td className="text-right">
                            <button
                              onClick={() =>
                                setExamIdToDelete((exam as any)._id)
                              }
                              className="btn btn-sm btn-danger"
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                    {context.exams?.length < 1 ? (
                      <tr>
                        <td colSpan={2} className="text-center text-muted">
                          Nothing attached
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
              <div className="pt-3">
                <button
                  onClick={() => setIsExamPickerOpen(true)}
                  className="btn btn-success btn-block"
                >
                  <i className="fas fa-plus-circle mr-2"></i> Add Exam
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 current-lecture-wrapper py-4">
          <div>
            <div className="card mt-3">
              <div className="card-body">
                <h5 className="card-title">Attached Courses</h5>
                {attachedCourses.map((c, index) => {
                  return (
                    <ul key={index}>
                      <li>
                        {" "}
                        <a
                          className="custom-link"
                          href={`/admin/courses/editor/${(c as any)._id}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {c.title}
                        </a>
                      </li>
                    </ul>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="card mt-3">
                <div className="card-body">
                  <h5 className="card-title">Add To Custom Collection</h5>
                  {isAddedToCustomCollection === true ? (
                    <h6 className="card-subtitle mb-2 mb-4 text-muted">
                      <i
                        style={{ color: "#46BC71" }}
                        className="fas fa-check-circle pr-1"
                      ></i>
                      This lecture is added on custom collection
                    </h6>
                  ) : null}
                  {isAddedToCustomCollection === true ? (
                    <button
                      className="btn btn-danger"
                      onClick={() => toggleAddAndRemoveLectureToModal()}
                    >
                      <i className="fas fa-minus-circle pr-2"></i>
                      Remove from collection
                    </button>
                  ) : (
                    <button
                      className="btn btn-secondary"
                      onClick={() => toggleAddAndRemoveLectureToModal()}
                    >
                      <i className="fas fa-plus-circle pr-2"></i>
                      Add to collection
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* @ts-ignore */}
      <ExamPicker
        isOpen={isExamPickerOpen}
        filter={(exam: Exam) => {
          return (
            context.exams.findIndex(
              (e) => (e as any)._id === (exam as any)._id
            ) < 0
          );
        }}
        isMultiSelect={true}
        toggle={() => setIsExamPickerOpen(false)}
        onSelect={(exams: Exam[]) => {
          setIsExamPickerOpen(false);
          module.controller.updateLecture("exams", [
            ...context.exams,
            ...exams,
          ]);
          module.controller.saveLecture();
        }}
      />
      {/* @ts-ignore */}
      <Modal
        isOpen={examIdToDelete !== null}
        toggle={() => setExamIdToDelete(null)}
      >
        {/* @ts-ignore */}
        <ModalHeader toggle={() => setExamIdToDelete(null)}>
          Delete Confirmation
        </ModalHeader>
        {/* @ts-ignore */}
        <ModalBody>
          <div className="alert alert-danger">
            <p className="m-0">Are you sure you want to remove this exam?</p>
          </div>
        </ModalBody>
        {/* @ts-ignore */}
        <ModalFooter>
           {/* @ts-ignore */}
          <Button color="secondary" onClick={() => setExamIdToDelete(null)}>
            Cancel
          </Button>{" "}
          {/* @ts-ignore */}
          <Button
            color="danger"
            onClick={() => {
              module.controller.updateLecture(
                "exams",
                context.exams.filter((e) => (e as any)._id !== examIdToDelete)
              );
              setExamIdToDelete(null);
              module.controller.saveLecture();
            }}
          >
            Yes, Remove
          </Button>
        </ModalFooter>
      </Modal>
      {/* @ts-ignore */}
      <Modal
        isOpen={isAddandRemoveLectureFromCollectionModal}
        toggle={toggleAddAndRemoveLectureToModal}
      >
        {/* @ts-ignore */}
        <ModalHeader>
          {isAddedToCustomCollection === true
            ? "Remove Lecture"
            : "Add Lecture"}
        </ModalHeader>
        {/* @ts-ignore */}
        <ModalBody>
          {isAddedToCustomCollection === true ? (
            <div className="alert alert-danger">
              <p className="m-0">
                Are you sure you want to remove this lecture from collection?
              </p>
            </div>
          ) : (
            <div className="alert alert-success">
              <p className="m-0">
                Are you sure you want to add this lecture to collection?
              </p>
            </div>
          )}
        </ModalBody>
        {/* @ts-ignore */}
        <ModalFooter>
          {/* @ts-ignore */}
          <Button
            color="secondary"
            onClick={() => toggleAddAndRemoveLectureToModal()}
          >
            Cancel
          </Button>
          {isAddedToCustomCollection === true ? (
            // @ts-ignore 
            <Button
              color="danger"
              onClick={() => _handleRemoveLectureToCollection()}
            >
              Remove
            </Button>
          ) : (
            // @ts-ignore 
            <Button
              color="success"
              onClick={() => _handleAddLectureToCollection()}
            >
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};
