import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "..";
import SubjectsListing from "../components/SubjectsListing";
import LecturesListing from "../components/LecturesListing";
import { Subject, RankFile, Exam, Course, DaySchedule } from "lakshya-shared";
import { Link } from "react-router-dom";
import { CourseCategories, getSchedule } from "lakshya-shared";
// import Chip from "@material-ui/core/Chip";
import { app } from "../../..";
import { resolveAssetUrl } from "../../../shared/utils";
import TagInputView from "./../../../shared/ResourceTag";
import DaySelector from "./../../../shared/DaySelector";

class CourseEditor extends React.Component<
  ViewComponentPropType<DefaultModule>,
  any
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      isDeleting: false,
      deleteMessage: null,
      isRankFilePicker: false,
      isExamPickerOpen: false,
      rankFileRemoverModalVisible: null,
      examRemoverModalVisible: null,
      isScheduleOpen: false,
    };
    this._handleGetItemById = this._handleGetItemById.bind(this);
    this._handleUpdateCourse = this._handleUpdateCourse.bind(this);
    this._handleAddSubject = this._handleAddSubject.bind(this);
    this.render_subjectsEditor = this.render_subjectsEditor.bind(this);
  }

  toggleIsDeleteModalOpen = () =>
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
      isDeleting: false,
    });

  openSchedule = () => this.setState({ isScheduleOpen: true });
  closeSchedule = () => this.setState({ isScheduleOpen: false });



  _handleDeleteCourse() {
    this.setState(
      {
        isDeleting: true,
        deleteMessage: "Unknown error",
      },
      () =>
        setTimeout(() => {
          this.props.module.services
            .deleteCourseById((this.props.match.params as any).courseId)
            .then((response: any) => {
              this.setState(
                {
                  isDeleteModalOpen: false,
                },
                () => {
                  this.props.history.replace("/admin/courses")

                }
              );
            })
            .catch((e) => {
              this.setState(
                {
                  isDeleteModalOpen: false,
                },
                () => {
                  this.props.module.showError(
                    e.message ? e.message : "Network error",
                    "Delete Failed",
                    true
                  );
                }
              );
            });
        }, 400)
    );
  }

  _handleGetItemById(id: string) {
    this.props.module.services
      .getCourseById(id)
      .then((response: any) => {
        this.props.module.controller.setCourse(response);
        // this.props.module.controller.selectSubject(0);
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching data failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }

  _handleUpdateCourse() {
    this.props.module.controller.saveCourse();
  }

  _handleAddSubject = (data: any) => {
    this.props.module.controller.addSubject({
      lecturesMetaData: [],
      title: data.title,
      bannerImageId: null,
      bannerImageFizeSizeInBytes: 0,
      lectures: [],
      lectureDaySchedulePlan: {},
      lectureTopicMetadata: {},
      isActive: true
    });
  };

  _handleAddLecture = (data: any) => {
    // const context = this.props.context;
  };

  render_subjectsEditor() {
    const context = this.props.context;
    const subjects = context.course && context.course.subjects;
    let currentView = null;

    if (context.selectedSubjectIndex > -1) {
      currentView = (
        <LecturesListing
          onAddLecture={this._handleAddLecture}
          courseId={(context.course as any)._id}
          goBack={() => this.props.module.controller.selectSubject(-1)}
          context={context.course.subjects[context.selectedSubjectIndex]}
          module={this.props.module}
        />
      );
    } else {
      // Show course view
      currentView = (
        <SubjectsListing
          course={context.course}
          module={this.props.module}
          subjects={subjects}
          onSubjectSelected={(item: Subject, index: number) =>
            this.props.module.controller.selectSubject(index)
          }
          __handleAddSubject={this._handleAddSubject}
        />
      );
    }
    return currentView;
  }

  render_listingEditor() {
    const context = this.props.context;
    const module = this.props.module;
    const Dropzone = app.modules.FileUploader.views.Dropzone;
    const FileView = app.modules.FileUploader.views.FileView;

    // const calculateDiscountRate = (compareAtPrice: number, price: number) => {
    //   let discountRate = 0;
    //   if (!isNaN(compareAtPrice) && !isNaN(price)) {
    //     const discountAmount = compareAtPrice - price;
    //     if (discountAmount > 0) {
    //       discountRate = parseFloat(
    //         ((discountAmount / compareAtPrice) * 100).toFixed(2)
    //       );
    //     }
    //   }
    //   module.controller.updateCourse("discountRatePromoLabel", discountRate);
    // };

    let currentView = (
      <>
        <h4 className="mt-3">Basic Information</h4>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="d-block">Banner Image</label>
              {context.course.bannerImageId ? (
                <>
                  <img
                    style={{ height: 300 }}
                    src={resolveAssetUrl(
                      context.course.bannerImageId,
                      "public"
                    )}
                    alt="Course Banner"
                  />
                  <FileView
                    fileId={context.course.bannerImageId}
                    fileName="Banner Image"
                    fileSizeInBytes={context.course.bannerImageFizeSizeInBytes}
                    onDelete={() => {
                      module.controller.updateCourse("bannerImageId", null);
                      module.controller.updateCourse(
                        "bannerImageFizeSizeInBytes",
                        0
                      );
                      module.controller.saveCourse();
                    }}
                  />
                </>
              ) : (
                <Dropzone
                  label="Upload Banner Image"
                  accept="image/*"
                  accessMode="public"
                  onSuccess={(files: any[]) => {
                    if (files && Array.isArray(files)) {
                      if (files.length > 0) {
                        module.controller.updateCourse(
                          "bannerImageId",
                          files[0]._id.toString()
                        );
                        module.controller.updateCourse(
                          "bannerImageFizeSizeInBytes",
                          files[0].sizeInBytes
                        );
                        module.controller.saveCourse();
                      }
                    }
                  }}
                />
              )}
              <small id="descriptionFieldHelp" className="form-text text-muted">
                Banner Image will be shown in the Course home page
              </small>
            </div>
            <div className="form-group">
              <label>Banner Video</label>
              {context.course.previewVideoId ? (
                <>
                  <video
                    controls={true}
                    src={resolveAssetUrl(
                      context.course.previewVideoId,
                      "public"
                    )}
                    className="w-100"
                  />
                  <FileView
                    fileId={context.course.previewVideoId}
                    fileName="Banner Video"
                    fileSizeInBytes={context.course.previewVideoFileSizeInBytes}
                    onDelete={() => {
                      module.controller.updateCourse("previewVideoId", null);
                      module.controller.updateCourse(
                        "previewVideoFileSizeInBytes",
                        0
                      );
                      module.controller.saveCourse();
                    }}
                  />
                </>
              ) : (
                <Dropzone
                  label="Upload Preview Video"
                  accept="video/*"
                  accessMode="public"
                  onSuccess={(files: any[]) => {
                    if (files && Array.isArray(files)) {
                      if (files.length > 0) {
                        module.controller.updateCourse(
                          "previewVideoId",
                          files[0]._id.toString()
                        );
                        module.controller.updateCourse(
                          "previewVideoFileSizeInBytes",
                          files[0].sizeInBytes
                        );
                        module.controller.saveCourse();
                      }
                    }
                  }}
                />
              )}
              <small id="descriptionFieldHelp" className="form-text text-muted">
                Banner Image will be shown in the Course home page
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="descriptionField">Description</label>
              <textarea
                value={context.course.description}
                onChange={(e) =>
                  module.controller.updateCourse("description", e.target.value)
                }
                rows={4}
                className="form-control"
                id="descriptionField"
                aria-describedby="descriptionFieldHelp"
              />
              <small id="descriptionFieldHelp" className="form-text text-muted">
                Description will be shown in course preview and in 'About
                Course' section
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="categorySelect">Category</label>
              <select
                className="form-control"
                id="categorySelect"
                value={context.course.category || ""}
                onChange={(e) => {
                  module.controller.updateCourse("category", e.target.value);
                  module.controller.updateCourse("tags", []);
                }}
              >
                <option value={"not-selected"}>Please choose a category</option>
                {CourseCategories.sort((a, b) =>
                  a.category > b.category ? 1 : -1
                ).map((category) => {
                  return (
                    <option key={category.key} value={category.key}>
                      {category.category}
                    </option>
                  );
                })}
              </select>
            </div>
            {((): any => {
              const selectedCategory = CourseCategories.find(
                (c) => c.key === context.course.category
              );
              if (selectedCategory) {
                return null;
                // return (
                //   <div className="form-group">
                //     <label htmlFor="descriptionField">Tags</label>
                //     <div>
                //       {selectedCategory.tags.map((tag) => {
                //         const isSelected =
                //           context.course.tags.indexOf(tag) > -1;
                //         return (
                //           <Chip
                //             key={tag}
                //             clickable
                //             onClick={() => {
                //               if (isSelected === true) {
                //                 // Remove selection
                //                 module.controller.updateCourse(
                //                   "tags",
                //                   context.course.tags.filter((t) => t !== tag)
                //                 );
                //               } else {
                //                 // Add to selection
                //                 module.controller.updateCourse("tags", [
                //                   ...context.course.tags,
                //                   tag,
                //                 ]);
                //               }
                //             }}
                //             label={tag}
                //             style={{ marginRight: 8, marginTop: 8 }}
                //             classes={{ colorPrimary: "bg-primary" }}
                //             variant={"default"}
                //             color={isSelected ? "primary" : "default"}
                //           />
                //         );
                //       })}
                //     </div>
                //     <small
                //       id="descriptionFieldHelp"
                //       className="form-text text-muted"
                //     >
                //       Tags lets user filter the courses when they perform search
                //     </small>
                //   </div>
                // );
              }

              return null;
            })()}
          </div>
        </div>
        <hr />
        <div className="form-group">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              checked={(context.course as any).isExpiredCourse}
              onChange={(e) =>
                module.controller.updateCourse(
                  "isExpiredCourse",
                  e.target.checked
                )
              }
              className="custom-control-input"
              id="expiredSwitch"
            />
            <label className="custom-control-label" htmlFor="expiredSwitch">
              {(context.course as any).isExpiredCourse === true
                ? "This course is expired and no longer available for users"
                : "Active course"}
            </label>
          </div>
        </div>
        <h4>Resource Tags</h4>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="sort-index">
                Sort Index
              </label>
              <input
                placeholder="Default: 0"
                className="form-control"
                id="sort-index"
                type="number"
                value={(context.course as any).sortIndex}
                onChange={(e) =>
                  module.controller.updateCourse(
                    "sortIndex",
                    e.target.value
                  )
                }
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <div>
                <TagInputView
                  value={context.course.cmsTags.map((t) => ({
                    label: t,
                    value: t,
                  }))}
                  onChange={(e: any, tags: any) => {
                    module.controller.updateCourse("cmsTags", [
                      ...tags.map((t: any) => t.value),
                    ]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <h4>Monetisation</h4>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="validitySelect">Default Rate Card</label>
              <select
                className="form-control"
                id="validitySelect"
                value={context.course.courseRateCardId}
                onChange={(e) =>
                  module.controller.updateCourse(
                    "courseRateCardId",
                    e.target.value
                  )
                }
                aria-describedby="validitySelecteHelp"
              >
                <option value={"not-selected"}>Please choose a validity</option>
                {app.modules.Subscription.getState().master.map((_, i) => {
                  return (
                    <option
                      key={i}
                      value={(_ as any)._id}
                    >{`${_.title} (INR ${_.netAmount})`}</option>
                  );
                })}
              </select>
            </div>

            <div>
              <button
                onClick={() => {
                  module.controller.addSubscription(
                    {
                      subscriptionId: "",
                    }
                  )
                }}
                className="btn btn-primary">Add Subsription</button>
              {context.course.attachedSubscriptions?.length === 0 ? (
                <div className="pt-4">
                  <label>No additonal subscriptions attached other than default subscriptions</label>
                </div>
              ) : (
                <div className="pt-4">
                  <label>Attached Subscrptions</label>
                </div>
              )}


              {context.course.attachedSubscriptions?.map((subscription: any, index) => {
                return (
                  <div key={index}>
                    <div className="row">
                      <div className="col-10 pt-2">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="validitySelect"
                            value={subscription.subscriptionId}
                            onChange={(e) =>
                              module.controller.updateSubscription(
                                index,
                                "subscriptionId",
                                e.target.value
                              )
                            }
                            aria-describedby="validitySelecteHelp"
                          >
                            <option value={"not-selected"}>Please choose a validity</option>
                            {app.modules.Subscription.getState().master.map((_, i) => {
                              return (
                                <option
                                  key={i}
                                  value={(_ as any)._id}
                                >{`${_.title} (INR ${_.netAmount})`}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-2 pt-2">
                        <button
                          onClick={() => {
                            module.controller.removeSubscription(index)
                          }}
                          className="btn btn-sm btn-danger mt-2"
                        >
                          <i className="fas fa-trash" />
                        </button>
                      </div>
                    </div>

                  </div>
                )
              })}
            </div>

            <div className="form-group form-check pt-4">
              <input
                checked={context.course.isBestSelling}
                onChange={(e) =>
                  module.controller.updateCourse(
                    "isBestSelling",
                    e.target.checked
                  )
                }
                type="checkbox"
                className="form-check-input"
                id="bestSellerFlagCheck"
              />
              <label className="form-check-label" htmlFor="bestSellerFlagCheck">
                Best Seller Flag Enabled
              </label>
            </div>
            <div className="form-group">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  checked={context.course.isVisible}
                  onChange={(e) =>
                    module.controller.updateCourse(
                      "isVisible",
                      e.target.checked
                    )
                  }
                  className="custom-control-input"
                  id="stickySwitch"
                />
                <label className="custom-control-label" htmlFor="stickySwitch">
                  {context.course.isVisible === true
                    ? "This course is enabled for public visibility"
                    : "Public visibility has turned off for this course"}
                </label>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-12">
                <h4>Day Planner</h4>
                <div className="form-group pt-3">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      checked={context.course.enableDaySchedule}
                      onChange={(e) =>
                        module.controller.updateCourse(
                          "enableDaySchedule",
                          e.target.checked
                        )
                      }
                      className="custom-control-input"
                      id="enableDaySchedule"
                    />
                    <label className="custom-control-label" htmlFor="enableDaySchedule">
                      {context.course.enableDaySchedule === true
                        ? "Day planner is active"
                        : "Day planner is inactive"}
                    </label>
                  </div>
                </div>
                {context.course.enableDaySchedule === false ? (
                  <div>
                    {context.course.dayScheduleNote.map((note: any) => (
                      <div style={{ alignItems: "baseline" }} className="row">
                        <div className="col-9">
                          <FileView
                            key={note.resId}
                            pdfUri={resolveAssetUrl(note.pdfFileId, "private")}
                            enableDownload={true}
                            fileId={note.pdfFileId}
                            fileName={note.fileName}
                            fileSizeInBytes={note.fileSizeInBytes}
                            onDelete={() => {
                              module.controller.updateCourse(
                                "dayScheduleNote",
                                context.course.dayScheduleNote.filter(
                                  (noteToDelete: any) =>
                                    !(
                                      noteToDelete.resId === note.resId
                                    )
                                )
                              );
                              module.controller.saveCourse();
                            }}
                          />
                        </div>
                        <div className="col-3">
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              checked={(note as any).isActive}
                              onChange={(e) =>
                                module.controller.updateCourse(
                                  "dayScheduleNote",
                                  context.course.dayScheduleNote.map((pq: any) => {
                                    if (pq.resId === note.resId) {
                                      return {
                                        ...pq,
                                        isActive: e.target.checked
                                      }

                                    } else {
                                      return {
                                        ...pq
                                      }
                                    }
                                  })
                                )
                              }
                              className="custom-control-input"
                              id={`${note.resId}`}
                            />
                            <label className="custom-control-label" htmlFor={`${note.resId}`}>
                              {(note as any).isActive === true
                                ? "Active"
                                : "Inactive"}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                    {context.course.dayScheduleNote.length === 0 ? (
                      <div className="pt-3">
                        <Dropzone
                          mode="mini"
                          label="Add Day planner"
                          accept="application/pdf"
                          onSuccess={(uploadedFiles) => {
                            module.controller.updateCourse("dayScheduleNote", [
                              ...context.course.syllabus,
                              ...uploadedFiles.map((file, index) => {
                                const dayScheduleNote: any = {
                                  fileName: file.fileName,
                                  fileSizeInBytes: file.sizeInBytes,
                                  pdfFileId: file._id.toString(),
                                  resId: new Date().valueOf() + index,
                                  isActive: false
                                };
                                return dayScheduleNote;
                              }),
                            ]);
                            module.controller.saveCourse();
                          }}
                        />
                      </div>
                    ) : null}

                  </div>
                ) : null}
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-12">
                <h4>PQ and Syllabus</h4>
                <label>Previous Questions</label>
                {context.course.previousQuestions.map((note) => (
                  <div style={{ alignItems: "baseline" }} className="row">
                    <div className="col-7">
                      <FileView
                        key={note.resId}
                        pdfUri={resolveAssetUrl(note.pdfFileId, "private")}
                        enableDownload={true}
                        fileId={note.pdfFileId}
                        fileName={note.fileName}
                        fileSizeInBytes={note.fileSizeInBytes}
                        onDelete={() => {
                          module.controller.updateCourse(
                            "previousQuestions",
                            context.course.previousQuestions.filter(
                              (noteToDelete) =>
                                !(
                                  noteToDelete.resId === note.resId
                                )
                            )
                          );
                          module.controller.saveCourse();
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <input
                        placeholder="Year"
                        className="form-control"
                        id="sort-index"
                        type="number"
                        value={note.year}
                        onChange={(e) => {
                          module.controller.updateCourse(
                            "previousQuestions",
                            context.course.previousQuestions.map((pq) => {
                              if (pq.resId === note.resId) {
                                return {
                                  ...pq,
                                  year: e.target.value
                                }

                              } else {
                                return {
                                  ...pq
                                }
                              }
                            })
                          );
                        }}
                      />
                    </div>
                    <div className="col-3">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          checked={(note as any).isActive}
                          onChange={(e) =>
                            module.controller.updateCourse(
                              "previousQuestions",
                              context.course.previousQuestions.map((pq) => {
                                if (pq.resId === note.resId) {
                                  return {
                                    ...pq,
                                    isActive: e.target.checked
                                  }

                                } else {
                                  return {
                                    ...pq
                                  }
                                }
                              })
                            )
                          }
                          className="custom-control-input"
                          id={`${note.resId}`}
                        />
                        <label className="custom-control-label" htmlFor={`${note.resId}`}>
                          {(note as any).isActive === true
                            ? "Active"
                            : "Inactive"}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="pt-3">
                  <Dropzone
                    mode="mini"
                    label="Add a previous question"
                    accept="application/pdf"
                    onSuccess={(uploadedFiles) => {
                      module.controller.updateCourse("previousQuestions", [
                        ...context.course.previousQuestions,
                        ...uploadedFiles.map((file, index) => {
                          const pq: any = {
                            fileName: file.fileName,
                            fileSizeInBytes: file.sizeInBytes,
                            pdfFileId: file._id.toString(),
                            resId: new Date().valueOf() + index,
                            year: 0,
                            isActive: false
                          };
                          return pq;
                        }),
                      ]);
                      module.controller.saveCourse();
                    }}
                  />
                </div>
                <label className="pt-3">Syllabus</label>

                {context.course.syllabus?.map((note: any) => (
                  <div style={{ alignItems: "baseline" }} className="row">
                    <div className="col-9">
                      <FileView
                        key={note.resId}
                        pdfUri={resolveAssetUrl(note.pdfFileId, "private")}
                        enableDownload={true}
                        fileId={note.pdfFileId}
                        fileName={note.fileName}
                        fileSizeInBytes={note.fileSizeInBytes}
                        onDelete={() => {
                          module.controller.updateCourse(
                            "syllabus",
                            context.course.syllabus.filter(
                              (noteToDelete: any) =>
                                !(
                                  noteToDelete.resId === note.resId
                                )
                            )
                          );
                          module.controller.saveCourse();
                        }}
                      />
                    </div>
                    <div className="col-3">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          checked={(note as any).isActive}
                          onChange={(e) =>
                            module.controller.updateCourse(
                              "syllabus",
                              context.course.syllabus?.map((pq: any) => {
                                if (pq.resId === note.resId) {
                                  return {
                                    ...pq,
                                    isActive: e.target.checked
                                  }

                                } else {
                                  return {
                                    ...pq
                                  }
                                }
                              })
                            )
                          }
                          className="custom-control-input"
                          id={`${note.resId}`}
                        />
                        <label className="custom-control-label" htmlFor={`${note.resId}`}>
                          {(note as any).isActive === true
                            ? "Active"
                            : "Inactive"}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="pt-3">
                  <Dropzone
                    mode="mini"
                    label="Add Syllabus"
                    accept="application/pdf"
                    onSuccess={(uploadedFiles) => {
                      module.controller.updateCourse("syllabus", [
                        ...context.course.syllabus,
                        ...uploadedFiles.map((file, index) => {
                          const syllabus: any = {
                            fileName: file.fileName,
                            fileSizeInBytes: file.sizeInBytes,
                            pdfFileId: file._id.toString(),
                            resId: new Date().valueOf() + index,
                            isActive: false
                          };
                          return syllabus;
                        }),
                      ]);
                      module.controller.saveCourse();
                    }}
                  />
                </div>

              </div>
            </div>






          </div>
        </div>
      </>
    );
    return currentView;
  }

  render_rankFiles = () => {
    const context = this.props.context;
    const module = this.props.module;
    const RankFilePicker = app.modules.RankFile.views.RankFilePicker;
    const ExamPicker = app.modules.Exam.views.ExamPicker;

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card mt-3">
                <div className="card-body">
                  <h5 className="card-title">Attached Rank Files</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Table contains all attached rank file resources
                  </h6>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Title</th>
                        <th scope="col">Subscription</th>
                        <th scope="col" className="text-right">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {context.course.rankFiles.map((r, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`/admin/rank-files/editor/${(r as any)._id
                                  }`}
                              >
                                {r.title}{" "}
                                <i className="fas fa-external-link-alt" />
                              </a>
                            </td>
                            <td>
                              {((): any => {
                                if (r.masterSubscription) {
                                  return (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`/admin/subscriptions/editor/${(r.masterSubscription as any)._id
                                        }`}
                                    >
                                      {r.masterSubscription.title}{" "}
                                      <i className="fas fa-external-link-alt" />
                                    </a>
                                  );
                                }
                                return "No Association";
                              })()}
                            </td>
                            <td className="text-right">
                              <button
                                onClick={() => {
                                  this.setState({
                                    rankFileRemoverModalVisible: r._id,
                                  });
                                }}
                                className="btn btn-sm btn-danger"
                              >
                                <i className="fas fa-trash" />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                      {context.course.rankFiles.length < 1 ? (
                        <tr>
                          <td colSpan={4} className="text-muted text-center">
                            No Items
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={4} className="text-center">
                          <button
                            onClick={() =>
                              this.setState({ isRankFilePicker: true })
                            }
                            className="btn btn-sm btn-primary"
                          >
                            <i className="fas fa-plus-circle" /> Attach Rank
                            File
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-body">
                  <h5 className="card-title">Attached Exams</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Table contains all attached exams
                  </h6>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Scheduled Days</th>
                        <th scope="col">Title</th>
                        <th scope="col">Subscription</th>
                        <th scope="col" className="text-right">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {context.course.exams.map((examLink, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <th scope="row">
                              <div>
                                <DaySelector
                                  value={examLink.dayTags.map((t) => ({
                                    label: `Day ${t}`,
                                    value: t,
                                  }))}
                                  onChange={(e: any, tags: any) => {
                                    module.controller.updateCourse(
                                      "exams",
                                      context.course.exams.map(
                                        (_link, index) => {
                                          if (index === i) {
                                            return Object.assign({}, _link, {
                                              dayTags: tags.map((t: any) =>
                                                !isNaN(t.value)
                                                  ? parseInt(t.value)
                                                  : 0
                                              ),
                                            });
                                          }

                                          return _link;
                                        }
                                      )
                                    );
                                  }}
                                />
                              </div>
                            </th>
                            <td>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`/admin/exams/editor/${(examLink as any)._id
                                  }`}
                              >
                                {examLink.exam
                                  ? examLink.exam.title
                                  : "UNDEFINED"}{" "}
                                <i className="fas fa-external-link-alt" />
                              </a>
                            </td>
                            <td>
                              {((): any => {
                                if (
                                  examLink.exam &&
                                  examLink.exam.masterSubscription
                                ) {
                                  return (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`/admin/subscriptions/editor/${(examLink.exam
                                        .masterSubscription as any)._id
                                        }`}
                                    >
                                      {examLink.exam.masterSubscription.title}{" "}
                                      <i className="fas fa-external-link-alt" />
                                    </a>
                                  );
                                }
                                return "No Association";
                              })()}
                            </td>
                            <td className="text-right">
                              <button
                                onClick={() => {
                                  this.setState({
                                    examRemoverModalVisible: examLink._id,
                                  });
                                }}
                                className="btn btn-sm btn-danger"
                              >
                                <i className="fas fa-trash" />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                      {context.course.exams.length < 1 ? (
                        <tr>
                          <td colSpan={5} className="text-muted text-center">
                            No Items
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={5} className="text-center">
                          <button
                            onClick={() =>
                              this.setState({ isExamPickerOpen: true })
                            }
                            className="btn btn-sm btn-primary"
                          >
                            <i className="fas fa-plus-circle" /> Attach Exam
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* @ts-ignore */}

        <RankFilePicker
          filter={(rankFile: RankFile) => {
            return (
              context.course.rankFiles.findIndex(
                (e) => (e as any)._id === rankFile._id
              ) < 0
            );
          }}
          isOpen={this.state.isRankFilePicker}
          isMultiSelect={true}
          toggle={() => this.setState({ isRankFilePicker: false })}
          onSelect={(rankFiles: RankFile[]) => {
            if (Array.isArray(rankFiles) && rankFiles.length > 0) {
              module.controller.updateCourse("rankFiles", [
                ...context.course.rankFiles,
                ...rankFiles,
              ]);
              this.setState({ isRankFilePicker: false }, () =>
                module.controller.saveCourse()
              );
            }
          }}
        />
        {/* @ts-ignore */}

        <ExamPicker
          isOpen={this.state.isExamPickerOpen}
          isMultiSelect={true}
          toggle={() => this.setState({ isExamPickerOpen: false })}
          onSelect={(exams: Exam[]) => {
            const examLinks = exams.map((exam) => {
              return { dayTags: [], exam };
            });
            if (Array.isArray(exams) && exams.length > 0) {
              module.controller.updateCourse("exams", [
                ...context.course.exams,
                ...examLinks,
              ]);
              this.setState({ isExamPickerOpen: false }, () =>
                module.controller.saveCourse().then(() => {
                  this._handleGetItemById(
                    (this.props.match.params as any).courseId
                  );
                })
              );
            }
          }}
        />
        {/* @ts-ignore */}

        <Modal
          isOpen={this.state.rankFileRemoverModalVisible !== null}
          toggle={() => this.setState({ rankFileRemoverModalVisible: null })}
        >
          {/* @ts-ignore */}

          <ModalHeader
            toggle={() => this.setState({ rankFileRemoverModalVisible: null })}
          >
            Removal Confirmation
          </ModalHeader>
          {/* @ts-ignore */}

          <ModalBody>
            <div className="alert alert-danger">
              <p className="m-0">
                Do you want to remove the selected rank file?
              </p>
            </div>
          </ModalBody>
          {/* @ts-ignore */}

          <ModalFooter>
            {/* @ts-ignore */}

            <Button
              onClick={() =>
                this.setState({ rankFileRemoverModalVisible: null })
              }
            >
              Cancel
            </Button>
            {/* @ts-ignore */}

            <Button
              color="danger"
              onClick={() => {
                module.controller.updateCourse(
                  "rankFiles",
                  context.course.rankFiles.filter(
                    (r) => r._id !== this.state.rankFileRemoverModalVisible
                  )
                );
                this.setState({ rankFileRemoverModalVisible: null }, () =>
                  module.controller.saveCourse()
                );
              }}
            >
              Yes, Remove
            </Button>
          </ModalFooter>
        </Modal>
        {/* @ts-ignore */}

        <Modal
          isOpen={this.state.examRemoverModalVisible !== null}
          toggle={() => this.setState({ examRemoverModalVisible: null })}
        >
          {/* @ts-ignore */}

          <ModalHeader
            toggle={() => this.setState({ examRemoverModalVisible: null })}
          >
            Removal Confirmation
          </ModalHeader>
          {/* @ts-ignore */}

          <ModalBody>
            <div className="alert alert-danger">
              <p className="m-0">Do you want to remove the selected exam?</p>
            </div>
          </ModalBody>
          {/* @ts-ignore */}

          <ModalFooter>
            {/* @ts-ignore */}

            <Button
              onClick={() => this.setState({ examRemoverModalVisible: null })}
            >
              Cancel
            </Button>
            {/* @ts-ignore */}

            <Button
              color="danger"
              onClick={() => {
                module.controller.updateCourse(
                  "exams",
                  context.course.exams.filter(
                    (r) => r._id !== this.state.examRemoverModalVisible
                  )
                );
                this.setState({ examRemoverModalVisible: null }, () =>
                  module.controller.saveCourse()
                );
              }}
            >
              Yes, Remove
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  componentDidMount() {
    this._handleGetItemById((this.props.match.params as any).courseId);
  }
  render() {
    const context = this.props.context;
    const editorMode = (this.props.match.params as any).editorMode
      ? (this.props.match.params as any).editorMode
      : "general";

    if (!context.course) {
      return <label>Loading</label>;
    }

    let currentView = null;
    switch (editorMode) {
      case "subjects": {
        currentView = this.render_subjectsEditor();
        break;
      }
      case "general": {
        currentView = this.render_listingEditor();
        break;
      }
      case "rankfiles": {
        currentView = this.render_rankFiles();
        break;
      }
      default: {
        currentView = <div></div>;
      }
    }
    let query = ""

    if (window.location.href.indexOf('type=v1') > -1) {
      query = "?type=v1"
    }
    return (
      <>
        {/* @ts-ignore */}

        <Helmet>
          <title>{`Course Editor > ${context.course.title}`}</title>
        </Helmet>
        <div className="container-fluid course-editor">
          <div className="row pb-5">
            <div className="col-12 d-flex flex-direction-column header-wrapper py-4 px-4">
              <div className="flex-shrink-0 d-flex flex-direction-column justify-content-center align-items-center">
                {!query ? (
                  <Link to="/admin/courses" className="go-back-button">
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                ) : null}

                <input
                  className="ml-2 pl-2 title-edit-input"
                  value={context.course.title}
                  onChange={(e) =>
                    this.props.module.controller.updateCourse(
                      "title",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="flex-fill"></div>
              <div className="flex-shrink d-flex flex-direction-row">
                <div className="status-wrapper pr-4">
                  <i
                    className={`fas fa-circle pr-2 ${context.course.isPublished === true
                      ? "text-success"
                      : "text-danger"
                      }`}
                  ></i>
                  <label className="mb-0">
                    {context.course.isPublished === true
                      ? "Published"
                      : "Unpublished"}
                  </label>
                </div>
                <button
                  disabled={!this.props.context.haveContextUpdated}
                  type="button"
                  className="btn btn-primary save-button-wrapper px-5 py-2 mr-3"
                  onClick={this._handleUpdateCourse}
                >
                  Save
                </button>
                <button
                  disabled={this.props.context.haveContextUpdated}
                  onClick={() => {
                    app.modules.Composer.showComposer({
                      body: "",
                      imageUrl: resolveAssetUrl(
                        context.course.bannerImageId,
                        "public"
                      ),
                      resourceID: (context.course as any)._id,
                      resourceType: "course",
                      title: context.course.title,
                      clickAction: "open_course",
                      targetPhoneNumber: "",
                    });
                  }}
                  className="delete-button-wrapper mr-3"
                >
                  <i className="fas fa-paper-plane"></i>
                </button>
                <button
                  onClick={() => this.openSchedule()}
                  className="delete-button-wrapper mr-3"
                >
                  <i className="fas fa-calendar"></i>
                </button>
                {!query ? (
                  <button
                    onClick={() => this.toggleIsDeleteModalOpen()}
                    className="delete-button-wrapper"
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                ) : null}

              </div>
            </div>
            <div className="tab-switcher col-12">
              <Link
                to={`/admin/courses/editor/${(context.course as any)._id}${query}`}
                className={`pt-3 pb-3 px-4 ${editorMode === "general" ? "selected" : ""
                  }`}
              >
                General
              </Link>
              <Link
                to={`/admin/courses/editor/${(context.course as any)._id
                  }/subjects${query}`}
                className={`pt-3 pb-3 px-4 ${editorMode === "subjects" ? "selected" : ""
                  }`}
              >
                Subjects
              </Link>
              <Link
                to={`/admin/courses/editor/${(context.course as any)._id
                  }/rankfiles${query}`}
                className={`pt-3 pb-3 px-4 ${editorMode === "rankfiles" ? "selected" : ""
                  }`}
              >
                Exams
              </Link>
            </div>
            <div className="col-12 course-editor-content-wrapper pl-5">
              {context.course.isVisible === true &&
                context.course.publishWarnings.length > 0 ? (
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="alert alert-danger mb-0" role="alert">
                        <h4 className="alert-heading">Course Offline!</h4>
                        <p>
                          This content cannot be seen by students and other
                          users through the app. To make it public, please
                          resolve below warnings.
                        </p>
                        <hr />
                        <ol>
                          {context.course.publishWarnings.map(
                            (warning, index) => (
                              <li key={index}>{warning}</li>
                            )
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {currentView}
            </div>
          </div>
        </div>
        {/* @ts-ignore */}

        <Modal
          toggle={this.toggleIsDeleteModalOpen}
          isOpen={this.state.isDeleteModalOpen}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation</h5>
              <button
                onClick={this.toggleIsDeleteModalOpen}
                type="button"
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6 style={{ fontWeight: "normal" }}>
                <div className="alert alert-danger" role="alert">
                  <h4 className="alert-heading">Warning!</h4>
                  <p>
                    You are about to delete this course, which is undoable. It
                    will remove reference to all subjects, groups and other
                    associated assets. However, none of the lecture videos or
                    notes will be affected.
                  </p>
                  <hr />
                  <p className="mb-0">Do you wish to continue?</p>
                </div>
              </h6>
            </div>
            <div className="modal-footer">
              <button
                disabled={this.state.isDeleting}
                type="button"
                className="btn btn-secondary"
                onClick={this.toggleIsDeleteModalOpen}
              >
                Cancel
              </button>
              <button
                disabled={this.state.isDeleting}
                onClick={() => {
                  this._handleDeleteCourse();
                }}
                type="button"
                className="btn btn-danger"
              >
                {this.state.isDeleting === true ? "Deleting..." : "Yes, Delete"}
              </button>
            </div>
          </div>
        </Modal>
        {/* @ts-ignore */}

        <Modal
          size="lg"
          style={{ maxWidth: "1300px", width: "100%" }}
          toggle={this.closeSchedule}
          isOpen={this.state.isScheduleOpen}
        >
          <ScheduleViewer
            course={context.course}
            onClose={this.closeSchedule}
          />
        </Modal>
      </>
    );
  }
}

function ScheduleViewer(props: any) {
  const course: Course = props.course;
  const [schedule, setSchedule] = React.useState<DaySchedule[]>([]);

  React.useEffect(() => {
    if (course) {
      getSchedule(course)
        .then((value) => {
          setSchedule(value);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [course]);

  if (!Array.isArray(schedule)) {
    return null;
  }

  const lectureLengths = schedule.reduce((accumulator, item) => {
    return [...accumulator, item.lectures.length];
  }, []);

  const totalExamLengths = schedule.reduce((accumulator, item) => {
    return [...accumulator, item.exams.length];
  }, []);

  let rows: any[][] = [];

  for (var i = 0; i < Math.max(...lectureLengths, ...totalExamLengths); i++) {
    const j = i;
    rows.push(
      (() => {
        let result: string[] = [];
        schedule.forEach((item) => {
          // Push Lecture
          if (item.lectures[j]) {
            result.push(item.lectures[j].title);
          } else {
            result.push("-");
          }

          // Push Exam
          if (item.exams[j]) {
            result.push(item.exams[j].title);
          } else {
            result.push("-");
          }
        });

        return result;
      })()
    );
  }

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Schedule</h5>
        <button onClick={props.onClose} type="button" className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {schedule.length < 1 ? (
          <div className="alert alert-warning m-3" role="alert">
            No schedules have been created yet!
          </div>
        ) : null}
        <div className="table-responsive">
          <table
            style={{ fontSize: 10 }}
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                {schedule.map((schedule) => (
                  <th
                    key={schedule.dayIndex}
                    scope="col"
                    colSpan={2}
                    className="text-center"
                  >
                    {schedule.title}
                  </th>
                ))}
              </tr>
              <tr>
                {schedule.map((schedule, index) => (
                  <React.Fragment key={index}>
                    <th scope="col">Lectures</th>
                    <th scope="col">Exam</th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => {
                return (
                  <tr key={index}>
                    {row.map((col, index) => {
                      return (
                        <td
                          key={index}
                          className={col === "-" ? "text-muted" : ""}
                        >
                          {col}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={props.onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default CourseEditor;
