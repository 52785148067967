import { ArkModule, withAuthentication } from "@skyslit/ark-react";
import { connect } from "react-redux";
import StarterPageView from "./views/StarterPage.view";
import { ComponentMap } from "@skyslit/ark-react/build/types";
import AuthPageView from "./views/Auth.view";
import RegisterPageView from "./views/RegisterPage.view";
import RecoveryPageView from "./views/RecoveryPage.View";

export type Movie = {
  title: string;
  actor: string;
};

export type StateType = {
  messageToDisplay: string;
  movies: Movie[];
};

export default class AuthModule extends ArkModule<StateType, "Main"> {
  constructor() {
    super("AuthModule");
    this.initializeServerContext = true;

    this.useConnect(connect);

    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          case this.actionTypes.TOGGLE_MESSAGE: {
            return Object.assign({}, state, {
              messageToDisplay:
                state.messageToDisplay === "Hello World"
                  ? "Good Day"
                  : "Hello World",
            });
          }
          case this.actionTypes.POPULATE_MOVIES: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              movies: [...state.movies, ...value],
            });
          }
          default: {
            return state;
          }
        }
      };
    };

    this.main = () => {
      /** This function will be executed while initializing a package */
    };
  }

  actionTypes = {
    TOGGLE_MESSAGE: "TOGGLE_MESSAGE",
    POPULATE_MOVIES: "POPULATE_MOVIES",
  };

  views: ComponentMap = {
    StarterPage: StarterPageView,
    AuthPage: AuthPageView,
    RegisterPage: RegisterPageView,
    RecoveryPage: RecoveryPageView,
  };

  controller = {
    toggleTitle: () => {
      this.dispatch({
        type: this.actionTypes.TOGGLE_MESSAGE,
      });
    },
  };

  services = {
    registerUser: (name: string, emailAddress: string, password: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/auth/register", {
            name,
            emailAddress,
            password,
          })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    signInWithEmailAndPassword: (emailAddress: string, password: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/auth/login", {
            emailAddress,
            password,
          })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getRecoveryOptions: (email: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/auth/recovery/initiate", email)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    initiateRecovery: (option: object) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/auth/recovery/recovery-channel", option)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    validateOTP: (otp: string, otpToken: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/auth/recovery/otp-verification", {
            otp,
            otpToken,
          })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    resetPassword: (newPassword: string, otp: string, otpToken: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/auth/recovery/execute", {
            newPassword,
            otp,
            otpToken,
          })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    finishSocialAuthentication: (token: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/auth/social-authentication", token)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    logout: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider('Main')
          .get('/api/auth/logout')
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    }
  };

  initialState: StateType = {
    messageToDisplay: "Hello World",
    movies: [],
  };

  getDefaultRoutes() {
    return [
      {
        path: "/auth/recovery",
        component: this.views.RecoveryPage,
      },
      {
        path: "/auth/register",
        component: this.views.RegisterPage,
      },
      {
        path: "/auth/login",
        component: this.views.AuthPage,
        Router: withAuthentication("/account", false),
      },
      {
        path: "/account",
        component: this.views.StarterPage,
        Router: withAuthentication("/auth/login", true),
      },
    ];
  }
}
