import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "..";
// import { Link } from "react-router-dom";
import CourseListing from "../components/CourseListing";
import { Modal } from "reactstrap";
import { useForm } from "react-hook-form";
type StateType = {
  isModalOpen: boolean;
  isCourseCreatorBusy: boolean;
  data: [];
  isDuplicateModalOpen: boolean;
  count: any;
  idToDuplicate: any;
  duplicationValidationMsg: any;
  isduplicationLoading: boolean;
};
class CoursePage extends React.Component<
  ViewComponentPropType<DefaultModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isModalOpen: false,
      isCourseCreatorBusy: false,
      data: [],
      isDuplicateModalOpen: false,
      count: 0,
      idToDuplicate: "",
      duplicationValidationMsg: "",
      isduplicationLoading: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.openDuplicateModal = this.openDuplicateModal.bind(this);
    this.closeDuplicateModal = this.closeDuplicateModal.bind(this);
    this._handleAddCourse = this._handleAddCourse.bind(this);
    this._handleFetchCourses = this._handleFetchCourses.bind(this);
    this.setCourseCreatorIsBusy = this.setCourseCreatorIsBusy.bind(this);
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  openDuplicateModal(id: any) {
    this.setState({
      isDuplicateModalOpen: true,
      idToDuplicate: id,
    });
  }

  closeDuplicateModal() {
    this.setState({
      isDuplicateModalOpen: false,
    });
  }

  setCourseCreatorIsBusy(isBusy: boolean = true) {
    this.setState({
      isCourseCreatorBusy: isBusy,
    });
  }

  _handleAddCourse = (data: any) => {
    this.setCourseCreatorIsBusy(true);
    this.props.module.services
      .addCourse(data.title)
      .then((response: any) => {
        this.setCourseCreatorIsBusy(false);
        this.toggleModal();
        this.props.history.push(`/admin/courses/editor/${response._id}`);
      })
      .catch((e) => {
        this.setCourseCreatorIsBusy(false);
        this.props.module.showError(
          "Course cannot be added",
          e.message ? e.message : "Network error",
          true
        );
      });
  };
  _handleFetchCourses() {
    this.props.module.services
      .getAllCourse()
      .then((response: any) => {
        this.setState({
          data: response,
        });
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching course failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }

  _handleDuplicateCourse = () => {
    if (this.state.count > 0) {
      this.setState({
        isduplicationLoading: true,
      });
      this.props.module.services
        .duplicateCourseById(this.state.idToDuplicate, this.state.count)
        .then((response: any) => {
          this.setState({
            isDuplicateModalOpen: false,
            duplicationValidationMsg: "",
            count: 0,
            isduplicationLoading: false,
          });
          this._handleFetchCourses();
        })
        .catch((e) => {
          this.setState({
            duplicationValidationMsg: "",
            count: 0,
            isduplicationLoading: false,
          });
          this.props.module.showError(
            "Course cannot be duplicated",
            e.message ? e.message : "Network error",
            true
          );
        });
    } else {
      this.setState({
        duplicationValidationMsg: "Count should be more than 0",
      });
    }
  };
  componentDidMount() {
    this._handleFetchCourses();
  }

  render() {
    return (
      <>
        <>
          {/* @ts-ignore */}

          <Helmet>
            <title>Course Manager - Lakshya eLearning System</title>
          </Helmet>
          <CourseListing
            toggleModal={this.toggleModal}
            openDuplicateModal={this.openDuplicateModal}
            data={this.state.data}
            user={this.props.global.userInfo}
          />
          {/* @ts-ignore */}

          <Modal
            autoFocus={true}
            centered={true}
            toggle={this.toggleModal}
            backdrop={
              this.state.isCourseCreatorBusy === true ? "static" : undefined
            }
            keyboard={!this.state.isCourseCreatorBusy}
            isOpen={this.state.isModalOpen}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">New Course</h5>
                {this.state.isCourseCreatorBusy === false ? (
                  <button
                    type="button"
                    onClick={this.toggleModal}
                    className="close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                ) : null}
              </div>
              <div className="modal-body">
                <Form
                  toggleModal={this.toggleModal}
                  _handleAddCourse={this._handleAddCourse}
                  isCourseCreatorBusy={this.state.isCourseCreatorBusy}
                />
              </div>
            </div>
          </Modal>
          {/* @ts-ignore */}
          <Modal
            autoFocus={true}
            // centered={true}
            toggle={this.closeDuplicateModal}
            isOpen={this.state.isDuplicateModalOpen}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Duplicate Course</h5>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="count" className="form-label">
                    Number of courses to be duplicated
                  </label>
                  <input
                    readOnly={this.state.isduplicationLoading}
                    value={this.state.count}
                    onChange={(e) =>
                      this.setState({
                        count: e.target.value,
                      })
                    }
                    type="number"
                    className="form-control"
                    id="count"
                  />

                  <p className="text-danger pt-2">
                    {this.state.duplicationValidationMsg}
                  </p>
                </div>
              </div>{" "}
              <div className="modal-footer">
                <button
                  type="button"
                  disabled={this.state.isduplicationLoading}
                  onClick={this.closeDuplicateModal}
                  className="btn btn-default"
                >
                  Cancel
                </button>
                <button
                  disabled={this.state.isduplicationLoading}
                  onClick={this._handleDuplicateCourse}
                  type="submit"
                  className="btn btn-primary"
                >
                  {this.state.isduplicationLoading === true
                    ? "Duplicating..."
                    : "Duplicate"}
                </button>
              </div>
            </div>
          </Modal>
        </>
      </>
    );
  }
}

function Form(props: any) {
  const { register, handleSubmit, errors } = useForm();
  const _handleAddCourse = props._handleAddCourse;
  const toggleModal = props.toggleModal;
  const isCourseCreatorBusy: boolean = props.isCourseCreatorBusy;
  return (
    <form onSubmit={handleSubmit(_handleAddCourse)}>
      <input
        autoFocus={true}
        autoComplete="off"
        disabled={isCourseCreatorBusy}
        name="title"
        ref={register({ required: true })}
        type="text"
        className="form-control"
        placeholder="Course Title (e.g. Secretariat Assistant)"
      />
      {errors.title && (
        <small className="text-danger" id="otp-error">
          Please provide a course title
        </small>
      )}
      <div className="modal-footer">
        <button
          disabled={isCourseCreatorBusy}
          type="button"
          onClick={toggleModal}
          className="btn btn-default"
        >
          Cancel
        </button>
        <button
          disabled={isCourseCreatorBusy}
          type="submit"
          className="btn btn-primary"
        >
          {isCourseCreatorBusy === true ? "Creating..." : "Create Course"}
        </button>
      </div>
    </form>
  );
}

export default CoursePage;
