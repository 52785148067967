import React from "react";
import { useForm } from "react-hook-form";
export default (props: any) => {
  const { register, handleSubmit, errors } = useForm();
  const onSignIn = props.onSignIn;

  return (
    <>
      <div className="row">
        <div className="col-sm-4 col-md-6 col-xl-8 background-image-wrapper d-none d-sm-block"></div>
        <div className="col-12 col-sm-8 col-md-6 col-xl-4 login-content-wrapper px-3 px-sm-5">
          <div className="px-3">
            <h2>Teach. Repeat.</h2>
            <h1 className="pt-3">Login</h1>
            <form onSubmit={handleSubmit(onSignIn)} className="pt-5">
              <div className="form-group">
                <label>Email address</label>
                <input
                  name="email"
                  ref={register({ required: true })}
                  type="email"
                  className="form-control form-control-lg"
                  id="sign-in-email-input"
                  placeholder="Enter email"
                />
                {errors.email && (
                  <p className="text-danger" id="sign-in-email-error">
                    Email is Required
                  </p>
                )}
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  name="password"
                  ref={register({ required: true })}
                  type="password"
                  className="form-control form-control-lg"
                  id="sign-in-password-input"
                  placeholder="Enter password"
                />
                {errors.password && (
                  <p className="text-danger" id="sign-in-password-error">
                    Password is Required
                  </p>
                )}
              </div>
              <div className="text-center">
                <button
                  id="sign-in"
                  type="submit"
                  className="login-button-wrapper btn-block py-3 py-sm-4"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
