import React from "react";
import RankFileModule from "..";
import { RankFilePageGroup } from "lakshya-shared";
import { Modal } from "reactstrap";

export default function (props: any) {
  const index = props.index;
  const groups: RankFilePageGroup[] = props.groups;
  const module: RankFileModule = props.module;
  const context: RankFilePageGroup = props.context;
  const hasAttachment = context.exam !== null;
  const [newIndex, setNewIndex] = React.useState(null);
  const [isMoveToModalOpen, setIsMoveToModalOpen] = React.useState(false);
  const toggleMoveToModal = () => setIsMoveToModalOpen(!isMoveToModalOpen);

  const moveArrayItemToNewIndex = (
    arr: RankFilePageGroup[],
    old_index: number,
    new_index: number
  ) => {
    if (newIndex !== null && newIndex <= arr.length) {
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      module.controller.updateEditorData("groups", arr);
      setNewIndex(null);
      toggleMoveToModal();
      return arr;
    }
  };
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>
          <div className="form-group">
            <input
              value={context.title}
              onChange={(e) =>
                module.controller.updateExamGroup(
                  context.resId,
                  "title",
                  e.target.value
                )
              }
              type="text"
              className="form-control"
              placeholder="e.g. Page 1 - 12"
            />
          </div>
        </td>
        <td>
          <div className="form-group">
            <textarea
              value={context.description}
              onChange={(e) =>
                module.controller.updateExamGroup(
                  context.resId,
                  "description",
                  e.target.value
                )
              }
              className="form-control"
              placeholder="(Optional)"
              rows={3}
            ></textarea>
          </div>
        </td>
        <td>
          <div>
            {context.exam ? (
              <a
                rel="noopener noreferrer"
                href={`/admin/exams/editor/${context.exam && (context.exam as any)._id
                  }`}
                target="_blank"
              >
                {`${context.exam.title} `}
                <i className="fas fa-external-link-alt"></i>
              </a>
            ) : (
              <span className="text-muted">No Attachments</span>
            )}
          </div>
        </td>
        <td className="text-right">
          <div>
            <button
              onClick={() => toggleMoveToModal()}
              style={
                {
                  // height: 27,
                  // fontSize: 12,
                  // position: "absolute",
                  // right: 60,
                  // top: "33%",
                }
              }
              className="btn btn-primary btn-sm mr-2"
            >
              Move to
            </button>
            <button
              onClick={props.onSelectAttachment}
              className="btn btn-success btn-sm"
            >
              {`${hasAttachment === true ? "Change" : "Add"} Exam`}
            </button>{" "}
            <button onClick={props.onDelete} className="btn btn-danger btn-sm">
              <i className="fas fa-trash" />
            </button>
          </div>
        </td>
      </tr>
      {/* @ts-ignore */}
      <Modal toggle={toggleMoveToModal} isOpen={isMoveToModalOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Move Exams</h5>
            <button type="button" className="close" onClick={toggleMoveToModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <label className="mb-1">Current SL No: {index + 1}</label>
            <div>
              <label>Total items: {groups.length}</label>
            </div>
            <input
              value={newIndex ? newIndex : ""}
              onChange={(e) => setNewIndex(e.target.value)}
              placeholder="Move to"
              type="number"
              className="form-control mt-2"
            ></input>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={toggleMoveToModal}
            >
              Cancel
            </button>
            <button
              onClick={() =>
                moveArrayItemToNewIndex(groups, index, newIndex - 1)
              }
              type="button"
              disabled={newIndex === null || newIndex > groups.length}
              className="btn btn-primary"
            >
              Move
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
