import React, { Component } from "react";
import ReactTable, { Filter } from "react-table";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import VideoCatalogueModule from "..";

const TableFilter = (propertyKey: string) => (filter: Filter, row: any, column: any): any => {
  if (row._original[propertyKey]) {
    try {
      return row._original[propertyKey].trim().toLowerCase().indexOf(filter.value.trim().toLowerCase()) > -1;
    } catch (e) {
      console.error(e);
    }
  }
  return false;
}

export default class TableView extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isNewModalOpen: false,
      idToDelete: null,
      editData: null,
      isBusy: false
    };
    this.toggleAddModal = this.toggleAddModal.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
  }

  closeConfirmationModal = () => this.setState({ idToDelete: null, isBusy: false });

  showDeleteConfirmation = (id: string) => this.setState({ idToDelete: id, isBusy: false });

  deleteCoupon = () => {
    this.setState({
      isBusy: true
    }, () => setTimeout(() => {
      this.props.module.services.delete(this.state.idToDelete)
        .then(() => {
          this.closeConfirmationModal();
          this.setState({ isBusy: false });
        })
        .catch((e: any) => {
          this.setState({ isBusy: false });
          this.props.module.showError(
            e.message ? e.message : "Network error",
            "Item cannot be added",
            true
          );
        })
    }, 300))
    
  }

  toggleAddModal() {
    this.setState({
      isNewModalOpen: !this.state.isNewModalOpen,
      editData: null
    });
  }

  openEditModal(editData: any) {
    this.setState({
      isNewModalOpen: true,
      editData
    });
  }

  handleAddClick = (data: any) => {
    const module: VideoCatalogueModule = this.props.module;
    this.setState({
      isBusy: true
    }, () => setTimeout(() => {
      if (this.state.editData === null) {
        module.services
        .create(data)
        .then((response: any) => {
          this.toggleAddModal();
          this.setState({ isBusy: false });
        })
        .catch((e) => {
          this.setState({ isBusy: false });
          this.props.module.showError(
            e.message ? e.message : "Network error",
            "Item cannot be added",
            true
          );
        });
      } else {
        module.services.updateById(this.state.editData._id, data)
        .then((response: any) => {
          this.setState({ isBusy: false });
          this.toggleAddModal();
        })
        .catch((e) => {
          this.setState({ isBusy: false });
          this.props.module.showError(
            e.message ? e.message : "Network error",
            "Item cannot be added",
            true
          );
        });
      }
    }, 300));
  };

  handleFetch() {
    const module: VideoCatalogueModule = this.props.module;
    module.services
      .getAll()
      .then((response: any) => {
        module.controller.setListingData(response);
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching course failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }

  componentDidMount() {
    this.handleFetch();
  }

  render() {
    const data = this.props.data;
    if (!data) {
      return <div>Loading</div>;
    }

    return (
      <>
        <div className="col-12">
          <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
            <h4>Discount Coupon Manager</h4>
            <button
              onClick={this.toggleAddModal}
              className="add-lecture-button-wrapper px-5 py-3"
            >
              + New Coupon
            </button>
          </div>
          <div className="pt-3">
            <ReactTable
              minRows={3}
              defaultPageSize={100}
              data={data}
              columns={[
                {
                  Header: "Coupon Code",
                  accessor: "code",
                  filterable: true,
                  filterMethod: TableFilter('code')
                },
                {
                  Header: "Min. Order",
                  accessor: "minOrderAmount",
                  filterable: true,
                  filterMethod: TableFilter('minOrderAmount'),
                },
                {
                  Header: "Type",
                  accessor: "discountType",
                  filterable: true,
                  filterMethod: TableFilter('discountType'),
                },
                {
                  Header: "Value",
                  accessor: "value",
                  filterable: true,
                  filterMethod: TableFilter('value'),
                },
                {
                  Header: "Impressions",
                  accessor: "impressions",
                  filterable: true,
                  filterMethod: TableFilter('impressions'),
                },
                {
                  Header: "Redemptions",
                  accessor: "redemptions",
                  filterable: true,
                  filterMethod: TableFilter('redemptions'),
                },
                {
                  Header: "Is Active",
                  accessor: "isActive",
                  filterable: true,
                  filterMethod: TableFilter('isActive'),
                  Cell: (props) => {
                    return (
                      props.value === true ? 'true' : 'false'
                    )
                  }
                },
                {
                  Header: "Actions",
                  accessor: "_id",
                  Cell: (props) => {
                    return (
                      <>
                      {/* @ts-ignore */}
                        <Button onClick={() => this.openEditModal(props.original)} size="sm">Edit</Button>
                        {/* @ts-ignore */}
                        <Button className="ml-2" color="danger" onClick={() => this.showDeleteConfirmation(props.value)} size="sm">Delete</Button>
                      </>
                    )
                  }
                },
              ]}
            />
          </div>
        </div>
        {/* @ts-ignore */}
        <Modal
          toggle={this.toggleAddModal}
          isOpen={this.state.isNewModalOpen}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Coupon</h5>
              <button
                type="button"
                onClick={this.toggleAddModal}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <NewItemForm
                isBusy={this.state.isBusy}
                defaultValue={this.state.editData}
                handleAddClick={this.handleAddClick}
                toggleAddModal={this.toggleAddModal}
              />
            </div>
          </div>
        </Modal>
        {/* @ts-ignore */}
        <Modal
          toggle={this.closeConfirmationModal}
          isOpen={this.state.idToDelete !== null}
        >
          {/* @ts-ignore */}
          <ModalHeader toggle={this.closeConfirmationModal}>
            Delete Confirmation
          </ModalHeader>
          {/* @ts-ignore */}
          <ModalBody>
            This action cannot be undone. Do you want to delete this coupon?
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            {/* @ts-ignore */}
            <Button disabled={this.state.isBusy} onClick={this.closeConfirmationModal}>Cancel</Button>
            {/* @ts-ignore */}
            <Button color="danger" disabled={this.state.isBusy} onClick={() => this.deleteCoupon()}>Confirm Delete</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

function NewItemForm(props: any) {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: props.defaultValue ? props.defaultValue : {
      code: '',
      discountType: 'rate',
      value: 0,
      isActive: false,
      minOrderAmount: -1
    }
  });
  const toggleAddModal = props.toggleAddModal;
  const handleAddClick = props.handleAddClick;
  const isEditMode = props.defaultValue ? true : false;
  const isBusy = props.isBusy;

  return (
    <form onSubmit={handleSubmit(handleAddClick)}>
      <div className="form-group">
        <label htmlFor="couponInput">Coupon Code</label>
        <input 
          ref={register({ required: true })}
          name="code"
          type="text" 
          disabled={isEditMode === true}
          className="form-control" id="couponInput" 
          aria-describedby="couponHelp" placeholder="e.g. OFFER-10" />
        <small id="couponHelp" className="form-text text-muted">You can't update coupon after creating it</small>
        {errors.code && (
          <small id="couponHelp" className="form-text text-danger">Please enter coupon code</small>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="typeInput">Type</label>
        <select ref={register({ required: true })} className="form-control" id="typeInput" name="discountType">
          <option value="rate">By Rate (%)</option>
          <option value="amount">By Amount (INR)</option>
        </select>
        {errors.discountType && (
          <small className="form-text text-danger">Discount type is required</small>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="valueInput">Value</label>
        <input 
          ref={register({ required: true })}
          name="value"
          type="number"
          step={0.01} 
          className="form-control" id="valueInput" 
          aria-describedby="couponHelp" placeholder="Value (INR or %)" />
        {errors.value && (
          <small id="couponHelp" className="form-text text-danger">Discount value is required</small>
        )}
      </div>

      <hr />

      <div className="form-group">
        <label htmlFor="minOrderInput">Min. Order</label>
        <input 
          ref={register({ required: false })}
          name="minOrderAmount"
          type="number"
          step={0.01} 
          className="form-control" id="minOrderInput" placeholder="INR / (Default: -1)" />
      </div>

      {
        props.defaultValue ? (
          <div className="form-check mb-3">
            <input ref={register({ required: false })} className="form-check-input" type="checkbox" name="isActive" id="isActiveCheck" />
            <label className="form-check-label" htmlFor="isActiveCheck">
              Is Active
            </label>
          </div>
        ) : null
      }

      <div className="modal-footer">
        <button
          disabled={isBusy}
          type="button"
          onClick={toggleAddModal}
          className="btn btn-default"
        >
          Cancel
        </button>
        <button disabled={isBusy} type="submit" className="btn btn-primary">
          {props.defaultValue ? 'Update' : 'Create Coupon'}
        </button>
      </div>
    </form>
  );
}
