import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import { ComponentMap } from "@skyslit/ark-react/build/types";
import ListingView from "./views/Listing.view";
import EditorView from "./views/Editor.view";
import MeetingLinkViews from "./views/meeting-links";
import { CurrentAffair } from "lakshya-shared";
import moment from "moment";

export type StateType = {
  editorData: CurrentAffair;
  listingData: CurrentAffair[];
  haveContextUpdated: boolean;
};

export default class CurrentAffairModule extends ArkModule<StateType> {
  constructor() {
    super("CurrentAffairModule");

    this.useConnect(connect);

    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          case this.actionTypes.SET_LISTING: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              listingData: value,
            });
          }
          case this.actionTypes.UPDATE_EDITOR_DATA: {
            const { key, value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              editorData: Object.assign({}, state.editorData, {
                [key]: value,
              }),
            });
          }
          case this.actionTypes.MARK_AS_SAVED: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: false,
              editorData: Object.assign({}, state.editorData, {
                publishWarnings: value.publishWarnings,
                isPublished: value.isPublished,
              }),
            });
          }
          case this.actionTypes.SET_EDITOR_DATA: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              editorData: value,
            });
          }
          default: {
            return state;
          }
        }
      };
    };

    this.main = () => {
      this.loadListingData();
    };
  }

  loadListingData = () => {
    if (this.package.store.getState().__CORE_PACKAGE.isAuthenticated === true) {
      this.services
        .getAll()
        .then((data) => {
          this.controller.setListingData(data);
        })
        .catch(() => {
          setTimeout(() => {
            this.loadListingData();
          }, 100);
        });
    } else {
      setTimeout(() => {
        // REMOVED FROM REPEAT
        // this.loadListingData();
      }, 1000);
    }
  };

  controller = {
    setListingData: (value: any) => {
      this.dispatch({
        type: this.actionTypes.SET_LISTING,
        payload: {
          value,
        },
      });
    },
    setEditorData: (value: CurrentAffair) => {
      this.dispatch({
        type: this.actionTypes.SET_EDITOR_DATA,
        payload: {
          value,
        },
      });
    },
    updateEditorData: (key: string, value: any) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_EDITOR_DATA,
        payload: {
          key,
          value,
        },
      });
    },
    saveEditorData: () => {
      const courseToUpdate = this.getState().editorData;
      this.services
        .updateById((courseToUpdate as any)._id, courseToUpdate)
        .then((response: any) => {
          this.dispatch({
            type: this.actionTypes.MARK_AS_SAVED,
            payload: {
              value: response,
            },
          });
        })
        .catch((e) => {
          this.showError(
            "Update subject failed",
            e.message ? e.message : "Network error",
            true
          );
        });
    },
  };

  actionTypes = {
    GET_ALL_BUNDLES: "GET_ALL_BUNDLES",
    SET_LISTING: "SET_LISTING",
    SET_EDITOR_DATA: "SET_EDITOR_DATA",
    UPDATE_EDITOR_DATA: "UPDATE_EDITOR_DATA",
    MARK_AS_SAVED: "MARK_AS_SAVED",
  };

  services = {
    create: (title: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/admin/current-affairs", {
            title: title,
          })
          .then((response) => {
            this.loadListingData();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getAll: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/admin/current-affairs")
          .then((response) => {
            resolve(
              (response.data &&
                response.data.map((d: any) => {
                  d.titleDisplay = moment(d.title).format("ll");
                  d.hasPDFUploaded = d.pdfFileId ? "Yes" : "No";
                  return d;
                })) ||
                null
            );
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getOneById: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/current-affairs/${id}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    updateById: (id: string, value: CurrentAffair) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/current-affairs/${id}`, { value: value })
          .then((response) => {
            this.loadListingData();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    delete: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .delete(`/api/admin/current-affairs/${id}`)
          .then((response) => {
            this.loadListingData();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    createMeetingLink: (url: string,title:string, startDate: number, endDate: number,) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post("/api/admin/meeting-links", {
            url,
            title,
            startDate,
            endDate
          })
          .then((response) => {
            this.loadListingData();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    listAllMeetingLinks: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/admin/meeting-links")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    getAllCourseTitles: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/course-title`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    updateMeetingLinkStatus: (id:String) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put("/api/admin/update/meeting-link", {
            id
          })
          .then((response) => {
            this.loadListingData();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    deleteMeetingLink: (id:String) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .delete(`/api/admin/meeting-link/${id}`, {
          })
          .then((response) => {
            this.loadListingData();
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
  };

  views: ComponentMap = {
    Listing: ListingView,
    Editor: EditorView,
    MeetingLinks: MeetingLinkViews,
  };

  initialState: StateType = {
    editorData: null,
    listingData: [],
    haveContextUpdated: false,
  };
}
