import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import LakshyaStudentsModule from "..";
import ReactTable, { Filter } from "react-table";

const TableFilter = (propertyKey: string) => (filter: Filter, row: any, column: any): any => {
  if (row._original[propertyKey]) {
    try {
      return row._original[propertyKey].trim().toLowerCase().indexOf(filter.value.trim().toLowerCase()) > -1;
    } catch (e) {
      console.error(e);
    }
  }
  return false;
}

class NotificationManagerView extends React.Component<
  ViewComponentPropType<LakshyaStudentsModule>,
  any
  > {

  componentDidMount() {
    this.props.module.controller.refreshData(false);
  }

  render() {
    const { isRefreshing, data } = this.props.context;
    return (
      <>
      {/* @ts-ignore */}
        <Helmet>
          <title>Notifications - Lakshya eLearning System</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
                <h4>Notifications {'>'} Alerts</h4>
                <div>
                  <button
                    disabled={isRefreshing}
                    onClick={() => {
                      this.props.module.controller.markAllAsRead();
                    }}
                    className="btn btn-secondary ml-3"
                  >
                    {
                      isRefreshing === true ? <i className="fas fa-spin fa-sync-alt"></i> : 'Mark all as read'
                    }
                  </button>
                  {' '}
                  <button
                    disabled={isRefreshing}
                    onClick={() => {
                      this.props.module.controller.refreshData(true);
                      this.props.module.refreshCount();
                    }}
                    className="btn btn-secondary ml-3"
                  >
                    {
                      isRefreshing === true ? <i className="fas fa-spin fa-sync-alt"></i> : <i className="fas fa-sync-alt"></i>
                    }
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12">
              <ReactTable
                loading={isRefreshing}
                data={data}
                minRows={3}
                defaultPageSize={100}
                columns={[
                  {
                    Header: "Created On",
                    accessor: "createdAtFormatted",
                    width: 210,
                    filterable: true,
                    filterMethod: TableFilter('createdAtFormatted'),
                    className: 'd-flex align-items-center'
                  },
                  {
                    Header: "Message",
                    accessor: "message",
                    filterable: true,
                    filterMethod: TableFilter('message'),
                    className: 'd-flex align-items-center',
                    Cell: (props) => {
                      return (
                        <span className={props.original.hasRead === 'Yes' ? 'text-muted' : 'text-info'}>{props.value}</span>
                      )
                    }
                  },
                  {
                    Header: "Has Read",
                    accessor: "hasRead",
                    filterable: true,
                    width: 110,
                    filterMethod: TableFilter('hasRead'),
                    className: 'd-flex align-items-center',
                    Cell: (props) => (
                      <span className={props.value === 'Yes' ? 'text-muted' : 'text-danger'}>{props.value}</span>
                    )
                  },
                  {
                    Header: "Actions",
                    headerClassName: 'text-right',
                    accessor: "_id",
                    className: 'd-flex align-items-center justify-content-end',
                    width: 110,
                    Cell: (props) => {
                      let link: string = null;
                      const notification: Notification = props.original;
                      if (notification.data) {
                        const { resourceType, resourceId } = notification.data;
                        if (resourceType) {
                          switch (resourceType) {
                            case 'lecture': {
                              if (resourceId) {
                                link = `/admin/videos/lectures/${resourceId}/discussions`;
                              }
                              break;
                            }
                            case 'winner-announcement': {
                              if (resourceId) {
                                link = `/admin/winners/editor/${resourceId}`;
                              }
                              break;
                            }
                            default: {
                              break;
                            }
                          }
                        }
                      }
                      if (link) {
                        return (
                          <a target="_blank" href={link} rel="noopener noreferrer" className="btn btn-primary">View <i className="fas fa-external-link-alt" /></a>
                        )
                      } else {
                        return null;
                      }
                    }
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NotificationManagerView;
