import React, { useState } from "react";
import Lecture from "./Lecture";
import { Subject, LectureVideo } from "lakshya-shared";
import { Modal } from "reactstrap";
import ReactTable, { Filter } from "react-table";
import CourseEditorModule from "..";
import { app } from "../../..";
import { resolveAssetUrl } from "../../../shared/utils";
import { Link } from "react-router-dom";
import fileSize from "filesize";
import { CSVLink } from "react-csv";


const defaultImage = require("./../assets/images/default-image.png");

export default (props: any) => {
  const context: Subject = props.context;
  const courseId: string = props.courseId
  const [isAddLectureModalOpen, setIsAddLectureModalOpen] = useState(false);
  const toggleAddLectureModal = () =>
    setIsAddLectureModalOpen(!isAddLectureModalOpen);
  const module: CourseEditorModule = props.module;
  const Dropzone = app.modules.FileUploader.views.Dropzone;
  const FileView = app.modules.FileUploader.views.FileView;


  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const mappedLectures = React.useMemo(() => {
    const lec = context.lectures.map((lecture) => {
      return {
        title: lecture.title,
        duration: lecture.videoDurationInMinutes
      }
    })
    return lec
  }, [context.lectures])
  console.log("As", context)

  return (
    <>
      <div className="topic-page-wrapper pt-3">
        <div className="title-wrapper">
          <div className="subject-title-wrapper align-items-center">
            <button onClick={props.goBack} className="go-back-button">
              <i className="fas fa-arrow-left"></i>
            </button>
            <h5 style={{ color: "#a7a7a7" }} className="pl-2 m-0">
              Subjects /
            </h5>
            <input
              className="subject-title-edit-input"
              value={context.title}
              onChange={(e) =>
                module.controller.updateSubject(
                  module.getState().selectedSubjectIndex,
                  "title",
                  e.target.value
                )
              }
            />
          </div>
          <div>
            <button
              onClick={() => {
                app.modules.Composer.showComposer({
                  body: "",
                  imageUrl: (() => {
                    if (context.lectures.length > 0) {
                      return context.lectures[0].bannerImageId
                        ? resolveAssetUrl(
                          context.lectures[0].bannerImageId,
                          "public"
                        )
                        : undefined;
                    }
                    return undefined;
                  })(),
                  resourceID: `${(module.getState().course as any)._id}/${context.title
                    }`,
                  resourceType: "course",
                  title: context.title,
                  clickAction: "open_subject_course",
                  targetPhoneNumber: "",
                });
              }}
              className="btn btn-secondary mr-3"
            >
              <i className="fas fa-paper-plane"></i>
            </button>
            <button
              type="button"
              className="btn btn-success px-4 py-2"
              onClick={toggleAddLectureModal}
            >
              Add Lecture
            </button>
          </div>
        </div>
        <div className="pt-4">
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="descriptionField">Banner Image</label>
                {context.bannerImageId ? (
                  <>
                    <img
                      className="d-block"
                      src={resolveAssetUrl(context.bannerImageId, "public")}
                      alt="Course Banner"
                    />
                    <FileView
                      fileId={context.bannerImageId}
                      fileName="Banner Image"
                      fileSizeInBytes={context.bannerImageFizeSizeInBytes}
                      onDelete={() => {
                        module.controller.updateSubject(
                          module.getState().selectedSubjectIndex,
                          "bannerImageId",
                          null
                        );
                        module.controller.updateSubject(
                          module.getState().selectedSubjectIndex,
                          "bannerImageFizeSizeInBytes",
                          0
                        );
                        module.controller.saveCourse();
                      }}
                    />
                  </>
                ) : (
                  <Dropzone
                    label="Upload Banner Image"
                    accept="image/*"
                    accessMode="public"
                    onSuccess={(files: any[]) => {
                      if (files && Array.isArray(files)) {
                        if (files.length > 0) {
                          module.controller.updateSubject(
                            module.getState().selectedSubjectIndex,
                            "bannerImageId",
                            files[0]._id.toString()
                          );
                          module.controller.updateSubject(
                            module.getState().selectedSubjectIndex,
                            "bannerImageFizeSizeInBytes",
                            files[0].sizeInBytes
                          );
                          module.controller.saveCourse();
                        }
                      }
                    }}
                  />
                )}
                <small
                  id="descriptionFieldHelp"
                  className="form-text text-muted"
                >
                  Banner Image will be shown in the Course home page
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  checked={context.isActive}
                  onChange={(e) =>
                  
                   {
                    console.log("isActive", e.target.checked)
                    module.controller.updateSubject(
                      module.getState().selectedSubjectIndex,
                      "isActive",
                      e.target.checked
                    )
                   }
                  }
                  className="custom-control-input"
                  id="stickySwitch"
                />
                <label className="custom-control-label" htmlFor="stickySwitch">
                  {context.isActive === true
                    ? "This subject is enabled for public visibility"
                    : "Public visibility has turned off for this subject"}
                </label>
              </div>
            </div>
        <div className="lectures-wrapper pt-5">
          <div style={{ paddingBottom: 24, display: "flex", flexDirection: "row" }}>
            <h5 className="pb-3 pr-3">Lectures ({context.lectures.length})</h5>
            {/* @ts-ignore */}
            <CSVLink data={mappedLectures}>Export to CSV</CSVLink>
          </div>
          <div>
            {context.lectures.length < 1 ? (
              <div
                className="mt-4 alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <strong>No Lectures Added!</strong> You can use 'Add Lecture'
                button to link videos and notes.
              </div>
            ) : null}
            {context.lectures.map((lecture, index) => {
              return (
                <Lecture
                  enableDelete={true}
                  lecture={context.lectures}
                  subjectContext={context}
                  courseId={courseId}
                  index={index}
                  module={module}
                  onTagUpdate={(e: any, tags: any) => {
                    const lectureId = (lecture as any)._id;
                    module.controller.updateSubject(
                      module.getState().selectedSubjectIndex,
                      "lectureDaySchedulePlan",
                      Object.assign({}, context.lectureDaySchedulePlan, {
                        [lectureId]: tags.map((t: any) =>
                          !isNaN(t.value) ? parseInt(t.value) : 0
                        ),
                      })
                    );
                  }}
                  onTopicChange={(topic: string) => {
                    const lectureId = (lecture as any)._id;
                    module.controller.updateSubject(
                      module.getState().selectedSubjectIndex,
                      "lectureTopicMetadata",
                      Object.assign({}, context.lectureTopicMetadata, {
                        [lectureId]: topic,
                      })
                    );
                  }}
                  onDelete={() => {
                    const idToDelete = (lecture as any)._id;
                    module.controller.updateSubject(
                      module.getState().selectedSubjectIndex,
                      "lectures",
                      context.lectures.filter((l: any) => l._id !== idToDelete)
                    );
                    module.controller.addRemovedLectureIds(idToDelete);
                  }}
                  key={(lecture as any)._id}
                  context={lecture}
                  _id={(lecture as any)._id}
                  onMove={(item: any, fromId: string, toId: string) => {
                    let toIndex = context.lectures.findIndex(
                      (l) => (l as any)._id === toId
                    );
                    let fromIndex = context.lectures.findIndex(
                      (l) => (l as any)._id === fromId
                    );

                    function immutablySwapItems(
                      items: any[],
                      firstIndex: number,
                      secondIndex: number
                    ) {
                      // Constant reference - we can still modify the array itself
                      const results = items.slice();
                      const firstItem = items[firstIndex];
                      results[firstIndex] = items[secondIndex];
                      results[secondIndex] = firstItem;

                      return results;
                    }

                    module.controller.updateSubject(
                      module.getState().selectedSubjectIndex,
                      "lectures",
                      immutablySwapItems(context.lectures, fromIndex, toIndex)
                    );
                  }}
                  onClick={() =>
                    props.onLectureSelected &&
                    props.onLectureSelected(lecture, index)
                  }
                />
              );
            })}
          </div>
          <hr style={{ width: "100%", height: 1, backgroundColor: "#ccc" }} />
          <div className="py-3">
            <button
              className="btn btn-danger"
              type="button"
              onClick={toggleDeleteModal}
            >
              Remove Subject
            </button>
          </div>
        </div>
      </div>
      {/* @ts-ignore */}
      <Modal toggle={toggleDeleteModal} isOpen={isDeleteModalOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Remove Subject</h5>
            <button type="button" className="close" onClick={toggleDeleteModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {context.lectures.length > 0 ? (
              <h6
                className="alert alert-warning"
                role="alert"
                style={{ fontWeight: "normal" }}
              >
                {" "}
                Please delete all lectures and try again!
              </h6>
            ) : (
              <h6 style={{ fontWeight: "normal" }}>
                Are you sure want to delete this subject?
              </h6>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={toggleDeleteModal}
            >
              Cancel
            </button>
            <button
              disabled={context.lectures.length > 0}
              onClick={() => {
                module.controller.removeSubject(
                  module.getState().selectedSubjectIndex
                );
                toggleDeleteModal();
                module.controller.saveCourse();
              }}
              type="button"
              className="btn btn-danger"
            >
              Yes, Remove
            </button>
          </div>
        </div>
      </Modal>
      {/* @ts-ignore */}

      <Modal
        toggle={toggleAddLectureModal}
        size={"lg"}
        isOpen={isAddLectureModalOpen}
      >
        <div className="modal-content">
          <div className="modal-header sticky-header-wrapper d-flex align-items-center">
            <h5 className="modal-title">Add Lecture Video</h5>
            <button
              type="button"
              className="close"
              onClick={toggleAddLectureModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <ReactTable
                    data={
                      app.modules.VideoCatalogue.getState().lectures
                      // .filter(
                      //   (l: any) =>
                      //     context.lectures.findIndex((ll: any) => ll._id === l._id) ===
                      //     -1
                      // )
                    }
                    minRows={3}
                    defaultPageSize={10}
                    columns={[
                      {
                        Header: "Title",
                        accessor: "title",
                        filterable: true,
                        filterMethod: (
                          filter: Filter,
                          row: any,
                          column: any
                        ): any => {
                          if (row.title) {
                            try {
                              return (
                                row.title
                                  .trim()
                                  .toLowerCase()
                                  .indexOf(filter.value.trim().toLowerCase()) >
                                -1
                              );
                            } catch (e) {
                              console.error(e);
                            }
                          }
                          return false;
                        },
                        Cell: (row) => {
                          const lecture = row.original;
                          return (
                            <div key={row.original._id}>
                              <img
                                className="d-block"
                                width={124}
                                src={
                                  lecture.bannerImageId
                                    ? resolveAssetUrl(
                                      lecture.bannerImageId,
                                      "public"
                                    )
                                    : defaultImage
                                }
                                alt="Video thumbnail"
                              />
                              <Link
                                className="text-primary mt-3 d-block"
                                rel="noopener noreferrer"
                                target="_blank"
                                to={`/admin/videos/lectures/${row.original._id}`}
                              >
                                {row.value}
                              </Link>
                            </div>
                          );
                        },
                      },
                      {
                        Header: "Status",
                        accessor: "isPublished",
                        width: 100,
                        Cell: (row) => {
                          return (
                            <span
                              className={`${row.value === true
                                ? "text-success"
                                : "text-danger font-weight-bold"
                                }`}
                            >
                              {row.value === true ? "Published" : "Unpublished"}
                            </span>
                          );
                        },
                      },
                      {
                        Header: "Duration (File Size)",
                        accessor: "videoDurationInMinutes",
                        Cell: (row) => {
                          const inMins: number = parseFloat(row.value);
                          const lecture: LectureVideo = row.original;
                          const hasVideo = lecture.videoResourceId !== null;
                          if (hasVideo) {
                            return (
                              <>
                                {!isNaN(inMins) ? (
                                  <>
                                    <span className="text-muted">{`${inMins} Mins.`}</span>
                                    <br />
                                  </>
                                ) : null}
                                <span className="text-muted">{`File: ${fileSize(
                                  lecture.videoFileSizeInBytes
                                )}`}</span>
                              </>
                            );
                          } else {
                            return (
                              <span className="text-danger">
                                Video not uploaded
                              </span>
                            );
                          }
                        },
                      },
                      {
                        Header: "Action",
                        accessor: "_id",
                        className: "text-right",
                        Cell: (row) => {
                          const selected = context.lectures.find(
                            (l: any) => l._id === row.original.id
                          );
                          return (
                            <div>
                              {selected ? (
                                <button
                                  onClick={() => {
                                    const idToDelete = row.original._id;
                                    module.controller.updateSubject(
                                      module.getState().selectedSubjectIndex,
                                      "lectures",
                                      context.lectures.filter(
                                        (l: any) => l._id !== idToDelete
                                      )
                                    );
                                    module.controller.addRemovedLectureIds(
                                      idToDelete
                                    );
                                  }}
                                  style={{ width: 100 }}
                                  className="btn btn-danger"
                                >
                                  <i className="fas fa-minus-circle pr-2" />
                                  Unselect
                                </button>
                              ) : (
                                <button
                                  style={{ width: 100 }}
                                  onClick={() => {
                                    // console.log(row.original)
                                    module.controller.updateSubject(
                                      module.getState().selectedSubjectIndex,
                                      "lectures",
                                      [...context.lectures, row.original]
                                    );
                                    module.controller.addLectureIds(
                                      row.original._id
                                    );
                                    // toggleAddLectureModal();
                                  }}
                                  className="btn btn-success"
                                >
                                  <i className="fas fa-plus-circle pr-2" />
                                  Insert
                                </button>
                              )}
                            </div>
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            {/* {app.modules.VideoCatalogue.getState()
              .lectures.filter(
                (l: any) =>
                  context.lectures.findIndex((ll: any) => ll._id === l._id) ===
                  -1
              )
              .map((lecture: any) => (
                <Lecture
                  disabledIntendation={true}
                  key={lecture._id}
                  context={lecture}
                  onClick={() => {
                    module.controller.updateSubject(
                      module.getState().selectedSubjectIndex,
                      "lectures",
                      [...context.lectures, lecture]
                    );
                    toggleAddLectureModal();
                  }}
                />
              ))} */}
          </div>
        </div>
      </Modal>
    </>
  );
};
