import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import { ComponentMap } from "@skyslit/ark-react/build/types";
import CourseEditorView from "./views/CourseEditor.view";
import { Course, Subject, LectureVideo } from "lakshya-shared";

export type StateType = {
  course: Course;
  haveContextUpdated: boolean;
  selectedSubjectIndex: number;
  selectedLectureIndex: number;
  addedLectureIds: any;
  removedLecturesIds: any;
};

export default class CourseEditorModule extends ArkModule<StateType> {
  constructor() {
    super("CourseEditorModule");

    this.useConnect(connect);
    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          case this.actionTypes.MARK_AS_SAVED: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: false,
              course: Object.assign({}, state.course, {
                isPublished: value.isPublished,
                publishWarnings: value.publishWarnings,
              }),
            });
          }
          case this.actionTypes.ADD_COURSE: {
            const { courses } = action.payload;
            return Object.assign({}, state, {
              course: courses,
              selectedSubjectIndex: -1,
              selectedLectureIndex: -1,
              haveContextUpdated: false,
            });
          }
          case this.actionTypes.UPDATE_COURSE: {
            const { key, value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              course: Object.assign({}, state.course, {
                [key]: value,
              }),
            });
          }
          case this.actionTypes.ADD_SUBJECT: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              course: Object.assign({}, state.course, {
                subjects: [...state.course.subjects, value],
              }),
            });
          }
          case this.actionTypes.UPDATE_SUBJECT: {
            const { subjectIndex, key, value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              course: Object.assign({}, state.course, {
                subjects: state.course.subjects.map((subject, index) => {
                  if (index === subjectIndex) {
                    return Object.assign({}, subject, {
                      [key]: value,
                    });
                  }
                  return subject;
                }),
              }),
            });
          }
          case this.actionTypes.SELECT_SUBJECT: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              selectedSubjectIndex: value,
              selectedLectureIndex: -1,
            });
          }
          case this.actionTypes.ADD_SUBSCRIPTION: {
            const { value } = action.payload;
          
            return Object.assign({}, state, {
              haveContextUpdated: true,
              course: Object.assign({}, state.course, {
                attachedSubscriptions: [...state.course.attachedSubscriptions, value],
              }),
            });
          }
          case this.actionTypes.UPDATE_SUBSCRIPTION: {
            const { subscriptionIndex, key, value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              course: Object.assign({}, state.course, {
                attachedSubscriptions: state.course.attachedSubscriptions.map((subscription, index) => {
                  if (index === subscriptionIndex) {
                    return Object.assign({}, subscription, {
                      [key]: value,
                    });
                  }
                  return subscription;
                }),
              }),
            });
          }
          case this.actionTypes.REMOVE_SUBSCRIPTION: {
            const { subscriptionIndex } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              selectedSubjectIndex: -1,
              course: Object.assign({}, state.course, {
                attachedSubscriptions: state.course.attachedSubscriptions.filter(
                  (subscription, index) => index !== subscriptionIndex
                ),
              }),
            });
          }
          case this.actionTypes.REMOVE_SUBJECT: {
            const { subjectIndex } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              selectedSubjectIndex: -1,
              course: Object.assign({}, state.course, {
                subjects: state.course.subjects.filter(
                  (subject, index) => index !== subjectIndex
                ),
              }),
            });
          }
          case this.actionTypes.SELECT_LECTURE: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              selectedLectureIndex: value,
            });
          }
          case this.actionTypes.ADD_LECTURE: {
            const { subjectId, value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              course: Object.assign({}, state.course, {
                subjects: state.course.subjects.map((subject, index) => {
                  if (index === subjectId) {
                    return Object.assign({}, subject, {
                      lectures: [...subject.lectures, value],
                    });
                  }

                  return subject;
                }),
              }),
            });
          }
          case this.actionTypes.UPDATE_LECTURE: {
            const { subjectId, lectureId, key, value } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              course: Object.assign({}, state.course, {
                subjects: state.course.subjects.map((subject, index) => {
                  if (index === subjectId) {
                    return Object.assign({}, subject, {
                      lectures: subject.lectures.map(
                        (lecture, lectureIndex) => {
                          if (lectureIndex === lectureId) {
                            return Object.assign({}, lecture, {
                              [key]: value,
                            });
                          }
                          return lecture;
                        }
                      ),
                    });
                  }

                  return subject;
                }),
              }),
            });
          }
          case this.actionTypes.REMOVE_LECTURE: {
            const { subjectId, lectureId } = action.payload;
            return Object.assign({}, state, {
              haveContextUpdated: true,
              selectedLectureIndex: -1,
              course: Object.assign({}, state.course, {
                subjects: state.course.subjects.map((subject, index) => {
                  if (index === subjectId) {
                    return Object.assign({}, subject, {
                      lectures: subject.lectures.filter(
                        (lecture, lectureIndex) => lectureIndex !== lectureId
                      ),
                    });
                  }
                  return subject;
                }),
              }),
            });
          }
          case this.actionTypes.ADD_LECTUREIDS: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              addedLectureIds: [...state.addedLectureIds, value],
            });
          }
          case this.actionTypes.ADD_REMOVED_LECTUREIDS: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              removedLecturesIds: [...state.removedLecturesIds, value],
            });
          }
          case this.actionTypes.CLEAR_ADDED_AND_REMOVED_LECTUREIDS: {
            return Object.assign({}, state, {
              removedLecturesIds: [],
              addedLectureIds: [],
            });
          }
          case this.actionTypes.REMOVE_ADDED_LECTUREID: {
            const { lectureId } = action.payload;
            return Object.assign({}, state, {
              addedLectureIds: state.addedLectureIds.filter(
                (id: any) => id !== lectureId
              ),
            });
          }
          case this.actionTypes.REMOVE_REMOVED_LECTUREID: {
            const { lectureId } = action.payload;
            return Object.assign({}, state, {
              removedLecturesIds: state.removedLecturesIds.filter(
                (id: any) => id !== lectureId
              ),
            });
          }
          default: {
            return state;
          }
        }
      };
    };

    this.main = () => {};
  }
  controller = {
    saveCourse: () => {
      return new Promise((resolve, reject) => {
        const courseToUpdate = this.getState().course;
        this.services
          .updateCourseById((courseToUpdate as any)._id, courseToUpdate)
          .then((response: any) => {
            this.services
              .attachCourseToLecture(
                (courseToUpdate as any)._id,
                this.getState().addedLectureIds,
                this.getState().removedLecturesIds
              )
              .then(() => {
                this.controller.clearAddedAndRemovedLectureIds();
              });
            this.dispatch({
              type: this.actionTypes.MARK_AS_SAVED,
              payload: {
                value: response,
              },
            });
            resolve(response);
          })
          .catch((e) => {
            this.showError(
              "Update subject failed",
              e.message ? e.message : "Network error",
              true
            );
            reject(e);
          });
      });
    },
    setCourse: (courses: Course) => {
      this.dispatch({
        type: this.actionTypes.ADD_COURSE,
        payload: {
          courses,
        },
      });
    },
    updateCourse: (key: string, value: any) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_COURSE,
        payload: {
          key,
          value,
        },
      });
    },
    addSubject: (subject: Subject) => {
      this.dispatch({
        type: this.actionTypes.ADD_SUBJECT,
        payload: {
          value: subject,
        },
      });
    },
    selectSubject: (index: number) => {
      this.dispatch({
        type: this.actionTypes.SELECT_SUBJECT,
        payload: {
          value: index,
        },
      });
    },
    updateSubject: (subjectIndex: number, key: string, value: any) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_SUBJECT,
        payload: {
          key,
          value,
          subjectIndex,
        },
      });
    },
    addSubscription: (subscription: any) => {
      this.dispatch({
        type: this.actionTypes.ADD_SUBSCRIPTION,
        payload: {
          value: subscription,
        },
      });
    },
    updateSubscription: (subscriptionIndex: number, key: string, value: any) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_SUBSCRIPTION,
        payload: {
          key,
          value,
          subscriptionIndex,
        },
      });
    },
    removeSubscription: (subscriptionIndex: number) => {
      this.dispatch({
        type: this.actionTypes.REMOVE_SUBSCRIPTION,
        payload: {
          subscriptionIndex,
        },
      });
    },
    selectLecture: (index: number) => {
      this.dispatch({
        type: this.actionTypes.SELECT_LECTURE,
        payload: {
          value: index,
        },
      });
    },
    addLecture: (subjectId: number, lecture: LectureVideo) => {
      this.dispatch({
        type: this.actionTypes.ADD_LECTURE,
        payload: {
          subjectId,
          value: lecture,
        },
      });
    },
    updateLecture: (
      subjectId: number,
      lectureId: number,
      key: string,
      value: any
    ) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_LECTURE,
        payload: {
          subjectId,
          lectureId,
          key,
          value,
        },
      });
    },
    removeLecture: (subjectId: number, lectureId: number) => {
      this.dispatch({
        type: this.actionTypes.REMOVE_LECTURE,
        payload: {
          subjectId,
          lectureId,
        },
      });
    },
    removeSubject: (subjectIndex: number) => {
      this.dispatch({
        type: this.actionTypes.REMOVE_SUBJECT,
        payload: {
          subjectIndex,
        },
      });
    },
    addLectureIds: (id: String) => {
      if (this.getState().removedLecturesIds.indexOf(id) > -1) {
        this.dispatch({
          type: this.actionTypes.REMOVE_REMOVED_LECTUREID,
          payload: {
            lectureId: id,
          },
        });
      }
      this.dispatch({
        type: this.actionTypes.ADD_LECTUREIDS,
        payload: {
          value: id,
        },
      });
    },
    addRemovedLectureIds: (id: any) => {
      if (this.getState().addedLectureIds.indexOf(id) > -1) {
        this.dispatch({
          type: this.actionTypes.REMOVE_ADDED_LECTUREID,
          payload: {
            lectureId: id,
          },
        });
      }
      this.dispatch({
        type: this.actionTypes.ADD_REMOVED_LECTUREIDS,
        payload: {
          value: id,
        },
      });
    },
    clearAddedAndRemovedLectureIds: () => {
      this.dispatch({
        type: this.actionTypes.CLEAR_ADDED_AND_REMOVED_LECTUREIDS,
      });
    },

    removeAddedLectureId: (id: String) => {
      this.dispatch({
        type: this.actionTypes.REMOVE_ADDED_LECTUREID,
        payload: {
          lectureId: id,
        },
      });
    },
  };

  actionTypes = {
    MARK_AS_SAVED: "MARK_AS_SAVED",
    ADD_COURSE: "ADD_COURSE",
    UPDATE_COURSE: "UPDATE_COURSE",
    ADD_SUBJECT: "ADD_SUBJECT",
    UPDATE_SUBJECT: "UPDATE_SUBJECT",
    SELECT_SUBJECT: "SELECT_SUBJECT",
    ADD_SUBSCRIPTION: "ADD_SUBSCRIPTION",
    UPDATE_SUBSCRIPTION: "UPDATE_SUBSCRIPTION",
    ADD_LECTURE: "ADD_LECTURE",
    SELECT_LECTURE: "SELECT_LECTURE",
    UPDATE_LECTURE: "UPDATE_LECTURE",
    REMOVE_SUBJECT: "REMOVE_SUBJECT",
    REMOVE_SUBSCRIPTION: "REMOVE_SUBSCRIPTION",
    REMOVE_LECTURE: "REMOVE_LECTURE",
    ADD_LECTUREIDS: "ADD_LECTUREIDS",
    ADD_REMOVED_LECTUREIDS: "ADD_REMOVED_LECTUREIDS",
    CLEAR_ADDED_AND_REMOVED_LECTUREIDS: "CLEAR_ADDED_AND_REMOVED_LECTUREIDS",
    REMOVE_ADDED_LECTUREID: "REMOVE_ADDED_LECTUREID",
    REMOVE_REMOVED_LECTUREID: "REMOVE_REMOVED_LECTUREID",
  };

  services = {
    getCourseById: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get(`/api/admin/courses/${id}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    updateCourseById: (id: string, value: Course) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/courses/${id}`, { value: value })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    deleteCourseById: (id: string) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .delete(`/api/admin/courses/${id}`)
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },

    attachCourseToLecture: (
      id: string,
      addedLectureIds: [],
      removedLectureIds: []
    ) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .post(`/api/admin/lecture/attach-course/${id}`, {
            addedLectureIds,
            removedLectureIds,
          })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
  };
  initialState: StateType = {
    course: null,
    haveContextUpdated: false,
    selectedSubjectIndex: -1,
    selectedLectureIndex: -1,
    addedLectureIds: [],
    removedLecturesIds: [],
  };

  views: ComponentMap = {
    CourseEditor: CourseEditorView,
  };
}
