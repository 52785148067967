
// import io from 'socket.io-client';
import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import { ComponentMap } from '@skyslit/ark-react/build/types';

export type StateType = {
    
};

export default class RTDClient extends ArkModule<StateType> {

  constructor() {
    super("RTDClient");

    this.useConnect(connect);

    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          default: {
            return state;
          }
        }
      };
    };

    this.main = () => {
        // this.io = io(this.package.getConfig().Main.baseURL);
        // this.io.on('say_hello', () => {
        //   console.log('Hello there dameem');
        // })
    };
  }

  eventNames = () => {
    return [
      'connected_once'
    ]
  }

  onConnect = (handler: (socket: SocketIOClient.Socket) => void) => {
    this.io.on('connect', handler);
    if (this.io.connected === true) {
      handler && handler(this.io);
    }
  }

  io: SocketIOClient.Socket;

  controller = {
    
  };

  actionTypes = {
    
  };

  services = {
    
  };

  views: ComponentMap = {
      
  };

  initialState: StateType = {

  };
}
