import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import LakshyaStudentsModule from "..";
import moment from "moment";
import ReactTable, { Filter } from "react-table";
import { ImportModal } from "./../components/ImportModal";

type Mode = "create" | "update";

type StateType = {
  modalMode: Mode;
  isModalOpen: boolean;
  idToUpdate: string;
  name: string;
  phoneNumber: string;
  expiryDate: string;
  isModalBusy: boolean;
  isErrorModalOpen: boolean;
  isDeleteConfirmationModalOpen: boolean;
  errorMessage: string;
  warnings: string[];
  errorModalHeading: string;
  errorModalMessage: string;
  isImportModalOpen: boolean;
  selectAll: boolean;
};

const TableFilter = (propertyKey: string) => (
  filter: Filter,
  row: any,
  column: any
): any => {
  if (row._original[propertyKey]) {
    try {
      return (
        row._original[propertyKey]
          .trim()
          .toLowerCase()
          .indexOf(filter.value.trim().toLowerCase()) > -1
      );
    } catch (e) {
      console.error(e);
    }
  }
  return false;
};

class StudentManagerView extends React.Component<
  ViewComponentPropType<LakshyaStudentsModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      modalMode: "create",
      isModalOpen: false,
      idToUpdate: null,
      name: "",
      phoneNumber: "",
      expiryDate: "",
      isModalBusy: false,
      isErrorModalOpen: false,
      isDeleteConfirmationModalOpen: false,
      errorMessage: null,
      warnings: [],
      errorModalHeading: "",
      errorModalMessage: "",
      isImportModalOpen: false,
      selectAll: false,
    };
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
  }

  componentDidMount() {
    this.props.module.controller.refreshStudents(false);
  }

  closeEditorModal = () =>
    this.setState({
      isModalOpen: false,
      idToUpdate: null,
      name: "",
      phoneNumber: "",
      expiryDate: "",
      isModalBusy: false,
    });
  closeErrorModal = () =>
    this.setState({
      isErrorModalOpen: false,
      errorMessage: null,
      warnings: [],
    });
  closeImportModal = () => this.setState({ isImportModalOpen: false });

  openModal = (data: any, mode: Mode) => {
    let expiryDate = moment(data.expiryDate || null);
    this.setState({
      isModalBusy: false,
      isModalOpen: true,
      idToUpdate: data._id || null,
      name: data.name || "",
      phoneNumber: data.phoneNumber || "",
      expiryDate:
        expiryDate.isValid() === true ? expiryDate.format("YYYY-MM-DD") : "",
      modalMode: mode,
    });
  };

  openErrorModal = (msgs: string = null, warnings: string[] = []) =>
    this.setState({
      isModalBusy: false,
      isErrorModalOpen: true,
      errorMessage: msgs,
      warnings,
      errorModalMessage:
        "Process failed, you may need to resolve below warnings before retrying",
      errorModalHeading: "Warnings!",
    });

  handleSaveBtnClick = () => {
    const { modalMode } = this.state;
    this.setState(
      {
        isModalBusy: true,
      },
      () =>
        setTimeout(() => {
          if (modalMode === "update") {
            this.props.module.services
              .updateStudent(this.state.idToUpdate, {
                name: this.state.name,
                phoneNumber: this.state.phoneNumber,
                expiryDate: this.state.expiryDate,
              })
              .then((response) => {
                console.log(response);
                this.props.module.controller.updateRow(
                  this.state.idToUpdate,
                  response
                );
                this.closeEditorModal();
              })
              .catch((err) => {
                this.closeEditorModal();
                this.openErrorModal(err.message || "Unknown error");
              });
          } else {
            this.props.module.services
              .createStudent({
                phoneNumber: this.state.phoneNumber,
                name: this.state.name,
                expiryDate: this.state.expiryDate,
              })
              .then((response: any) => {
                this.props.module.controller.unshiftRows(response);
                this.closeEditorModal();
              })
              .catch((err) => {
                this.closeEditorModal();
                this.openErrorModal(err.message || "Unknown error");
              });
          }
        }, 400)
    );
  };

  showDeletePrompt = () => {
    this.setState({
      isModalBusy: false,
      isDeleteConfirmationModalOpen: true,
      errorModalHeading: "Delete Confirmation",
      errorModalMessage:
        "You are about to delete the following students. This action is undoable. Do you want to continue?",
      warnings: this.props.context.selectedIds.map((id) => {
        const rowRef = this.props.context.data.find((d) => d._id === id);
        if (rowRef) {
          return `${rowRef.name} - ${rowRef.phoneNumber} Expiry: ${rowRef.expiryDateDisplay}`;
        }
        return id;
      }),
    });
  };

  performDelete = () => {
    this.setState(
      {
        isModalBusy: true,
      },
      () =>
        setTimeout(() => {
          this.props.module.services
            .deleteStudents(this.props.context.selectedIds)
            .then((response) => {
              this.props.module.controller.deleteRows(
                this.props.context.selectedIds
              );
              this.closeDeleteConfirmationModal();
            })
            .catch((err) => {
              this.closeDeleteConfirmationModal();
              setTimeout(() => {
                this.openErrorModal(err.message || "Unknown error");
              }, 100);
            });
        }, 300)
    );
  };

  closeDeleteConfirmationModal = () => {
    this.setState({
      isDeleteConfirmationModalOpen: false,
    });
  };

  toggleSelectAll() {
    this.setState(
      {
        selectAll: !this.state.selectAll,
      },
      () => {
        if (this.state.selectAll === true) {
          this.props.module.controller.selectAll();
        } else {
          this.props.module.controller.deselectAll();
        }
      }
    );
  }

  render() {
    const {
      isModalOpen,
      modalMode,
      name,
      phoneNumber,
      expiryDate,
      isModalBusy,
    } = this.state;
    const { isRefreshing, data } = this.props.context;
    return (
      <>
        {/* @ts-ignore */}
        <Helmet>
          <title>Lakshya Students Manager - Lakshya eLearning System</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
                <h4>Lakshya Students Manager</h4>
                <div>
                  <button
                    disabled={isRefreshing}
                    onClick={() =>
                      this.openModal(
                        {
                          name: "",
                          phoneNumber: "",
                          expiryDate: "",
                        },
                        "create"
                      )
                    }
                    // onClick={this.toggleAddExamModal}
                    className="add-subscription-button-wrapper px-5 py-3"
                  >
                    <i className="fas fa-upload"></i> Add / Upload Student Data
                  </button>
                  <button
                    disabled={isRefreshing}
                    onClick={() => {
                      this.props.module.controller.refreshStudents(true);
                    }}
                    className="btn btn-secondary ml-3"
                  >
                    {isRefreshing === true ? (
                      <i className="fas fa-spin fa-sync-alt"></i>
                    ) : (
                      <i className="fas fa-sync-alt"></i>
                    )}
                  </button>
                  <button
                    disabled={this.props.context.isRefreshing}
                    onClick={() => this.toggleSelectAll()}
                    className="btn btn-secondary ml-3"
                  >
                    {" "}
                    {this.state.selectAll ? "Deselect all" : "Select all"}
                  </button>
                  <button
                    disabled={this.props.context.selectedIds.length < 1}
                    onClick={() => this.showDeletePrompt()}
                    className="btn btn-danger ml-3"
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12">
              <ReactTable
                loading={isRefreshing}
                data={data}
                minRows={3}
                defaultPageSize={100}
                columns={[
                  {
                    Header: "",
                    accessor: "_id",
                    className: "d-flex align-items-center",
                    width: 50,
                    Cell: (row) => {
                      return (
                        <input
                          type="checkbox"
                          checked={this.props.module.controller.isChecked(
                            row.value
                          )}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              this.props.module.controller.checkRow(row.value);
                            } else {
                              this.props.module.controller.uncheckRow(
                                row.value
                              );
                            }
                          }}
                        />
                      );
                    },
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                    filterable: true,
                    filterMethod: TableFilter("name"),
                    className: "d-flex align-items-center",
                    Cell: (row) => {
                      return (
                        <button
                          onClick={() => this.openModal(row.original, "update")}
                          className="btn btn-link"
                        >
                          {row.value}
                        </button>
                      );
                    },
                  },
                  {
                    Header: "Registration",
                    accessor: "user",
                    filterable: true,
                    filterMethod: TableFilter("hasRegistered"),
                    className: "d-flex align-items-center",
                    Cell: (row) => {
                      const hasRegistered = row.original.hasRegistered;
                      if (hasRegistered === "Yes") {
                        return <span className="text-success">Yes</span>;
                      }
                      return <span className="text-muted">Not Registered</span>;
                    },
                  },
                  {
                    Header: "Phone Number",
                    accessor: "phoneNumber",
                    className: "d-flex align-items-center",
                    filterable: true,
                    filterMethod: TableFilter("phoneNumber"),
                  },
                  {
                    Header: "Expiry Date",
                    accessor: "expiryDateDisplay",
                    className: "d-flex align-items-center",
                    filterable: true,
                    filterMethod: TableFilter("expiryDateDisplay"),
                    Cell: (row) => (
                      <span
                        className={
                          row.value === "Not Specified" ? "text-muted" : ""
                        }
                      >
                        {row.value}
                      </span>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {/* @ts-ignore */}
        <Modal
          isOpen={isModalOpen}
          backdrop="static"
          keyboard={false}
          toggle={this.closeEditorModal}
        >
          {/* @ts-ignore */}
          <ModalHeader
            toggle={!isModalBusy ? this.closeEditorModal : undefined}
          >
            {modalMode === "create" ? "New Student" : "Edit Student Details"}
          </ModalHeader>
          {/* @ts-ignore */}
          <ModalBody>
            <div className="form-group">
              <label htmlFor="nameField">Name</label>
              <input
                disabled={isModalBusy}
                type="text"
                className="form-control"
                id="nameField"
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
                placeholder="(Required)"
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneField">Phone Number</label>
              <input
                disabled={isModalBusy}
                type="text"
                className="form-control"
                id="phoneField"
                value={phoneNumber}
                onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                placeholder="10 digit phone number (Required)"
              />
            </div>
            <div className="form-group">
              <label htmlFor="expiryDate">Expiry Date (Optional)</label>
              <input
                disabled={isModalBusy}
                type="date"
                className="form-control"
                id="expiryDate"
                value={expiryDate}
                onChange={(e) => {
                  console.log(e.target.value);
                  this.setState({ expiryDate: e.target.value });
                }}
                placeholder="DD-MM-YYYY (Optional)"
              />
            </div>
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter className="justify-content-between">
            <div>
              {modalMode === "create" ? (
                //@ts-ignore
                <Button
                  disabled={isModalBusy}
                  color="success"
                  onClick={() => {
                    this.closeEditorModal();
                    this.setState({
                      isImportModalOpen: true,
                    });
                  }}
                >
                  <span>
                    <i className="fas fa-upload"></i> Upload .csv File
                  </span>
                </Button>
              ) : null}
            </div>
            <div>
              {/* @ts-ignore */}
              <Button
                disabled={isModalBusy}
                color="primary"
                onClick={this.handleSaveBtnClick}
              >
                {isModalBusy === true
                  ? "Processing..."
                  : modalMode === "create"
                    ? "Add Student"
                    : "Save Changes"}
              </Button>{" "}
              {/* @ts-ignore */}
              <Button
                disabled={isModalBusy}
                color="secondary"
                onClick={this.closeEditorModal}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        {/* Error Modal */}
        {/* @ts-ignore */}
        <Modal
          isOpen={this.state.isErrorModalOpen}
          toggle={this.closeErrorModal}
        >
          {/* @ts-ignore */}
          <ModalBody>
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">{this.state.errorModalHeading}</h4>
              <p>{this.state.errorModalMessage}</p>
              <hr />
              <ol>
                {this.state.warnings.length > 0 ? (
                  this.state.warnings.map((msgs, i) => {
                    return <li key={i}>{msgs}</li>;
                  })
                ) : (
                  <li>{this.state.errorMessage}</li>
                )}
              </ol>
            </div>
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            <div>
              {/* @ts-ignore */}
              <Button color="secondary" onClick={this.closeErrorModal}>
                Close
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        {/* Delete Confirmation Modal */}
        {/* @ts-ignore */}
        <Modal
          isOpen={this.state.isDeleteConfirmationModalOpen}
          backdrop="static"
          keyboard={false}
          toggle={this.closeDeleteConfirmationModal}
        >
          {/* @ts-ignore */}
          <ModalBody>
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">{this.state.errorModalHeading}</h4>
              <p>{this.state.errorModalMessage}</p>
              <hr />
              <ol>
                {this.state.warnings.length > 0 ? (
                  this.state.warnings.map((msgs, i) => {
                    return <li key={i}>{msgs}</li>;
                  })
                ) : (
                  <li>{this.state.errorMessage}</li>
                )}
              </ol>
            </div>
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            <div>
              {/* @ts-ignore */}
              <Button
                disabled={isModalBusy}
                color="secondary"
                onClick={this.closeDeleteConfirmationModal}
              >
                Cancel
              </Button>
              {/* @ts-ignore */}
              <Button
                disabled={isModalBusy}
                color="danger"
                className="ml-3"
                onClick={this.performDelete}
              >
                {isModalBusy
                  ? `Deleting ${this.props.context.selectedIds.length} item(s)...`
                  : `Yes, Delete ${this.props.context.selectedIds.length} item(s)`}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        <ImportModal
          onSuccess={(data: any) => {
            this.closeImportModal();
            this.props.module.controller.unshiftRows(data, false);
          }}
          isOpen={this.state.isImportModalOpen}
          onToggle={this.closeImportModal}
          axios={this.props.module.getServiceProvider("Main")}
        />
      </>
    );
  }
}

export default StudentManagerView;
