import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider, connect } from "react-redux";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { app } from "../package";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

app
  .usei18next(i18next, I18nextProvider, initReactI18next)
  .useRouter(BrowserRouter, Switch, Route, Redirect);

app.initialize(
  "Browser",
  (err, opts) => {
    ReactDOM.render(
      // @ts-ignore
      <HelmetProvider>
        <Provider store={opts.setupStore(true)}>
          <DndProvider backend={HTML5Backend}>
            <opts.Router />
            {/* @ts-ignore */}
            <app.modules.Composer.views.ComposerModal />
          </DndProvider>
        </Provider>
      </HelmetProvider>,
      document.getElementById("root")
    );
  },
  connect
);

serviceWorker.unregister();
