import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import moment from "moment";
import StudentManagerView from './views/StudentManager.view'

export type StateType = {
  data: any[],
  isRefreshing: boolean
  hasInitialized: boolean
  selectedIds: string[]
};

export default class LakshyaStudentsModule extends ArkModule<StateType> {
  constructor() {
    super("LakshyaStudentsModule");

    this.useConnect(connect);

    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          case this.actionTypes.SET_IS_REFRESHING: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              isRefreshing: value
            })
          }
          case this.actionTypes.UPDATE_SELECTION: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              selectedIds: value
            })
          }
          case this.actionTypes.SET_DATA:
          case this.actionTypes.UNSHIFT_ITEMS: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              data: action.type === this.actionTypes.SET_DATA ? value : [...value, ...state.data],
              isRefreshing: false,
              hasInitialized: true,
            })
          }
          case this.actionTypes.UPDATE_ITEM: {
            const { id, value } = action.payload;
            return Object.assign({}, state, {
              data: state.data.map((row) => {
                if (id === row._id.toString()) {
                  return value;
                }
                return row;
              })
            })
          }
          case this.actionTypes.REMOVE_ITEM: {
            const { ids } = action.payload;
            return Object.assign({}, state, {
              data: state.data.filter((row) => ids.indexOf(row._id.toString()) < 0),
              selectedIds: []
            })
          }
          default: {
            return state;
          }
        }
      };
    };
  }

  __processDataToRow = (row: any): any => {
    const createdMoment = moment(row.createdAt)
    row.isLakshyaStudentDisplay = row.isLakshyaStudent === true ? 'Yes' : 'No'
    row.joinedOn = createdMoment.format('lll');
    return row;
  }

  controller = {
    refreshData: (force: boolean = false) => {
      const state = this.getState();
      if (state.hasInitialized === false || force === true) {
        this.dispatch({
          type: this.actionTypes.SET_IS_REFRESHING,
          payload: {
            value: true
          }
        })
        setTimeout(() => {
          this.services.fetchAllUsers()
            .then((values: any) => {
              this.controller.unshiftRows(values, true);
            })
            .catch((err) => {
              this.dispatch({
                type: this.actionTypes.SET_IS_REFRESHING,
                payload: {
                  value: false
                }
              })
              this.showError(err.message || 'Unknown error', 'Refresh Failed', true);
            })
        }, 700);
      }
    },
    checkRow: (id: string) => {
      const state = this.getState();
      this.dispatch({
        type: this.actionTypes.UPDATE_SELECTION,
        payload: {
          value: [...state.selectedIds, id]
        }
      })
    },
    uncheckRow: (id: string) => {
      const state = this.getState();
      this.dispatch({
        type: this.actionTypes.UPDATE_SELECTION,
        payload: {
          value: state.selectedIds.filter((s) => s !== id)
        }
      })
    },
    isChecked: (id: string) => {
      const state = this.getState();
      return state.selectedIds.findIndex(i => i === id) > -1
    },
    unshiftRows: (rows: any[], force: boolean = false) => {
      this.dispatch({
        type: force === false ? this.actionTypes.UNSHIFT_ITEMS : this.actionTypes.SET_DATA,
        payload: {
          value: rows.map(this.__processDataToRow)
        }
      })
    },
    updateRow: (id: string, row: any) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_ITEM,
        payload: {
          id,
          value: this.__processDataToRow(row)
        }
      })
    },
    deleteRows: (ids: string[]) => {
      this.dispatch({
        type: this.actionTypes.REMOVE_ITEM,
        payload: {
          ids
        }
      })
    }
  }

  services = {
    fetchAllUsers: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/admin/users")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    updateStudent: (id: string, payload: any) => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put(`/api/admin/lakshya-students/${id}`, { value: payload })
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
  }

  actionTypes = {
    SET_DATA: 'SET_DATA',
    SET_IS_REFRESHING: 'SET_IS_REFRESHING',
    UPDATE_SELECTION: 'UPDATE_SELECTION',
    UNSHIFT_ITEMS: 'UNSHIFT_ITEMS',
    UPDATE_ITEM: 'UPDATE_ITEM',
    REMOVE_ITEM: 'REMOVE_ITEM',
  };

  views = {
    StudentManager: StudentManagerView
  }

  initialState: StateType = {
    data: [],
    isRefreshing: false,
    hasInitialized: false,
    selectedIds: []
  };
}
