import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default (props: any) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const newPassword = useRef({});
  newPassword.current = watch("newPassword", "");
  const { t } = useTranslation();
  const onResetPassword = props.onResetPassword;
  const setStep = props.setStep;

  return (
    <>
      <div style={{ flex: 1 }} className="pt-5 px-2 px-sm-4">
        <div className="text-center">
          <h6 className="text-secondary">{t("Step 4 - Account Recovery")}</h6>
          <h5>{t("Create new password")}</h5>
          <hr />
        </div>
        <form onSubmit={handleSubmit(onResetPassword)}>
          <div className="form-group">
            <label>{t("New Password")}</label>
            <input
              name="newPassword"
              ref={register({ required: true, minLength: 5 })}
              type="password"
              className="form-control"
              id="reset-password-input"
              placeholder="Enter your new password"
            />
            {errors.newPassword && errors.newPassword.type === "required" && (
              <p className="text-danger" id="new-password-error">
                {t("Password is Required")}
              </p>
            )}
            {errors.newPassword && errors.newPassword.type === "minLength" && (
              <p className="text-danger" id="new-password-length-error">
                {t("Password must be at least 5 characters")}
              </p>
            )}
            {/* <div className="pt-2">
              <span className="text-muted" style={{ fontSize: 14 }}>
                {t(
                  "The password must contain atleast 8 character, one uppercase letter, one lowercase letter and a number"
                )}
              </span>
            </div> */}
          </div>
          <div className="form-group">
            <label>{t("Confirm Password")}</label>
            <input
              name="confirmPassword"
              ref={register({
                required: true,
                validate: (value) => value === newPassword.current,
              })}
              type="Password"
              className="form-control"
              id="reset-confirm-password-input"
              placeholder="Confirm password"
            />
            {/* {errors.confirmPassword && (
              <p className="text-danger" id="confrim-password-error">
                {t("Confirm Password is Required")}
              </p>
            )} */}
            {errors.confirmPassword && (
              <p className="text-danger" id="password-match-error">
                {t("The passwords do not match")}
              </p>
            )}
          </div>
          <div className="text-right pt-3">
            <button
              type="button"
              onClick={() => setStep("otpVerification")}
              className="btn btn-secondary px-4 mr-3"
            >
              {t("Cancel")}
            </button>
            <button
              id="reset-password"
              type="submit"
              className="btn btn-info px-4 px-xl-5"
            >
              {t("Submit")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
