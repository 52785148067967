import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import moment from "moment";
import NotificationManager from './views/NotificationManager'

export type StateType = {
  unreadCount: number
  data: any[],
  isRefreshing: boolean
  hasInitialized: boolean
  selectedIds: string[]
};

export default class NotificationModule extends ArkModule<StateType> {
  constructor() {
    super("NotificationModule");

    this.useConnect(connect);

    this.getReducer = () => {
      return (state: StateType = this.initialState, action: any) => {
        switch (action.type) {
          case this.actionTypes.SET_IS_REFRESHING: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              isRefreshing: value
            })
          }
          case this.actionTypes.UPDATE_SELECTION: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              selectedIds: value
            })
          }
          case this.actionTypes.SET_DATA:
          case this.actionTypes.UNSHIFT_ITEMS: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              data: action.type === this.actionTypes.SET_DATA ? value : [...value, ...state.data],
              isRefreshing: false,
              hasInitialized: true,
            })
          }
          case this.actionTypes.UPDATE_ITEM: {
            const { id, value } = action.payload;
            return Object.assign({}, state, {
              data: state.data.map((row) => {
                if (id === row._id.toString()) {
                  return value;
                }
                return row;
              })
            })
          }
          case this.actionTypes.REMOVE_ITEM: {
            const { ids } = action.payload;
            return Object.assign({}, state, {
              data: state.data.filter((row) => ids.indexOf(row._id.toString()) < 0),
              selectedIds: []
            })
          }
          case this.actionTypes.MARK_EVERYTHING_AS_READ: {
            return Object.assign({}, state, {
              unreadCount: 0,
              data: state.data.map((row) => {
                row.hasRead = 'Yes';
                return row;
              })
            })
          }
          case this.actionTypes.SET_READ_COUNT: {
            const { value } = action.payload;
            return Object.assign({}, state, {
              unreadCount: value
            })
          }
          default: {
            return state;
          }
        }
      };
    };
  }

  __processDataToRow = (row: any): any => {
    const createdMoment = moment(row.createdAt)
    row.hasRead = row.hasRead === true ? 'Yes' : 'No'
    row.createdAtFormatted = createdMoment.format('lll');
    return row;
  }

  controller = {
    refreshData: (force: boolean = false) => {
      const state = this.getState();
      if (state.hasInitialized === false || force === true) {
        this.dispatch({
          type: this.actionTypes.SET_IS_REFRESHING,
          payload: {
            value: true
          }
        })
        setTimeout(() => {
          this.services.fetchData()
            .then((values: any) => {
              this.controller.unshiftRows(values, true);
            })
            .catch((err) => {
              this.dispatch({
                type: this.actionTypes.SET_IS_REFRESHING,
                payload: {
                  value: false
                }
              })
              this.showError(err.message || 'Unknown error', 'Refresh Failed', true);
            })
        }, 700);
      }
    },
    markAllAsRead: () => {
      this.dispatch({
        type: this.actionTypes.SET_IS_REFRESHING,
        payload: {
          value: true
        }
      })
      setTimeout(() => {
        this.services.markAsRead()
          .then((values: any) => {
            this.dispatch({
              type: this.actionTypes.SET_IS_REFRESHING,
              payload: {
                value: false
              }
            })
            this.dispatch({
              type: this.actionTypes.MARK_EVERYTHING_AS_READ
            })
          })
          .catch((err) => {
            this.dispatch({
              type: this.actionTypes.SET_IS_REFRESHING,
              payload: {
                value: false
              }
            })
            this.showError(err.message || 'Unknown error', 'Refresh Failed', true);
          })
      }, 700);
    },
    checkRow: (id: string) => {
      const state = this.getState();
      this.dispatch({
        type: this.actionTypes.UPDATE_SELECTION,
        payload: {
          value: [...state.selectedIds, id]
        }
      })
    },
    uncheckRow: (id: string) => {
      const state = this.getState();
      this.dispatch({
        type: this.actionTypes.UPDATE_SELECTION,
        payload: {
          value: state.selectedIds.filter((s) => s !== id)
        }
      })
    },
    isChecked: (id: string) => {
      const state = this.getState();
      return state.selectedIds.findIndex(i => i === id) > -1
    },
    unshiftRows: (rows: any[], force: boolean = false) => {
      this.dispatch({
        type: force === false ? this.actionTypes.UNSHIFT_ITEMS : this.actionTypes.SET_DATA,
        payload: {
          value: rows.map(this.__processDataToRow)
        }
      })
    },
    updateRow: (id: string, row: any) => {
      this.dispatch({
        type: this.actionTypes.UPDATE_ITEM,
        payload: {
          id,
          value: this.__processDataToRow(row)
        }
      })
    },
    deleteRows: (ids: string[]) => {
      this.dispatch({
        type: this.actionTypes.REMOVE_ITEM,
        payload: {
          ids
        }
      })
    }
  }

  services = {
    fetchData: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/admin/notifications")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    markAsRead: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .put("/api/admin/notifications/read")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    },
    getUnreadCount: () => {
      return new Promise((resolve, reject) => {
        this.getServiceProvider("Main")
          .get("/api/admin/notifications/count")
          .then((response) => {
            resolve((response.data && response.data) || null);
          })
          .catch((err) => {
            reject((err.response && err.response && err.response.data) || err);
          });
      });
    }
  }

  actionTypes = {
    SET_DATA: 'SET_DATA',
    MARK_EVERYTHING_AS_READ: 'MARK_EVERYTHING_AS_READ',
    SET_READ_COUNT: 'SET_READ_COUNT',
    SET_IS_REFRESHING: 'SET_IS_REFRESHING',
    UPDATE_SELECTION: 'UPDATE_SELECTION',
    UNSHIFT_ITEMS: 'UNSHIFT_ITEMS',
    UPDATE_ITEM: 'UPDATE_ITEM',
    REMOVE_ITEM: 'REMOVE_ITEM',
  };

  main() {
    this.refreshCount();
  }

  refreshCount = () => {
    this.services.getUnreadCount()
    .then((val: any) => {
      if (val && val.count) {
        this.dispatch({
          type: this.actionTypes.SET_READ_COUNT,
          payload: {
            value: val.count
          }
        })
      }
    })
    .catch((err: any) => {
      setTimeout(() => {
        // REMOVED FROM REPEAT
        // this.refreshCount();
      }, 1000);
    })
  }

  views = {
    NotificationManager,
    NotificationCount: (props: any): JSX.Element => {
      return props.children && props.children(props.context.unreadCount);
    }
  }

  initialState: StateType = {
    unreadCount: 0,
    data: [],
    isRefreshing: false,
    hasInitialized: false,
    selectedIds: []
  };
}
