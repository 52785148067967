import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { RankFile, Exam } from "lakshya-shared";
import CourseEditorModule from "..";
import { app } from "../../..";
import { resolveAssetUrl } from "../../../shared/utils";
import ExamGroup from "./ExamGroup";

export default (props: any) => {
  const [groupResIdToSetExam, setGroupResIdToSetExam] = React.useState(null);
  const [groupResIdToDelete, setGroupResIdToDelete] = React.useState(null);
  const context: RankFile = props.context;
  const module: CourseEditorModule = props.module;
  const Dropzone = app.modules.FileUploader.views.Dropzone;
  const FileView = app.modules.FileUploader.views.FileView;
  const ExamPicker = app.modules.Exam.views.ExamPicker;

  return (
    <>
      <div className="lecture-details-page-wrapper pl-2">
        <div className="col-12 col-md-6 col-lg-6 current-lecture-wrapper py-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Visibility & Subscription</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Attach or Modify Master Subscription
              </h6>
              <div className="form-group mt-4">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    checked={context.isVisible}
                    onChange={(e) =>
                      module.controller.updateEditorData(
                        "isVisible",
                        e.target.checked
                      )
                    }
                    className="custom-control-input"
                    id="visibilityCheck"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="visibilityCheck"
                  >
                    {context.isVisible === true
                      ? "This item is enabled for public visibility"
                      : "Public visibility has turned off for this item"}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="rateCardInput">Rate Card</label>
                <select
                  value={
                    (typeof context.masterSubscription === "object"
                      ? context.masterSubscription &&
                      (context.masterSubscription as any)._id
                      : (context.masterSubscription as any)) || "not-selected"
                  }
                  onChange={(e) => {
                    module.controller.updateEditorData(
                      "masterSubscription",
                      e.target.value
                    );
                  }}
                  className="form-control"
                  id="rateCardInput"
                >
                  <option value="not-selected">
                    Please choose a rate card
                  </option>
                  {app.modules.Subscription.getState().master.map((s, i) => (
                    <option
                      key={i}
                      value={(s as any)._id}
                    >{`${s.title} (INR ${s.netAmount})`}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="exam-description">
                  Description
                </label>
                <textarea
                  placeholder="Give a brief description on this exam"
                  className="form-control"
                  id="exam-description"
                  value={context.description}
                  onChange={(e) =>
                    module.controller.updateEditorData(
                      "description",
                      e.target.value
                    )
                  }
                  rows={3}
                ></textarea>
              </div>
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="sort-index">
                  Sort Index
                </label>
                <input
                  placeholder="Default: 0"
                  className="form-control"
                  id="sort-index"
                  value={(context as any).sortIndex}
                  onChange={(e) =>
                    module.controller.updateEditorData(
                      "sortIndex",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Cover Art</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Upload Rank File Cover Image
              </h6>

              <div className="form-group mt-4">
                <label htmlFor="descriptionField">Banner Image</label>
                {context.bannerImageId ? (
                  <>
                    <img
                      className="d-block"
                      src={resolveAssetUrl(context.bannerImageId, "public")}
                      style={{
                        height: 300,
                        maxWidth: "100%",
                        margin: "0 auto",
                      }}
                      alt="Course Banner"
                    />
                    <FileView
                      fileId={context.bannerImageId}
                      fileName="Thumbnail Image"
                      fileSizeInBytes={context.bannerImageFizeSizeInBytes}
                      onDelete={() => {
                        module.controller.updateEditorData(
                          "bannerImageId",
                          null
                        );
                        module.controller.updateEditorData(
                          "bannerImageFizeSizeInBytes",
                          0
                        );
                        module.controller.saveEditorData();
                      }}
                    />
                  </>
                ) : (
                  <Dropzone
                    label="Upload Banner Image"
                    accept="image/*"
                    accessMode="public"
                    onSuccess={(files: any[]) => {
                      if (files && Array.isArray(files)) {
                        if (files.length > 0) {
                          module.controller.updateEditorData(
                            "bannerImageId",
                            files[0]._id.toString()
                          );
                          module.controller.updateEditorData(
                            "bannerImageFizeSizeInBytes",
                            files[0].sizeInBytes
                          );
                          module.controller.saveEditorData();
                        }
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Exams {"&"} Groups</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Manage page wise grouping of exams
              </h6>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Attachment</th>
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {context.groups.map((group, index) => {
                    return (
                      <ExamGroup
                        key={index}
                        index={index}
                        module={module}
                        groups={context.groups}
                        context={group}
                        onSelectAttachment={() => {
                          setGroupResIdToSetExam(group.resId);
                        }}
                        onDelete={() => {
                          setGroupResIdToDelete(group.resId);
                        }}
                      />
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={5} className="text-center">
                      <button
                        onClick={() => {
                          module.controller.updateEditorData("groups", [
                            ...context.groups,
                            {
                              title: "",
                              description: "",
                              exam: null,
                              resId: new Date().valueOf(),
                            },
                          ]);
                        }}
                        className="btn btn-primary btn-small"
                      >
                        <i className="fas fa-plus-circle" /> New Group
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* @ts-ignore */}
      <ExamPicker
        isOpen={groupResIdToSetExam !== null}
        isMultiSelect={false}
        toggle={() => setGroupResIdToSetExam(null)}
        onSelect={(exams: Exam[]) => {
          if (Array.isArray(exams) && exams.length > 0) {
            module.controller.updateExamGroup(
              groupResIdToSetExam,
              "exam",
              exams[0]
            );
          }
          setGroupResIdToSetExam(null);
          module.controller.saveEditorData();
        }}
      />
      {/* @ts-ignore */}
      <Modal
        isOpen={groupResIdToDelete !== null}
        toggle={() => setGroupResIdToDelete(null)}
      >
        {/* @ts-ignore */}
        <ModalHeader toggle={() => setGroupResIdToDelete(null)}>
          Delete Confirmation
        </ModalHeader>
        {/* @ts-ignore */}
        <ModalBody>
          <div className="alert alert-danger">
            <p className="m-0">
              Are you sure you want to remove this group and its references?
            </p>
          </div>
        </ModalBody>
        {/* @ts-ignore */}
        <ModalFooter>
          {/* @ts-ignore */}
          <Button color="secondary" onClick={() => setGroupResIdToDelete(null)}>
            Cancel
          </Button>{" "}
          {/* @ts-ignore */}
          <Button
            color="danger"
            onClick={() => {
              module.controller.updateEditorData(
                "groups",
                context.groups.filter((e) => e.resId !== groupResIdToDelete)
              );
              setGroupResIdToDelete(null);
            }}
          >
            Yes, Remove
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
