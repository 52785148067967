import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DiscussionModule from "..";
import { Link } from "react-router-dom";
type StateType = {};
class DiscussionPage extends React.Component<
  ViewComponentPropType<DiscussionModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <>
        {/* @ts-ignore */}
          <Helmet>
            <title>Discussions</title>
          </Helmet>
          <div className="container-fluid dicsuiion-wrapper">
            <div className="row">
              <div className="col-12 discussion-layout-wrapper px-0">
                <div className="discussion-sidebar pt-5">
                  <div className="px-4">
                    <div className="d-flex flex-direction-row">
                      <h5>Discussions</h5>
                    </div>
                    <label>
                      Select among the options to view specific discussions
                      added or received for each of them.
                    </label>
                  </div>
                  <div className="pt-2">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <Link
                          to="/admin/discussions/subject"
                          className="nav-link selected py-3 pl-5"
                        >
                          Subjects
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a href="/" className="nav-link py-3 pl-5">
                          Exams
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/" className="nav-link py-3 pl-5">
                          Quiz
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="discussion-emty-page-content">
                  <div className="text-center empty-subject-wrapper">
                    <i className="fas fa-comments"></i>
                    <h5 className="pt-3">Select to show discussions</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default DiscussionPage;
