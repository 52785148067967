import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import { ComponentMap } from "@skyslit/ark-react/build/types";
import Layout from "./components/AdminLayout";

export type StateType = {
  StartMessage: {
    msg1: string;
  };
};

export default class LayoutModule extends ArkModule<StateType, "AuthServer"> {
  constructor() {
    super("LayoutModule");

    this.useConnect(connect);

    this.main = () => {};
  }

  views: ComponentMap = {
    AdminLayout: Layout,
  };
}
