import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "..";
import ReactTable from "react-table";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { app } from "../../..";
import { Course } from "lakshya-shared";


type StateType = {
  isModalOpen: boolean;
  isMeetingLinkBusy: boolean;
  data: [];
  url: string;
  title: string;
  startDate: number;
  endDate: number;
  courseId: string;
  courses: [];
  isCourseSelectModalOpen: boolean;
  idToDelete: string;
  isDeleteModalOpen: boolean

};

class MeetingLinksListingView extends React.Component<
  ViewComponentPropType<DefaultModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isModalOpen: false,
      isMeetingLinkBusy: false,
      data: [],
      url: "",
      startDate: moment.now().valueOf(),
      endDate: moment.now().valueOf(),
      title: "",
      courseId: "",
      courses: [],
      isCourseSelectModalOpen: false,
      idToDelete: "",
      isDeleteModalOpen: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.setMeetinLinkIsBusy = this.setMeetinLinkIsBusy.bind(this);
    this.toggleCourseSelectModal = this.toggleCourseSelectModal.bind(this);
    this.toggleDeleteModalOpen = this.toggleDeleteModalOpen.bind(this);
    this.openDeleteModalOpen = this.openDeleteModalOpen.bind(this);



  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  toggleDeleteModalOpen() {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
    });
  }

  openDeleteModalOpen(id: string) {
    this.setState({
      isDeleteModalOpen: true,
      idToDelete: id
    });
  }


  toggleCourseSelectModal() {
    this.setState({
      isCourseSelectModalOpen: !this.state.isCourseSelectModalOpen
    });
  }


  setMeetinLinkIsBusy(isBusy: boolean = true) {
    this.setState({
      isMeetingLinkBusy: isBusy,
    });
  }

  _handleCreateMeetingLink = () => {
    this.setMeetinLinkIsBusy(true);
    const { url, title, startDate, endDate } = this.state
    this.props.module.services
      .createMeetingLink(url, title, startDate, endDate)
      .then(() => {
        this.setMeetinLinkIsBusy(false);
        this.toggleModal();
        this._handleListAllMeetingLinks();
      })
      .catch((e) => {
        this.setMeetinLinkIsBusy(false);
        this.props.module.showError(
          "Meeting link cannot be added",
          e.message ? e.message : "Network error",
          true
        );
      });
  };

  convertDateToTableFriendlyFormat = (date: any) => {
    return {
      ...date,
      startDate: moment(date.startDate).format("llll"),
      endDate: moment(date.endDate).format("llll"),

    };
  };

  _handleListAllMeetingLinks() {
    this.props.module.services
      .listAllMeetingLinks()
      .then((response: any) => {
        this.setState({
          url: "",
          title: "",
          startDate: moment.now().valueOf(),
          endDate: moment.now().valueOf(),

          data: response.map((date: any) =>
            this.convertDateToTableFriendlyFormat(date)
          ),
        });
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching Meetings failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }

  _handleUpdateMeetingStatus(id: string) {
    this.props.module.services
      .updateMeetingLinkStatus(id)
      .then((response: any) => {
        this._handleListAllMeetingLinks()
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching Meetings failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }

  _handleDeleteMeetingStatus(id: string) {
    this.props.module.services
      .deleteMeetingLink(id)
      .then((response: any) => {
        this._handleListAllMeetingLinks()
        this.toggleDeleteModalOpen()
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching Meetings failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }


  _handleFecthAllCourseTitles() {
    this.props.module.services
      .getAllCourseTitles()
      .then((response: any) => {
        this.setState({
          courses: response
        });
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching Meetings failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }

  componentDidMount() {
    this._handleListAllMeetingLinks();
    this._handleFecthAllCourseTitles()
  }



  render() {
    return (
      <>
        {/* @ts-ignore */}

        <Helmet>
          <title>Meeting Link</title>
        </Helmet>

        <div className="container-fluid meeting-link-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
                <h4>Meeting Links</h4>
                <button
                  onClick={this.toggleModal}
                  className="add-subscription-button-wrapper px-5 py-3"
                >
                  Add url
                </button>
              </div>
              <ReactTable
                minRows={3}
                data={this.state.data}
                defaultPageSize={10}
                columns={[
                  {
                    Header: "Name",
                    accessor: "title",
                  },
                  {
                    Header: "URL",
                    accessor: "url",
                    Cell: (row) => {
                      const url: string = row.value;
                      return (
                        <>
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-link"
                          >
                            {url}
                          </a>

                        </>
                      )
                    }
                  },
                  {
                    Header: "Is Active",
                    accessor: "isActive",
                    Cell: (row) => {
                      const isActive: Boolean = row.value;
                      const id: string = (row.original as any)._id;
                      return (
                        <>
                          {isActive ? (
                            <>
                              <span className="text-muted">Active</span>
                              <button
                                onClick={() => this._handleUpdateMeetingStatus(id)}
                                className="delete-button-wrapper mx-3"
                              >
                                Make Inactive
                              </button></>
                          ) : (
                            <>
                              <span className="text-muted">Inactive</span>
                              <button
                                onClick={() => this._handleUpdateMeetingStatus(id)}
                                className="btn btn-link mx-3"
                              >
                                Make Active
                              </button>
                            </>
                          )}

                        </>
                      );
                    },
                  },
                  {
                    Header: "Start date",
                    accessor: "startDate",
                  },
                  {
                    Header: "End date",
                    accessor: "endDate",
                  },
                  {
                    Header: "Actions",
                    headerClassName: 'text-right',
                    accessor: "_id",
                    className: 'd-flex align-items-center justify-content-end',
                    Cell: (row) => {
                      const id: string = (row.original as any)._id;
                      return (
                        <>
                          <button
                            onClick={() => this.toggleCourseSelectModal()}
                            className="delete-button-wrapper mr-3"
                          >
                            <i className="fas fa-paper-plane"></i>
                          </button>

                          <button
                            onClick={() => this.openDeleteModalOpen(id)}
                            className="delete-button-wrapper mr-3"
                          >
                            <i className="fas fa-trash"></i>
                          </button>

                        </>
                      )
                    }
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {/* @ts-ignore */}

        <Modal
          autoFocus={true}
          centered={true}
          toggle={this.toggleModal}
          isOpen={this.state.isModalOpen}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Url</h5>
              <button
                type="button"
                onClick={this.toggleModal}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <label>Name</label>
                <input
                  disabled={this.state.isMeetingLinkBusy}
                  autoFocus={true}
                  autoComplete="off"
                  value={this.state.title}
                  onChange={(e) =>
                    this.setState({
                      title: e.target.value,
                    })
                  }
                  type="text"
                  className="form-control"
                  placeholder="Enter Meeting link"
                />
              </div>
              <div style={{ paddingTop: 10 }}>
                <label>Url</label>
                <input
                  disabled={this.state.isMeetingLinkBusy}
                  autoFocus={true}
                  autoComplete="off"
                  value={this.state.url}
                  onChange={(e) =>
                    this.setState({
                      url: e.target.value,
                    })
                  }
                  type="text"
                  className="form-control"
                  placeholder="Enter Meeting link"
                />
              </div>
              <div style={{ paddingTop: 10 }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    disabled={this.state.isMeetingLinkBusy}
                    margin="normal"
                    id="date-picker-dialog"
                    label="Start Date"
                    format="llll"
                    name="startDate"
                    style={{ width: "100%" }}
                    value={moment(this.state.startDate)}
                    onChange={(dt) => {
                      this.setState({
                        startDate: moment(dt).valueOf(),
                      });
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    disabled={this.state.isMeetingLinkBusy}
                    margin="normal"
                    id="date-picker-dialog"
                    label="End Date"
                    format="llll"
                    name="endDate"
                    style={{ width: "100%" }}
                    value={moment(this.state.endDate)}
                    onChange={(dt) => {
                      this.setState({
                        endDate: moment(dt).valueOf(),
                      });
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={this.toggleModal}
                  className="btn btn-default"
                >
                  Cancel
                </button>
                <button
                  disabled={this.state.isMeetingLinkBusy}
                  onClick={this._handleCreateMeetingLink}
                  className="btn btn-primary"
                >
                  {this.state.isMeetingLinkBusy === true
                    ? "Creating..."
                    : "Create Link"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* @ts-ignore */}

        <Modal
          autoFocus={true}
          centered={true}
          toggle={this.toggleCourseSelectModal}
          isOpen={this.state.isCourseSelectModalOpen}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Url</h5>
              <button
                type="button"
                onClick={this.toggleCourseSelectModal}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <select
                value={this.state.courseId || "not-selected"}
                onChange={(e) => {
                  this.setState({
                    courseId: e.target.value
                  })

                }}
                className="form-control"
              >
                <option value="not-selected">
                  Please choose a Course
                </option>
                {this.state.courses.map((course: Course, i) => (
                  <option
                    key={i}
                    value={(course as any)._id}
                  >{course.title}</option>
                ))}
              </select>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => this.toggleCourseSelectModal()}
                  className="btn btn-default"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    this.toggleCourseSelectModal()
                    app.modules.Composer.showComposer({
                      body: "",
                      imageUrl: null,
                      resourceID: this.state.courseId,
                      resourceType: "Meeting links",
                      title: "Meeting Link",
                      clickAction: "open_link",
                      targetPhoneNumber: "",
                    });
                  }}
                  className="btn btn-primary"
                >
                  Compose
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* @ts-ignore */}

        <Modal
          isOpen={this.state.isDeleteModalOpen}
          toggle={this.toggleDeleteModalOpen}
        >
          {/* @ts-ignore */}

          <ModalHeader>Remove Meeting Link</ModalHeader>
          {/* @ts-ignore */}

          <ModalBody>
            <div className="alert alert-danger">
              <p className="m-0">
                Are you sure you want to remove this meeting link?
              </p>
            </div>
          </ModalBody>
          {/* @ts-ignore */}

          <ModalFooter>
            {/* @ts-ignore */}

            <Button
              color="secondary"
              onClick={() => this.toggleDeleteModalOpen()}
            >
              Cancel
            </Button>
            {/* @ts-ignore */}

            <Button
              color="danger"
              onClick={() =>
                this._handleDeleteMeetingStatus(this.state.idToDelete)
              }
            >
              Remove
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default MeetingLinksListingView;
