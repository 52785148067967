import React from "react";
import moment from 'moment';
import { useHistory } from 'react-router';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { AnswerSheet, Exam } from "lakshya-shared";
import ExamEditorModule from "..";
import { app } from './../../../index';

export default function (props: any) {
  const [isRankSyncing, setIsRankSyncing] = React.useState(false);
  const [isPublishing, setIsPublishing] = React.useState(false);
  const [isSyncPrompt, setisSyncPrompt] = React.useState(false);
  const [publishPrompt, setpublishPrompt] = React.useState(false);
  const [winnerAnnouncementAnswer, setWinnerAnnouncementAnswer] = React.useState(null);
  const [publishAction, setpublishAction] = React.useState<'publish' | 'unpublish'>('publish');
  const history = useHistory();
  const analytics: AnswerSheet[] = props.analytics;
  const isRefreshing: boolean = props.isRefreshing;
  const module: ExamEditorModule = props.module;
  const exam: Exam = props.exam;

  const promptSync = () => {
    setisSyncPrompt(true);
  }

  const promptPublish = (value: 'publish' | 'unpublish') => {
    setpublishPrompt(true);
    setpublishAction(value);
  }

  const sync = () => {
    closeSyncPrompt();
    setIsRankSyncing(true);
    module.services.refreshRanking((exam as any)._id)
    .then((value: any) => {
      setIsRankSyncing(false);
      props.onRefreshClick && props.onRefreshClick();
      module.controller.updateRankInfo(value.lastRankRefresh, value.isRankPublished);
    })
    .catch((err) => {
      setIsRankSyncing(false);
      module.showError(err.message || 'Unknown error', 'Syncing Failed', true);
    })
  }

  const performPublish = () => {
    closeSyncPrompt();
    setIsPublishing(true);
    module.services.updateRankPublishStatus((exam as any)._id, publishAction === 'publish' ? 'published' : 'unpublished')
    .then((value: any) => {
      closePublishPrompt();
      props.onRefreshClick && props.onRefreshClick();
      module.controller.updateRankInfo(value.lastRankRefresh, value.isRankPublished);
    })
    .catch((err) => {
      closePublishPrompt();
      module.showError(err.message || 'Unknown error', 'Syncing Failed', true);
    })
  }

  const closeSyncPrompt = () => setisSyncPrompt(false);
  const closePublishPrompt = () => {
    setpublishPrompt(false);
    setIsPublishing(false);
  }
  console.log(analytics)
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Ranking Options</h5>
                <label className="">Sync and Publish Rank</label><br />
                  <label className="">Status: <i className={`fas fa-circle text-${exam.isRankPublished === true ? 'success' : 'danger'}`}></i>{' '}{exam.isRankPublished === true ? 'Published' : 'Unpublished'}</label><br />
                  {
                    exam.lastRankRefresh ? (
                      <label className="">Last refreshed on: {moment(exam.lastRankRefresh).format('lll')}</label>
                    ) : null
                  }
                <div className="mt-2">
                  <button onClick={promptSync} disabled={isRankSyncing || exam.isRankPublished} className="btn btn-secondary">
                    {
                      isRankSyncing === true ? (
                        <>
                          <i className="fas fa fa-circle-notch fa-spin"></i>{' '}Syncing...
                        </>
                      ) : (
                          'Sync Ranking'
                        )
                    }
                  </button>{' '}
                  {
                    exam.isRankPublished === true ? (
                      <button disabled={isRankSyncing || !exam.lastRankRefresh} onClick={() => promptPublish('unpublish')} className="btn btn-warning">
                        Unpublish Rank
                      </button>
                    ) : (
                        <button disabled={isRankSyncing || !exam.lastRankRefresh} onClick={() => promptPublish('publish')} className="btn btn-success">
                          Publish Rank
                        </button>
                      )
                  }
                </div>
              </div>
            </div>
            <div className="card mt-2">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h5 className="card-title">Exam Analytics</h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      The table contains top 100 students and their performance
                      index
                  </h6>
                  </div>
                  <div>
                    <button
                      disabled={isRefreshing}
                      onClick={props.onRefreshClick}
                      className="btn btn-secondary"
                    >
                      <i
                        className={`fas fa-sync ${
                          isRefreshing === true ? "fa-spin" : ""
                          }`}
                      ></i>
                    </button>
                  </div>
                </div>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Student</th>
                      <th scope="col">Rank</th>
                      <th scope="col">Score</th>
                      <th scope="col">Percentage</th>
                      <th scope="col">Correct</th>
                      <th scope="col">Wrong</th>
                      <th scope="col">Unattempted</th>
                      {/* <th scope="col">Exam Duration</th> */}
                      <th scope="col">Unseen Questions</th>
                      <th scope="col" className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {analytics.map((answer, index) => {
                      const user: any = answer.userId;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <span>
                              <span>{user.phoneNumber}</span>
                              <br />
                              <span>{user.emailAddress}</span>
                              <br />
                              <span>{user.name}</span>
                            </span>
                          </td>
                          <td className={answer.rank > -1 ? '' : 'text-muted'}>{answer.rank > -1 ? answer.rank + (answer.totalParticipants > -1 ? `/${answer.totalParticipants}` : '') : answer.rank}</td>
                          <td>{answer.score}</td>
                          <td>{answer.performance}</td>
                          <td>{answer.correctAnswerCount}</td>
                          <td>{answer.wrongAnswerCount}</td>
                          <td>{answer.unAttemptedAnswerCount}</td>
                          {/* <td>
                                                        {`${answer.effectiveExamDurationInSec} sec`}
                                                    </td> */}
                          <td>{answer.unseenQuestionCount}</td>
                          <td className="text-right">
                            <button onClick={() => setWinnerAnnouncementAnswer(answer)} className="btn btn-sm btn-success">
                              Draft Announcement
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {analytics.length < 1 && isRefreshing === false ? (
                  <div className="text-center">
                    <span className="text-muted">No results found</span>
                  </div>
                ) : isRefreshing === true && analytics.length < 1 ? (
                  <div className="text-center">
                    <span className="text-muted">Refreshing...</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* @ts-ignore */}
      <Modal isOpen={isSyncPrompt} toggle={closeSyncPrompt}>
      {/* @ts-ignore */}
        <ModalHeader toggle={closeSyncPrompt}>
          Confirmation
        </ModalHeader>
      {/* @ts-ignore */}
        <ModalBody>
          <p className="p-0">Do you want to sync ranking?</p>
        </ModalBody>
      {/* @ts-ignore */}
        <ModalFooter>
      {/* @ts-ignore */}
          <Button color="default" onClick={closeSyncPrompt}>Cancel</Button>
          {/* @ts-ignore */}
          <Button color="primary" onClick={sync}>Yes, Sync</Button>
        </ModalFooter>
      </Modal>
      {/* @ts-ignore */}
      <Modal isOpen={publishPrompt} toggle={closePublishPrompt}>
        {/* @ts-ignore */}
        <ModalHeader toggle={closePublishPrompt}>
          {publishAction === 'publish' ? 'Publish' : 'Unpublish'} Confirmation
        </ModalHeader>
        {/* @ts-ignore */}
        <ModalBody>
          <p className="p-0">You are about to {publishAction} ranking for this exam. Do you want to continue?</p>
        </ModalBody>
        {/* @ts-ignore */}
        <ModalFooter>
          {/* @ts-ignore */}
          <Button disabled={isPublishing} color="default" onClick={closePublishPrompt}>Cancel</Button>
          {/* @ts-ignore */}
          <Button disabled={isPublishing} color="primary" onClick={performPublish}>
            {
              isPublishing ? (
                <span><i className="fas fa-circle-notch fa-spin"></i>{' Processing'}</span>
              ) : (
                <>
                  Yes, {publishAction === 'publish' ? 'Publish' : 'Unpublish'}
                </>
              )
            }
          </Button>
        </ModalFooter>
      </Modal>
      {/* Winner Announcement */}
      {/* @ts-ignore */}
      <Modal isOpen={winnerAnnouncementAnswer !== null} toggle={() => setWinnerAnnouncementAnswer(null)}>
        {/* @ts-ignore */}
          <ModalBody>
            <div className="alert alert-info" role="alert">
            <h4 className="alert-heading">
              Announcement Confirmation
            </h4>
              <p>Do you want to draft winner announcement for {winnerAnnouncementAnswer ? winnerAnnouncementAnswer.name || winnerAnnouncementAnswer.phoneNumber : 'this user'}?</p>
            </div>
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            <div>{/* @ts-ignore */}
              <Button color="secondary" className="mr-3" onClick={() => setWinnerAnnouncementAnswer(null)}>No</Button>
              {/* @ts-ignore */}
              <Button color="success" onClick={() => {
                setWinnerAnnouncementAnswer(null);
                app.modules.Winner.controller.draftAnnouncement({
                  user: winnerAnnouncementAnswer.userId,
                  winnerName: winnerAnnouncementAnswer.userId.name,
                  claimMessage: 'You are today\'s winner',
                  titleMessage: 'Today\'s Winner',
                  examTitle: exam.title,
                  rank: winnerAnnouncementAnswer.rank,
                  totalParticipants: winnerAnnouncementAnswer.totalParticipants
                })
                  .then((response: any) => {
                    // Navigate to winner announcement page
                    history.push(`/admin/winners/editor/${response._id}`)
                  })
                  .catch(() => {
                    // Do nothing
                  })
              }}>Yes</Button>
            </div>
          </ModalFooter>
        </Modal>
    </>
  );
}
