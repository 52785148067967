import React from 'react';
import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import { ComponentMap, ViewComponentPropType } from "@skyslit/ark-react/build/types";
import NotificationModal from "./NotificationModal";
import { ComposerOptions } from 'lakshya-shared';

export type StateType = {
    isActive: boolean,
    opts: Partial<ComposerOptions>
};

export default class NotificationComposer extends ArkModule<StateType> {

    initialState: StateType = {
        isActive: false,
        opts: {}
    };

    constructor() {
        super("NotificationComposer");

        this.useConnect(connect);

        this.getReducer = () => {
            return (state: StateType = this.initialState, action: any) => {
                switch (action.type) {
                    case this.actionTypes.HIDE_COMPOSER: {
                        return Object.assign({}, state, {
                            isActive: false
                        })
                    }
                    case this.actionTypes.SHOW_COMPOSER: {
                        const { value } = action.payload;
                        return Object.assign({}, state, {
                            isActive: true,
                            opts: value || {}
                        })
                    }
                    default: {
                        return state;
                    }
                }
            };
        };

        this.main = () => { };
    }

    showComposer = (opts: Partial<ComposerOptions>) => {
        this.dispatch({
            type: this.actionTypes.SHOW_COMPOSER,
            payload: {
                value: opts
            }
        })
    }

    controller = {
        hideComposer: () => {
            this.dispatch({
                type: this.actionTypes.HIDE_COMPOSER
            })
        }
    }


    actionTypes = {
        SHOW_COMPOSER: "SHOW_COMPOSER",
        HIDE_COMPOSER: "HIDE_COMPOSER",
    };


    views: ComponentMap = {
        ComposerModal: (props: ViewComponentPropType<NotificationComposer>) => {
            if (props.context.isActive === true) {
                return <NotificationModal {...props} isOpen={props.context.isActive} toggle={this.controller.hideComposer} {...props.context.opts} />
            }
            
            return null;
        },
    };

    services = {
        sendNotification: (opts: Partial<ComposerOptions>) => {
            return new Promise((resolve, reject) => {
                this.getServiceProvider("Main")
                  .post("/api/admin/notifications/send", opts)
                  .then((response) => {
                    resolve((response.data && response.data) || null);
                  })
                  .catch((err) => {
                    reject((err.response && err.response && err.response.data) || err);
                  });
              });
        }
    }
}
