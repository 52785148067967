import React, { useState } from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "../module";
import { useTranslation } from "react-i18next";
import Login from "../components/SignIn";
import Register from "../components/Register";
import { app } from "../../..";

export default (props: ViewComponentPropType<DefaultModule>) => {
  const [currentView, setCurrentView] = useState("signIn");
  const [socialAuthenticationToken, setSocialAuthenticationToken] = useState(
    ""
  );
  const { t } = useTranslation();

  //  Signin with username and password service call function
  const _handleSignin = (data: any) => {
    props.module.services
      .signInWithEmailAndPassword(data.email, data.password)
      .then((response: any) => {
        props.module.setCurrentUser(true, null, response);
        app.modules.Notification.refreshCount();
        app.modules.CurrentAffairs.loadListingData();
        app.modules.RankFile.loadListingData();
        app.modules.Subscription.controller.refreshMaster(true);
        app.modules.VideoCatalogue.loadLectures();
      })
      .catch((err) => {
        props.module.showError(err.message, "Authentication Failed", true);
      });
  };

  // User registration service call function
  const _handleRegisterUser = async (data: any) => {
    props.module.services
      .registerUser(data.fullName, data.registerEmail, data.registerPassword)
      .then((response: any) => {
        props.module.setCurrentUser(true, null, response);
      })
      .catch(() => {
        props.module.showError("Network error", "Sign In Failed", true);
      });
  };

  // Sign in through social provider gateway service call function
  function _handleFinishSocialAuthentication() {
    setSocialAuthenticationToken("123456789");
    props.module.services
      .finishSocialAuthentication(socialAuthenticationToken)
      .then((response: any) => {
        props.module.setCurrentUser(true, null, response);
      })
      .catch(() => {
        props.module.showError("Network error", "Sign In Failed", true);
      });
  }
  return (
    <>
     {/* @ts-ignore */}
      <Helmet>
        <title>{t("Sign In")}</title>
      </Helmet>
      <div className="container-fluid auth-view-wrapper">
        {currentView === "signIn" ? (
          <Login
            registerView={setCurrentView}
            onSocialAuthentication={_handleFinishSocialAuthentication}
            onSignIn={_handleSignin}
          />
        ) : (
          <Register
            SignInView={setCurrentView}
            onRegister={_handleRegisterUser}
          />
        )}
      </div>
    </>
  );
};
