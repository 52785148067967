import React, { Component } from "react";
import ReactTable from "react-table";
import { Modal } from "reactstrap";
import { useForm } from "react-hook-form";
import VideoCatalogueModule from "..";
import { Link } from "react-router-dom";
import { resolveAssetUrl } from "../../../shared/utils";
import { Subscription } from "lakshya-shared";

const defaultImage = require("./../assets/images/default-image.png");

export default class TableView extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isNewModalOpen: false,
    };
    this.toggleAddModal = this.toggleAddModal.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
  }

  toggleAddModal() {
    this.setState({
      isNewModalOpen: !this.state.isNewModalOpen,
    });
  }

  handleAddClick = (data: any) => {
    const module: VideoCatalogueModule = this.props.module;
    const history = this.props.history;
    module.services
      .create(data.title)
      .then((response: any) => {
        this.toggleAddModal();
        history.push(`/admin/rank-files/editor/${response._id}`);
      })
      .catch((e) => {
        this.props.module.showError(
          "Item cannot be added",
          e.message ? e.message : "Network error",
          true
        );
      });
  };

  handleFetch() {
    const module: VideoCatalogueModule = this.props.module;
    module.services
      .getAll()
      .then((response: any) => {
        module.controller.setListingData(response);
      })
      .catch((e) => {
        this.props.module.showError(
          "Fetching course failed",
          e.message ? e.message : "Network error",
          true
        );
      });
  }

  componentDidMount() {
    this.handleFetch();
  }

  render() {
    const data = this.props.data;
    if (!data) {
      return <div>Loading</div>;
    }
    return (
      <>
        <div className="col-12">
          <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
            <h4>Rank File Manager</h4>
            <button
              onClick={this.toggleAddModal}
              className="add-lecture-button-wrapper px-5 py-3"
            >
              Add Rank File
            </button>
          </div>
          <div className="pt-3">
            <ReactTable
              minRows={3}
              defaultPageSize={10}
              data={data}
              columns={[
                {
                  Header: "Title",
                  accessor: "title",
                  Cell: (row) => {
                    const data = row.original;
                    return (
                      <div className="d-flex">
                        <img
                          width={124}
                          src={
                            data.bannerImageId
                              ? resolveAssetUrl(data.bannerImageId, "public")
                              : defaultImage
                          }
                          alt="Video thumbnail"
                        />
                        <Link
                          className="text-primary ml-3"
                          rel="noopener noreferrer"
                          to={`/admin/rank-files/editor/${row.original._id}`}
                        >
                          {row.value}
                        </Link>
                      </div>
                    );
                  },
                },
                {
                  Header: "Status",
                  accessor: "isPublished",
                  width: 100,
                  Cell: (row) => {
                    return (
                      <span
                        className={`${row.value === true
                            ? "text-success"
                            : "text-danger font-weight-bold"
                          }`}
                      >
                        {row.value === true ? "Published" : "Unpublished"}
                      </span>
                    );
                  },
                },
                {
                  Header: "Subscription",
                  accessor: "masterSubscription",
                  Cell: (row) => {
                    const subscription: Subscription = row.value;
                    if (subscription) {
                      return (
                        <Link
                          target="_blank"
                          className="text-success"
                          rel="noopener noreferrer"
                          to={`/admin/subscriptions/editor/${(subscription as any)._id
                            }`}
                        >
                          {subscription.title}
                          <i className="fas fa-external-link-alt ml-3"></i>
                        </Link>
                      );
                    } else {
                      return (
                        <span className="text-muted">No Associations</span>
                      );
                    }
                  },
                },
              ]}
            />
          </div>
        </div>
        {/* @ts-ignore */}
        <Modal
          autoFocus={true}
          centered={true}
          toggle={this.toggleAddModal}
          isOpen={this.state.isNewModalOpen}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Item</h5>
              <button
                type="button"
                onClick={this.toggleAddModal}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <NewItemForm
                handleAddClick={this.handleAddClick}
                toggleAddModal={this.toggleAddModal}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

function NewItemForm(props: any) {
  const { register, handleSubmit, errors } = useForm();
  const toggleAddModal = props.toggleAddModal;
  const handleAddClick = props.handleAddClick;

  return (
    <form onSubmit={handleSubmit(handleAddClick)}>
      <input
        autoFocus={true}
        autoComplete="off"
        name="title"
        ref={register({ required: true })}
        type="text"
        className="form-control"
        placeholder="Enter Title"
      />
      {errors.title && (
        <small className="text-danger" id="otp-error">
          Please provide a title
        </small>
      )}
      <div className="modal-footer">
        <button
          type="button"
          onClick={toggleAddModal}
          className="btn btn-default"
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-primary">
          Create Rank File
        </button>
      </div>
    </form>
  );
}
