import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DiscussionModule from "..";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
type StateType = {
  dropdownOpen: boolean;
  statusDropdownOpen: boolean;
};
class DiscussionEditorPage extends React.Component<
  ViewComponentPropType<DiscussionModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      dropdownOpen: false,
      statusDropdownOpen: false,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleStatusDropdown = this.toggleStatusDropdown.bind(this);
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  toggleStatusDropdown() {
    this.setState({
      statusDropdownOpen: !this.state.statusDropdownOpen,
    });
  }

  render() {
    return (
      <>
        <>
          {/* @ts-ignore */}

          <Helmet>
            <title>Discussions</title>
          </Helmet>
          <div className="container-fluid dicsuiion-wrapper">
            <div className="row">
              <div className="col-12 discussion-layout-wrapper px-0">
                <div className="discussion-sidebar pt-5">
                  <div className="px-4">
                    <div className="d-flex flex-direction-row">
                      <Link to="/admin/discussions" className="go-back-button">
                        <i className="fas fa-arrow-left"></i>
                      </Link>
                      <h5 className="pl-4">Subjects</h5>
                    </div>
                    <label>
                      Select among the options to view specific discussions
                      added or received for each of them.
                    </label>
                  </div>
                  <div className="pt-2">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a href="/" className="nav-link selected py-3 pl-5">
                          Mathematics
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/" className="nav-link py-3 pl-5">
                          English
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/" className="nav-link py-3 pl-5">
                          General Knowledge
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="discussion-editor-content pt-5">
                  <div className="header-wrapper px-5">
                    <h4>Mathematics</h4>
                    <button className="add-button-wrapper px-5 py-3">
                      Add Discussion
                    </button>
                  </div>
                  <div className="group-button-and-sort-button-wrapper px-5 pt-5">
                    <div
                      className="btn-group custom-button-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button className="btn btn-secondary selected px-4">
                        All
                      </button>
                      <button className="btn btn-secondary px-4">Added</button>
                      <button className="btn btn-secondary px-4">
                        Received Comments
                      </button>
                    </div>
                    {/* @ts-ignore */}

                    <Dropdown
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggleDropdown}
                    >
                      {/* @ts-ignore */}

                      <DropdownToggle className="sort-dropdown-button" caret>
                        Sort By
                      </DropdownToggle>
                      {/* @ts-ignore */}

                      <DropdownMenu>
                        {/* @ts-ignore */}

                        <DropdownItem>Date</DropdownItem>
                        {/* @ts-ignore */}

                        <DropdownItem>Lecture</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>

                  <div className="table-responsive pt-5">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Student Name</th>
                          <th>Comment</th>
                          <th>Reply</th>
                          <th>Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Abbas Rahman</td>
                          <td>
                            <textarea
                              rows={4}
                              placeholder="Enter comment"
                              className="form-control comment-input"
                            />
                          </td>
                          <td>
                            <textarea
                              rows={4}
                              placeholder="Enter your reply"
                              className="form-control comment-input"
                            />
                          </td>
                          <td>12-02-2020 06:00pm</td>
                          <td>
                            <div>
                              <button className="status-button-wrapper mr-3 px-3 py-2">
                                <i
                                  style={{ color: "#46BC71" }}
                                  className="fas fa-check-circle pr-3"
                                ></i>
                                Approve
                              </button>
                              <button className="status-button-wrapper px-3 py-2">
                                <i
                                  style={{ color: "#BC4646" }}
                                  className="fas fa-times-circle pr-3"
                                ></i>
                                Decline
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Abbas Rahman</td>
                          <td>
                            <textarea
                              rows={4}
                              placeholder="Enter comment"
                              className="form-control comment-input"
                            />
                          </td>
                          <td>
                            <textarea
                              rows={4}
                              placeholder="Enter your reply"
                              className="form-control comment-input"
                            />
                          </td>
                          <td>12-02-2020 06:00pm</td>
                          <td>
                            <div>
                              <button className="status-button-wrapper mr-3 px-3 py-2">
                                <i
                                  style={{ color: "#46BC71" }}
                                  className="fas fa-check-circle pr-3"
                                ></i>
                                Approve
                              </button>
                              <button className="status-button-wrapper px-3 py-2">
                                <i
                                  style={{ color: "#BC4646" }}
                                  className="fas fa-times-circle pr-3"
                                ></i>
                                Decline
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Abbas Rahman</td>
                          <td>
                            <textarea
                              rows={4}
                              placeholder="Enter comment"
                              className="form-control comment-input"
                            />
                          </td>
                          <td>
                            <textarea
                              rows={4}
                              placeholder="Enter your reply"
                              className="form-control comment-input"
                            />
                          </td>
                          <td>12-02-2020 06:00pm</td>
                          <td>
                            {/* @ts-ignore */}
                            <Dropdown
                              isOpen={this.state.statusDropdownOpen}
                              toggle={this.toggleStatusDropdown}
                            >
                              {/* @ts-ignore */}
                              <DropdownToggle
                                style={{ backgroundColor: "white" }}
                                caret
                              >
                                <i
                                  style={{ color: "#46BC71" }}
                                  className="fas fa-check-circle pr-3"
                                ></i>
                                Approved
                              </DropdownToggle>
                              {/* @ts-ignore */}
                              <DropdownMenu>
                                {/* @ts-ignore */}
                                <DropdownItem>
                                  <i
                                    style={{ color: "#46BC71" }}
                                    className="fas fa-check-circle pr-3"
                                  ></i>
                                  Approve
                                </DropdownItem>
                                {/* @ts-ignore */}
                                <DropdownItem>
                                  <i
                                    style={{ color: "#BC4646" }}
                                    className="fas fa-times-circle pr-3"
                                  ></i>
                                  Decline
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default DiscussionEditorPage;
