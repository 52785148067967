import { ArkModule } from "@skyslit/ark-react";
import { connect } from "react-redux";
import { ComponentMap } from "@skyslit/ark-react/build/types";
import NotFoundPage from "./components/NotFound.view";

export type StateType = {
  StartMessage: {
    msg1: string;
  };
};

export default class PageNotFoundModule extends ArkModule<StateType, "AuthServer"> {
  constructor() {
    super("LayoutModule");

    this.useConnect(connect);

    this.main = () => {};
  }

  views: ComponentMap = {
    NotFoundPage: NotFoundPage,
  };
}
