import {
  ArkPackage,
  BaseConfigType,
  withAuthentication,
  withCondition,
} from "@skyslit/ark-react";
import "./shared/styles/index.scss";
import React from "react";
import FileUploadModule from "./modules/FileUploadModule";
import CourseModule from "./modules/CourseModule";
import CourseEditorModule from "./modules/CourseEditorModule";
import AuthModule from "./modules/AuthModule/module";
import LayoutModule from "./modules/LayoutModule";
import PageNotFoundModule from "./modules/PageNotFoundModule";
import ExamModule from "./modules/ExamModule";
import ExamEditorModule from "./modules/ExamEditorModule";
import SubscriptionModule from "./modules/SubscriptionModule";
import VideoCatalogueModule from "./modules/VideoCatalogue";
import DiscussionModule from "./modules/DiscussionModule";
import LakshyaStudentsModule from "./modules/LakshyaStudentsModule";
import UserManagerModule from "./modules/UserManagerModule";
import OrderManagerModule from "./modules/OrderManagerModule";
import RankFileModule from "./modules/RankFileModule";
import CurrentAffairsModule from "./modules/CurrentAffairsModule";
import NotificationModule from "./modules/NotificationModule";
import WinnerModule from "./modules/WinnerModule";
import CouponModule from "./modules/CouponModule";
import NotificationComposerModule from "./modules/NotificationComposer/module";
import RTDClient from "./modules/RTDClient/index";


export interface PackageType {
  Course: CourseModule;
  CourseEditor: CourseEditorModule;
  Auth: AuthModule;
  Layout: LayoutModule;
  PageNotFound: PageNotFoundModule;
  Exam: ExamModule;
  ExamEditor: ExamEditorModule;
  FileUploader: FileUploadModule;
  Subscription: SubscriptionModule;
  VideoCatalogue: VideoCatalogueModule;
  Discussion: DiscussionModule;
  LakshyaStudents: LakshyaStudentsModule;
  UserManager: UserManagerModule;
  OrderManager: OrderManagerModule;
  RankFile: RankFileModule;
  Notification: NotificationModule;
  CurrentAffairs: CurrentAffairsModule;
  Winner: WinnerModule;
  Composer: NotificationComposerModule;
  RTDClient: RTDClient;
  Coupon: CouponModule;
}

export type ConfigType = BaseConfigType & {
  publicAssetBucketUrl: string;
};

const _package = ArkPackage.getInstance<
  PackageType,
  ConfigType,
  "Main" | "AdminProc"
>();

_package.configOpts = {
  default: {
    publicAssetBucketUrl:
      "https://dev-lakshya-public-assets.s3.ap-south-1.amazonaws.com",
    Main: {
      baseURL: "http://localhost:3000",
      withCredentials: true,
    },
    AdminProc: {
      baseURL: "http://localhost:3000",
      withCredentials: true,
    },
  },
  dev_remote: {
    publicAssetBucketUrl:
      "https://dev-lakshya-public-assets.s3.ap-south-1.amazonaws.com",
    Main: {
      baseURL: "https://api-lakshya-demo.skyslit.net",
      withCredentials: true,
    },
    AdminProc: {
      baseURL: "https://api-lakshya-demo.skyslit.net",
      withCredentials: true,
    },
  },
  production: {
    publicAssetBucketUrl:
      "https://lakshya-app-public-assets.s3.ap-south-1.amazonaws.com",
    Main: {
      baseURL: "https://admin-services-2.lakshyakerala.app",
      withCredentials: true,
    },
    AdminProc: {
      baseURL: "https://adminproc.lakshyakerala.app",
      withCredentials: true,
    },
  },
};
console.log("hittttttt", _package)
_package.configMode = process.env.REACT_APP_BUILD_DEV;
_package.configMode = "production";
// _package.configMode = "default"

_package.serviceProviderModuleMap = {
  FileUploader: {
    AdminProc: _package.getServiceProvider("AdminProc"),
  },
};

_package.registerModule("Course", new CourseModule());
_package.registerModule("CourseEditor", new CourseEditorModule());
_package.registerModule("Auth", new AuthModule());
_package.registerModule("RTDClient", new RTDClient());
_package.registerModule("Layout", new LayoutModule());
_package.registerModule("PageNotFound", new PageNotFoundModule());
_package.registerModule("Exam", new ExamModule());
_package.registerModule("ExamEditor", new ExamEditorModule());
_package.registerModule("FileUploader", new FileUploadModule());
_package.registerModule("Subscription", new SubscriptionModule());
_package.registerModule("VideoCatalogue", new VideoCatalogueModule());
_package.registerModule("Discussion", new DiscussionModule());
_package.registerModule("LakshyaStudents", new LakshyaStudentsModule());
_package.registerModule("UserManager", new UserManagerModule());
_package.registerModule("OrderManager", new OrderManagerModule());
_package.registerModule("RankFile", new RankFileModule());
_package.registerModule("Notification", new NotificationModule());
_package.registerModule("CurrentAffairs", new CurrentAffairsModule());
_package.registerModule("Winner", new WinnerModule());
_package.registerModule("Coupon", new CouponModule());
_package.registerModule("Composer", new NotificationComposerModule());



_package.routeConfig = [
  {
    path: "/auth/login",
    component: _package.modules.Auth.views.AuthPage,
    Router: withAuthentication("/admin/videos", false),
  },
  {
    path: "/admin/courses/editor/:courseId/:editorMode?",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.CourseEditor.views.CourseEditor {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
  },
  {
    path: "/admin/courses",
    component: (props: any) => {
      return (

        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.Course.views.CoursePage {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/exams/editor/:examId/:editorMode?",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.ExamEditor.views.ExamEditor {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/exams/:templateId?",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.Exam.views.ExamPage {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/subscriptions/editor/:subscriptionId/:editorMode?",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.Subscription.views.SubscriptionEditorPage
            {...props}
          />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/subscriptions",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.Subscription.views.SubscriptionPage {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/videos",
    exact: true,
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.VideoCatalogue.views.VideoCataloguePage
            {...props}
          />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/custom-lectures",
    exact: true,
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.VideoCatalogue.views.CustomLectureCollection
            {...props}
          />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/videos/lectures/:id/:editorMode?",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.VideoCatalogue.views.LectureEditorView {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/lakshya/students",
    exact: true,
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          <_package.modules.LakshyaStudents.views.StudentManager {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/users",
    exact: true,
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          <_package.modules.UserManager.views.StudentManager {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/notifications",
    exact: true,
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          <_package.modules.Notification.views.NotificationManager {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/rank-files/editor/:id/:editorMode?",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.RankFile.views.Editor {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
  },
  {
    path: "/admin/rank-files",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.RankFile.views.Listing {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/winners/editor/:id/:editorMode?",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.Winner.views.Editor {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
  },
  {
    path: "/admin/winners",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.Winner.views.Listing {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/coupons",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.Coupon.views.Listing {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/meeting-links",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.CurrentAffairs.views.MeetingLinks {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
  },
  {
    path: "/admin/current-affairs/editor/:id/:editorMode?",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.CurrentAffairs.views.Editor {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
  },
  {
    path: "/admin/current-affairs",
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.CurrentAffairs.views.Listing {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/orders",
    exact: true,
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          <_package.modules.OrderManager.views.StudentManager {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/discussions",
    exact: true,
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.Discussion.views.DiscussionPage {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/discussions/subject",
    exact: true,
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.Discussion.views.DiscussionEditorPage {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/admin/process/queue",
    exact: true,
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          <_package.modules.FileUploader.views.QueueManagerView {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/",
    exact: true,
    Router: withCondition({
      onFailureRedirectPath: "/admin/videos",
      predicate: () => false,
    }),
  },
  {
    path: "/admin/pending-pdf",
    exact: true,
    component: (props: any) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <_package.modules.Layout.views.AdminLayout {...props}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <_package.modules.VideoCatalogue.views.PendingPdf {...props} />
        </_package.modules.Layout.views.AdminLayout>
      );
    },
    Router: withAuthentication("/auth/login", true),
  },
  {
    path: "/",
    component: _package.modules.PageNotFound.views.NotFoundPage,
  },
];

export const app = _package;
