import React from "react";
import { QuestionGroup } from "lakshya-shared/build/exam";
import ExamEditorModule from "..";
import { resolveAssetUrl } from "../../../shared/utils";
import { app } from './../../../';

export default (props: any) => {
  const isExpanded: boolean = props.isExpanded;
  const toggleExpanded: any = props.toggleExpanded;
  const hasTimeRestriction: boolean = props.hasTimeRestriction;
  const context: QuestionGroup = props.context;
  const module: ExamEditorModule = props.module;
  const onOptionClicked: any = props.onOptionClicked;

  const Dropzone = app.modules.FileUploader.views.Dropzone;
  const FileView = app.modules.FileUploader.views.FileView;

  // const selectedSubject = ExamSubjectMaster.find(
  //   (m) => m.value === context.subjectKey
  // );

  return (
    <>
      <tr>
        <th colSpan={5}>
          <div className="d-flex">
            <span className="pl-3 d-flex align-items-center">{props.index + 1}</span>
            <button
              onClick={() => toggleExpanded && toggleExpanded()}
              className="btn btn-default btn-small"
              style={{ color: "#585858", outline: 0, border: "none" }}
            >
              <i
                style={{ transform: isExpanded ? undefined : "rotate(270deg)" }}
                className="fas fa-chevron-circle-down"
              ></i>
            </button>
            <div className="form-group m-0 d-inline-block ml-3">
              <input
                type="text"
                value={context.title}
                onChange={(e) =>
                  module.controller.updateQuestionGroup(
                    context.resId,
                    "title",
                    e.target.value
                  )
                }
                className="form-control"
                placeholder="Group Title"
              />
            </div>
            <div
              className="d-flex flex-fill"
              style={{
                justifyContent: "flex-start",
                marginLeft: 8,
                alignItems: "center",
              }}
            >
              <span
                style={{ fontWeight: "normal" }}
              >{`${context.questions.length} Question(s)`}</span>
              {hasTimeRestriction ? (
                <span
                  style={{ fontWeight: "normal" }}
                >{`${context.durationInMins} Min(s)`}</span>
              ) : null}
            </div>
            {isExpanded ? (
              <>
                <div className="form-group m-0 d-inline-block ml-3">
                  <button
                    onClick={() =>
                      onOptionClicked(context.resId, context.durationInMins)
                    }
                    className="btn btn-outline-primary btn-small"
                  >
                    <i
                      style={{
                        transform: isExpanded ? undefined : "rotate(270deg)",
                      }}
                      className="fas fa-trash"
                    ></i>
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </th>
      </tr>
      {isExpanded === true ? (
        context.questions.length > 0 ? (
          context.questions.map((question, index) => {
            return (
              <tr key={index} className="Question-creator-wrapper">
                <td>{index + 1}</td>
                <td>
                  <div>
                    <fieldset>
                      <div className="d-flex mb-2">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name={`mimeType--${index}`} onChange={(e) => {
                            if (e.target.checked === true) {
                              module.controller.updateQuestion(
                                context.resId,
                                question.resId,
                                "questionMimeType",
                                'text'
                              )
                            }
                          }} id={`textOpt-${index}`} value="option1" checked={question.questionMimeType === 'text'} />
                          <label className="form-check-label" htmlFor={`textOpt-${index}`}>
                            Text
                        </label>
                        </div>
                        <div className="form-check ml-3">
                          <input className="form-check-input" type="radio" name={`mimeType--${index}`} onChange={(e) => {
                            if (e.target.checked === true) {
                              module.controller.updateQuestion(
                                context.resId,
                                question.resId,
                                "questionMimeType",
                                'image'
                              )
                            }
                          }} id={`imageOpt-${index}`} value="option2" checked={question.questionMimeType === 'image'} />
                          <label className="form-check-label" htmlFor={`imageOpt-${index}`}>
                            Image
                        </label>
                        </div>
                      </div>
                    </fieldset>
                    {
                      question.questionMimeType === 'image' ? (
                        <div className="form-group mt-4">
                          {question.bannerImageId ? (
                            <>
                              <img
                                className="d-block"
                                src={resolveAssetUrl(question.bannerImageId, "public")}
                                style={{ height: 300, maxWidth: '100%', margin: '0 auto' }}
                                alt="Course Banner"
                              />
                              <FileView
                                fileId={question.bannerImageId}
                                fileName="Question Image"
                                fileSizeInBytes={question.bannerImageFizeSizeInBytes}
                                onDelete={() => {
                                  module.controller.updateQuestion(
                                    context.resId,
                                    question.resId,
                                    "bannerImageId",
                                    null);
                                  module.controller.updateQuestion(
                                    context.resId,
                                    question.resId,
                                    "bannerImageFizeSizeInBytes",
                                    0
                                  );
                                  module.controller.saveExam();
                                }}
                              />
                            </>
                          ) : (
                              <Dropzone
                                label="Upload Question Image"
                                accept="image/*"
                                accessMode="public"
                                onSuccess={(files: any[]) => {
                                  if (files && Array.isArray(files)) {
                                    if (files.length > 0) {
                                      module.controller.updateQuestion(
                                        context.resId,
                                        question.resId,
                                        "bannerImageId",
                                        files[0]._id.toString()
                                      );
                                      module.controller.updateQuestion(
                                        context.resId,
                                        question.resId,
                                        "bannerImageFizeSizeInBytes",
                                        files[0].sizeInBytes
                                      );
                                      module.controller.saveExam();
                                    }
                                  }
                                }}
                              />
                            )}
                        </div>
                      ) : (
                          <textarea
                            rows={9}
                            placeholder="Question goes here..."
                            className="ml-2 pl-2 form-control question-edit-input"
                            value={question.question}
                            onChange={(e) =>
                              module.controller.updateQuestion(
                                context.resId,
                                question.resId,
                                "question",
                                e.target.value
                              )
                            }
                          />
                      )
                    }
                  </div>
                </td>
                <td>
                  <div>
                    {question.options.map((option, index) => {
                      return (
                        <div key={`${index}`} className="form-group form-check">
                          <input
                            name={`question-${question.resId}`}
                            className="form-check-input"
                            type="radio"
                            checked={question.correctAnswerIndex === index}
                            onChange={(e) =>
                              e.currentTarget.checked
                                ? module.controller.updateQuestion(
                                    context.resId,
                                    question.resId,
                                    "correctAnswerIndex",
                                    index
                                  )
                                : null
                            }
                          />
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder={`Option ${index + 1}`}
                            value={option}
                            onChange={(e) =>
                              module.controller.updateQuestionOption(
                                context.resId,
                                question.resId,
                                index,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </td>
                <td>
                  <div>
                    <div className="form-group">
                      <label>Tag #1</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={question.tag1}
                        onChange={(e) =>
                          module.controller.updateQuestion(
                            context.resId,
                            question.resId,
                            "tag1",
                            e.target.value
                          )
                        }
                        placeholder="(Optional)"
                      />
                    </div>
                    <div className="form-group">
                      <label>Solution</label>
                      <textarea
                        rows={3}
                        className="form-control form-control-sm"
                        value={question.solution}
                        onChange={(e) =>
                          module.controller.updateQuestion(
                            context.resId,
                            question.resId,
                            "solution",
                            e.target.value
                          )
                        }
                        placeholder="(Optional)"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="form-group d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={() =>
                        props.onDeleteQuestion &&
                        props.onDeleteQuestion(
                          context.resId,
                          question.resId,
                          question.question
                        )
                      }
                      className="btn btn-danger mt-3"
                    >
                      <i className="fas fa-minus-circle"></i>
                    </button>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td className="text-center" colSpan={5}>
              No Questions
            </td>
          </tr>
        )
      ) : null}
      {isExpanded === true ? (
        <tr>
          <td colSpan={5}>
            <div className="col-12 text-center my-2">
              <button
                onClick={() => module.controller.addQuestion(context.resId)}
                className="btn btn-info"
              >
                + Add Question
              </button>
            </div>
          </td>
        </tr>
      ) : null}
    </>
  );
};
