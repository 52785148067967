import React, { useState } from "react";
import moment from "moment";
import { ViewComponentPropType } from "@skyslit/ark-react";
import DiscussionModule from "..";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Comment } from "lakshya-shared";
import { app } from "./../../../index";

function CommentItem(props: any) {
  const isPublished: boolean = props.isPublished ? props.isPublished : false;
  const context: Comment = props.context;
  const [rowsToDisplay, setRowsToDisplay] = useState(3);
  let seperatorHeight = 0;
  switch (context.replies.length) {
    case 1:
      seperatorHeight = 30;
      break;
    case 2:
      seperatorHeight = 60;
      break;

    default:
      seperatorHeight = 100;
      break;
  }
  return (
    <div className="row p-2 comment-item">
      <div className="col-12">
        {context.isAdministrator === true ? (
          <i
            className="fas fa-user-graduate mr-2"
            style={{ opacity: isPublished ? 1 : 0.5 }}
            title="Administrator"
          />
        ) : null}{" "}
        <div className="comment-reply-wrapper">
          <div>
            <p className="d-inline" style={{ opacity: isPublished ? 1 : 0.5 }}>
              <b>{context.effectiveDisplayName}</b>
            </p>
            <span className="pl-3" style={{ color: "#ccc" }}>
              {moment((context as any).createdAt).fromNow()}
            </span>{" "}
            {context.isPinned === true ? (
              <span
                style={{
                  marginLeft: 10,
                  padding: "5px 10px",
                  fontSize: 12,
                  fontWeight: "bold",
                }}
                className="badge badge-info"
              >
                Pinned
              </span>
            ) : null}
          </div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="text-right"
          >
            <button
              style={{ color: "#B3B3B3", fontWeight: "bold", fontSize: 12 }}
              onClick={props.openReplyModal}
              className="btn btn-link"
            >
              Reply
            </button>
            <button
              style={{ color: "#B3B3B3", fontSize: 14 }}
              onClick={() => props.onEdit && props.onEdit(context)}
              className="btn btn-link"
            >
              <i className="fas fa-pen"></i>
            </button>
            <button
              style={{ color: "#B3B3B3", fontSize: 14 }}
              onClick={() =>
                props.onDelete && props.onDelete((context as any)._id)
              }
              className="btn btn-link"
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </div>
        </div>
        {isPublished === false ? (
          <span
            className="ml-2 text-danger"
            style={{ opacity: isPublished ? 1 : 0.5 }}
          >
            (Not visible to public)
          </span>
        ) : null}
        <div className="my-2">
          <p style={{ opacity: isPublished ? 1 : 0.5 }}>{context.message}</p>
        </div>
        <div className="d-flex flex-direcrion-row">
          {context.replies && context.replies.length > 0 ? (
            <div
              style={{ height: seperatorHeight }}
              className="seperator mt-2"
            ></div>
          ) : null}
          <div className="pl-3 pb-3 w-100">
            {context.replies.length > 0 ? (
              <span style={{ color: "#C6C6C6", fontSize: 12 }}>
                Replies({context.replies.length})
              </span>
            ) : null}
            {context.replies
              .slice(0, rowsToDisplay)
              .map((reply: Comment, index) => {
                return (
                  <div className="pt-2 pl-3 reply-wrapper" key={index}>
                    <div className="comment-reply-wrapper">
                      <div className="d-flex flex-direction-row">
                        <div className="user-name-wrapper px-3">
                          {reply.isAdministrator === true ? (
                            <i
                              className="fas fa-user-graduate mr-2"
                              style={{
                                opacity: isPublished ? 1 : 0.5,
                                color: "#AFAFAF",
                                fontSize: 12,
                              }}
                              title="Administrator"
                            />
                          ) : null}{" "}
                          <p
                            style={{
                              color: "#AFAFAF",
                              fontWeight: "bold",
                              fontSize: 11,
                            }}
                            className="d-inline mb-0"
                          >
                            {reply.displayName}
                          </p>
                        </div>
                        <span
                          className="pl-3"
                          style={{ color: "#ccc", fontSize: 11 }}
                        >
                          {moment((reply as any).createdAt).fromNow()}
                        </span>
                      </div>
                      <div className="reply-actions-wrapper">
                        <button
                          style={{ color: "#B3B3B3", fontSize: 14 }}
                          onClick={() =>
                            props.onEdit && props.editReplyModal(context, reply)
                          }
                          className="btn btn-link"
                        >
                          <i className="fas fa-pen"></i>
                        </button>
                        <button
                          style={{ color: "#B3B3B3", fontSize: 14 }}
                          onClick={() =>
                            props.openDeleteReplyPrompt &&
                            props.openDeleteReplyPrompt(context, reply)
                          }
                          className="btn btn-link"
                        >
                          <i className="far fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div style={{ color: "#9D9D9D", fontSize: 13 }}>
                      <p className="mb-0">{reply.message}</p>
                    </div>
                  </div>
                );
              })}
            {context.replies && context.replies.length > 3 ? (
              <div className="d-flex justify-content-center">
                {rowsToDisplay > 3 ? (
                  <button
                    style={{
                      color: "#B3B3B3",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                    onClick={() => setRowsToDisplay(3)}
                    className="btn btn-link"
                  >
                    Hide replies
                  </button>
                ) : (
                  <button
                    style={{
                      color: "#B3B3B3",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                    onClick={() => setRowsToDisplay(context.replies.length)}
                    className="btn btn-link"
                  >
                    See more replies
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

type PropType = {
  resType: string;
  resId: string;
};

export default class DiscussionView extends React.Component<
  ViewComponentPropType<DiscussionModule> & PropType,
  any
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isRefreshing: false,
      isModalOpen: false,
      isModalBusy: false,
      idToUpdate: null,
      replyId: null,
      displayName: "",
      message: "",
      isAdministrator: true,
      isVisible: true,
      comments: [],
      isEditMode: false,
      isReplyEditMode: false,
      isreplyModalOpen: false,
      replyMessage: "",
      isDeleteModalOpen: false,
      isPinned: false,
      educators: [],
      selectedEducator: ""
    };
  }

  openModal = () =>
    this.setState({
      isModalOpen: true,
      isEditMode: false,
      idToUpdate: "null",
      displayName: "Lecturer",
      isAdministrator: true,
      isVisible: true,
      message: "",
      isModalBusy: false,
      isReplyEditMode: false,
      userMobileNumber: "",
    });
  editModal = (comment: Comment) =>
    this.setState({
      isModalOpen: true,
      isEditMode: true,
      isVisible: comment.isVisible === true,
      idToUpdate: (comment as any)._id,
      displayName: comment.displayName,
      isAdministrator: comment.isAdministrator,
      message: comment.message,
      isModalBusy: false,
      isReplyEditMode: false,
      isPinned: comment.isPinned,
    });

  editReplyModal = (comment: Comment, reply: Comment) =>
    this.setState({
      isModalOpen: true,
      idToUpdate: (comment as any)._id,
      displayName: reply.displayName,
      isAdministrator: reply.isAdministrator,
      message: reply.message,
      replyId: (reply as any)._id,
      isModalBusy: false,
      isReplyEditMode: true,
      isEditMode: false,
    });

  closeModal = () => this.setState({ isModalOpen: false });
  closeReplyModal = () => {
    this.setState({
      isreplyModalOpen: false,
    });
  };
  openReplyModal = (comment: Comment) =>
    this.setState({
      isreplyModalOpen: true,
      idToUpdate: (comment as any)._id,
      displayName: "Lecturer",
      isAdministrator: true,
      isVisible: true,
      isModalBusy: false,
      userMobileNumber: comment.user.phoneNumber,
    });

  openDeletePrompt = (id: string) =>
    this.setState({
      isDeleteModalOpen: true,
      idToUpdate: id,
      isModalBusy: false,
      isReplyEditMode: false,
    });
  openDeleteReplyPrompt = (comment: Comment, reply: Comment) =>
    this.setState({
      isDeleteModalOpen: true,
      idToUpdate: (comment as any)._id,
      replyId: (reply as any)._id,
      isModalBusy: false,
      isReplyEditMode: true,
    });

  closeDeletePrompt = () => this.setState({ isDeleteModalOpen: false });

  sendNotification = (reply: any) => {
    app.modules.Composer.services
      .sendNotification({
        body: reply.body,
        clickAction: "open_lecture",
        groupTargets: ["lakshya-students", "purchased-users"],
        imageUrl:
          "https://lakshya-app-public-assets.s3.ap-south-1.amazonaws.com/lakshya.png",
        resourceID: reply.resourceID,
        resourceType: reply.resourceType,
        targetPhoneNumber: reply.targetPhoneNumber,
        targetType: "specific-user",
        title: reply.title,
      })
      .then((value) => {
        this.props.module.showMessage(
          "Notification sent successfully",
          "Process Complete",
          true
        );
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          __errorMessage: err.message || "Something went wrong",
        });
      });
  };

  postReply = () => {
    const { resId, resType } = this.props;
    this.setState({
      isModalBusy: true,
    });
    this.props.module.services
      .postReplyAsAdmin(
        resType,
        resId,
        this.state.replyMessage,
        this.state.idToUpdate,
        this.state.displayName,
        this.state.isAdministrator
      )
      .then((comment: any) => {
        this.sendNotification({
          title: this.state.displayName + " - replied to you",
          resourceID: resId,
          resourceType: resType,
          targetPhoneNumber: this.state.userMobileNumber,
          body: this.state.replyMessage,
        });
        this.closeModal();
        this.setState({
          replyMessage: "",
          isreplyModalOpen: false,
          isModalBusy: false,
        });
        this.getComments();
      })
      .catch((err) => {
        this.setState(
          {
            isModalBusy: false,
          },
          () =>
            this.props.module.showError(
              "Failed",
              err.message || "Unknown error",
              true
            )
        );
      });
  };

  postComment = () => {
    const { resId, resType } = this.props;
    this.setState(
      {
        isModalBusy: true,
      },
      () =>
        setTimeout(() => {
          if (this.state.isReplyEditMode === true) {
            this.props.module.services
              .updateReplyAsAdmin(
                this.state.idToUpdate,
                this.state.replyId,
                this.state.message,
                this.state.selectedEducator,
                this.state.isAdministrator
              )
              .then((comment: any) => {
                this.closeModal();
                this.setState({
                  replyMessage: "",
                  isreplyModalOpen: false,
                  isModalBusy: false,
                });
                this.getComments();
              })
              .catch((err) => {
                this.setState(
                  {
                    isModalBusy: false,
                  },
                  () =>
                    this.props.module.showError(
                      "Failed",
                      err.message || "Unknown error",
                      true
                    )
                );
              });
          } else {
            if (this.state.isEditMode === true) {
              // Update changes
              this.props.module.services
                .updateMessageAsAdmin(this.state.idToUpdate, {
                  displayName: this.state.selectedEducator,
                  message: this.state.message,
                  isAdministrator: this.state.isAdministrator,
                  resourceType: resType,
                  resourceId: resId,
                  isVisible: this.state.isVisible,
                  isPinned: this.state.isPinned,
                })
                .then((comment: any) => {
                  this.closeModal();
                  this.setState({
                    comments: this.state.comments.map((_comment: any) => {
                      if (this.state.idToUpdate === _comment._id) {
                        return comment;
                      }
                      return _comment;
                    }),
                  });
                })
                .catch((err) => {
                  this.setState(
                    {
                      isModalBusy: false,
                    },
                    () =>
                      this.props.module.showError(
                        "Failed",
                        err.message || "Unknown error",
                        true
                      )
                  );
                });
            } else {
              this.props.module.services
                .postMessageAsAdmin({
                  displayName: this.state.selectedEducator,
                  message: this.state.message,
                  isAdministrator: this.state.isAdministrator,
                  resourceType: resType,
                  resourceId: resId,
                  isVisible: this.state.isVisible,
                })
                .then((comment: any) => {
                  this.closeModal();
                  this.setState({
                    comments: [comment, ...this.state.comments],
                  });
                })
                .catch((err) => {
                  this.setState(
                    {
                      isModalBusy: false,
                    },
                    () =>
                      this.props.module.showError(
                        "Failed",
                        err.message || "Unknown error",
                        true
                      )
                  );
                });
            }
          }
        })
    );
  };

  getComments = () => {
    const { resId, resType } = this.props;
    this.setState(
      {
        isRefreshing: true,
      },
      () =>
        setTimeout(() => {
          this.props.module.services
            .getMessages(resType, resId)
            .then((comments: any) => {
              this.closeModal();
              this.setState({
                isRefreshing: false,
                comments: comments,
              });
            })
            .catch((err) => {
              setTimeout(() => {
                this.getComments();
              }, 100);
            });
        })
    );
  };
  getAllEducators = () => {
    this.props.module.services
      .getAllEducators()
      .then((educators: any) => {
        this.setState({
          educators: educators,
        });
      })
      .catch((err) => {
        setTimeout(() => {
          this.getComments();
        }, 100);
      });
  };

  deleteComment = () => {
    this.setState(
      {
        isModalBusy: true,
      },
      () =>
        setTimeout(() => {
          if (this.state.isReplyEditMode === true) {
            this.props.module.services
              .deleteReply(this.state.idToUpdate, this.state.replyId)
              .then(() => {
                this.closeDeletePrompt();
                this.getComments();
                this.setState({
                  isModalBusy: false,
                });
              })
              .catch((err) => {
                this.setState(
                  {
                    isModalBusy: false,
                  },
                  () =>
                    this.props.module.showError(
                      "Failed",
                      err.message || "Unknown error",
                      true
                    )
                );
              });
          } else {
            this.props.module.services
              .deleteMessage(this.state.idToUpdate)
              .then(() => {
                this.closeDeletePrompt();
                this.setState({
                  comments: this.state.comments.filter((_comment: any) => {
                    if (this.state.idToUpdate === _comment._id) {
                      return false;
                    }
                    return true;
                  }),
                });
              })
              .catch((err) => {
                this.setState(
                  {
                    isModalBusy: false,
                  },
                  () =>
                    this.props.module.showError(
                      "Failed",
                      err.message || "Unknown error",
                      true
                    )
                );
              });
          }
        })
    );
  };

  componentDidMount() {
    this.getComments();
    this.getAllEducators()
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-12 text-right">
              <button
                disabled={this.state.isRefreshing}
                onClick={this.getComments}
                className="btn btn-secondary mr-3"
              >
                {this.state.isRefreshing === true ? (
                  <i className="fas fa-sync fa-spin" />
                ) : (
                  <i className="fas fa-sync" />
                )}
              </button>
              <button onClick={this.openModal} className="btn btn-success">
                Write a comment
              </button>
            </div>
            <div className="col-12 mt-4">
              {this.state.comments.map((comment: Comment, index: number) => {
                return (
                  <CommentItem
                    key={(comment as any)._id}
                    editReplyModal={this.editReplyModal}
                    openDeleteReplyPrompt={this.openDeleteReplyPrompt}
                    openReplyModal={() => this.openReplyModal(comment)}
                    context={comment}
                    onEdit={(c: any) => this.editModal(c)}
                    onDelete={(id: string) => {
                      this.openDeletePrompt(id);
                    }}
                    isPublished={comment.isVisible}
                  />
                );
              })}
              {this.state.comments.length < 1 ? (
                <div className="alert alert-success" role="alert">
                  <h4 className="alert-heading">No Comments!</h4>
                  <hr />
                  <p className="mb-0">
                    Click the above button to create a new comment
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* @ts-ignore */}

        <Modal
          isOpen={this.state.isModalOpen}
          keyboard={false}
          backdrop="static"
          toggle={this.closeModal}
        >
          {/* @ts-ignore */}

          <ModalHeader
            toggle={this.state.isModalBusy ? undefined : this.closeModal}
          >
            {this.state.isReplyEditMode === true
              ? "Edit Reply"
              : this.state.isEditMode === true
                ? "Edit Comment"
                : "New Comment"}
            {/* {this.state.isEditMode === true ? "Edit" : "New"} Comment */}
          </ModalHeader>
          {/* @ts-ignore */}

          <ModalBody>
            <form>
              <div className="form-check mb-3">
                <input
                  disabled={this.state.isModalBusy}
                  type="checkbox"
                  checked={this.state.isAdministrator}
                  onChange={(e) =>
                    this.setState({ isAdministrator: e.target.checked })
                  }
                  className="form-check-input"
                  id="isAdministratorCheck"
                />
                <label
                  className="form-check-label"
                  htmlFor="isAdministratorCheck"
                >
                  Is Administrator
                </label>
              </div>
              <div className="form-group">
                <label>Choose Educator</label>
                <select
                  className="custom-select"
                  value={this.state.selectedEducator}
                  onChange={(e) => this.setState({
                    selectedEducator: e.target.value
                  })}
                >
                  <option value="">Choose Educator</option>
                  {this.state.educators.map((educator: any) => (
                    <option key={educator.id} value={educator.name}>
                      {educator.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="form-group">
                <label htmlFor="nameField">Display Name (Admin Only)</label>
                <input
                  disabled={this.state.isModalBusy}
                  className="form-control"
                  value={this.state.displayName}
                  onChange={(e) =>
                    this.setState({ displayName: e.target.value })
                  }
                  id="nameField"
                  aria-describedby="nameFieldHelp"
                  placeholder="e.g. Lecturer / John Doe"
                />
                <small id="nameFieldHelp" className="form-text text-muted">
                  To be shown as the user
                </small>
              </div> */}
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  disabled={this.state.isModalBusy}
                  value={this.state.message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                  className="form-control"
                  id="message"
                  rows={4}
                ></textarea>
              </div>
              {this.state.isEditMode === true ? (
                <>
                  <div className="form-check mb-3">
                    <input
                      disabled={this.state.isModalBusy}
                      type="checkbox"
                      checked={this.state.isVisible === true}
                      onChange={(e) =>
                        this.setState({ isVisible: e.target.checked })
                      }
                      className="form-check-input"
                      id="isVisiblityCheck"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isVisiblityCheck"
                    >
                      Is Visible (To Public)
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      disabled={this.state.isModalBusy}
                      type="checkbox"
                      checked={this.state.isPinned}
                      onChange={(e) =>
                        this.setState({ isPinned: e.target.checked })
                      }
                      className="form-check-input"
                      id="isPinnedCheck"
                    />
                    <label className="form-check-label" htmlFor="isPinnedCheck">
                      Is Pinned
                    </label>
                  </div>
                </>
              ) : null}
            </form>
          </ModalBody>
          {/* @ts-ignore */}

          <ModalFooter>
            {/* @ts-ignore */}

            <Button
              disabled={this.state.isModalBusy}
              color="primary"
              onClick={this.postComment}
            >
              {this.state.isModalBusy === true ? (
                <>
                  <i className="fas fa-circle-notch fa-spin"></i>
                  {" Processing..."}
                </>
              ) : this.state.isReplyEditMode === true ? (
                "Update Reply"
              ) : this.state.isEditMode === true ? (
                "Update Comment"
              ) : (
                "Post Comment"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        {/* @ts-ignore */}

        <Modal
          isOpen={this.state.isDeleteModalOpen}
          toggle={this.closeDeletePrompt}
        >
          {/* @ts-ignore */}

          <ModalHeader toggle={this.closeDeletePrompt}>
            {this.state.isReplyEditMode === true
              ? "Delete Reply"
              : "Delete Comment"}
          </ModalHeader>
          {/* @ts-ignore */}

          <ModalBody>
            <div className="alert alert-warning">
              <p className="m-0">
                {this.state.isReplyEditMode === true
                  ? " Are you sure you want to delete this reply?"
                  : " Are you sure you want to delete this comment?"}
              </p>
            </div>
          </ModalBody>
          {/* @ts-ignore */}

          <ModalFooter>
            {/* @ts-ignore */}

            <Button
              disabled={this.state.isModalBusy}
              color="secondary"
              onClick={this.closeDeletePrompt}
            >
              Cancel
            </Button>
            {/* @ts-ignore */}

            <Button
              disabled={this.state.isModalBusy}
              color="danger"
              onClick={this.deleteComment}
            >
              {this.state.isModalBusy === true ? (
                <>
                  <i className="fas fa-circle-notch fa-spin" />
                  {" Deleting..."}
                </>
              ) : (
                "Yes, Delete"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        {/* @ts-ignore */}

        <Modal
          isOpen={this.state.isreplyModalOpen}
          keyboard={false}
          backdrop="static"
          toggle={this.closeReplyModal}
        >
          {/* @ts-ignore */}

          <ModalHeader toggle={this.closeReplyModal}>New Reply</ModalHeader>
          {/* @ts-ignore */}

          <ModalBody>
            <form>
              <div className="form-check mb-3">
                <input
                  disabled={this.state.isModalBusy}
                  type="checkbox"
                  checked={this.state.isAdministrator}
                  onChange={(e) =>
                    this.setState({ isAdministrator: e.target.checked })
                  }
                  className="form-check-input"
                  id="isAdministratorCheck"
                />
                <label
                  className="form-check-label"
                  htmlFor="isAdministratorCheck"
                >
                  Is Administrator
                </label>
              </div>
              <div className="form-group">
                <label>Choose Educator</label>
                <select
                  className="custom-select"
                  value={this.state.displayName}
                  onChange={(e) => this.setState({
                    displayName: e.target.value
                  })}
                >
                  <option value="">Choose Educator</option>
                  {this.state.educators.map((educator: any) => (
                    <option key={educator.id} value={educator.name}>
                      {educator.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="form-group">
                <label htmlFor="nameField">Display Name (Admin Only)</label>
                <input
                  disabled={this.state.isModalBusy}
                  className="form-control"
                  value={this.state.displayName}
                  onChange={(e) =>
                    this.setState({ displayName: e.target.value })
                  }
                  id="nameField"
                  aria-describedby="nameFieldHelp"
                  placeholder="e.g. Lecturer / John Doe"
                />
                <small id="nameFieldHelp" className="form-text text-muted">
                  To be shown as the user
                </small>
              </div> */}
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  value={this.state.replyMessage}
                  onChange={(e) =>
                    this.setState({ replyMessage: e.target.value })
                  }
                  className="form-control"
                  id="message"
                  rows={4}
                ></textarea>
              </div>

              {/* <div className="form-check mb-3">
                                <input disabled={this.state.isModalBusy} type="checkbox" checked={this.state.isVisible === true} onChange={(e) => this.setState({ isVisible: e.target.checked })} className="form-check-input" id="isVisiblityCheck" />
                                <label className="form-check-label" htmlFor="isVisiblityCheck">Is Visible (To Public)</label>
                            </div> */}
            </form>
          </ModalBody>
          {/* @ts-ignore */}

          <ModalFooter>
            {/* @ts-ignore */}

            <Button
              disabled={this.state.isModalBusy}
              color="primary"
              onClick={this.postReply}
            >
              {this.state.isModalBusy === true ? (
                <>
                  <i className="fas fa-circle-notch fa-spin"></i>
                  {" Processing..."}
                </>
              ) : (
                "Post Reply"
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
