import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default (props: any) => {
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();
  const onIntiateRecovery = props.onIntiateRecovery;
  const setStep = props.setStep;
  const recoveryOption = props.recoveryOption;
  const setSelectedOption = props.setSelectedOption;
  const selectedOption = props.selectedOption;

  const renderEmailOption = (item: any, index: number) => {
    return (
      <div key={index}>
        <div className="form-check pb-3">
          <input
            ref={register({ required: true })}
            className="form-check-input"
            type="radio"
            name="selectedOption"
            id={`selectedOption-${index}`}
            value={index}
            onChange={() => setSelectedOption(index)}
          />
          <label
            className="form-check-label"
            htmlFor={`selectedOption-${index}`}
          >
            Send verification email to {item.value}
          </label>
        </div>
        {selectedOption === index ? (
          <div className="px-4 pt-3">
            <span className="text-secondary" style={{ fontSize: 14 }}>
              {t("To verify this is your email, complete the hidden part")}
            </span>
            <div className="form-group pt-3">
              <input
                ref={register({ required: true })}
                name="challenge"
                type="email"
                id="recovery-email-input"
                className="form-control"
                placeholder="Enter the email"
              />
              {errors.challenge && (
                <p className="text-danger" id="recovey-email-error">
                  {t("Email is Required")}
                </p>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  };
  const renderMobileNumberOption = (item: any, index: number) => {
    return (
      <div key={index}>
        <div className="form-check pb-3">
          <input
            ref={register({ required: true })}
            className="form-check-input"
            type="radio"
            name="selectedOption"
            id={`selectedOption-${index}`}
            value={index}
            onChange={() => setSelectedOption(index)}
          />
          <label
            className="form-check-label"
            htmlFor={`selectedOption-${index}`}
          >
            Send verification message to {item.value}
          </label>
        </div>
        {selectedOption === index ? (
          <div className="px-4 pt-3">
            <span className="text-secondary" style={{ fontSize: 14 }}>
              {t("To verify this is your phone, complete the hidden part")}
            </span>
            <div className="form-group pt-3">
              <input
                ref={register({ required: true })}
                name="challenge"
                type="number"
                id="recovery-mobile-number-input"
                className="form-control"
                placeholder="Enter the number"
              />
              {errors.challenge && (
                <p className="text-danger" id="recovey-mobile-number-error">
                  {t("Mobile Number is Required")}
                </p>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div style={{ flex: 1 }} className="pt-5 px-2 px-sm-4">
        <div className="text-center">
          <h6 className="text-secondary">{t("Step 2 - Account Recovery")}</h6>
          <h5>{t("Choose Recovery Method")}</h5>
          <hr />
        </div>
        <form onSubmit={handleSubmit(onIntiateRecovery)}>
          {recoveryOption.map((item: any, index: number) => {
            if (item.type === "email") {
              return renderEmailOption(item, index);
            } else {
              return renderMobileNumberOption(item, index);
            }
          })}
          <div className="form-check pb-3">
            <input
              ref={register}
              className="form-check-input"
              type="radio"
              name="noAccess"
              value="true"
              id="no-access"
            />
            <label className="form-check-label" htmlFor="no-access">
              {t("I don't have any of these")}
            </label>
          </div>
          <div className="text-right pt-3">
            <button
              type="button"
              onClick={() => setStep("accountIdentification")}
              className="btn btn-secondary px-4 mr-2"
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              id="recovery-channel-verification"
              className="btn btn-info px-3 px-sm-4"
            >
              {t("Send Code")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
