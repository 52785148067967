import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import LakshyaStudentsModule from "..";
import ReactTable, { Filter } from "react-table";
import { resolveAssetUrl } from "../../../shared/utils";

const defaultImage = require("../assets/images/default-image.png");

type Mode = "create" | "review";

type StateType = {
  modalMode: Mode;
  isModalOpen: boolean;
  idToUpdate: string;
  resourceId: string;
  resourceType: "course" | "exam" | "lecture" | "rankfile";
  phoneNumber: string;
  amount: number;
  subscriptionTerm:
  | "not-selected"
  | "12-months"
  | "6-months"
  | "4-months"
  | "2-months"
  | "1-month"
  | "15-days"
  | "10-days"
  | "8-days"
  | "6-days"
  | "5-days"
  | "4-days"
  | "3-days"
  | "2-days"
  | "1-days";
  isModalBusy: boolean;
  isErrorModalOpen: boolean;
  errorMessage: string;
  warnings: string[];
  errorModalHeading: string;
  errorModalMessage: string;
  isImportModalOpen: boolean;
  resourcePreview: any;
  userPreview: any;

  orderIdToDelete: string;
  orderDeleting: boolean;
};

const TableFilter = (propertyKey: string) => (
  filter: Filter,
  row: any,
  column: any
): any => {
  if (row._original[propertyKey]) {
    try {
      return (
        row._original[propertyKey]
          .trim()
          .toLowerCase()
          .indexOf(filter.value.trim().toLowerCase()) > -1
      );
    } catch (e) {
      console.error(e);
    }
  }
  return false;
};

class StudentManagerView extends React.Component<
  ViewComponentPropType<LakshyaStudentsModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      modalMode: "create",
      isModalOpen: false,
      idToUpdate: null,
      resourceType: "course",
      resourceId: "",
      phoneNumber: "",
      amount: 0,
      subscriptionTerm: "not-selected",
      isModalBusy: false,
      isErrorModalOpen: false,
      errorMessage: null,
      warnings: [],
      errorModalHeading: "",
      errorModalMessage: "",
      isImportModalOpen: false,

      resourcePreview: null,
      userPreview: null,

      orderIdToDelete: null,
      orderDeleting: false,
    };
  }

  componentDidMount() {
    this.props.module.controller.refreshData(false);
  }

  closeEditorModal = () =>
    this.setState({
      isModalOpen: false,
      idToUpdate: null,
      phoneNumber: "",
      isModalBusy: false,
    });
  closeErrorModal = () =>
    this.setState({
      isErrorModalOpen: false,
      errorMessage: null,
      warnings: [],
    });
  closeImportModal = () => this.setState({ isImportModalOpen: false });

  openModal = (data: any, mode: Mode) => {
    this.setState({
      isModalBusy: false,
      isModalOpen: true,
      idToUpdate: data._id || null,
      resourceId: data.name || "",
      phoneNumber: data.phoneNumber || "",
      amount: 0,
      subscriptionTerm: "not-selected",
      modalMode: mode,
    });
  };

  review = () => {
    let warnings: string[] = [];
    if (!this.state.phoneNumber || this.state.phoneNumber === "") {
      warnings.push("Phone number is required");
    }
    if (!this.state.resourceId || this.state.resourceId === "") {
      warnings.push("Resource ID is required");
    }
    if (
      !this.state.subscriptionTerm ||
      this.state.subscriptionTerm === "not-selected"
    ) {
      warnings.push("Please choose a subscription term");
    }

    if (warnings.length > 0) {
      return this.openErrorModal("", warnings);
    }

    // Load review item
    this.props.module.services
      .getOrderReviewInfo(
        this.state.resourceId,
        this.state.resourceType,
        this.state.phoneNumber,
        this.state.amount,
        this.state.subscriptionTerm
      )
      .then((response: any) => {
        this.setState({
          userPreview: response.user,
          resourcePreview: response.resource,
          modalMode: "review",
          isModalBusy: false,
          // isModalOpen: false
        });
      })
      .catch((err) => {
        return this.openErrorModal(err.message);
      });
  };

  confirm = () => {
    this.props.module.services
      .createManualOrder(
        this.state.resourceId,
        this.state.resourceType,
        this.state.phoneNumber,
        this.state.amount,
        this.state.subscriptionTerm
      )
      .then((response: any) => {
        this.props.module.controller.unshiftRows([response]);
        this.closeEditorModal();
      })
      .catch((err) => {
        return this.openErrorModal(err.message);
      });
  };

  openErrorModal = (msgs: string = null, warnings: string[] = []) =>
    this.setState({
      isModalBusy: false,
      isErrorModalOpen: true,
      errorMessage: msgs,
      warnings,
      errorModalMessage:
        "Process failed, you may need to resolve below warnings before retrying",
      errorModalHeading: "Warnings!",
    });

  handleSaveBtnClick = () => {
    const { modalMode } = this.state;
    this.setState(
      {
        isModalBusy: true,
      },
      () =>
        setTimeout(() => {
          if (modalMode === "review") {
            this.confirm();
          } else {
            this.review();
          }
        }, 400)
    );
  };

  openManualOrder = () => {
    this.setState({
      isModalOpen: true,
      modalMode: "create",
      phoneNumber: "",
    });
  };

  deleteOrder = () => {
    this.setState(
      {
        orderDeleting: true,
      },
      () => {
        this.props.module.services
          .deleteManualOrder(this.state.orderIdToDelete)
          .then((response) => {
            this.props.module.controller.deleteRows([
              this.state.orderIdToDelete,
            ]);
            this.setState({
              orderIdToDelete: null,
            });
          })
          .catch((err) => {
            return this.openErrorModal(err.message);
          });
      }
    );
  };

  render() {
    const {
      isModalOpen,
      modalMode,
      resourceId,
      resourceType,
      phoneNumber,
      amount,
      subscriptionTerm,
      isModalBusy,
    } = this.state;
    const { isRefreshing, data } = this.props.context;
    return (
      <>
        {/* @ts-ignore */}
        <Helmet>
          <title>Order Manager - Lakshya eLearning System</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
                <h4>Order Manager {">"} Completed</h4>
                <div>
                  <button
                    disabled={isRefreshing}
                    onClick={() => {
                      this.props.module.controller.refreshData(true);
                    }}
                    className="btn btn-secondary ml-3"
                  >
                    {isRefreshing === true ? (
                      <i className="fas fa-spin fa-sync-alt"></i>
                    ) : (
                      <i className="fas fa-sync-alt"></i>
                    )}
                  </button>
                  <button
                    onClick={this.openManualOrder}
                    className="btn btn-primary ml-3"
                  >
                    Create Manual Order
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12">
              <ReactTable
                loading={isRefreshing}
                data={data}
                minRows={3}
                defaultPageSize={100}
                columns={[
                  // {
                  //   Header: "",
                  //   accessor: "_id",
                  //   className: 'd-flex align-items-center',
                  //   width: 50,
                  //   Cell: (row) => {
                  //     return (
                  //       <input type="checkbox" checked={this.props.module.controller.isChecked(row.value)} onChange={(e) => {
                  //         if (e.target.checked === true) {
                  //           this.props.module.controller.checkRow(row.value)
                  //         } else {
                  //           this.props.module.controller.uncheckRow(row.value)
                  //         }
                  //       }} />
                  //     );
                  //   },
                  // },
                  {
                    Header: "Date / Time",
                    accessor: "createdAtDisplay",
                    filterable: true,
                    filterMethod: TableFilter("createdAtDisplay"),
                    className: "d-flex align-items-center",
                  },
                  {
                    Header: "Mode",
                    accessor: "createdBy",
                    filterable: true,
                    filterMethod: TableFilter("createdBy"),
                    className: "d-flex align-items-center",
                    Cell: (row) => {
                      return (
                        <span
                          className={
                            row.value === "admin"
                              ? "text-success"
                              : "text-muted"
                          }
                        >
                          {row.value}
                        </span>
                      );
                    },
                  },
                  {
                    Header: "Coupon Code",
                    accessor: "discountCouponCode",
                    filterable: true,
                    filterMethod: TableFilter("discountCouponCode"),
                    className: "d-flex align-items-center",
                    Cell: (row) => {
                      return (
                        <span>{row.value ? row.value : "No coupon used"}</span>
                      );
                    },
                  },
                  {
                    Header: "Customer Info",
                    accessor: "userInfo",
                    filterable: true,
                    filterMethod: TableFilter("userInfo_phoneNumber"),
                    className: "d-flex align-items-center",
                    Cell: (row) => {
                      const user = row.value;
                      if (user) {
                        return (
                          <span>
                            <span className="text-success">
                              {user.phoneNumber || user.emailAddress}
                            </span>
                            <br />
                            {user.name ? <span>{user.name}</span> : null}
                          </span>
                        );
                      }

                      return <span className="text-muted">N/A</span>;
                    },
                  },
                  {
                    Header: "Subscription",
                    accessor: "subscriptionId",
                    className: "d-flex align-items-center",
                    // filterable: true,
                    filterMethod: TableFilter("subscriptionId"),
                    Cell: (row) => {
                      const subscriptionId: string = row.value;
                      return (
                        <span>
                          {subscriptionId ? (
                            <>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`/admin/subscriptions/editor/${subscriptionId}`}
                                className="btn-link"
                              >
                                {row.original.title}
                              </a>
                              <br />
                            </>
                          ) : (
                            <>
                              <b>{row.original.title}</b>
                              <br />
                            </>
                          )}
                          <span className="text-primary">
                            TERM: <b>INR {row.original.subscriptionTerm}</b>
                          </span>
                          <br />
                          <span className="text-primary">
                            NETT AMOUNT: <b>INR {row.original.netAmount}</b>
                          </span>
                        </span>
                      );
                    },
                  },
                  {
                    Header: "Resource",
                    accessor: "resourceType",
                    className: "d-flex align-items-center",
                    // filterable: true,
                    filterMethod: TableFilter("resourceType"),
                    Cell: (row) => {
                      let url: string = "/";

                      switch (row.value) {
                        case "course": {
                          url = `/admin/courses/editor/${row.original.resourceId}`;
                          break;
                        }
                        case "exam": {
                          url = `/admin/exams/editor/${row.original.resourceId}`;
                          break;
                        }
                        case "lecture": {
                          url = `/admin/videos/lectures/${row.original.resourceId}`;
                          break;
                        }
                        case "rankfile": {
                          url = `/admin/rank-files/editor/${row.original.resourceId}`;
                          break;
                        }
                      }

                      return (
                        <span>
                          <span>{row.value}</span>
                          <br />
                          <a
                            className="btn btn-link"
                            href={url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {row.original.resourceId}
                          </a>
                        </span>
                      );
                    },
                  },
                  {
                    Header: "RazorPay ID",
                    accessor: "razorPayId",
                    // filterable: true,
                    filterMethod: TableFilter("razorPayId"),
                    className: "d-flex align-items-center",
                  },
                  {
                    Header: "Status",
                    accessor: "status",
                    // filterable: true,
                    filterMethod: TableFilter("status"),
                    className: "d-flex align-items-center",
                  },
                  {
                    Header: "Actions",
                    accessor: "_id",
                    className: "d-flex align-items-center justify-content-end",
                    Cell: (row) => {
                      // if (row.original.createdBy === "admin") {
                      //   return (
                      //     <button
                      //       onClick={() =>
                      //         this.setState({
                      //           orderIdToDelete: row.value,
                      //           orderDeleting: false,
                      //         })
                      //       }
                      //       className="btn btn-sm btn-danger"
                      //     >
                      //       <i className="fas fa-trash"></i>
                      //     </button>
                      //   );
                      // }

                      // return null;
                      return (
                        <button
                          onClick={() =>
                            this.setState({
                              orderIdToDelete: row.value,
                              orderDeleting: false,
                            })
                          }
                          className="btn btn-sm btn-danger"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {/* @ts-ignore */}
        <Modal
          isOpen={isModalOpen}
          backdrop="static"
          keyboard={false}
          toggle={this.closeEditorModal}
        >
          {/* @ts-ignore */}
          <ModalHeader
            toggle={!isModalBusy ? this.closeEditorModal : undefined}
          >
            {modalMode === "create" ? "Create Order" : "Review & Confirm"}
          </ModalHeader>
          {this.state.modalMode === "create" ? (
            //@ts-ignore
            <ModalBody>
              <div className="form-group">
                <label htmlFor="resType">Resource Type</label>
                <select
                  disabled={isModalBusy}
                  className="form-control"
                  value={resourceType}
                  onChange={(e) =>
                    this.setState({ resourceType: e.target.value as any })
                  }
                  id="resType"
                >
                  <option value="course">Course</option>
                  <option value="exam">Exam</option>
                  <option value="lecture">Lecture</option>
                  <option value="rankfile">Rankfile</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="resId">Resource ID</label>
                <input
                  disabled={isModalBusy}
                  type="text"
                  className="form-control"
                  id="resId"
                  value={resourceId}
                  onChange={(e) =>
                    this.setState({ resourceId: e.target.value })
                  }
                  placeholder="(Required)"
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneField">Phone Number</label>
                <input
                  autoFocus
                  disabled={isModalBusy}
                  type="text"
                  className="form-control"
                  id="phoneField"
                  value={phoneNumber}
                  onChange={(e) =>
                    this.setState({ phoneNumber: e.target.value })
                  }
                  placeholder="10 digit phone number (Required)"
                />
              </div>
              <div className="form-group">
                <label htmlFor="amountField">Amount (Optional)</label>
                <input
                  disabled={isModalBusy}
                  type="number"
                  step={0.01}
                  className="form-control"
                  id="amountField"
                  value={amount}
                  onChange={(e) => {
                    try {
                      this.setState({ amount: parseFloat(e.target.value) });
                    } catch (e) { }
                  }}
                  placeholder="0.00"
                />
              </div>
              <div className="form-group">
                <label htmlFor="subscriptionTerm">Resource Period</label>
                <select
                  disabled={isModalBusy}
                  className="form-control"
                  value={subscriptionTerm}
                  onChange={(e) =>
                    this.setState({ subscriptionTerm: e.target.value as any })
                  }
                  id="subscriptionTerm"
                >
                  <option value="not-selected">Not Selected</option>
                  <option value="12-months">12 Months</option>
                  <option value="6-months">6 Months</option>
                  <option value="4-months">4 Months</option>
                  <option value="3-months">3 Months</option>
                  <option value="2-months">2 Months</option>
                  <option value="45-days">45 Days</option>
                  <option value="1-month">1 Month</option>
                  <option value="15-days">15 Days</option>

                  <option value="10-days">10 Days</option>
                  <option value="8-days">8 Days</option>
                  <option value="6-days">6 Days</option>
                  <option value="5-days">5 Days</option>
                  <option value="4-days">4 Days</option>
                  <option value="3-days">3 Days</option>
                  <option value="2-days">2 Days</option>
                  <option value="1-days">1 Day</option>
                </select>
              </div>
            </ModalBody>
          ) : (
            //@ts-ignore
            <ModalBody>
              <table className="table table-striped table-sm">
                <tbody>
                  <tr>
                    <td
                      style={{ width: 100, verticalAlign: "middle" }}
                      rowSpan={8}
                    >
                      <img
                        width="100"
                        src={
                          this.state.resourcePreview
                            ? resolveAssetUrl(
                              this.state.resourcePreview.bannerImageId,
                              "public"
                            )
                            : defaultImage
                        }
                        alt="Notification banner"
                      />
                    </td>
                    <td>
                      <small>Properties</small>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Resource Type: <b>{this.state.resourceType}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ID: <b>{this.state.resourceId}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Title:{" "}
                      <b>
                        {this.state.resourcePreview
                          ? this.state.resourcePreview.title
                          : "N/A"}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small>Student Details</small>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Name:{" "}
                      <b>
                        {this.state.userPreview
                          ? this.state.userPreview.name
                          : "N/A"}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Phone Number:{" "}
                      <b>
                        {this.state.userPreview
                          ? this.state.userPreview.phoneNumber
                          : "N/A"}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Validity: <b>{this.state.subscriptionTerm}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
          )}
          {/* @ts-ignore */}
          <ModalFooter className="justify-content-between">
            <div></div>
            <div>
              {/* @ts-ignore */}
              <Button
                disabled={isModalBusy}
                color="primary"
                onClick={this.handleSaveBtnClick}
              >
                {isModalBusy === true
                  ? "Processing..."
                  : modalMode === "create"
                    ? "Review"
                    : "Confirm"}
              </Button>{" "}
              {modalMode === "review" ? (
                // @ts-ignore
                <Button
                  disabled={isModalBusy}
                  color="secondary"
                  onClick={() => this.setState({ modalMode: "create" })}
                >
                  Go Back
                </Button>
              ) : null}
            </div>
          </ModalFooter>
        </Modal>
        {/* Error Modal */}
        {/* @ts-ignore */}
        <Modal
          isOpen={this.state.isErrorModalOpen}
          toggle={this.closeErrorModal}
        >
          {/* @ts-ignore */}
          <ModalBody>
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">{this.state.errorModalHeading}</h4>
              <p>{this.state.errorModalMessage}</p>
              <hr />
              <ol>
                {this.state.warnings.length > 0 ? (
                  this.state.warnings.map((msgs, i) => {
                    return <li key={i}>{msgs}</li>;
                  })
                ) : (
                  <li>{this.state.errorMessage}</li>
                )}
              </ol>
            </div>
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            <div>
              {/* @ts-ignore */}
              <Button color="secondary" onClick={this.closeErrorModal}>
                Close
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        {/* @ts-ignore */}
        <Modal
          isOpen={this.state.orderIdToDelete !== null}
          toggle={() => this.setState({ orderIdToDelete: null })}
          keyboard={false}
          backdrop="static"
        >
          {/* @ts-ignore */}
          <ModalBody>
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">Delete Confirmation</h4>
              <p>Are you sure about deleting this order?</p>
            </div>
          </ModalBody>
          {/* @ts-ignore */}
          <ModalFooter>
            <div>
              {/* @ts-ignore */}
              <Button
                disabled={this.state.orderDeleting === true}
                color="primary"
                className="mr-2"
                onClick={this.deleteOrder}
              >
                {this.state.orderDeleting === true
                  ? "Deleting..."
                  : "Yes, Delete"}
              </Button>
              {/* @ts-ignore */}
              <Button
                disabled={this.state.orderDeleting === true}
                color="secondary"
                onClick={() => this.setState({ orderIdToDelete: null })}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default StudentManagerView;
