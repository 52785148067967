import React from "react";
import { ViewComponentPropType } from "@skyslit/ark-react";
import { Helmet } from "react-helmet-async";
import DefaultModule from "..";
import ExamListings from "../components/ExamListings";
import { Modal } from "reactstrap";
import { useForm } from "react-hook-form";
// import ExamTemplate from "../components/ExamTemplate";
// import { ExamTemplates } from "lakshya-shared";

type StateType = {
  isAddExamModalOpen: boolean;
  data: [];
  selectedExamTemplate: string;
  errorMessage: string;
  isCopyExamModalOpen: boolean
  isCopyExamLoading: boolean

};
class ExamPage extends React.Component<
  ViewComponentPropType<DefaultModule>,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isAddExamModalOpen: false,
      data: [],
      selectedExamTemplate: "regular",
      errorMessage: "",
      isCopyExamModalOpen: false,
      isCopyExamLoading: false
    };
    this.toggleAddExamModal = this.toggleAddExamModal.bind(this);
    this._handleAddExam = this._handleAddExam.bind(this);
    this.toggleCopyExamModal = this.toggleCopyExamModal.bind(this);
    this._handleCopyExam = this._handleCopyExam.bind(this);

  }

  toggleAddExamModal() {
    this.setState({
      isAddExamModalOpen: !this.state.isAddExamModalOpen,
    });
  }

  toggleCopyExamModal() {
    this.setState({
      isCopyExamModalOpen: !this.state.isCopyExamModalOpen,
    });
  }

  _handleAddExam = (data: any) => {
    if (this.state.selectedExamTemplate === "") {
      this.setState({
        errorMessage: "Please select a template",
      });
      return;
    }
    this.props.module.services
      .addExam(data.title, this.state.selectedExamTemplate)
      .then((response: any) => {
        this.toggleAddExamModal();
        this.props.history.push(`/admin/exams/editor/${response._id}`);
      })
      .catch((e) => {
        this.props.module.showError(
          "Course cannot be added",
          e.message ? e.message : "Network error",
          true
        );
      });
  };

  _handleCopyExam = (data: any) => {
    this.setState({
      isCopyExamLoading: true
    })
    this.props.module.services
      .CopyImagesFromCampus(data.id)
      .then((response: any) => {
        this.toggleAddExamModal();
        this.setState({
          isCopyExamLoading: false
        })
        this.props.history.push(`/admin/exams/editor/${response._id}`);
      })
      .catch((e) => {
        this.setState({
          isCopyExamLoading: false
        })
        this.props.module.showError(
          "Exam cannot be added",
          e.message ? e.message : "Network error",
          true
        );
      });
  };

  render() {
    let currentTab: string = (this.props.match.params as any).templateId;
    currentTab = currentTab ? currentTab : "all";
    return (
      <>
        <>
          {/* @ts-ignore */}
          <Helmet>
            <title>Examination Manager - Lakshya eLearning System</title>
          </Helmet>
          <div className="container-fluid exam-listings-wrapper">
            <div className="row">
              <div className="col-12">
                <div className="sticky-button-wrapper py-4 pr-3 d-flex justify-content-between align-items-center">
                  <h4>Examination Manager</h4>
                  <div>
                    <button
                      onClick={this.toggleCopyExamModal}
                      className="copy-exam-button-wrapper px-5 py-3 mr-3"
                    >
                      Copy Exam
                    </button>
                    <button
                      onClick={this.toggleAddExamModal}
                      className="add-subscription-button-wrapper px-5 py-3"
                    >
                      Create Exam
                    </button>
                  </div>
                </div>
                {/* <div
                  className="btn-group custom-button-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <Link
                    to="/admin/exams/"
                    className={`btn btn-secondary px-4 ${
                      currentTab === "all" ? "selected" : ""
                    }`}
                  >
                    All
                  </Link>
                  {ExamTemplates.map((item, index) => (
                    <Link
                      key={index}
                      to={`/admin/exams/${item.templateId}`}
                      className={`btn btn-secondary px-4 ${
                        currentTab === item.templateId ? "selected" : ""
                      }`}
                    >
                      {item.title}
                    </Link>
                  ))}
                </div> */}
                <ExamListings
                  key={currentTab}
                  currentTab={currentTab}
                  data={this.state.data}
                  module={this.props.module}
                />
              </div>
            </div>
          </div>
          {/* @ts-ignore */}
          <Modal
            toggle={this.toggleAddExamModal}
            isOpen={this.state.isAddExamModalOpen}
            className="add-exam-modal-wrapper"
          >
            <div className="modal-header">
              <div>
                <h5 className="m-0">Create New Examination</h5>
              </div>
              <button
                type="button"
                onClick={this.toggleAddExamModal}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* <div className="modal-body">
              <div className="modal-content">
                <div className="px-5">
                  {ExamTemplates.map((context: any, index: number) => (
                    <ExamTemplate
                      selectedExamTemplate={this.state.selectedExamTemplate}
                      currentTemplate={() =>
                        this.setState({
                          selectedExamTemplate: context.templateId,
                        })
                      }
                      key={index}
                      context={context}
                    />
                  ))}
                  <p className="text-danger pt-1">{this.state.errorMessage}</p>
                </div>
              </div>
            </div> */}
            <Form
              _handleAddExam={this._handleAddExam}
              toggleAddExamModal={this.toggleAddExamModal}
            />
          </Modal>

          {/* @ts-ignore */}
          <Modal
            toggle={this.toggleCopyExamModal}
            isOpen={this.state.isCopyExamModalOpen}
            className="add-exam-modal-wrapper"
          >
            <div className="modal-header">
              <div>
                <h5 className="m-0">Copy Examination</h5>
              </div>
              <button
                type="button"
                onClick={this.toggleCopyExamModal}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <CopyExamForm
              _handleCopyExam={this._handleCopyExam}
              isCopyExamLoading={this.state.isCopyExamLoading}
            />
          </Modal>
        </>
      </>
    );
  }
}

function Form(props: any) {
  const { register, handleSubmit, errors } = useForm();
  const _handleAddExam = props._handleAddExam;
  return (
    <form onSubmit={handleSubmit(_handleAddExam)}>
      <div className=" custom-modal-footer px-4 py-4">
        <label>Exam Name</label>
        <div className="input-and-submit-wrapper">
          <div className="flex-fill pr-4">
            <input
              autoFocus={true}
              autoComplete="off"
              name="title"
              ref={register({ required: true })}
              type="text"
              className="form-control form-control-lg"
              placeholder="Give a name for your new examination"
            />
            {errors.title && (
              <small className="text-danger" id="otp-error">
                Please provide a exam title
              </small>
            )}
          </div>
          <div className="flex-shrink-0">
            <button
              type="submit"
              className="btn btn-primary create-exam-wrapper px-4"
            >
              Create Exam
            </button>
          </div>
        </div>
        {/* <button
            type="button"
            onClick={toggleAddExamModal}
            className="btn btn-default"
          >
            Cancel
          </button> */}
      </div>
    </form>
  );
}

function CopyExamForm(props: any) {
  const { register, handleSubmit, errors } = useForm();
  const _handleCopyExam = props._handleCopyExam;
  const isCopyExamLoading = props.isCopyExamLoading
  return (
    <form onSubmit={handleSubmit(_handleCopyExam)}>
      <div className=" custom-modal-footer px-4 py-4">
        <label>Exam Id</label>
        <div className="input-and-submit-wrapper">
          <div className="flex-fill pr-4">
            <input
              autoFocus={true}
              autoComplete="off"
              name="id"
              ref={register({ required: true })}
              type="text"
              className="form-control form-control-lg"
              placeholder="Exam Id"
            />
            {errors.title && (
              <small className="text-danger" id="otp-error">
                Please provide a exam id
              </small>
            )}
          </div>
          <div className="flex-shrink-0">
            <button
              disabled={isCopyExamLoading}
              type="submit"
              className="btn btn-primary create-exam-wrapper px-4"
            >
              {isCopyExamLoading === false ? "Import Exam" : "Importing ..."}

            </button>
          </div>
        </div>
      </div>
    </form>
  );
}


export default ExamPage;
